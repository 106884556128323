/**
 * @flow
 */

"use strict";

const initialState = { tab: "home" };

function navigation(state = initialState, action = null) {
  if (action.type === "SWITCH_TAB") {
    return { ...state, tab: action.tab };
  }
  return state;
}

export default navigation;
