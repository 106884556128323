import React from "react";
import StoreService from "../../../../services/StoreService";
import StoreView from "./StoreView";
import history from "../../../../utils/History";
import Actions from "./../../../../state/actions";
import { connect } from "react-redux";

class StoreViewContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.loadStore();
  }

  loadStore = () => {
    StoreService.fetchStore(
      this.props.match.params.type,
      this.props.match.params.storeId
    )
      .then(response => {
        this.setState({ store: response, loading: false });
      })
      .catch(e => this.setState({ loading: false }));
  };

  deleteStocks = (stockIds, skuIds) => {
    let api = null;
    if (this.props.match.params.type !== "v") {
      api = StoreService.deleteStocks(
        this.props.match.params.storeId,
        stockIds
      );
    } else {
      api = StoreService.deleteVirtualStoreStocks(
        this.props.match.params.storeId,
        stockIds,
        skuIds
      );
    }
    api.then(() => {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  };

  deleteStore = () => {
    let api =
      this.props.match.params.type === "v"
        ? StoreService.deleteVirtualStore
        : StoreService.deleteStore;

    api(this.props.match.params.storeId).then(response => {
      history.push("/dashboard/stores");
      this.props.toastMessage("Store has been deleted.", "success", 5);
    });
  };

  publishStore = url => {
    let storeId = this.props.match.params.storeId;
    return StoreService.publishVirtualStore(storeId, url).then(response => {
      this.props.toastMessage("Your store has been published.", "success", 5);
      history.push("/dashboard/stores/v/" + storeId);
    });
  };

  render() {
    let { store, loading } = this.state;

    return (
      <StoreView
        store={store}
        loading={loading}
        refreshStore={this.loadStore}
        deleteStocks={this.deleteStocks}
        deleteStore={this.deleteStore}
        type={this.props.match.params.type}
        view={this.props.match.params.view || "s"}
        publishStore={this.publishStore}
      />
    );
  }
}

const actions = {
  toastMessage: Actions.toastMessage
};

export default connect(
  null,
  actions
)(StoreViewContainer);
