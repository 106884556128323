import React from "react";
import StoreService from "../../../../services/StoreService";
import NewStoreMataData from "./NewStoreMataData";
import history from "../../../../utils/History";
import { toastMessage } from "../../../../state/actions/toaster";
import { connect } from "react-redux";

class NewStoreContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  createStore = storeInfo => {
    StoreService.createStore(storeInfo)
      .then(response => {
        this.props.toastMessage("Store has been created.", "success", 3);
        history.push(`/dashboard/stores/ps/${response._id}/s`);
      })
      .catch(e => {
        this.props.toastMessage(
          "Unable to create Store now. Please try again later",
          "error",
          5
        );
        console.log("Virtual Store Creation:", e);
      });
  };

  render() {
    return <NewStoreMataData submit={this.createStore} />;
  }
}

function actions(dispatch) {
  return {
    toastMessage: (message, type, duration) =>
      dispatch(toastMessage(message, type, duration))
  };
}

export default connect(
  null,
  actions
)(NewStoreContainer);
