import ajax from './Ajax'

export default {
    getBidRequestById: function (id, cb) {
        ajax.get('/bid-requests/' + id).then(function (res) {
            if (!res.success) {
                cb({});
            } else {
                cb(res.data);
            }
        });
    }
}

