import React from "react";
import ShopWrapper from "../../Seller/Store/ShopWrapper";

class ProjectRequirementsWizard extends React.PureComponent {
  render() {
    return (
      <div className="my-3">
        <ShopWrapper stocks={true} projectId={this.props.projectId} />
      </div>
    );
  }
}

export default ProjectRequirementsWizard;
