import React from "react";
import { InputGroup } from "react-bootstrap";

class ResellerMarkupForm extends React.Component {
  constructor(props) {
    super(props);
    props.user == "admin"
      ? (this.state = { price: props.markUpValue })
      : (this.state = {});
  }

  changeMarkUpPrice = (e) => {
    this.setState({ price: e.target.value });
    let productIndex = this.props.productIndex;
    this.props.addMarkUpPrice(e.target.value, productIndex);
  };

  render() {
    return (
      <div>
        <div className="mb-1">
          <div className="mb-1">Your Margin/{this.props.basicUnit}</div>

          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">₹</InputGroup.Text>
            </InputGroup.Prepend>
            <input
              className="form-control"
              style={{
                border: "1px solid hsl(0,0%,80%)",
                borderRadius: "4px",
                height: "38px",
                padding: "2px 8px",
                width: "100px",
              }}
              type="number"
              placeholder="Price"
              value={this.state.price}
              onChange={this.changeMarkUpPrice}
            />
          </InputGroup>
        </div>
      </div>
    );
  }
}

export default ResellerMarkupForm;
