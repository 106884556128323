import React from "react";
import { connect } from "react-redux";
import Actions from "../../state/actions";
import SignInForm from "./SignInForm";
import validationUtils from "../../utils/ValidationUtils";
import Error from "../../widgets/alerts/Error";
import LinkToModal from "../../widgets/modal/LinkToModal";
import "./UserForm.css";
import history from "../../utils/History";
import ModalN from "../../widgets/modal/ModalN";
import ResendEmailConfirmation from "./ResendEmailConfirmation";
import ForgotPassword from "./ForgotPassword";

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  parseURLQueryParams = (search) => {
    //search: "?cp=email"
    if (!search || !search.substring(1)) {
      return {};
    }
    search = search.substring(1);
    let params = search.split("&");
    if (params && params.length > 0) {
      let out = {};
      params.forEach(p => {
        let values = p.split("=");
        out[values[0]] = values[1];
      });
      return out;
    }
    return {};
  }
  showForgotPasswordPopup = () => {
    this.setState({ forgotPasswordPopup: true });
  };

  showEmailConfirmationPopup = () => {
    this.setState({ emailConfirmationPopup: true });
  };

  cancelForgotPasswordPopup = () => {
    this.setState({ forgotPasswordPopup: false });
  };

  cancelEmailConfirmationPopup = () => {
    this.setState({ emailConfirmationPopup: false });
  };

  signIn = formData => {
    let params = null;
    if (this.props.location && this.props.location.search) {
      params = this.parseURLQueryParams(this.props.location.search);
    }
    this.setState({ error: "", errorType: null });
    var errorMessage = validationUtils.validateLoginForm(formData);
    if (errorMessage) {
      this.setState({ error: errorMessage });
      return;
    }

    var suCallback = data => {
      let { location } = this.props;
      if (location.state && location.state.nextPathname) {
        history.push(location.state.nextPathname);
      } else {
        history.push("/dashboard");
      }
    };
    var errCallback = message => {
      var errorType = null;
      if (message === "PHONE_NOT_CONFIRMED") {
        message = (
          <span>
            Phone is not confirmed yet. Please click{" "}
            <a href="/confirm/phone" className="btn-link">
              here
            </a>{" "}
            to confirm phone.
          </span>
        );
        errorType = "link";
      } else if (message === "EMAIL_NOT_CONFIRMED") {
        message = (
          <div>
            <span>
              Email is not confirmed yet. Click{" "}
              <a className="btn-link" onClick={this.showEmailConfirmationPopup}>
                here
              </a>{" "}
              to resend email confirmation again if required.
            </span>
          </div>
        );
        errorType = "link";
      }
      this.setState({ error: message, errorType: errorType });
    };
    if (params && params.t === 'a') {
      this.props.logIn(
        formData.email,
        formData.password,
        true,
        errCallback,
        suCallback
      );
    } else {
      this.props.logIn(
        formData.email,
        formData.password,
        false,
        errCallback,
        suCallback
      );
    }
  };

  render() {
    return (
      <div style={{ marginTop: "60px" }}>
        <section className="slice ">
          <div className="wp-section">
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-4">
                  <div className="wp-block default user-form">
                    <div
                      className="form-header base"
                      style={{ textAlign: "center" }}
                    >
                      <h2 style={{ fontWeight: 500 }}>
                        Sign in to your account
                      </h2>
                    </div>
                    <Error
                      message={this.state.error}
                      style={{ margin: "10px" }}
                      type={this.state.errorType}
                    />

                    <div className="form-body">
                      <SignInForm onSubmit={this.signIn} btnSize="btn" />
                    </div>
                    <div
                      className="form-footer base"
                      style={{ textAlign: "center" }}
                    >
                      <p>
                        <span style={{ color: "#444" }}>
                          Lost your password? Click{" "}
                          <a
                            onClick={this.showForgotPasswordPopup}
                            style={{ color: "#2a6496" }}
                            className="btn-link"
                          >
                            here
                          </a>{" "}
                          to recover.
                        </span>
                      </p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <ModalN
          show={this.state.emailConfirmationPopup}
          title="Resend Email Confirmation"
          close={this.cancelEmailConfirmationPopup}
        >
          <ResendEmailConfirmation
            onComplete={this.cancelEmailConfirmationPopup}
          />
        </ModalN>

        <ModalN
          show={this.state.forgotPasswordPopup}
          title="Forgot Password"
          close={this.cancelForgotPasswordPopup}
        >
          <ForgotPassword />
        </ModalN>
      </div>
    );
  }
}

const actions = (dispatch, ownProps) => {
  return {
    logIn: (email, password, isOps, ecb, scb) => {
      dispatch(Actions.logIn(email, password, isOps, ecb, scb));
    },
  };
};

export default connect(
  null,
  actions
)(SignIn);
