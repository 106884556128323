import React from "react";

class StockPriceForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { price: props.price || null };
  }

  onChangeStockPriceValue = (e) => {
    this.setState({ price: e.target.value });
  };

  submitForm = (e) => {
    e.preventDefault();
    this.props.updateStockPrice(
      this.props.stock._id,
      this.state.price,
      this.props.index
    );
  };

  render() {
    let { price } = this.state;

    return (
      <form onSubmit={this.submitForm} className="d-flex">
        <div className="d-flex ">
          ₹{" "}
          <input
            placeholder="Price"
            value={price}
            onChange={this.onChangeStockPriceValue}
            style={{ width: "50px" }}
          />
        </div>
        <div className="d-flex align-items-center ml-2">
          <button type="submit" className="btn btn-primary btn-xs">
            Submit
          </button>
          <a
            className="btn btn-light btn-xs ml-2"
            onClick={this.props.cancelPriceEdit}
          >
            Cancel
          </a>
        </div>
      </form>
    );
  }
}

export default StockPriceForm;
