import { BarLoader } from "react-spinners";
import React, { PureComponent } from "react";

class BsBarLoader extends PureComponent {
  render() {
    let { width = 100 } = this.props;
    return (
      <div className="d-flex my-2">
        <BarLoader color="#20a8d8" width={width} widthUnit="px" />
      </div>
    );
  }
}

export default BsBarLoader;
