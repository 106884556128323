import React from "react";
import signUpService from "../../services/SignUpService";
import Validation from "../../utils/ValidationUtils";
import Error from "../../widgets/alerts/Error";
import Success from "../../widgets/alerts/Success";
import "./UserForm.css";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  submit = e => {
    e.preventDefault();
    this.setState({ error: "" });
    var errorMessage = Validation.validateForgotPassword(this.state.phone);
    if (errorMessage) {
      this.setState({ error: errorMessage });
      return;
    }
    signUpService.forgotPassword(this.state.phone, res => {
      if (res.success) {
        this.setState({
          success:
            "We have sent you an email/message with a link to reset your password"
        });
      } else {
        var message =
          res.message ||
          "We are sorry! Sign up failed. Please try again later.";
        this.setState({ error: message });
        return;
      }
    });
  };
  render() {
    return (
      <section className="slice ">
        <div className="wp-section">
          <div className="col-md-10 col-md-offset-1">
            <div className="wp-block default user-form">
              <Error message={this.state.error} style={{ margin: "10px" }} />
              <Success
                message={this.state.success}
                style={{ margin: "10px" }}
              />

              <div className="form-body">
                <form
                  onSubmit={this.submit}
                  name="phoneOTPForm"
                  id="phoneOTPForm"
                  className="sky-form"
                  noValidate
                >
                  <fieldset>
                    <section>
                      <div className="form-group">
                        <label className="label">Email/Phone</label>
                        <label className="input">
                          <input
                            type="text"
                            name="email"
                            value={this.state.phone}
                            onChange={e =>
                              this.setState({ phone: e.target.value })
                            }
                          />
                        </label>
                      </div>
                    </section>
                    <section style={{ textAlign: "center" }}>
                      <button
                        className="btn btn-base btn-sign-in"
                        style={{ width: "200px" }}
                        type="submit"
                      >
                        <span>Submit</span>
                      </button>
                    </section>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ForgotPassword;
