import React from "react";
import StoreStockView from "./StoreStockView";
import InfiniteScroller from "../../../../widgets/InfiniteScroll/InfiniteScroller";
import BsBarLoader from "../../../../widgets/BsBarLoader";
import ShopProductsMenu from "./../../Shop/ShopProductsMenu";
import ShopProductsFilter from "./../../Shop/ShopProductsFilter";
import http from "../../../../services/Ajax";

class StoreStocksView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      products: [],
      subtypes: [],
      brands: [],
      filters: [],
      checkedFilters: {},
      productsCount: 0,
      currentPage: 1,
      storeType: props.type === "v" ? "virtual" : "physical",
    };
  }
  componentDidMount = () => {
    this.init();
  };

  init = () => {
    this.getSubtypes();
  };

  setQueryParameters(attribute, value) {
    let { checkedFilters = {} } = this.state;

    if (attribute === "subtype") {
      checkedFilters = {};
      checkedFilters[attribute] = value;
    } else {
      let attrValues = checkedFilters[attribute] || [];
      let index = attrValues.indexOf(value);
      if (index !== -1) {
        attrValues.splice(index, 1);
      } else {
        attrValues.push(value);
      }
      checkedFilters[attribute] = [...attrValues];
    }
    return checkedFilters;
  }

  handleFilterChange = (attribute, value) => {
    let checkedFilters = this.setQueryParameters(attribute, value);
    this.setState(
      {
        checkedFilters: { ...checkedFilters },
      },
      () => {
        if (attribute === "subtype") {
          this.getBrands();
          this.getBrandFilters();
        }
        if (attribute === "brand") {
          this.getBrandFilters();
        }
        this.setState({ currentPage: 1, products: [] }, this.filterStocks);
      }
    );
  };

  getSubtypes = () => {
    let { storeId } = this.props;
    let { storeType } = this.state;
    let api = "/api/v2/sku/stock/subtypes";
    http.post(api, { storeId, storeType }).then((response) => {
      this.setState(
        {
          subtypes: response.subtypes,
          selectedSubtype: response.selectedSubtype,
        },
        () => {
          this.getBrands(response.subtypes);
          this.getBrandFilters(response.subtypes);
          this.filterStocks(response.subtypes);
        }
      );
    });
  };

  getBrands = (subtypes) => {
    let { storeId } = this.props;
    let { storeType } = this.state;
    let api = "/api/v2/sku/stock/brands";
    let filter = {
      subtype:
        subtypes && subtypes.length >= 1
          ? subtypes[0]
          : this.state.checkedFilters.subtype,
      storeId,
      storeType,
    };
    http.post(api, filter).then((response) => {
      this.setState({
        brands: response.brands,
      });
    });
  };

  getBrandFilters = (subtypes) => {
    let { storeId } = this.props;
    let { storeType } = this.state;
    let api = "/api/v2/sku/stock/filters";
    let filter = {
      subtype:
        subtypes && subtypes.length >= 1
          ? subtypes[0]
          : this.state.checkedFilters.subtype,
      brand: this.state.checkedFilters.brands,
      storeType,
      storeId,
    };
    http.post(api, filter).then((response) => {
      this.setState({ filters: response.filters });
    });
  };

  filterStocks = (subtypes) => {
    let { checkedFilters = {} } = this.state;
    if (subtypes && subtypes.length >= 1) {
      checkedFilters.subtype = subtypes[0];
    }
    checkedFilters = JSON.parse(JSON.stringify(checkedFilters));
    this.setState({ loadingProducts: true, checkedFilters });
    this.props.loadStocks(checkedFilters);
  };

  onSelectForDelete = (id, skuId) => {
    this.props.handleDelete(id, skuId);
  };

  render() {
    let { stocks = [], loading, loadMore, totalCount } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <ShopProductsMenu
              subtypes={this.state.subtypes}
              checkedFilters={this.state.checkedFilters}
              handleMenuChange={this.handleFilterChange}
            />
          </div>
        </div>
        <div className="row">
          {stocks.length ? (
            <div className="col-md-3 border border-dark py-3">
              <ShopProductsFilter
                brands={this.state.brands}
                filters={this.state.filters}
                checkedFilters={this.state.checkedFilters}
                handleFilterChange={this.handleFilterChange}
              />
            </div>
          ) : null}
          {stocks.length >= 1 ? (
            <div>
              <div
                className="col-md-9 d-flex justify-content-center mb-1"
                style={{
                  fontWeight: "500",
                }}
              >
                {`Showing ${stocks.length} of ${totalCount}`}
              </div>

              <InfiniteScroller
                loadMore={() => {
                  if (!loading && loadMore) this.props.loadStocks({}, true);
                }}
              >
                {stocks.map((stock, index) => {
                  return (
                    <StoreStockView
                      stock={stock}
                      checkedForDeletion={
                        this.props.deleteStockList.indexOf(stock._id) !== -1
                      }
                      index={index}
                      handleMouseOut={this.handleMouseOut}
                      handleMouseIn={this.handleMouseIn}
                      onSelectForDelete={this.onSelectForDelete}
                      cancelPriceEdit={this.cancelPriceEdit}
                      updateStockPrice={this.props.updateStockPrice}
                      updateStockMarkUpPrice={this.props.updateStockMarkUpPrice}
                      type={this.props.type}
                      refreshStocks={this.props.refreshStocks}
                    />
                  );
                })}
              </InfiniteScroller>

              {loading && (
                <div
                  style={{ margin: "0px auto", width: 100, display: "flex" }}
                >
                  <BsBarLoader />
                </div>
              )}
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center">
                  <p className="mt-5 px-5" style={{ fontSize: 16 }}>
                    You have not added any stocks to this store yet. Click{" "}
                    <a className="btn-link" onClick={this.props.showAddStocks}>
                      here
                    </a>{" "}
                    to add stocks.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default StoreStocksView;
