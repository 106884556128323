import React from "react";
import Select from "react-select";
import AdminService from "../../../../services/AdminService";
import Actions from "../../../../state/actions";
import { connect } from "react-redux";

class LeadsFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filters: {} };
  }

  componentDidMount = () => {
    this.props.setFilters(this.state.filters);
  };

  filterLeads = () => {
    let { createdOnStart, createdOnEnd, updatedOnStart, updatedOnEnd } =
      this.state.filters;
    let filters = JSON.parse(JSON.stringify(this.state.filters));
    if (createdOnStart) {
      if (!createdOnEnd) {
        this.props.toastMessage(
          "Please select end range for Created on",
          "error",
          10
        );
        return;
      }
    }
    if (createdOnEnd) {
      if (!createdOnStart) {
        this.props.toastMessage(
          "Please select start range for Created on",
          "error",
          10
        );
        return;
      }
    }

    if (updatedOnStart) {
      if (!updatedOnEnd) {
        this.props.toastMessage(
          "Please select end range for Upadted on",
          "error",
          10
        );
        return;
      }
    }

    if (updatedOnEnd) {
      if (!updatedOnStart) {
        this.props.toastMessage(
          "Please select start range for Updated on",
          "error",
          10
        );
        return;
      }
    }
    this.props.setFilters(filters);
  };

  checkBoxChange = (index, field, value, checked) => {
    let filters = this.state.filters;
    let attrValues = filters[field] || [];
    if (checked) {
      attrValues.push(value);
    } else {
      index = filters[field].indexOf(value);
      attrValues.splice(index, 1);
    }
    filters[field] = [...attrValues];
    this.setState({ filters: { ...filters } });
  };

  handleMobileNumberChange = (e) => {
    let filters = this.state.filters;
    filters.mobileNumber = e.target.value || null;
    this.setState({ filters: { ...filters } });
  };

  updatedOnStart = (e) => {
    let filters = this.state.filters;
    let updatedOnStart = e.target.value;
    updatedOnStart = new Date(updatedOnStart);
    filters["updatedOnStart"] = updatedOnStart;
    this.setState({ filters: { ...filters } });
  };

  updatedOnEnd = (e) => {
    let filters = this.state.filters;
    let updatedOnEnd = e.target.value;
    updatedOnEnd = new Date(updatedOnEnd);
    filters["updatedOnEnd"] = updatedOnEnd;
    this.setState({ filters: { ...filters } });
  };

  createdOnStart = (e) => {
    let filters = this.state.filters;
    let createdOnStart = e.target.value;
    createdOnStart = new Date(createdOnStart);
    filters["createdOnStart"] = createdOnStart;
    this.setState({ filters: { ...filters } });
  };

  createdOnEnd = (e) => {
    let filters = this.state.filters;
    let createdOnEnd = e.target.value;
    createdOnEnd = new Date(createdOnEnd);
    filters["createdOnEnd"] = createdOnEnd;
    this.setState({ filters: { ...filters } });
  };

  nextCallDate = (e) => {
    let filters = this.state.filters;
    let nextCallDate = e.target.value;
    nextCallDate = new Date(nextCallDate);
    filters["nextCallDate"] = nextCallDate;
    this.setState({ filters: { ...filters } });
  };

  onChangeServiceEmployee = (option) => {
    let filters = this.state.filters;
    filters["employeeId"] = option._id;
    this.setState({ filters: { ...filters } });
  };

  showNew = (checked) => {
    let filters = this.state.filters;
    if (checked) {
      filters["showNewLeads"] = true;
    } else {
      filters["showNewLeads"] = false;
    }
    this.setState({ filters: { ...filters } });
  };

  showUnAttended = (checked) => {
    let filters = this.state.filters;
    if (checked) {
      filters["showUnAttendedLeads"] = true;
    } else {
      filters["showUnAttendedLeads"] = false;
    }
    this.setState({ filters: { ...filters } });
  };

  showAttended = (checked) => {
    let filters = this.state.filters;
    if (checked) {
      filters["showAttendedLeads"] = true;
    } else {
      filters["showAttendedLeads"] = false;
    }
    this.setState({ filters: { ...filters } });
  };

  render() {
    let {
      followUpTypeOptions,
      fromComponent,
      employeeOptions,
      businessTypeOptions = [],
      leadSourceOptions = [],
      statusOptions = [],
    } = this.props;

    return (
      <div
        className="d-flex justify-content-between sidebar sidebar--style-4 z-depth-1-top"
        style={{ padding: "1rem" }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {leadSourceOptions && leadSourceOptions.length > 0 && (
            <div>
              <div
                className="mb-1"
                style={{ fontWeight: 500, fontSize: "14px" }}
              >
                Lead source
              </div>
              <div>
                <div className="d-flex">
                  <div>
                    {leadSourceOptions.slice(0, 9).map((option, index) => (
                      <div className="d-flex">
                        <input
                          type="checkbox"
                          id="checkBox"
                          onChange={(e) => {
                            this.checkBoxChange(
                              index,
                              "leadSource",
                              option.value,
                              e.target.checked
                            );
                          }}
                        />
                        <span className="ml-1">{option.label}</span>
                      </div>
                    ))}
                  </div>
                  <div className="ml-2">
                    {leadSourceOptions
                      .slice(9, leadSourceOptions.length)
                      .map((option, index) => (
                        <div className="d-flex">
                          <input
                            type="checkbox"
                            id="checkBox"
                            onChange={(e) => {
                              this.checkBoxChange(
                                index,
                                "leadSource",
                                option.value,
                                e.target.checked
                              );
                            }}
                          />
                          <span className="ml-1">{option.label}</span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              className={
                fromComponent === "lh" || fromComponent === "adminLH"
                  ? "d-flex"
                  : ""
              }
            >
              {followUpTypeOptions && followUpTypeOptions.length > 0 && (
                <div className="col-md">
                  <div
                    className="mb-1"
                    style={{ fontWeight: 500, fontSize: "14px" }}
                  >
                    Follow-up type
                  </div>
                  {followUpTypeOptions.map((option, index) => (
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        id="checkBox"
                        onChange={(e) => {
                          this.checkBoxChange(
                            index,
                            "followUpType",
                            option.value,
                            e.target.checked
                          );
                        }}
                      />
                      <span className="ml-1">{option.label}</span>
                    </div>
                  ))}
                </div>
              )}
              {businessTypeOptions && businessTypeOptions.length > 0 && (
                <div
                  className={
                    fromComponent.toLowerCase().indexOf("lh") == -1
                      ? "mt-1"
                      : "ml-1"
                  }
                >
                  <div
                    className="mb-1"
                    style={{ fontWeight: 500, fontSize: "14px" }}
                  >
                    Business type
                  </div>
                  {businessTypeOptions.map((option, index) => (
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        id="checkBox"
                        onChange={(e) => {
                          this.checkBoxChange(
                            index,
                            "businessType",
                            option.value,
                            e.target.checked
                          );
                        }}
                      />
                      <span className="ml-1">{option.label}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div
            className="ml-2"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <div
                  className="mb-1"
                  style={{ fontWeight: 500, fontSize: "14px" }}
                >
                  Created between
                </div>
                <div>
                  <div>
                    <input
                      type="date"
                      className="form-control"
                      onChange={this.createdOnStart}
                    />
                  </div>
                  <div className="text-center mt-1 strong">and</div>
                  <div className="mt-1">
                    <input
                      type="date"
                      className="form-control"
                      onChange={this.createdOnEnd}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="mt-1"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {fromComponent.toLowerCase().indexOf("pl") != -1 ? (
                <div>
                  <div
                    className="mb-1"
                    style={{ fontWeight: 500, fontSize: "14px" }}
                  >
                    Next-call date
                  </div>
                  <input
                    type="date"
                    className="form-control"
                    onChange={this.nextCallDate}
                  />
                </div>
              ) : (
                <div>
                  <div
                    className="mb-1"
                    style={{ fontWeight: 500, fontSize: "14px" }}
                  >
                    Updated between
                  </div>
                  <div>
                    <div>
                      <input
                        type="date"
                        className="form-control"
                        onChange={this.updatedOnStart}
                      />
                    </div>
                    <div className="text-center mt-1 strong">and</div>
                    <div className="mt-1">
                      <input
                        type="date"
                        className="form-control"
                        onChange={this.updatedOnEnd}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className="ml-2"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <div
                  className="mb-1"
                  style={{
                    fontWeight: 500,
                    fontSize: "14px",
                    width: "220px",
                  }}
                >
                  Assignee
                </div>
                <Select
                  className="flex-fill mb-2"
                  placeholder="Select service employee"
                  options={employeeOptions}
                  onChange={this.onChangeServiceEmployee}
                />
              </div>
            </div>
            <div>
              <div
                className="mb-1"
                style={{ fontWeight: 500, fontSize: "14px" }}
              >
                Mobile no
              </div>
              <input
                type="text"
                className="form-control mainLoginInput"
                onChange={this.handleMobileNumberChange}
                placeholder="&#61442; "
              />
            </div>
          </div>
          {fromComponent != "lh" && fromComponent != "adminLH" && (
            <div
              className="ml-2"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div style={{ marginLeft: 20 }}>
                <div
                  className="mb-1"
                  style={{ fontWeight: 500, fontSize: "14px", width: "220px" }}
                >
                  Updated status
                </div>

                <div className="d-flex">
                  <input
                    type="checkbox"
                    id="checkBox"
                    onChange={(e) => {
                      this.showNew(e.target.checked);
                    }}
                  />
                  <span
                    className="ml-1"
                    style={{
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    New leads
                  </span>
                </div>

                {(fromComponent === "tl" ||
                  fromComponent === "adminTL" ||
                  fromComponent === "ul" ||
                  fromComponent === "adminUL") && (
                  <div className="d-flex">
                    <input
                      type="checkbox"
                      id="checkBox"
                      onChange={(e) => {
                        this.showUnAttended(e.target.checked);
                      }}
                    />
                    <span
                      className="ml-1"
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                      }}
                    >
                      Unattended(today)
                    </span>
                  </div>
                )}
                {(fromComponent === "tl" || fromComponent === "adminTL") && (
                  <div className="d-flex">
                    <input
                      type="checkbox"
                      id="checkBox"
                      onChange={(e) => {
                        this.showAttended(e.target.checked);
                      }}
                    />
                    <span
                      className="ml-1"
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                      }}
                    >
                      Attended(today)
                    </span>
                  </div>
                )}
              </div>
              {statusOptions && statusOptions.length > 0 && (
                <div
                  className={
                    fromComponent === "adminLH" || fromComponent === "lh"
                      ? "col-md ml-1"
                      : "col-md mr-1"
                  }
                >
                  <div
                    className="mb-1"
                    style={{ fontWeight: 500, fontSize: "14px" }}
                  >
                    Lead Status
                  </div>
                  {statusOptions.map((option, index) => (
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        id="checkBox"
                        onChange={(e) => {
                          this.checkBoxChange(
                            index,
                            "status",
                            option.value,
                            e.target.checked
                          );
                        }}
                      />
                      <span className="ml-1">{option.label}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
        <div style={{ marginLeft: "-60px" }} className="d-flex align-items-end">
          <button className="btn btn-primary" onClick={this.filterLeads}>
            Filter
          </button>
        </div>
      </div>
    );
  }
}

function actions(dispatch) {
  return {
    toastMessage: (message, type, duration) => {
      dispatch(Actions.toastMessage(message, type, duration));
    },
  };
}
export default connect(null, actions)(LeadsFilter);
