import React, { Component } from "react";
import ShopProductsMenu from "./ShopProductsMenu";
import ShopProducts from "./ShopProducts";
import ShopProductsFilter from "./ShopProductsFilter";
import http from "../../../../src/services/Ajax";
import ProjectService from "../../../services/ProjectService";
import InfiniteScroller from "../../../widgets/InfiniteScroll/InfiniteScroller";
import {CATALOG_PRODUCTS_PAGE_SIZE} from "../Constants";

class Shop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      subtypes: [],
      brands: [],
      filters: [],
      checkedFilters: { subtype: "Cement" },
      productsCount: 0,
      currentPage: 1
    };
  }

  componentDidMount() {
    if (this.props.stocks) {
      ProjectService.fetchProject(this.props.projectId).then(project => {
        this.setState({ project: project }, this.init);
      });
    } else {
      this.init();
    }
  }

  init = () => {
    this.getSubtypes();
    this.getBrands();
    this.getBrandFilters();
    this.filterProducts();
  };

  setQueryParameters(attribute, value) {
    let { checkedFilters = {} } = this.state;

    if (attribute === "subtype") {
      checkedFilters = {};
      checkedFilters[attribute] = value;
    } else {
      let attrValues = checkedFilters[attribute] || [];
      let index = attrValues.indexOf(value);
      if (index !== -1) {
        attrValues.splice(index, 1);
      } else {
        attrValues.push(value);
      }
      checkedFilters[attribute] = [...attrValues];
    }
    return checkedFilters;
  }

  handleFilterChange = (attribute, value) => {
    let checkedFilters = this.setQueryParameters(attribute, value);
    this.setState(
      {
        checkedFilters: { ...checkedFilters }
      },
      () => {
        if (attribute === "subtype") {
          this.getBrands();
          this.getBrandFilters();
        }
        if (attribute === "brand") {
          this.getBrandFilters();
        }
        this.setState({ currentPage: 1, products: [] }, this.filterProducts);
      }
    );
  };

  getSubtypes = () => {
    let api = "/api/v2/sku/subtypes";

    http.post(api, {}).then(response => {
      this.setState({
        subtypes: response.subtypes,
        selectedSubtype: response.selectedSubtype
      });
    });
  };

  getBrands = () => {
    let api = "/api/v2/sku/brands";
    let filter = { subtype: this.state.checkedFilters.subtype };
    http.post(api, filter).then(response => {
      this.setState({
        brands: response.brands
      });
    });
  };

  getBrandFilters = () => {
    let api = "/api/v2/sku/filters";
    let filter = {
      subtype: this.state.checkedFilters.subtype,
      brand: this.state.checkedFilters.brands
    };
    http.post(api, filter).then(response => {
      this.setState({ filters: response.filters });
    });
  };

  filterProducts = () => {
    let api = "/api/v2/sku/products";

    let { checkedFilters = {}, currentPage, products } = this.state;
    checkedFilters = JSON.parse(JSON.stringify(checkedFilters));
    if (this.state.project) {
      api = api + "?projectId=" + this.state.project._id;
    }
    checkedFilters.page = currentPage;
    checkedFilters.pageSize = CATALOG_PRODUCTS_PAGE_SIZE;
    this.setState({ loadingProducts: true });
    http
      .post(api, checkedFilters)
      .then(response => {
        this.setState({
          loadingProducts: false,
          products: products.concat(response.products),
          productsCount: response.count,
          currentPage: currentPage + 1
        });
      })
      .catch(e => this.setState({ loadingProducts: false }));
  };

  changeProductValue = (index, key, value, min) => {
    let { products } = this.state;
    let product = products[index];
    product = { ...product, [key]: value };
    products[index] = product;
    this.setState({ products: [...products] });
  };

  render() {
    let { currentPage, productsCount, loadingProducts } = this.state;
    let totalPages = Math.ceil(productsCount / CATALOG_PRODUCTS_PAGE_SIZE);
    let loadMore = currentPage - 1 < totalPages;

    return (
      <div className="">
        <div className="row">
          <div className="col-md-12">
            <ShopProductsMenu
              subtypes={this.state.subtypes}
              checkedFilters={this.state.checkedFilters}
              handleMenuChange={this.handleFilterChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-3 border border-dark py-3">
            <ShopProductsFilter
              brands={this.state.brands}
              filters={this.state.filters}
              checkedFilters={this.state.checkedFilters}
              handleFilterChange={this.handleFilterChange}
            />
          </div>
          <div className="col-md-9">
            <InfiniteScroller
              loadMore={() => {
                if (!loadingProducts && loadMore) this.filterProducts();
              }}
            >
              <ShopProducts
                user={this.props.user}
                stocks={this.props.stocks}
                products={this.state.products || []}
                selectProduct={this.props.selectProduct}
                changeProductValue={this.changeProductValue}
              />
            </InfiniteScroller>
          </div>
        </div>
      </div>
    );
  }
}

export default Shop;
