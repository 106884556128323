import React from "react";
import AdminService from "../../../../services/AdminService";
import LeadsFilter from "../employees/LeadsFilter";
import AdminLeadDisplay from "./AdminLeadDisplay";
import AdminLeadsHeader from "./AdminLeadsHeader";
import InfiniteScroller from "../../../../widgets/InfiniteScroll/InfiniteScroller";
import { LEADS_PAGE_SIZE } from "../../Constants";

const followUpTypeOptions = [
  { value: "customer", label: "Customer" },
  { value: "seller", label: "Seller" },
  { value: "collection", label: "Collection" },
  { value: "store creation", label: "Store Creation" },
];

class AdminTodaysLeads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leads: [],
      count: 0,
      currentPage: 1,
    };
  }

  componentDidMount() {
    this.setState({ employeeOptions: this.props.employeeOptions });
  }

  setFilters = (filters) => {
    this.setState(
      { leads: [], filters: { ...filters }, count: 0, currentPage: 1 },
      this.loadLeads
    );
  };

  loadLeads = () => {
    let { currentPage, leads, filters = {} } = this.state;
    filters = JSON.parse(JSON.stringify(filters));
    filters.page = currentPage;
    filters.pageSize = LEADS_PAGE_SIZE;
    this.setState({ loadingLeads: true });
    AdminService.getAdminTodaysLeads(filters)
      .then((response = []) => {
        if (currentPage === 1) {
          this.setState({
            leads: leads.concat(response.leads),
            count: response.count,
            currentPage: currentPage + 1,
            loadingLeads: false,
          });
        } else {
          this.setState({
            leads: leads.concat(response.leads),
            currentPage: currentPage + 1,
            loadingLeads: false,
          });
        }
      })
      .catch((e) => this.setState({ loadingLeads: false }));
  };

  render() {
    let { leads, currentPage, count, loadingLeads } = this.state || {};
    let {
      businessTypeOptions = [],
      leadSourceOptions = [],
      statusOptions = [],
    } = this.props;
    let totalPages = Math.ceil(count / LEADS_PAGE_SIZE);
    let loadMore = currentPage - 1 < totalPages;

    return (
      <div className="row">
        <div className="col-md-12 border border-dark pb-3">
          <LeadsFilter
            followUpTypeOptions={followUpTypeOptions}
            setFilters={this.setFilters}
            fromComponent="adminTL"
            employeeOptions={this.state.employeeOptions}
            businessTypeOptions={businessTypeOptions}
            leadSourceOptions={leadSourceOptions}
            statusOptions={statusOptions}
          />
        </div>
        {leads && leads.length >= 1 ? (
          <div>
            <h5 style={{ textAlignLast: "center" }}>
              {"Viewing" + " " + leads.length + " of " + count}
            </h5>
            <div className="col-md-12 table-responsive">
              <InfiniteScroller
                loadMore={() => {
                  if (!loadingLeads && loadMore) this.loadLeads();
                }}
              >
                <table className="table table-cart">
                  <AdminLeadsHeader />
                  <tbody>
                    {leads.map((lead) => {
                      return (
                        <AdminLeadDisplay
                          lead={lead}
                          loadLeads={this.loadLeads}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </InfiniteScroller>
            </div>
          </div>
        ) : (
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="text-center">
                <p className="mt-5 px-5">NO LEAD FOR TODAY </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default AdminTodaysLeads;
