import React from "react";
import { connect } from "react-redux";
import BidRequestRo from "../bidrequests/BidRequestRo";
import Bids from "./Bids";
import requestService from "../../services/BidRequestService";
import Actions from "../../state/actions";

class BidsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onBidSelect = bid => {
    this.setState({ bid: bid });
  };

  componentDidMount() {
    this.loadBidRequest();
  }

  loadBidRequest = () => {
    requestService.getBidRequestById(this.props.params.requestId, request => {
      this.setState({ request: request });
    });
  };

  confirmBid = () => {
    var request = this.state.request,
      bid = this.state.bid;
    this.props.confirmBid(
      request._id,
      bid._id,
      err => {
        this.setState({ message: err, messageType: "error" });
      },
      () => {
        var message = {
          content:
            "I have confirmed bid with value Rs. " +
            bid.unitPrice +
            " and delivery charge Rs. " +
            bid.deliveryCharges +
            " for bid request " +
            request.number,
          type: "T",
          sender: { type: "U" },
          date: new Date()
        };
        this.props.processMessage(message);
        this.props.toastMessage(
          "Done! Thanks for confirming a bid. We will get back to you with the order details in the chat.",
          "success",
          3
        );
      }
    );
  };

  viewOnly = () => {
    var status = this.state.request.status;
    if (status === "OPEN" || status === "BID_CONFIRMED") {
      return false;
    }
    return true;
  };

  render() {
    var request = this.state.request;
    if (!request) {
      return null;
    }
    return (
      <div>
        <div className="col-md-12">
          <h3 style={{ textAlign: "center" }}>
            Bid Request {"#" + request.number}
          </h3>
        </div>
        <div className="col-md-offset-2 col-md-8">
          <BidRequestRo request={request} />
        </div>
        <div className="col-md-12">
          <Bids
            bids={request.bids}
            onBidSelect={this.onBidSelect}
            selectedBid={this.state.bid}
            confirmedBid={request.confirmedBid}
            viewOnly={this.viewOnly()}
            quantity={request.quantity}
          />
        </div>
        {request.status === "OPEN" && (
          <div className="col-md-12 text-center">
            {this.state.bid && (
              <a
                className="btn btn-success pull-right"
                onClick={this.confirmBid}
              >
                Confirm Bid
              </a>
            )}
            {!this.state.bid && (
              <a className="btn btn-success not-active pull-right" disabled>
                Confirm Bid
              </a>
            )}
          </div>
        )}
      </div>
    );
  }
}

function actions(dispatch) {
  return {
    confirmBid: (request, bid, error, success) => {
      dispatch(Actions.confirmBid(request, bid, error, success));
    },
    processMessage: message => dispatch(Actions.processMessage(message)),
    toastMessage: (message, type, duration) => {
      dispatch(Actions.toastMessage(message, type, duration));
    }
  };
}
export default connect(
  null,
  actions
)(BidsView);
