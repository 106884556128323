import React from "react";
import history from "../../../../../utils/History";
import StoreService from "../../../../../services/StoreService";
import ModalN from "../../../../../widgets/modal/ModalN";
import ValidationUtils from "../../../../../utils/ValidationUtils";
import Error from "../../../../../widgets/alerts/Error";
import ServiceAreaForm from "../ServiceAreaForm";
import ServiceLocationsView from "../ServiceLocationsView";
import Actions from "../../../../../state/actions";
import { connect } from "react-redux";

class NewVirtualStoreContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    StoreService.getSellerPhone().then((response) => {
      if (response) {
        this.setState({ phone: response.phone });
      }
    });
  };

  cancelStoreDetailsForm = () => {
    history.push("/dashboard/stores");
  };

  onChangeName = (e) => {
    this.setState({ name: e.target.value });
  };

  onChangePhone = (e) => {
    this.setState({ phone: e.target.value });
  };

  showServiceAreaLocations = (serviceAreas) => {
    this.setState({
      showServiceLocations: true,
      serviceAreasForLocalities: serviceAreas.map((sa) => ({
        state: sa.state.value,
        district: sa.district.value,
        zone: sa.zone.value,
        localities: sa.localities.map((l) => l.value),
      })),
    });
  };

  hideServiceLocations = () => {
    this.setState({
      showServiceLocations: false,
      serviceAreasForLocalities: [],
    });
  };

  removeServiceArea = (saIndex) => {
    let { serviceAreas = [] } = this.state;
    serviceAreas.splice(saIndex, 1);
    serviceAreas = [...serviceAreas];
    this.setState({ serviceAreas: [...serviceAreas] });
  };

  showAddServiceArea = () => {
    this.setState({ showAddServiceAreaModal: true });
  };

  hideAddService = () => {
    this.setState({ showAddServiceAreaModal: false });
  };

  addServiceArea = (serviceArea) => {
    let { serviceAreas = [] } = this.state || [];

    for (let i = 0; i < serviceAreas.length; i++) {
      if (JSON.stringify(serviceAreas[i]) === JSON.stringify(serviceArea)) {
        this.props.toastMessage(
          "This service area has already been added",
          "error",
          3
        );
        this.hideAddService();
        return;
      }
    }
    serviceAreas.push(serviceArea);
    this.setState({ serviceAreas: [...serviceAreas] });
    this.hideAddService();
  };

  addServiceAreas = (newServiceAreas) => {
    let { serviceAreas = [] } = this.state || [];

    serviceAreas = serviceAreas.concat(newServiceAreas);
    this.setState({ serviceAreas });
    this.hideAddService();
  };

  submitForm = (e) => {
    e.preventDefault();
    var errorMessage = ValidationUtils.validateNewVirtualStoreForm(this.state);
    if (errorMessage) {
      this.setState({ error: errorMessage });
      return;
    }

    let storeInfo = JSON.parse(JSON.stringify(this.state));

    storeInfo.serviceAreas = storeInfo.serviceAreas.map((sa) => ({
      state: sa.state.value,
      district: sa.district.value,
      zone: sa.zone.value,
      localities: sa.localities.map((l) => l.value),
    }));

    StoreService.createVirtualStore(storeInfo)
      .then((response) => {
        this.props.toastMessage(
          "virtual Store has been created.",
          "success",
          3
        );
        history.push(`/dashboard/stores/v/${response._id}/s`);
      })
      .catch((e) => {
        this.props.toastMessage(
          "Sorry! Unable to create Virtual Store now. Please try again later",
          "error",
          5
        );
        console.log("Virtual Store Creation:", e);
      });
  };

  render() {
    let { name, serviceAreas, phone } = this.state;

    return (
      <div className="row d-flex justify-content-center">
        <div className="col-md-9">
          <div
            className="card form-card form-card--style-2"
            style={{
              border: "1px solid #f1f1f1",
              padding: "15px",
            }}
          >
            <div className="form-body">
              <form className="form-default" onSubmit={this.submitForm}>
                <h4 className="text-center">New Virtual Store</h4>

                <Error message={this.state.error} style={{ margin: "10px" }} />

                <div className="col-12">
                  <div className="form-group">
                    <label>Store Name</label>
                    <input
                      className="form-control form-control-lg"
                      value={name}
                      onChange={this.onChangeName}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <label>
                      Store Phone (You can have different phone for this virtual
                      store)
                    </label>
                    <input
                      className="form-control form-control-lg"
                      value={phone}
                      onChange={this.onChangePhone}
                    />
                  </div>
                </div>

                <div className="col-12 d-flex flex-column">
                  <h6>Service Area</h6>
                  <div
                    className="p-2 mb-1"
                    style={{ border: "1px solid #ddd" }}
                  >
                    <div className="mt-1">
                      {serviceAreas && (
                        <div>
                          {serviceAreas.map((serviceArea, saIndex) => (
                            <div
                              className="p-1 d-flex justify-content-between"
                              style={{
                                border: "1px solid #ddd",
                                marginBottom: "3px",
                              }}
                            >
                              <span>
                                {`${serviceArea.state.value} -${
                                  serviceArea.district.value
                                } - ${serviceArea.zone.value} - ${
                                  serviceArea.localities.length >= 2
                                    ? serviceArea.localities[0].value +
                                      " , " +
                                      serviceArea.localities[1].value +
                                      "..."
                                    : serviceArea.localities.map(
                                        (locality) => locality.value + ","
                                      )
                                }`}
                              </span>
                              <span className="d-flex align-items-center">
                                <a
                                  className="btn-link ml-1 mr-1"
                                  onClick={() => {
                                    this.showServiceAreaLocations([
                                      serviceArea,
                                    ]);
                                  }}
                                >
                                  View
                                </a>
                                <a
                                  className="btn-link text-danger mr-1"
                                  onClick={() => {
                                    this.removeServiceArea(saIndex);
                                  }}
                                >
                                  <i className="fa fa-times" />
                                </a>
                              </span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="d-flex ">
                      <a
                        className="btn-link btn-primary"
                        onClick={this.showAddServiceArea}
                      >
                        Add
                      </a>
                    </div>
                  </div>
                </div>

                <div className="my-3 d-flex justify-content-between">
                  <button
                    className="btn btn-secondary"
                    onClick={this.cancelStoreDetailsForm}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {this.state.showAddServiceAreaModal && (
          <ModalN
            title="Add service area"
            show={this.state.showAddServiceAreaModal}
            close={this.hideAddService}
            modalHeight={{
              minHeight: "calc(100vh - 500px)",
            }}
          >
            <ServiceAreaForm
              addServiceArea={this.addServiceArea}
              addServiceAreas={this.addServiceAreas}
              serviceAreas={serviceAreas}
              changeProductValue={this.changeProductValue}
            />
          </ModalN>
        )}

        {this.state.showServiceLocations && (
          <ModalN
            title="Service Locations"
            show={this.state.showServiceLocations}
            close={this.hideServiceLocations}
          >
            <ServiceLocationsView
              serviceAreas={this.state.serviceAreasForLocalities}
              from={true}
            />
          </ModalN>
        )}
      </div>
    );
  }
}

function actions(dispatch) {
  return {
    toastMessage: (message, type, duration) => {
      dispatch(Actions.toastMessage(message, type, duration));
    },
  };
}

export default connect(null, actions, null, { forwardRef: true })(
  NewVirtualStoreContainer
);
