import React from "react";
import CitySelector from "./../home/CitySelector";
import Auth from "./../../services/StorageService";
import ModalN from "../../widgets/modal/ModalN";

class CitySelectorModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { cities: [] };
  }

  componentDidMount() {
    var city = Auth.getCity();
    if (!city) {
      // this.showModal();
    }
  }

  showModal = () => {
    var cities = [{ value: "Chennai" }, { value: "Coimbatore" }];
    var allCities = cities.map(function(city) {
      return { value: city.value, label: city.value };
    });
    this.setState({ cities: allCities, show: true });
  };

  closeModal = () => {
    this.setState({ show: false });
  };

  render() {
    return (
      <ModalN
        show={this.state.show}
        close={this.closeModal}
        title="Select Your City"
      >
        <CitySelector
          onCitySelect={this.onCitySelect}
          cities={this.state.cities}
        />
      </ModalN>
    );
  }
}

export default CitySelectorModal;
