import React from "react";
import Select from "react-select";
import StoreService from "../../../../services/StoreService";
import ValidationUtils from "../../../../utils/ValidationUtils";
import Error from "../../../../widgets/alerts/Error";
import LocationFinder from "./LocationFinder";

class ServiceAreaForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    StoreService.getStateOptions().then((stateOptions) => {
      this.setState({
        stateOptions: stateOptions.map((l) => ({ value: l, label: l })),
      });
    });
  }

  onChangeState = (option, getOptions) => {
    this.setState(
      { state: option, district: null, zones: [], localities: [] },
      () => {
        getOptions === false ? (getOptions = false) : (getOptions = true);
        if (getOptions != false) {
          this.getDistricts(option.value);
        }
      }
    );
  };

  onChangeDistrict = (option, getOptions) => {
    this.setState({ district: option, zones: [], localities: [] }, () => {
      getOptions === false ? (getOptions = false) : (getOptions = true);
      if (getOptions != false) {
        this.getZones(option.value);
      }
    });
  };

  getDistricts = (state) => {
    StoreService.getDistrictOptions(state).then((districtOptions) => {
      this.setState({
        districtOptions: districtOptions.map((l) => ({ value: l, label: l })),
      });
    });
  };

  getZones = (district, selectAll) => {
    StoreService.getZoneOptions(district).then((zoneOptions) => {
      if (selectAll) {
        let { serviceAreas = [] } = this.props;
        let zones = serviceAreas.map((s) =>
          s.zone.value ? s.zone.value : s.zone
        );
        let filtered = [];
        zoneOptions.map((z) => {
          if (zones.indexOf(z.value) == -1) {
            filtered.push(z);
          }
        });
        this.setState({ zoneOptions: filtered, zones: [...filtered] });
        this.getLocalities(filtered.map((z) => z.value));
      } else {
        let { serviceAreas = [] } = this.props;
        let zones = serviceAreas.map((s) =>
          s.zone.value ? s.zone.value : s.zone
        );
        let filtered = [];
        zoneOptions.map((z) => {
          if (zones.indexOf(z.value) === -1) {
            filtered.push({ ...z, district });
          }
        });
        this.setState({ zoneOptions: filtered });
      }
    });
  };

  onChangeZones = (options, getOptions) => {
    getOptions === false ? (getOptions = false) : (getOptions = true);
    if (options) {
      if (options.length >= 1) {
        if (getOptions != false) {
          this.getLocalities(options.map((o) => o.value));
        }
        this.setState({ zones: [...options], localities: [] });
      } else {
        if (getOptions != false) {
          this.getLocalities([options.value]);
        }
        this.setState({ zones: [options] });
      }
    } else {
      this.setState({ zones: [] });
      return;
    }
  };

  onChangeLocalities = (options) => {
    let { zones, district, state } = this.state;
    if (options && options.length >= 1) {
      if (options.length > 1) {
        let zonesMap = {};
        options.forEach(
          (l) => (zonesMap[l.zone] = { label: l.zone, value: l.zone, district })
        );
        zones = Object.values(zonesMap);
      } else {
        this.setState({
          localities: [
            {
              label: options.label,
              value: options.value,
              zone: zones[0].value,
              district: district.value,
              state: state,
            },
          ],
        });
        return;
      }
    } else {
      this.setState({ localities: [] });
      return;
    }
    this.setState({
      localities: options ? [...options] : [],
      zones: [...zones],
    });
  };

  getLocalities = async (zones) => {
    let allZonesLocalities = await Promise.all(
      zones.map((zone) => StoreService.getLocalityOptions(zone))
    );

    let localitiesOptions = [];
    zones.forEach((zone, index) => {
      let zoneLocalities = allZonesLocalities[index];
      zoneLocalities.forEach((locality) => {
        localitiesOptions.push({
          ...locality,
          zone: zone,
          district: this.state.district.value,
          state: this.state.state,
        });
      });
    });
    this.setState({ localitiesOptions, localities: [...localitiesOptions] });
  };

  submitForm = (e) => {
    e.preventDefault();
    var errorMessage = ValidationUtils.validateServiceAreaForm(this.state);
    if (errorMessage) {
      this.setState({ error: errorMessage });
      return;
    }

    let { localities } = this.state;

    let serviceAreas = [];

    localities.forEach((locality) => {
      let { district, zone, state } = locality;

      let dIndex = serviceAreas.findIndex(
        (s) => s.district.value === district && s.zone.value === zone.value
      );
      if (dIndex === -1) {
        serviceAreas.push({
          state,
          district: { value: district, label: district },
          zone: { value: zone, label: zone },
          localities: [locality],
        });
      } else {
        serviceAreas[dIndex].localities.push(locality);
      }
    });

    const localitiesByDistrictZone = serviceAreas.reduce((acc, sa) => {
      if (
        !acc[sa.state.value + "_" + sa.district.value + "_" + sa.zone.value]
      ) {
        acc[sa.state.value + "_" + sa.district.value + "_" + sa.zone.value] =
          [];
      }
      acc[sa.state.value + "_" + sa.district.value + "_" + sa.zone.value].push({
        label: sa.localities[0].value,
        value: sa.localities[0].value,
      });

      return acc;
    }, {});

    console.log(localitiesByDistrictZone);
    let SA = [];
    for (let key in localitiesByDistrictZone) {
      SA.push({
        state: { label: key.split("_")[0], value: key.split("_")[0] },
        district: { label: key.split("_")[1], value: key.split("_")[1] },
        zone: { label: key.split("_")[2], value: key.split("_")[2] },
        localities: localitiesByDistrictZone[key],
      });
    }

    this.props.addServiceAreas(SA);
  };

  addAllZones = async () => {
    this.getZones(this.state.district.value, true);
  };

  render() {
    let { from } = this.props;
    let { zones } = this.state;
    let buttonText;
    if (from == "edit") {
      buttonText = "Submit";
    } else {
      buttonText = "save";
    }

    return (
      <div className="form-body">
        <form className="form-default" onSubmit={this.submitForm}>
          <Error message={this.state.error} style={{ margin: "10px" }} />
          <div className="d-flex mb-1 flex-column">
            <div
              style={{ height: "calc(100vh - 220px)", overflowY: "auto" }}
              className="form-body"
            >
              {from !== "sku" && (
                <div className="mb-1 d-flex justify-content-end">
                  <button type="submit" className="btn btn-primary">
                    {buttonText}
                  </button>
                </div>
              )}

              <div className="mr-1" style={{ width: "200px" }}>
                <LocationFinder
                  onChangeState={this.onChangeState}
                  onChangeDistrict={this.onChangeDistrict}
                  onChangeZone={this.onChangeZones}
                  onChangeLocality={this.onChangeLocalities}
                />
              </div>
              <Select
                className="flex-fill mb-1"
                placeholder="State"
                options={this.state.stateOptions}
                value={this.state.state}
                onChange={this.onChangeState}
                isMulti={false}
              />

              {this.state.state && (
                <Select
                  className="flex-fill mb-1"
                  placeholder="District"
                  options={this.state.districtOptions}
                  value={this.state.district}
                  onChange={this.onChangeDistrict}
                  isMulti={false}
                />
              )}

              {this.state.district && (
                <div>
                  {from !== "sku" && (
                    <a onClick={this.addAllZones}>
                      <i className="fa fa-plus" aria-hidden="true" /> Add all
                      zones from <span>{this.state.district.label}</span>
                    </a>
                  )}
                  <Select
                    className="flex-fill mb-1"
                    placeholder="Zone"
                    options={this.state.zoneOptions}
                    value={zones}
                    onChange={this.onChangeZones}
                    isMulti
                  />
                </div>
              )}

              {zones && zones.length >= 1 && (
                <Select
                  className="flex-fill"
                  placeholder="Localities"
                  options={this.state.localitiesOptions}
                  value={this.state.localities}
                  onChange={this.onChangeLocalities}
                  isMulti
                />
              )}
              <div className="mt-2 d-flex justify-content-end">
                <button type="submit" className="btn btn-primary">
                  {buttonText}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ServiceAreaForm;
