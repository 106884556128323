import React, { PureComponent } from "react";
import { parseThreshold, ThresholdUnits } from "./Threshold";

function isElementAtBottom(target) {
    var scrollThreshold =
        arguments.length <= 1 || arguments[1] === undefined ? 0.8 : arguments[1];

    var clientHeight =
        target === document.body || target === document.documentElement
            ? window.screen.availHeight
            : target.clientHeight;

    var threshold = (0, parseThreshold)(scrollThreshold);

    if (threshold.unit === ThresholdUnits.Pixel) {
        return (
            target.scrollTop + clientHeight >= target.scrollHeight - threshold.value
        );
    }

    return (
        target.scrollTop + clientHeight >=
        threshold.value / 100 * target.scrollHeight
    );
}

class InfiniteScroller extends PureComponent {
    componentDidMount() {
        let { parentId = "main-layout" } = this.props;
        if (document.getElementById(parentId))
            document
                .getElementById(parentId)
                .addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        let { parentId = "main-layout" } = this.props;
        if (document.getElementById(parentId))
            document
                .getElementById(parentId)
                .removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = e => {
        e.preventDefault();
        let { loadMore, parentId = "main-layout" } = this.props;
        let containerEl = document.getElementById(parentId);
        if (containerEl) {
            if (isElementAtBottom(containerEl)) {
                loadMore();
            }
        }
    };

    render() {
        return <div>{this.props.children}</div>;
    }
}

export default InfiniteScroller;
