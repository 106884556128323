import React from "react";
import { connect } from "react-redux";
import BidRequest from "./BidRequest";
import { fetchRequests } from "../../state/actions/request";

class BsRequestsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { requests: [] };
  }

  componentDidMount = () => {
    this.props.fetchRequests();
  };

  getCount = () => {
    var props = this.props;
    if (!props.requests || props.requests.length === 0) {
      return (
        <div style={{ textAlign: "center" }}>
          <div>No requests have been created yet.</div>
          <div>Let us know what you need in the chat.</div>
        </div>
      );
    } else {
      return (
        <div style={{ textAlign: "center" }}>
          <div>{props.requests.length} Request(s)</div>
        </div>
      );
    }
  };

  render() {
    if (!this.props.requests) {
      return null;
    }

    var bidRequestNodes = this.props.requests.map(function(request) {
      return (
        <div className="col-md-12" key={request._id}>
          <BidRequest request={request} />
        </div>
      );
    });
    return (
      <div>
        <h5 className="dashboard-title">Request For Quotes</h5>
        {this.getCount()}
        {bidRequestNodes}
      </div>
    );
  }
}

function select(state) {
  return {
    requests: state.requests ? state.requests.items : []
  };
}

function actions(dispatch) {
  return {
    fetchRequests: () => dispatch(fetchRequests())
  };
}

export default connect(
  select,
  actions
)(BsRequestsView);
