import http from "../services/Ajax";

class ProjectService {
  static createProject(ProjectInfo) {
    return http.post("/api/v2/projects", ProjectInfo).then(response => {
      if (response.success) {
        return response.data;
      } else {
        throw new Error();
      }
    });
  }

  static fetchProjects() {
    return http.get("/api/v2/projects").then(data => {
      if (data.success) {
        return data.data;
      } else {
        throw new Error();
      }
    });
  }

  static fetchProject(projectId) {
    return http.get("/api/v2/projects/" + projectId).then(data => {
      if (data.success) {
        return data.data;
      } else {
        throw new Error();
      }
    });
  }

  static addProjectRequirements(projectId, skuId) {
    return http
      .post("/api/v2/projects/" + projectId + "/requirements", { skuId })
      .then(response => {
        if (response.success) {
          return response.updated;
        } else {
          throw new Error();
        }
      });
  }

  static fetchRequirements(projectId) {
    return http
      .get("/api/v2/projects/" + projectId + "/requirements")
      .then(response => {
        if (response.success) {
          return response.data;
        } else {
          throw new Error();
        }
      });
  }

  static calculatePricing(quantity, skuId, serviceArea) {
    return http.post("/api/v2/sku/pricing", { quantity, skuId, serviceArea }).then(response => {
      return response;
    })
  }

  static createOrders(projectId, ordersData) {
    return http
      .post("/api/v2/projects/" + projectId + "/orders", ordersData)
      .then(response => {
        if (response.success) {
          return response.data;
        } else {
          throw new Error();
        }
      });
  }

  static getOrders(projectId) {
    return http.get("/api/v2/projects/" + projectId + "/orders").then(data => {
      if (data.success) {
        return data.data;
      } else {
        throw new Error();
      }
    });
  }

  static createBidRequest(projectId, bidRequestData) {
    return http
      .post("/api/v2/projects/" + projectId + "/bidRequests", bidRequestData)
      .then(response => {
        if (response.success) {
          return response.data;
        } else {
          throw new Error();
        }
      });
  }

  static getBidRequest(projectId) {
    return http
      .get("/api/v2/projects/" + projectId + "/bidRequests")
      .then(data => {
        if (data.success) {
          return data.data;
        } else {
          throw new Error();
        }
      });
  }

  static createOrderFromBid(projectId, ordersData) {
    return http
      .post("/api/v2/projects/" + projectId + "/order-bid", ordersData)
      .then(response => {
        if (response.success) {
          return response.data;
        } else {
          throw new Error();
        }
      });
  }

  static deleteProject(projectId) {
    return http.delete("/api/v2/projects/" + projectId);
  }
}

export default ProjectService;
