import React, { Component } from "react";
import { connect } from "react-redux";
import { Checkbox } from "./Checkbox";
import ChangePassword from "../../settings/ChangePassword";
import ModalN from "../../../widgets/modal/ModalN";
import Responsive from "../../../utils/Responsive";

class ShopProductsFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (attribute, value) => {
    this.props.handleFilterChange(attribute, value);
  };

  componentDidMount() {
    var mql = Responsive.smallScreenMedia();
    mql.addListener(this.mediaQueryChanged);
    this.setState({ mql: mql, showMobileNav: mql.matches });
  }

  componentWillUnmount() {
    this.state.mql.removeListener(this.mediaQueryChanged);
  }

  mediaQueryChanged = () => {
    this.setState({ showMobileNav: this.state.mql.matches });
  };

  showAllBrands = () => {
    this.setState({ allBrands: true });
  };

  closeAllBrands = () => {
    this.setState({ allBrands: false });
  };

  render() {
    let {
      checkedFilters,
      filters = [],
      attributeLabels = {},
      brands = [],
    } = this.props;

    return (
      <div className="sidebar sidebar--style-4 z-depth-1-top">
        <div className="mb-3">
          <div className="mb-1" style={{ fontWeight: 500, fontSize: "14px" }}>
            Brand
          </div>

          {brands.slice(0, 10).map((brand, index) => {
            let values = checkedFilters["brand"] || [];
            return (
              <BrandUI
                brand={brand}
                index={index}
                handleChange={this.handleChange}
                values={values}
              />
            );
          })}
          {brands.length > 10 && (
            <div style={{ marginLeft: 20 }}>
              <a className="btn-link" onClick={this.showAllBrands}>
                View More
              </a>
            </div>
          )}
        </div>

        {Object.keys(filters).map((attribute) => (
          <div className="mb-3">
            <div className="mb-1" style={{ fontWeight: 500, fontSize: "14px" }}>
              {attributeLabels[attribute] || attribute}
            </div>
            {filters[attribute].map((value, index) => {
              let values = this.props.checkedFilters[attribute] || [];
              return (
                <div key={attribute + "" + index} style={{ marginLeft: 20 }}>
                  <Checkbox
                    style={{ marginRight: "5px" }}
                    name={value}
                    checked={values.indexOf(value) !== -1}
                    onChange={() => this.handleChange(attribute, value)}
                  />
                  <span style={{ whiteSpace: "nowrap" }}>{value}</span>
                </div>
              );
            })}
          </div>
        ))}

        <ModalN
          title="Brands"
          show={this.state.allBrands}
          close={this.closeAllBrands}
          size="lg"
          style={
            this.state.showMobileNav
              ? {}
              : {
                  position: "absolute",
                  left: "270px",
                  top: "150px",
                }
          }
        >
          <div className="row pb-2">
            {brands.map((brand, index) => {
              let values = checkedFilters["brand"] || [];
              return (
                <div className="col-md-4 col-sm-6">
                  <BrandUI
                    brand={brand}
                    index={index}
                    handleChange={this.handleChange}
                    values={values}
                  />
                </div>
              );
            })}
          </div>
        </ModalN>
      </div>
    );
  }
}

function BrandUI({ brand, index, handleChange, values }) {
  return (
    <div key={brand + "" + index} style={{ marginLeft: 20 }}>
      <Checkbox
        style={{ marginRight: "5px" }}
        name={brand}
        checked={values.indexOf(brand) !== -1}
        onChange={() => handleChange("brand", brand)}
      />
      <span style={{ whiteSpace: "nowrap" }}>{brand}</span>
    </div>
  );
}

function data(state) {
  return {
    attributeLabels: state.sku.attributeLabels,
  };
}

export default connect(data)(ShopProductsFilter);
