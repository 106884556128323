/**
 * @flow
 */

"use strict";
import ajax from "./../../services/Ajax";
import storageService from "../../services/StorageService";

function saveUserData(formData, cb) {
  return (dispatch, getState) => {
    ajax.put("/users", formData).then(responseJson => {
      onSaveUserData(responseJson, cb, formData, dispatch);
    });
  };
}

function onSaveUserData(response, cb, formData, dispatch) {
  cb(response);
  if (response.success) {
    storageService.updateLoginUser(formData);
    dispatch({
      type: "UPDATED_USER_DATA",
      data: {
        name: formData.name,
        city: formData.city,
        address: formData.address
      }
    });
  }
}

function changePassword(formData, cb) {
  return (dispatch, getState) => {
    ajax.put("/users/change/password", formData).then(responseJson => {
      cb(responseJson);
    });
  };
}

function requestSettings() {
  return {
    type: "REQUEST_SETTINGS"
  };
}

function receiveSettings(settings) {
  return {
    type: "RECEIVE_SETTINGS",
    data: { settings: settings },
    isFetching: false
  };
}

function fetchSettings() {
  return dispatch => {
    dispatch(requestSettings());
    ajax.get("/users").then(json => {
      if (json.success) {
        dispatch(receiveSettings(json.data));
      } else {
        dispatch(receiveSettings(null));
      }
    });
  };
}

export { saveUserData, changePassword, fetchSettings };
