/**
 * @flow
 */

'use strict';
function messages(state = {isFetching: false, messages: []}, action = null) {

    var messages = null;

    switch (action.type) {
        case 'REQUEST_MESSAGES':
        {
            return Object.assign({}, state, {isFetching: true});
        }
        case 'RECEIVE_MESSAGES':
        {
            messages = action.data.messages;
            if (!messages || messages.length === 0) {
                return state
            }
            return Object.assign({}, state, {
                isFetching: false,
                messages: messages
            });
        }
        case 'RECEIVE_MESSAGE':
        {
            console.warn('inside RECEIVE_MESSAGE');
            var message = action.data.message;
            messages = Object.assign([], state.messages);
            if (message) {
                messages.push(message);
            }
            return Object.assign({}, state, {
                isFetching: false,
                messages: messages
            });
        }
        case 'ADD_MESSAGE':
        {
            messages = Object.assign([], state.messages);
            var incomingMessage = action.data.message;
            messages.push(incomingMessage);
            return Object.assign({}, state, {
                messages: messages
            });
        }
        case 'BID_CONFIRMED':
        {
            return state;
        }
        default:
        {
            return state;
        }
    }
}
export default messages;