import React from "react";
import AdminLeadsHistory from "./AdminLeadsHistory";
import AdminPipeLineLeads from "./AdminPipeLineLeads";
import AdminTodaysLeads from "./AdminTodaysLeads";
import AdminUnAttendedLeads from "./AdminUnAttendedLeads";
import CreateLeadForm from "./../CreateLeadForm";
import AdminService from "../../../../services/AdminService";
import AdminLeadsCount from "./AdminLeadsCount";

class AdminLeadsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { view: "l" };
  }

  componentDidMount = () => {
    this.getServiceEmployees();
    this.getLeadsCount();
    this.loadBusinessTypeOptions();
    this.loadLeadSource();
    this.getStatusOptions();
  };

  getStatusOptions = () => {
    AdminService.getStatusOptions().then((data) => {
      this.setState({ statusOptions: data[0].options });
    });
  };

  loadLeadSource = () => {
    AdminService.getLeadSourceOptions().then((data) => {
      this.setState({ leadSourceOptions: data[0].options });
    });
  };

  loadBusinessTypeOptions = () => {
    AdminService.getBusinessTypeOptions().then((options = []) => {
      this.setState({ businessTypeOptions: options[0].options });
    });
  };

  getServiceEmployees = () => {
    AdminService.getEmployees().then((employeeOptions = []) => {
      employeeOptions.forEach((option) => {
        option.label = option.name.toUpperCase();
        option.value = option.name;
        delete option.name;
      });
      this.setState({ employeeOptions });
    });
  };

  getLeadsCount = () => {
    AdminService.getAdminLeadsCount().then((response) => {
      if (response) {
        let { todaysCount, historyCount, unAttendedCount, pipelineCount } =
          response;
        this.setState({
          todaysCount,
          historyCount,
          unAttendedCount,
          pipelineCount,
        });
      }
    });
  };

  showCreateLead = () => {
    this.setState({ showCreateLead: true });
  };

  closeCreateLead = () => {
    this.setState({ showCreateLead: false });
  };

  showLeadsHistory = () => {
    this.setState({ view: "lh" });
  };

  showLeads = () => {
    this.setState({ view: "l" });
  };

  showUnattendedLeads = () => {
    this.setState({ view: "ul" });
  };

  showPipeLineLeads = () => {
    this.setState({ view: "pl" });
  };

  showLeadsCount = () => {
    this.setState({ view: "lc" });
  };

  getView = () => {
    let {
      view = "l",
      employeeOptions = [],
      businessTypeOptions = [],
      leadSourceOptions = [],
      statusOptions = [],
    } = this.state;

    if (view === "lh") {
      return (
        <div className="my-3 p-3 border border-light shadow">
          <AdminLeadsHistory
            employeeOptions={employeeOptions}
            getLeadsCount={this.getLeadsCount}
            businessTypeOptions={businessTypeOptions}
            leadSourceOptions={leadSourceOptions}
            statusOptions={statusOptions}
          />
        </div>
      );
    }
    if (
      view === "l" &&
      employeeOptions.length >= 1 &&
      businessTypeOptions.length >= 1
    ) {
      return (
        <div className="my-3 p-3 border border-light shadow">
          <AdminTodaysLeads
            employeeOptions={employeeOptions}
            getLeadsCount={this.getLeadsCount}
            leads={this.props.leads}
            businessTypeOptions={businessTypeOptions}
            leadSourceOptions={leadSourceOptions}
            statusOptions={statusOptions}
          />
        </div>
      );
    }
    if (view === "ul") {
      return (
        <div className="my-3 p-3 border border-light shadow">
          <AdminUnAttendedLeads
            employeeOptions={employeeOptions}
            getLeadsCount={this.getLeadsCount}
            businessTypeOptions={businessTypeOptions}
            leadSourceOptions={leadSourceOptions}
            statusOptions={statusOptions}
          />
        </div>
      );
    }

    if (view === "pl") {
      return (
        <div className="my-3 p-3 border border-light shadow">
          <AdminPipeLineLeads
            employeeOptions={employeeOptions}
            getLeadsCount={this.getLeadsCount}
            businessTypeOptions={businessTypeOptions}
            leadSourceOptions={leadSourceOptions}
            statusOptions={statusOptions}
          />
        </div>
      );
    }

    if (view === "lc") {
      return (
        <div className="my-3 p-3 border border-light shadow">
          <AdminLeadsCount getLeadsCount={this.getLeadsCount} from="admin" />
        </div>
      );
    }
  };

  render() {
    let { view = "l" } = this.state;
    let { showCreateLead } = this.state;

    if (showCreateLead) {
      return (
        <CreateLeadForm
          submit={this.props.submit}
          close={this.closeCreateLead}
        />
      );
    }

    return (
      <div className="mt-4">
        <div className="d-flex justify-content-between">
          <div>
            <button
              className="btn btn-primary btn-sm"
              onClick={this.showCreateLead}
            >
              Create lead
            </button>
          </div>
        </div>
        <div className="tabs tabs--style-2 tabs--centered" role="tabpanel">
          <ul
            className="nav nav-tabs justify-content-center d-flex"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <a
                className={
                  view === "lh"
                    ? "nav-link active text-center text-uppercase tab-link"
                    : "nav-link text-center text-uppercase tab-link"
                }
                onClick={this.showLeadsHistory}
              >
                {"Leads History " + " ( " + this.state.historyCount + " ) "}
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={
                  view === "l"
                    ? "nav-link active text-center text-uppercase tab-link"
                    : "nav-link text-center text-uppercase tab-link"
                }
                onClick={this.showLeads}
              >
                {"Today's Leads" + " ( " + this.state.todaysCount + " ) "}
              </a>
            </li>

            <li className="nav-item" role="presentation">
              <a
                className={
                  view === "ul"
                    ? "nav-link active text-center text-uppercase tab-link"
                    : "nav-link text-center text-uppercase tab-link"
                }
                onClick={this.showUnattendedLeads}
              >
                {"Unattended Leads" +
                  " ( " +
                  this.state.unAttendedCount +
                  " ) "}
              </a>
            </li>

            <li className="nav-item" role="presentation">
              <a
                className={
                  view === "pl"
                    ? "nav-link active text-center text-uppercase tab-link"
                    : "nav-link text-center text-uppercase tab-link"
                }
                onClick={this.showPipeLineLeads}
              >
                {" Pipeline Leads" + " ( " + this.state.pipelineCount + " ) "}
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={
                  view === "lc"
                    ? "nav-link active text-center text-uppercase tab-link"
                    : "nav-link text-center text-uppercase tab-link"
                }
                onClick={this.showLeadsCount}
              >
                Leads count
              </a>
            </li>
          </ul>
        </div>
        <div className="mt-2">{this.getView()}</div>
      </div>
    );
  }
}
export default AdminLeadsView;
