/**
 * @flow
 */

"use strict";
import ajax from "../../../src/services/Ajax";
import * as Toaster from "./toaster";
import * as Modal from "./modal";
import Auth from "./../../services/StorageService";

function opsSearchMasters(criteria, page) {
  return (dispatch, getState) => {
    ajax.post("/products/search", criteria).then(json => {
      if (json.success) {
        dispatch(receiveOpsUsers(json.data, page));
      } else {
        dispatch(receiveOpsUsers([], page));
      }
    });
  };
}

function opsSearchPriceList(criteria, page) {
  return (dispatch, getState) => {
    criteria = Object.assign({}, criteria);
    var city = Auth.getCity();
    if (city) {
      criteria.city = city.value;
    }
    ajax.post("/products/price-list/search", criteria).then(json => {
      if (json.success) {
        dispatch(receiveOpsUsers(json.data, page));
      } else {
        dispatch(receiveOpsUsers([], page));
      }
    });
  };
}

function receiveOpsUsers(users, currentPage) {
  return {
    type: "RECEIVE_OPS_MASTERS",
    data: { users: users, currentPage: currentPage },
    isFetching: false
  };
}

function opsCountMasters(criteria) {
  return dispatch => {
    criteria = Object.assign({}, criteria);
    var city = Auth.getCity();
    if (city) {
      criteria.city = city.value;
    }
    ajax.post("/products/count", criteria).then(json => {
      var count = 0;
      if (json.success) {
        count = json.data;
      }
      dispatch({
        type: "RECEIVE_OPS_MASTERS_COUNT",
        data: { count: count }
      });
    });
  };
}

function getSellers(input, cb) {
  return (dispatch, getState) => {
    ajax.get("/users/sellers?key=" + input).then(function (res) {
      var sellers = res.data || [];
      var newSellers = [];
      for (var i = 0; i < sellers.length; i++) {
        var seller = sellers[i];
        newSellers.push({ value: seller._id, label: seller.name });
      }
      //dispatch({
      //    type: 'RECEIVER_SELLERS',
      //    data: {sellers: newSellers}
      //});
      dispatch({
        type: "DUMMY"
      });
      cb(newSellers);
    });
  };
}

function createDeliveryAddress(userId, data, error, success) {
  return (dispatch, getState) => {
    ajax.post("/delivery-address/" + userId, data).then(res => {
      if (!res.success) {
        var message = res.message || "Server Error. Please try again later.";
        error(message);
      } else {
        success(res.data);
        dispatch({
          type: "DUMMY"
        });
      }
    });
  };
}

function createMaster(formData, ecb, scb) {
  return dispatch => {
    ajax.post("/products", formData).then(res => {
      if (res.success) {
        dispatch({
          type: "MASTER_CREATED",
          data: { master: res.data }
        });
        scb();
      } else {
        ecb(res.message);
      }
    });
  };
}

function editUser(userId, formData, ecb, scb) {
  return dispatch => {
    ajax.put("/users/" + userId, formData).then(res => {
      if (res.success) {
        dispatch({
          type: "USER_UPDATED",
          data: { userId: userId, update: formData }
        });
        scb();
      } else {
        ecb(res.message);
      }
    });
  };
}

function uploadProducts(data, ecb, scb) {
  return dispatch => {
    var formData = new FormData();
    for (var prop in data) {
      if (data.hasOwnProperty(prop)) formData.append(prop, data[prop]);
    }
    ajax.uploadFile("/products/upload/csv", formData).then(res => {
      if (res.success) {
        dispatch(
          Toaster.toastMessage("Done! Products has been added.", "success", 3)
        );
        dispatch(
          Modal.showModal("PRODUCT_UPLOAD_REPORT", { report: res.data }, "lg")
        );
        scb();
      } else {
        ecb(res.message);
      }
    });
  };
}

function getProducts() {
  return (dispatch, getState) => {
    ajax.get("/products").then(json => {
      if (json.success) {
        dispatch({
          type: "RECEIVE_OPS_PRODUCTS",
          data: { names: json.data }
        });
      }
    });
  };
}
function getProductCategories() {
  return (dispatch, getState) => {
    var cityObj = Auth.getCity();
    var city = cityObj.value;
    ajax.get("/products/category?city=" + city).then(json => {
      if (json.success) {
        dispatch({
          type: "RECEIVE_OPS_CATEGORIES",
          data: { productCategories: json.data }
        });
      }
    });
  };
}


function updateProduct(id, formData, ecb, scb) {
  return (dispatch, getState) => {
    ajax.put("/products/" + id, formData).then(res => {
      if (res.success) {
        scb();
      } else {
        ecb(res.message);
      }
    });
  };
}

function getProductAttributes() {
  return (dispatch) => {
    ajax.get("/api/v2/product/attribute-labels").then(json => {
      if (json.success) {
        dispatch({
          type: "RECEIVE_ATTRIBUTE_LABELS",
          data: { attributeLabels: json.data }
        });
      }
    });
  };
}

export {
  opsSearchMasters,
  opsSearchPriceList,
  opsCountMasters,
  getSellers,
  createDeliveryAddress,
  createMaster,
  editUser,
  uploadProducts,
  getProducts,
  getProductCategories,
  updateProduct,
  getProductAttributes
};
