import React from "react";
import Bid from "./Bid";

class BidLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onBidSelect = () => {
    this.props.onBidSelect(this.props.bid);
  };

  render() {
    var classes = this.props.active ? "activeBid" : "";
    return (
      <div>
        <div className={classes} onClick={this.onBidSelect}>
          <Bid
            {...this.props}
            quantity={this.props.quantity}
            classes="clickablePanel"
          />
        </div>
      </div>
    );
  }
}
export default BidLink;

//<div className="col-md-1">
//    <input type="checkbox" checked={this.props.active} onChange={this.onBidSelect}
//           style={{height:'15px',width:'15px'}}/>
//    </div>
//    <div className="col-md-11">
//        <Bid {...this.props}/>
//    </div>
