import React from "react";
import { connect } from "react-redux";
import SideBarMenu from "./SideBarMenu";
import responsive from "../../utils/Responsive";
import { Link } from "react-router-dom";
import StorageService from "../../../src/services/StorageService";

class LoggedInHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    var mql = responsive.smallScreenMedia();
    mql.addListener(this.mediaQueryChanged);
    this.setState({ mql: mql, showMobileNav: mql.matches });
  }

  componentWillUnmount() {
    this.state.mql.removeListener(this.mediaQueryChanged);
  }

  mediaQueryChanged = () => {
    this.setState({ showMobileNav: this.state.mql.matches });
  };

  toggleMobileMenu = () => {
    var showMobileMenu = !this.state.showMobileMenu;
    this.setState({ showMobileMenu: showMobileMenu });
  };

  onCloseMobileMenu = () => {
    this.setState({ showMobileMenu: false });
  };

  onClickCity = () => {
    this.setState({ showCityModal: true });
  };

  render() {
    var header = null;
    var user = this.props.user;
    var city = StorageService.getCity();
    if (this.state.showMobileNav) {
      header = (
        <div className="navbar-header" style={{ padding: "5px" }}>
          <div className="col-xs-2" style={{ padding: "10px 0 0 25px" }}>
            <a onClick={this.toggleMobileMenu} className="link">
              <i
                className="fa fa-bars"
                style={{ fontSize: "18px", color: "#fff" }}
              />
            </a>
          </div>
          <div className="col-xs-9" style={{ padding: "3px 0px 0px 0px" }}>
            <a href="/">
              <img src="images/logo.png" style={{ height: "32px" }} alt="" />
            </a>
          </div>
        </div>
      );
    } else {
      header = (
        <div>
          <div className="navbar-header">
            <a
              className="navbar-brand"
              href="/"
              style={{ marginTop: "9px", paddingLeft: "15px" }}
            >
              <img src="images/logo.png" style={{ height: "32px" }} alt="" />
            </a>
          </div>
          <div
            className="navbar-collapse collapse"
            style={{ marginRight: "40px" }}
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <Link style={{ paddingBottom: "12px" }} to="/dashboard">
                  Dashboard
                </Link>
              </li>
            </ul>
          </div>
        </div>
      );
    }
    return (
      <div>
        {header}
        <SideBarMenu
          show={this.state.showMobileMenu}
          onClose={this.onCloseMobileMenu}
          user={user}
        />
      </div>
    );
  }
}

function data(state) {
  return {
    user: state.user
  };
}

export default connect(data)(LoggedInHeader);
