var CITY = "app-city";
var CONTACT = "user-contact";
var L_USER = "L_USER";

function storeLoginUser(user) {
  localStorage.setItem(L_USER, JSON.stringify(user));
}

function updateLoginUser(newUser) {
  var user = getLoginUser();
  user.name = newUser.name;
  user.city = newUser.city;
  user.address = newUser.address;
  storeLoginUser(user);
}

function setCity(city) {
  localStorage.setItem(CITY, JSON.stringify(city));
}

function storeContact(contact) {
  sessionStorage.setItem(CONTACT, JSON.stringify(contact));
}

function getLoginUser() {
  var stored = JSON.parse(localStorage.getItem(L_USER));
  return stored;
}

function getCity() {
  var stored = JSON.parse(localStorage.getItem(CITY));
  return stored;
}

function getContact() {
  var stored = JSON.parse(sessionStorage.getItem(CONTACT));
  return stored;
}

function getToken() {
  var stored = JSON.parse(localStorage.getItem(L_USER));
  return stored ? stored.token : null;
}

function loggedIn() {
  return !!localStorage.getItem(L_USER);
}

function clearStorage() {
  localStorage.removeItem(L_USER);
}

export default {
  storeLoginUser: storeLoginUser,
  getLoginUser: getLoginUser,
  updateLoginUser: updateLoginUser,
  getToken: getToken,
  loggedIn: loggedIn,
  storeContact: storeContact,
  clearStorage: clearStorage,
  setCity: setCity,
  getCity: getCity,
  getContact: getContact,
};
