import React from "react";
import { connect } from "react-redux";
import Actions from "../../state/actions";
import Error from "../../widgets/alerts/Error";
import Validation from "../../utils/ValidationUtils";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.user || {};
  }

  submit = e => {
    e.preventDefault();
    this.setState({ error: "" });
    var state = this.state;
    var errorMessage = Validation.validateChangePassword(state);
    if (errorMessage) {
      this.setState({ error: errorMessage });
      return;
    }
    this.props.changePassword(state, res => {
      if (res.success) {
        this.props.onComplete();
        this.setState({
          currentPassword: "",
          newPassword: "",
          reNewPassword: ""
        });
        this.props.toastMessage(
          "Done! Your password has been changed.",
          "success",
          3
        );
      } else {
        var message =
          res.message ||
          "Error changing your profile. Please try again later or contact us in the chat.";
        this.setState({ error: message });
      }
    });
  };

  render() {
    return (
      <form
        name="cpForm"
        className="sky-form"
        onSubmit={this.submit}
        noValidate
      >
        <Error message={this.state.error} style={{ margin: "10px" }} />

        <fieldset>
          <section className="col-md-6 col-md-offset-3">
            <div className="form-group">
              <label className="label">Current Password</label>
              <label className="input">
                <input
                  type="password"
                  name="cPassword"
                  placeholder=""
                  className="chat-input"
                  value={this.state.currentPassword}
                  onChange={e =>
                    this.setState({ currentPassword: e.target.value })
                  }
                />
              </label>
            </div>
          </section>
          <section className="col-md-6 col-md-offset-3">
            <div className="form-group">
              <label className="label">New Password</label>
              <label className="input">
                <input
                  type="password"
                  name="newPassword"
                  placeholder=""
                  className="chat-input"
                  value={this.state.newPassword}
                  onChange={e => this.setState({ newPassword: e.target.value })}
                />
              </label>
            </div>
          </section>
          <section className="col-md-6 col-md-offset-3">
            <div className="form-group">
              <label className="label">Reenter New Password</label>
              <label className="input">
                <input
                  type="password"
                  name="reNewPassword"
                  placeholder=""
                  className="chat-input"
                  value={this.state.reNewPassword}
                  onChange={e =>
                    this.setState({ reNewPassword: e.target.value })
                  }
                />
              </label>
            </div>
          </section>
          <section className="col-md-6 col-md-offset-3">
            <button
              className="btn btn-base btn-sign-in pull-right"
              type="submit"
            >
              <span>Submit</span>
            </button>
          </section>
        </fieldset>
      </form>
    );
  }
}

export default connect(
  null,
  function(dispatch) {
    return {
      changePassword: (formData, cb) =>
        dispatch(Actions.changePassword(formData, cb)),
      toastMessage: (message, type, duration) => {
        dispatch(Actions.toastMessage(message, type, duration));
      }
    };
  }
)(ChangePassword);
