import React, { Component } from "react";
import {
  getMinMaxPrice,
  getMinMaxQty,
  getBestStock,
  getAvailableQuantity
} from "../../../utils/Utils";

function StockProduct({
  product,
  selectProduct,
  index,
  changeProductValue,
  onBuyNow
}) {
  let price = getMinMaxPrice(product.stocks);
  let qty = getMinMaxQty(product.stocks);
  let bestStock = {};
  let availableQuantity = {};
  let errorMsg;
  let totalCost = 0;
  if (product.quantity) {
    if (product.quantity >= qty.min) {
      bestStock = getBestStock(product.quantity, product.stocks);
      availableQuantity = getAvailableQuantity(product.quantity, bestStock);
      totalCost = bestStock.price.value * product.quantity;
    } else {
      errorMsg = "Enter Value greater than " + qty.min;
    }
  }

  return (
    <div className="my-3 p-3" style={{ border: "1px solid grey" }}>
      <div>
        {product.sku.category +
          " | " +
          product.sku.type +
          " | " +
          product.sku.subtype +
          " | " +
          product.sku.brand +
          product.sku.attributes.map(
            attribute => " | " + `${attribute.key}` + ":" + `${attribute.value}`
          )}
      </div>

      {/* <div className="my-2">{"Price : " + price.min + " - " + price.max}</div>
        <div>{"Min and Max Quantity : " + qty.min + " - " + qty.max}</div> */}
      {/* <div className="col-md-6 d-flex flex-column">
        <div className="p-2">
          <div className="mb-3">
            <div> </div>
            <input
              min={qty.min}
              // max={qty.max}
              type="number"
              style={{
                border: "1px solid hsl(0,0%,80%)",
                borderRadius: "4px",
                height: "38px",
                padding: "2px 8px",
              }}
              placeholder="Quantity"
              value={product.quantity}
              onChange={(e) => {
                changeProductValue(index, "quantity", e.target.value);
              }}
            />
          </div>
          {product.quantity >= qty.min && (
            <div>{`Price/Unit: ${
              bestStock ? bestStock.price.value : "NA"
            }`}</div>
          )}
          {product.quantity >= qty.min && (
            <div>{`Total cost: ${
              bestStock ? totalCost : "NA"
            }`}</div>
          )}
          {product.quantity >= qty.min && product.quantity%bestStock.serviceQty.multiple!=0 && (
            <div>{`Minimum possible quantity: ${
              bestStock ? availableQuantity.minAvailableQuantity : "NA"
            }`}</div>
          )}
          {product.quantity >= qty.min && product.quantity%bestStock.serviceQty.multiple!=0 &&(
            <div>{`Maximum possible quantity: ${
              bestStock ? availableQuantity.maxAvailableQuantity : "NA"
            }`}</div>
          )}

          {product.quantity < qty.min && <div>{`Error: ${errorMsg}`}</div>} 
        </div>
      </div>  */}

      <div className="d-flex justify-content-end">
        <button
          // disabled={ product.quantity % bestStock.serviceQty.multiple!=0 }
          className="btn btn-success btn-sm"
          onClick={() => {
            selectProduct(product);
          }}
        >
          Add Product to the Project
        </button>
      </div>
    </div>
  );
}

export default StockProduct;
