import React from "react";
import AdminService from "../../../services/AdminService";
import StoreService from "../../../services/StoreService";
import LocationFinder from "../Seller/Store/LocationFinder";
import Select from "react-select";
import Actions from "./../../../state/actions";
import { connect } from "react-redux";
import AsyncSelect from "react-select/async";

class OrdersFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.getStateOptions();
  };

  onChangeSku = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    return newValue;
  };

  loadSkuOptions = (sku, callback) => {
    if (sku.length >= 3) {
      AdminService.searchSku(sku).then((response) => {
        callback(
          response.skus.map((option) => {
            return { label: option._id, value: option._id };
          })
        );
      });
    }
  };

  onSelectSku = (option) => {
    let { filters = {} } = this.state;
    filters.sku = option.value;
    this.setState({ filters });
  };

  onChangeStoreName = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    return newValue;
  };

  loadStoreNameOptions = (storeName, callback) => {
    if (storeName.length >= 3) {
      AdminService.searchStoreName(storeName).then((response) => {
        callback(
          response.storeNames.map((option) => {
            return { label: option.name, value: option._id };
          })
        );
      });
    }
  };

  onSelectStoreName = (option) => {
    let { filters = {} } = this.state;
    filters.storeId = option.value;
    filters.storeName = option.label;
    this.setState({ filters });
  };

  getStateOptions = () => {
    StoreService.getStateOptions().then((stateOptions) => {
      this.setState({
        stateOptions: stateOptions.map((l) => ({ value: l, label: l })),
      });
    });
  };
  getDistrictOptions = () => {
    StoreService.getDistrictOptions().then((districtOptions) => {
      this.setState({
        districtOptions: districtOptions.map((l) => ({ value: l, label: l })),
      });
    });
  };

  getZones = (district) => {
    StoreService.getZoneOptions(district).then((zoneOptions) => {
      this.setState({ zoneOptions });
    });
  };

  getLocalities = (zone) => {
    let { serviceArea } = this.state;
    let localities = [];
    StoreService.getLocalityOptions(zone).then((localitiesOptions) => {
      serviceArea.localities = localitiesOptions.map((l) => l.value);
      serviceArea.localities.forEach((l) => {
        localities.push({ label: l, value: l });
      });
      this.setState({
        localitiesOptions,
        localities,
        serviceArea,
      });
    });
  };

  getServiceEmployees = (followUpType) => {
    AdminService.getEmployees(followUpType).then((employeeOptions = []) => {
      employeeOptions.forEach((option) => {
        option.label = option.name.toUpperCase();
        option.value = option.name;
        delete option.name;
      });
      this.setState({ employeeOptions: employeeOptions });
    });
  };

  onChangeState = (option, getOptions) => {
    let serviceArea = {};
    getOptions === false ? (getOptions = false) : (getOptions = true);
    if (getOptions != false) {
      this.getDistrictOptions(option.value);
    }
    serviceArea.state = option.value;
    this.setState({ serviceAreaFilter: true, serviceArea });
  };

  onChangeDistrict = (option, getOptions) => {
    let { serviceArea } = this.state;
    getOptions === false ? (getOptions = false) : (getOptions = true);
    if (getOptions != false) {
      this.getZones(option.value);
    }
    serviceArea.district = option.value;
    this.setState({ serviceAreaFilter: true, serviceArea });
  };

  onChangeZone = (option, getOptions) => {
    let { serviceArea } = this.state;
    getOptions === false ? (getOptions = false) : (getOptions = true);
    if (getOptions != false) {
      this.getLocalities(option.value);
    }
    serviceArea.zone = option.value;
    this.setState({ serviceArea });
  };

  onChangeLocalities = (options) => {
    let { serviceArea } = this.state;
    if (options) {
      if (options.length > 1) {
        serviceArea.localities = options.map((l) => l.value);
      } else {
        serviceArea.localities = options.value;
      }
    } else {
      serviceArea.localities = [];
    }
    this.setState({ serviceArea, localities: options });
  };

  clearFilters = () => {
    this.setState(
      { showClearFilter: false, filters: {}, serviceArea: {} },
      () => this.props.setFilters({})
    );
  };

  filterOrders = () => {
    let { filters = {}, serviceArea, serviceAreaFilter } = this.state;

    if (serviceAreaFilter && serviceArea) {
      filters.serviceArea = serviceArea;
    }
    filters = JSON.parse(JSON.stringify(filters));
    if (Object.keys(filters).length >= 1) {
      this.props.setFilters(filters);
      this.setState({ showClearFilter: true });
    } else {
      this.props.toastMessage("Please select atleast one filter", "error", 3);
    }
  };

  render() {
    let {
      serviceArea = {},
      localities = [],
      showClearFilter = false,
      filters = {},
    } = this.state;
    return (
      <div>
        <div
          className="d-flex justify-content-between sidebar sidebar--style-4 z-depth-1-top mb-2"
          style={{ padding: "1rem" }}
        >
          <div className="mr-1">
            <div
              className="mb-1"
              style={{ fontWeight: 500, fontSize: "14px", width: "415px" }}
            >
              SKU
            </div>
            <AsyncSelect
              className="flex-fill mb-1"
              placeholder="SKU"
              value={
                filters.sku ? { label: filters.sku, value: filters.sku } : null
              }
              onInputChange={this.onChangeSku}
              loadOptions={this.loadSkuOptions}
              onChange={this.onSelectSku}
            />
          </div>

          <div className="mr-1">
            <div
              className="mb-1"
              style={{ fontWeight: 500, fontSize: "14px", width: "220px" }}
            >
              Store name
            </div>
            <AsyncSelect
              className="flex-fill mb-1"
              placeholder="Store name"
              value={
                filters.storeName
                  ? { label: filters.storeName, value: filters.storeName }
                  : null
              }
              onInputChange={this.onChangeStoreName}
              loadOptions={this.loadStoreNameOptions}
              onChange={this.onSelectStoreName}
            />
          </div>

          <div className="mr-1" style={{ width: "200px" }}>
            <LocationFinder
              onChangeState={this.onChangeState}
              onChangeDistrict={this.onChangeDistrict}
              onChangeZone={this.onChangeZone}
              onChangeLocality={this.onChangeLocalities}
            />
            <div>
              <div className="mr-1" style={{ width: "200px" }}>
                <Select
                  className="flex-fill mb-1"
                  placeholder="State"
                  options={this.state.stateOptions}
                  value={
                    serviceArea.state
                      ? { label: serviceArea.state, value: serviceArea.state }
                      : null
                  }
                  onChange={this.onChangeState}
                  isMulti={false}
                />
              </div>
              {serviceArea.state && (
                <div className="mr-1" style={{ width: "200px" }}>
                  <Select
                    className="flex-fill mb-1"
                    placeholder="District"
                    options={this.state.districtOptions}
                    value={
                      serviceArea.district
                        ? {
                            label: serviceArea.district,
                            value: serviceArea.district,
                          }
                        : null
                    }
                    onChange={this.onChangeDistrict}
                    isMulti={false}
                  />
                </div>
              )}
              {serviceArea.district && (
                <div className="mr-1" style={{ width: "200px" }}>
                  <Select
                    className="flex-fill mb-1"
                    placeholder="Zone"
                    options={this.state.zoneOptions}
                    value={
                      serviceArea.zone
                        ? { label: serviceArea.zone, value: serviceArea.zone }
                        : null
                    }
                    onChange={this.onChangeZone}
                  />
                </div>
              )}

              {serviceArea.zone && (
                <div style={{ width: "400px" }}>
                  <Select
                    className="flex-fill"
                    placeholder="Localities"
                    options={this.state.localitiesOptions}
                    value={localities ? localities : null}
                    onChange={this.onChangeLocalities}
                    isMulti
                  />
                </div>
              )}
            </div>
            <div
              className="mt-1 d-flex align-items-center justify-content-center"
              style={{ float: "right" }}
            >
              {showClearFilter && (
                <div
                  style={{ cursor: "pointer" }}
                  className="d-flex align-items-center justify-content-center"
                  onClick={this.clearFilters}
                >
                  <a className="fa fa-times text-danger mr-1" />
                  <div className="text-danger mr-1">Clear filters</div>
                </div>
              )}
              <button className="btn btn-primary" onClick={this.filterOrders}>
                Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const actions = {
  toastMessage: Actions.toastMessage,
};

export default connect(null, actions)(OrdersFilter);
