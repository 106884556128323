import React from "react";
import StoreService from "./../../../../services/StoreService";
import ProjectService from "./../../../../services/ProjectService";
import ProductAsRequirement from "./ProductAsRequirement";
import Actions from "./../../../../state/actions";
import { connect } from "react-redux";

function groupBySubtype(products) {
  let subtypeMap = {};
  products.forEach((product) => {
    let subtype = product.sku.subtype + "-" + product.sku.brand;

    if (subtypeMap[subtype]) {
      subtypeMap[subtype].push(product);
    } else {
      subtypeMap[subtype] = [product];
    }
  });
  return subtypeMap;
}

class ProjectCartView extends React.PureComponent {
  constructor(props) {
    super(props);
    let subtypeMap = groupBySubtype(props.cart);
    this.state = {
      productMap: subtypeMap,
      show: false,
    };
  }

  manageDuplicate = (e, type, index, subType, brand) => {
    let { productMap } = this.state;
    let products = productMap[subType + "-" + brand];
    if (type === "copy") {
      products.push({ ...products[index] });
    } else if (type === "remove") {
      products.splice(index, 1);
    }
    this.setState({ products: [...products] });
  };

  changeProductQuantity = (subType, index, brand, key, value) => {
    let { productMap = {} } = this.state;
    let products = productMap[subType + "-" + brand];
    let product = products[index];
    // let error = false;

    // if (key === "quantity") {
    //   if (!value || isNaN(value)) {
    //     error = true;
    //   } else {
    //     let parsed = parseInt(value);
    //     let qty = getMinMaxQty(product.stocks);
    //     let bestStock = getBestStock(parsed, product.stocks);
    //     error = !(
    //       parsed >= qty.min && parsed % bestStock.serviceQty.multiple === 0
    //     );
    //   }
    // }

    product = { ...product, [key]: value };
    products[index] = product;
    productMap[subType + "-" + brand] = [...products];
    this.setState({ productMap: { ...productMap } });
  };

  validate = () => {
    return true;
  };

  getProductDetails = () => {
    let { products } = this.state;
    products.map((e) => {
      console.log("getting product details" + e.sku);
    });

    return products;
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  doBuyNow = (subType, brand) => {
    let { productMap } = this.state;
    let reqs = productMap[subType + "-" + brand];

    for (let i = 0; i < reqs.length; i++) {
      if (reqs[i].error) {
        this.props.toastMessage(
          "Some of the products are having errors. Please fix them before submitting.",
          "error",
          5
        );
        return;
      }
    }

    let ordersData = reqs.map((req) => ({
      quantity: req.quantity,
      skuId: req.sku._id,
    }));
    ProjectService.createOrders(this.props.project._id, ordersData).then(
      (response) => {
        this.props.toastMessage("Order(s) have been created.", "success", 5);
        this.setState({ show: true });
        delete productMap[subType + "-" + brand];
        this.setState({ productMap: { ...productMap } });
      }
    );
  };

  doBidRequest = (subType, brand) => {
    let { productMap } = this.state;
    let reqs = productMap[subType + "-" + brand];

    let bidRequestData = reqs.map((req) => ({
      quantity: req.quantity,
      sku: req.sku,
    }));
    ProjectService.createBidRequest(
      this.props.project._id,
      bidRequestData
    ).then((response) => {
      this.props.toastMessage("Bid Request has been created", "success", 5);
      delete productMap[subType + "-" + brand];
      this.setState({ productMap: { ...productMap } });
    });
  };

  calculateTotalPrice = (sb) => {
    let totalPrice = 0;
    this.state.productMap[sb].forEach((product) => {
      totalPrice += product.totalPrice;
      // this.setState({ totalPrice });
      return totalPrice;
    });
  };

  render() {
    let { productMap } = this.state;

    let subTypes = Object.keys(productMap);
    if (Object.keys(productMap).length) {
      return (
        <div>
          {subTypes.map((sb, index) => {
            let parts = sb.split("-");
            let subType = parts[0];
            let brand = parts[1];
            let products = productMap[sb];
            let fromComponent = "PROJECT_CART";
            let totalPrice = 0;

            return (
              <div
                className="row border border-dark"
                style={{
                  margin: "25px",
                  padding: "20px",
                  border: "2px solid #eeeeee",
                  borderRadius: "9px",
                }}
              >
                <div className="col-md-12">
                  <div className="d-flex flex-column">
                    <h4>{subType + "/" + brand}</h4>
                    <div className="d-flex row">
                      {products.map((product, index) => {
                        totalPrice += product.totalPrice;
                        return (
                          <ProductAsRequirement
                            requirement={product}
                            index={index}
                            changeProductQuantity={this.changeProductQuantity}
                            doBuyNow={this.props.doBuyNow}
                            doBidRequest={this.props.doBidRequest}
                            fromComponent={fromComponent}
                            subType={subType}
                            project={this.props.project}
                            updateCart={this.props.updateCart}
                          />
                        );
                      })}
                    </div>

                    <div className="d-flex justify-content-end align-items-center">
                      <div
                        className="price-value strong-600"
                        style={{ margin: "5px" }}
                      >
                        {`Total Price: ₹${totalPrice}`}
                      </div>

                      <button
                        className="btn btn-success"
                        style={{ margin: "5px" }}
                        onClick={() => {
                          this.doBuyNow(subType, brand);
                        }}
                      >
                        Buy now
                      </button>

                      {totalPrice >= 100000 && (
                        <div>
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              this.doBidRequest(subType, brand);
                            }}
                          >
                            Wait for better Bid
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <section className="slice sct-color-1">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="text-center">
                  <h5 style={{ textTransform: "none" }}>
                    Your cart is empty.{" "}
                    <a className="btn-link" onClick={this.props.closeCart}>
                      Click
                    </a>{" "}
                    here to go back.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
}

const actions = {
  toastMessage: Actions.toastMessage,
};

export default connect(null, actions)(ProjectCartView);
