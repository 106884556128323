/**
 * @flow
 */

'use strict';
function products(state = {
    isFetching: false, items: [], currentPage: 1, count: 0, productCategories: [],
    maxPages: 0, maxVisible: 1, perPage: 50, sellers: [], uploadReport: null
}, action = null) {

    switch (action.type) {
        case 'RECEIVE_OPS_MASTERS':
            return Object.assign({}, state, {
                isFetching: false,
                items: action.data.users,
                currentPage: action.data.currentPage
            });
        case'RECEIVE_OPS_MASTERS_COUNT': {
            var count = action.data.count;
            var maxPages = -1;
            if (count !== 0) {
                maxPages = Math.ceil(count / state.perPage);
            }
            var maxVisible = 10;
            if (maxPages < 10) {
                maxVisible = maxPages;
            }
            return Object.assign({}, state, {count: count, maxPages: maxPages, maxVisible: maxVisible});
        }
        case'RECEIVE_OPS_PRODUCTS': {
            var names = action.data.names;
            return Object.assign({}, state, {items: names});
        }
        case'RECEIVE_OPS_CATEGORIES': {
            var productCategories = action.data.productCategories;
            return Object.assign({}, state, {productCategories: productCategories});
        }
        case 'RECEIVER_SELLERS': {
            var sellers = action.data.sellers;
            return Object.assign({}, state, {sellers: sellers})
        }
        case 'MASTER_CREATED': {
            var master = action.data.master;
            return state;
        }
        case 'USERS_CREATED': {
            var report = action.data.report;
            return Object.assign({}, state, {report: report});
        }
        case 'USER_UPDATED': {
            var update = action.data.update, userId = action.data.userId;
            var users = state.items, user = null;
            for (var i = 0; i < users.length; i++) {
                if (userId === users[i]._id) {
                    user = users[i];
                    break;
                }
            }
            if (user) {
                user.name = update.name;
                user.email = update.email;
                user.phone = update.phone;
                user.city = update.city;
                user.address = update.address;
            }
            return Object.assign({}, state, {items: [].concat(users)})
        }
        default:
            return state;
    }
}

export default products;