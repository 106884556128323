import React from "react";
import { formatLeadDate } from "../../../utils/Utils";

export default function DetailedLeadView({ lead }) {
  let { serviceArea, nextCallDate } = lead;
  return (
    <div>
      <div>
        <h6>Lead Source : {lead.leadSource} </h6>
      </div>
      <div>
        <h6>Name : {lead.name || "NA"} </h6>
      </div>
      <div>
        <h6>Followup type : {lead.followUpType} </h6>
      </div>
      <div>
        <h6>Status : {lead.status || "NA"} </h6>
      </div>
      <div>
        <h6>Created at : {formatLeadDate(lead.createdAt)} </h6>
      </div>
      <div>
        <h6>Phone : {lead.phone} </h6>
      </div>
      <div>
        <h6>Email : {lead.email || "NA"} </h6>
      </div>
      <div>
        <h6>
          Service area :
          {serviceArea && Object.keys(serviceArea).length > 0 ? (
            <div>
              {`${serviceArea.locality ? `${serviceArea.locality}, ` : ""}
                ${serviceArea.zone ? `${serviceArea.zone}, ` : ""} 
                ${serviceArea.district ? serviceArea.district : ""}`}
            </div>
          ) : (
            <div>{"NA"}</div>
          )}{" "}
        </h6>
      </div>
      <div>
        <h6>Comment : {lead.comment || "NA"} </h6>
      </div>
      <div>
        <h6>Business type : {lead.businessType || "NA"} </h6>
      </div>
      <div>
        <h6>
          Updated on :
          {lead.updatedOn
            ? formatLeadDate(lead.updatedOn, "from")
            : "Not yet updated"}{" "}
        </h6>
      </div>
      <div>
        <h6>
          Next call date :
          {lead.status === "junk"
            ? "Junk Lead"
            : nextCallDate
            ? formatLeadDate(lead.nextCallDate)
            : "Not available"}{" "}
        </h6>
      </div>
    </div>
  );
}
