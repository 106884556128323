/**
 * @flow
 */

'use strict';
function requests(state = {
    isFetching: false, items: [], currentPage: 1, count: 0,
    maxPages: 0, maxVisible: 1, perPage: 5, request: null
}, action = null) {

    switch (action.type) {
        case 'REQUEST_REQUESTS':
            return Object.assign({}, state, {isFetching: true});
        case 'RECEIVE_REQUESTS':
            return Object.assign({}, state, {
                isFetching: false,
                items: action.data.requestItems
            });
        case 'RECEIVE_REQUEST':
        {
            return Object.assign({}, state, {
                request: action.data.request
            });
        }
        default:
            return state;
    }
}

export default requests;