import React from "react";

export default function ServiceLocationsView2({ serviceAreas }) {
  return (
    <div>
      <table className="table table-cart">
        <thead>
          <tr>
            <th>District</th>
            <th>Zone</th>
            <th>Localities</th>
          </tr>
        </thead>
        <tbody>
          {serviceAreas.map((d) => (
            <React.Fragment>
              <tr>
                <td>{d.district}</td>
                <td>
                  {d.zones.map((z) => (
                    <div>
                      <td>{z.zone}</td>
                      <hr></hr>
                    </div>
                  ))}
                </td>
                <td>
                  {d.zones.map((z) => (
                    <div>
                      <td>{z.localities.join(", ")}</td>
                      <hr></hr>
                    </div>
                  ))}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}
