import React from "react";
import AdminService from "../../../services/AdminService";
import StoreService from "../../../services/StoreService";
import SingleServiceAreaForm from "../Seller/Store/SingleServiceAreaForm";
import Actions from "./../../../state/actions";
import { connect } from "react-redux";
import Select from "react-select";
import RevenueView from "./RevenueView";

const months = [
  { label: "January", value: "01" },
  { label: "February", value: "02" },
  { label: "March", value: "03" },
  { label: "April", value: "04" },
  { label: "May", value: "05" },
  { label: "June", value: "06" },
  { label: "July", value: "07" },
  { label: "August", value: "08" },
  { label: "September", value: "09" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];
class GMVModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { serviceArea: {} };
  }

  componentDidMount() {
    StoreService.getStateOptions().then((stateOptions) => {
      this.setState({
        stateOptions: stateOptions.map((l) => ({ value: l, label: l })),
      });
    });
    let d = new Date();
    let month = d.getMonth() + 1;
    this.setState({ month }, () => {
      this.getGMVWrapper();
    });
  }

  onChangeState = (option, getOptions) => {
    let { serviceArea } = this.state;
    serviceArea.state = option.value;
    serviceArea.district = null;
    serviceArea.zone = null;
    serviceArea.locality = null;

    getOptions === false ? (getOptions = false) : (getOptions = true);
    this.setState({ serviceArea }, () => {
      if (getOptions != false) {
        this.getDistrictOptions(option.value);
      }
    });
  };

  onChangeDistrict = (option, getOptions) => {
    let { serviceArea } = this.state;
    serviceArea.district = option.value;
    serviceArea.district = option.value;
    serviceArea.zone = null;
    serviceArea.locality = null;
    getOptions === false ? (getOptions = false) : (getOptions = true);
    this.setState({ serviceArea }, () => {
      if (getOptions != false) {
        this.getZoneOptions(option.value);
      }
    });
  };

  onChangeZone = (option, getOptions) => {
    let { serviceArea } = this.state;
    serviceArea.zone = option.value;
    serviceArea.locality = null;
    getOptions === false ? (getOptions = false) : (getOptions = true);
    this.setState({ serviceArea }, () => {
      if (getOptions != false) {
        this.getLocalityOptions(option.value);
      }
    });
  };

  onChangeLocality = (option) => {
    let { serviceArea } = this.state;
    serviceArea.locality = option.value;
    this.setState({ serviceArea });
  };

  getDistrictOptions = (state) => {
    StoreService.getDistrictOptions(state).then((districtOptions) => {
      this.setState({
        districtOptions: districtOptions.map((l) => ({ value: l, label: l })),
      });
    });
  };

  getZoneOptions = (district) => {
    StoreService.getZoneOptions(district).then((zoneOptions) => {
      this.setState({ zoneOptions });
    });
  };

  getLocalityOptions = (zone) => {
    let { serviceArea } = this.state;
    StoreService.getLocalityOptions(zone).then((localitiesOptions) => {
      this.setState({
        localitiesOptions,
        localities: localitiesOptions,
        serviceArea,
      });
    });
  };

  onchangeMonth = (option) => {
    this.setState({ month: option.value });
  };

  getGMVWrapper = () => {
    let { serviceArea, month } = this.state;
    let { skuId } = this.props;

    if (Object.keys(serviceArea).length >= 1) {
      this.getGMV(skuId, month, serviceArea);
    } else {
      this.getGMV(skuId, month);
    }
  };

  getGMV = (skuId, month, serviceArea) => {
    AdminService.getSkuGmv(skuId, serviceArea, month).then((response) => {
      if (response.success) {
        let { completedOrders, pendingOrders } = response.data;
        let totalCompletedGMV = 0;
        let totalPendingGMV = 0;
        if (completedOrders && completedOrders.length >= 1) {
          completedOrders.map((order) => {
            totalCompletedGMV += order.totalPrice;
          });
        }

        if (pendingOrders && pendingOrders.length >= 1) {
          pendingOrders.map((order) => {
            totalPendingGMV += order.totalPrice;
          });
        }
        this.setState({
          totalCompletedGMV,
          totalPendingGMV,
          completedOrders,
          pendingOrders,
        });
      }
    });
  };

  render() {
    let {
      serviceArea = {},
      completedOrders = [],
      pendingOrders = [],
      month,
    } = this.state;
    return (
      <div>
        <div className="d-flex">
          <div>
            <div className="mb-2" style={{ width: "50%" }}>
              <div
                className="mb-1"
                style={{ fontWeight: 500, fontSize: "14px" }}
              >
                Month
              </div>
              <Select
                placeholder="Month"
                options={months}
                onChange={this.onchangeMonth}
              />
            </div>
            <div style={{ height: "calc(100vh - 380px)", width: "37%" }}>
              <div
                className="mb-1"
                style={{ fontWeight: 500, fontSize: "14px" }}
              >
                Service Area
              </div>
              <SingleServiceAreaForm
                districtOptions={this.state.districtOptions}
                serviceArea={serviceArea}
                onChangeDistrict={this.onChangeDistrict}
                zoneOptions={this.state.zoneOptions}
                onChangeZone={this.onChangeZone}
                localitiesOptions={this.state.localitiesOptions}
                onChangeLocality={this.onChangeLocality}
                onChangeState={this.onChangeState}
                stateOptions={this.state.stateOptions}
              />
            </div>
          </div>

          <div
            className="d-flex"
            style={{
              border: "1px solid #c4c4c4",
              padding: 10,
              borderRadius: 8,
              height: "max-content",
            }}
          >
            <div className="mr-1">
              {completedOrders && completedOrders.length >= 1 ? (
                <React.Fragment>
                  <div>
                    <h5>Delivered orders</h5>
                  </div>
                  <RevenueView orders={completedOrders} />
                </React.Fragment>
              ) : (
                <div>No completed orders</div>
              )}
            </div>
            <div className="mr-1">
              {pendingOrders && pendingOrders.length >= 1 ? (
                <React.Fragment>
                  <div>
                    <h5>Pending orders</h5>
                  </div>
                  <RevenueView orders={pendingOrders} />
                </React.Fragment>
              ) : (
                <div>No pending orders</div>
              )}
            </div>
          </div>
        </div>
        <div>
          <button
            onClick={this.getGMVWrapper}
            className="btn btn-md btn-primary mt-1"
          >
            Get GMV
          </button>
        </div>
      </div>
    );
  }
}

const actions = {
  toastMessage: Actions.toastMessage,
};

export default connect(null, actions)(GMVModal);
