import React, { Component } from "react";
import Product from "./Product";
import StockProduct from "./StockProduct";

class ShopProducts extends Component {
  render() {
    let { products, stocks } = this.props;
    // let ProductComponent = stocks ? StockProduct : Product;
    let ProductComponent = Product;
    return (
      <div className="row">
        {products.map((product, index) => {
          return (
            <ProductComponent
              user={this.props.user}
              stocks={stocks}
              product={product}
              selectProduct={this.props.selectProduct}
              index={index}
              changeProductValue={this.props.changeProductValue}
            />
          );
        })}
      </div>
    );
  }
}

export default ShopProducts;
