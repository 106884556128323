import React from "react";
import NavLink from "../../widgets/NavLink";
import LogOut from "../auth/LogOut";

class MobileLeftNavMain extends React.Component {
  onLinkClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    if (this.props.userType === "C") {
      return (
        <nav className="navbar">
          <div className="left-navbar">
            <ul className="nav nav-stacked">
              <li>
                <NavLink
                  to="/dashboard/projects"
                  onlyActiveOnIndex={true}
                  className="left-link"
                  onClick={this.onLinkClick}
                >
                  <i
                    className="fa fa-building"
                    style={{ marginRight: "10px" }}
                  />
                  Projects
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/dashboard"
                  onlyActiveOnIndex={true}
                  className="left-link"
                  onClick={this.onLinkClick}
                >
                  <i
                    className="fa fa-ellipsis-h"
                    style={{ marginRight: "10px" }}
                  />
                  Chat
                </NavLink>
              </li>
              {/*<li>*/}
              {/*  <NavLink*/}
              {/*    to="/dashboard/requests"*/}
              {/*    className="left-link"*/}
              {/*    onClick={this.onLinkClick}*/}
              {/*  >*/}
              {/*    <i*/}
              {/*      className="fa fa-ellipsis-h"*/}
              {/*      style={{ marginRight: "10px" }}*/}
              {/*    />*/}
              {/*    Bid Requests*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <NavLink*/}
              {/*    to="/dashboard/orders"*/}
              {/*    className="left-link"*/}
              {/*    onClick={this.onLinkClick}*/}
              {/*  >*/}
              {/*    <i*/}
              {/*      className="fa fa-file-text-o"*/}
              {/*      style={{ marginRight: "10px" }}*/}
              {/*    />*/}
              {/*    Orders*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              <li>
                <NavLink
                  to="/dashboard/settings"
                  className="left-link"
                  onClick={this.onLinkClick}
                >
                  <i className="fa fa-cog" style={{ marginRight: "10px" }} />
                  Settings
                </NavLink>
              </li>
              <li>
                <LogOut routeTo="signin" />
              </li>
            </ul>
          </div>
        </nav>
      );
    } else if (this.props.userType === "B") {
      return (
        <nav className="navbar">
          <div className="left-navbar">
            <ul className="nav nav-stacked">
              <li>
                <NavLink
                  to="/dashboard/stores"
                  onlyActiveOnIndex={true}
                  className="left-link"
                  onClick={this.onLinkClick}
                >
                  <i
                    className="fa fa-building"
                    style={{ marginRight: "10px" }}
                  />
                  Stores
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/dashboard/orders"
                  activeClassName="active-nav"
                  className="left-link"
                >
                  <i className="fa fa-list" style={{ marginRight: "10px" }} />
                  Orders
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/dashboard"
                  onlyActiveOnIndex={true}
                  className="left-link"
                  onClick={this.onLinkClick}
                >
                  <i
                    className="fa fa-ellipsis-h"
                    style={{ marginRight: "10px" }}
                  />
                  Chat
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/dashboard/settings"
                  className="left-link"
                  onClick={this.onLinkClick}
                >
                  <i className="fa fa-cog" style={{ marginRight: "10px" }} />
                  Settings
                </NavLink>
              </li>
              <li>
                <LogOut routeTo="signin" />
              </li>
            </ul>
          </div>
        </nav>
      );
    } else if (this.props.userType === "O") {
      return (
        <nav className="navbar">
          <div className="left-navbar">
            <ul className="nav nav-stacked">
              <li>
                <NavLink
                  to="/dashboard/leads"
                  activeClassName="active-nav"
                  className="left-link"
                >
                  <i
                    className="fa fa-list-ul"
                    style={{ marginRight: "10px" }}
                  />
                  Leads
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to="/dashboard/non-serviceable"
                  activeClassName="active-nav"
                  className="left-link"
                >
                  <i className="fa fa-times" style={{ marginRight: "10px" }} />
                  Non serviceable Reqs
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  to="/dashboard/virtual-stores"
                  activeClassName="active-nav"
                  className="left-link"
                >
                  <i
                    className="fa fa-building"
                    style={{ marginRight: "10px" }}
                  />
                  Reselling stores
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/dashboard/orders"
                  activeClassName="active-nav"
                  className="left-link"
                >
                  <i className="fa fa-list" style={{ marginRight: "10px" }} />
                  Orders
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/dashboard/bid-requests"
                  activeClassName="active-nav"
                  className="left-link"
                >
                  <i
                    className="fa fa-exchange"
                    style={{ marginRight: "10px" }}
                  />
                  Bid Requests
                </NavLink>
              </li>

              <li>
                <LogOut routeTo="/signin" />
              </li>
            </ul>
          </div>
        </nav>
      );
    } else {
      return null;
    }
  }
}

export default MobileLeftNavMain;
