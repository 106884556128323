import React from "react";

export default function(props) {
  return (
    <div
      id="globalError"
      style={{
        // margin: "5px 450px",
        display: "none",
        textAlign: "center",
        position: "absolute",
        left: "50%",
        zIndex: 9999,
        width: "300px",
        top: "10px"
      }}
    >
      <div
        style={{
          position: "relative",
          left: "-50%",
          background: "#fff",
          border: "2px solid #f8ac59"
        }}
      >
        Server Error. Please try again later.
      </div>
    </div>
  );
}
