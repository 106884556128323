import React from "react";
import { connect } from "react-redux";
import AnonymousHeader from "./AnonymousHeader";
import LoggedInHeader from "../../components/headers/LoggedInHeader";

class Header extends React.Component {
  render() {
    var loggedIn = !!(this.props.user && this.props.user.isLoggedIn);
    return (
      <div id="divHeaderWrapper">
        <header className="header-standard-2">
          <div
            className="navbar navbar-wp navbar-arrow mega-nav"
            role="navigation"
            style={{
              borderBottom: "0px",
              background: loggedIn
                ? this.props.pathname === "/"
                  ? "rgb(84, 178, 247)"
                  : "#3c8dbc"
                : "rgb(84, 178, 247)"
            }}
          >
            {!loggedIn && <AnonymousHeader />}
            {loggedIn && <LoggedInHeader />}
          </div>
        </header>
      </div>
    );
  }
}

function data(state) {
  return {
    user: state.user
  };
}

export default connect(data)(Header);
