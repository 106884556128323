import { parse } from "@babel/core";
import React from "react";
import AdminService from "../../../services/AdminService";
import InvestorService from "../../../services/InvestorService";
import InfiniteScroller from "../../../widgets/InfiniteScroll/InfiniteScroller";
import { VIRTUAL_STORE_PAGE_SIZE } from "../Constants";

const months = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

class VirtualStoresGmv extends React.Component {
  constructor(props) {
    super(props);
    this.state = { virtualStores: [], count: -1, currentPage: 1, filters: {} };
  }

  componentDidMount() {
    this.getVirtualStoresCount();
    this.getVirtualStoresGMV();
  }

  getMonths = () => {
    let { virtualStores } = this.state;
    let startMonth = virtualStores[0].createdAt.slice(5, 7);
    if (startMonth[0] == 0) {
      startMonth = startMonth[1];
    }
    startMonth = parseInt(startMonth);
    let startYear = virtualStores[0].createdAt.slice(0, 4);
    startYear = parseInt(startYear);
    let d = new Date();
    let thisMonth = d.getMonth() + 1;
    thisMonth = parseInt(thisMonth);
    let thisYear = d.getFullYear();
    thisYear = parseInt(thisYear);

    let months = [];

    for (
      startMonth;
      startMonth + " - " + startYear != thisMonth + " - " + thisYear;
      startMonth++
    ) {
      if (startMonth >= 13) {
        startMonth = 1;
        startYear += 1;
      }
      months.push({ month: startMonth, year: startYear });
    }
    months.push({ month: thisMonth, year: thisYear });
    return months.reverse();
  };

  modifyDate = (date) => {
    if (!date) {
      return "";
    }
    date = date.substr(0, date.indexOf("T"));
    date = date.split("-").reverse().join("-");
    return date;
  };

  getTotalGmvPerMonth = (month, year, storeId) => {
    let { orders = {}, virtualStores = [] } = this.state;
    let totalPrice = 0;
    if (orders[storeId]) {
      orders[storeId].map((order) => {
        let startMonth = order.createdAt.slice(5, 7);
        if (startMonth[0] == 0) {
          startMonth = startMonth[1];
        }
        let startYear = order.createdAt.slice(0, 4);
        if (startMonth == month && startYear == year) {
          totalPrice += order.totalPrice;
        }
      });
      return totalPrice != 0 ? "₹ " + parseInt(totalPrice) : "-";
    } else {
      return "-";
    }
  };

  getVirtualStoresCount = () => {
    let { filters = {} } = this.state;
    AdminService.getVirtualStoresCount(filters, "admin").then((data) => {
      this.setState({ count: data });
    });
  };

  getVirtualStoresGMV = () => {
    let {
      filters = {},
      currentPage,
      virtualStores = [],
      orders = {},
    } = this.state;
    filters.page = currentPage;
    filters.pageSize = VIRTUAL_STORE_PAGE_SIZE;
    this.setState({ loadingStores: true });
    InvestorService.getVirtualStoresGMV(filters).then((response) => {
      if (response.success) {
        this.setState({
          virtualStores: virtualStores.concat(response.virtualStores),
          loadingStores: false,
          orders: { ...orders, ...response.ordersMap },
          currentPage: currentPage + 1,
        });
      }
    });
  };

  render() {
    let { virtualStores = [], count, currentPage, loadingStores } = this.state;
    let loadMore = false;
    if (count !== -1) {
      let totalPages = Math.ceil(count / VIRTUAL_STORE_PAGE_SIZE);
      loadMore = currentPage - 1 < totalPages;
    }
    if (virtualStores.length >= 1) {
      return (
        <InfiniteScroller
          loadMore={() => {
            if (!loadingStores && loadMore) {
              this.getVirtualStoresGMV();
            }
          }}
        >
          <div className="d-flex align-items-center justify-content-center">
            <div>
              <h4>Monthly GMV</h4>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-cart">
              <thead>
                <tr>
                  <th>Store name</th>
                  <th>Date of creation</th>
                  {this.getMonths().map((m) => (
                    <th>{months[m.month] + " - " + m.year}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {virtualStores.map((store) => (
                  <tr>
                    <td>{store.name}</td>
                    <td>{this.modifyDate(store.createdAt)}</td>
                    {this.getMonths().map((m) => (
                      <td>
                        {this.getTotalGmvPerMonth(m.month, m.year, store._id)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </InfiniteScroller>
      );
    } else {
      return <div>No Virtual stores</div>;
    }
  }
}

export default VirtualStoresGmv;
