import React from "react";
import history from "../../../../utils/History";

class StoresView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  openStore = (storeId) => {
    history.push("/dashboard/stores/" + "ps/" + storeId + "/s");
  };

  openVirtualStore = (storeId) => {
    history.push("/dashboard/stores/" + "v/" + storeId + "/s");
  };

  startCreateStore = () => {
    history.push("/dashboard/new-store");
  };

  startCreateVirtualStore = () => {
    history.push("/dashboard/new-virtual-store");
  };

  render() {
    let { stores, virtualStores } = this.props;

    if (!stores) {
      return null;
    }

    return (
      <div>
        <div className="d-flex justify-content-between mb-3">
          <h3>My stocks</h3>
          <div className="d-flex">
            <div className="mr-1">
              <a className="btn btn-primary" onClick={this.startCreateStore}>
                Create Stock
              </a>
            </div>
            <div>
              <a
                className="btn btn-primary"
                onClick={this.startCreateVirtualStore}
              >
                Create Reselling Store
              </a>
            </div>
          </div>
        </div>

        {stores.length >= 1 ? (
          <div className="row">
            {stores.map((store) => {
              return (
                <div className="col-md-4">
                  <div className="card mb-1">
                    <div
                      className="card-body"
                      onClick={() => {
                        this.openStore(store._id);
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <h3 className="heading heading-5 strong-500">
                        {store.name}
                      </h3>
                      <span className="badge bg-base-1 text-uppercase mb-3">
                        {store.status || "ACTIVE"}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center">
                <p className="mt-5 px-5" style={{ fontSize: 16 }}>
                  You have not created any stores yet. Click{" "}
                  <a className="btn-link" onClick={this.startCreateStore}>
                    here
                  </a>{" "}
                  to create a store.
                </p>
              </div>
            </div>
          </div>
        )}
        <hr />
        <div>
          <h3>Reselling Stores</h3>
          {virtualStores.length >= 1 ? (
            <div className="row">
              {virtualStores.map((virtualStore) => {
                return (
                  <div className="col-md-4">
                    <div className="card mb-1">
                      <div
                        className="card-body"
                        onClick={() => {
                          this.openVirtualStore(virtualStore._id);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <h3 className="heading heading-5 strong-500">
                          {virtualStore.name}
                        </h3>
                        <span className="badge bg-base-1 text-uppercase mb-3">
                          {virtualStore.status || "ACTIVE"}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center">
                  <p className="mt-5 px-5" style={{ fontSize: 16 }}>
                    You have not created any Reselling stores yet. Click{" "}
                    <a
                      className="btn-link"
                      onClick={this.startCreateVirtualStore}
                    >
                      here
                    </a>{" "}
                    to create a Reselling store.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default StoresView;
