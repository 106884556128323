import React from "react";
import StoreService from "../../../../services/StoreService";
import Actions from "./../../../../state/actions";
import { connect } from "react-redux";
import ProductImage from "../../Shop/ProductImage";
import ProductTitle from "../../Shop/ProductTitle";

class StoreBidRequestView extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            storeBidRequests: [],
            bidValue: null
        };
    }

    componentDidMount() {
        this.loadStoreBidRequests();
    }

    loadStoreBidRequests = () => {
        StoreService.getStoreBidRequest(this.props.storeId).then(
            (storeBidRequests = []) => {
                this.setState({ storeBidRequests });
            }
        );
    };
    onChangeBidValue = (e, request) => {
        request.bidValue = e.target.value;
    };
    createNewBid = (request, bestPrice) => {
        let requestId = request._id;
        let bidValue = request.bidValue;
        if (bidValue) {
            if (bidValue >= bestPrice) {
                // window.alert("Enter value less than current bid");
                this.props.toastMessage(
                    "Enter value less than current bid",
                    "error",
                    5
                );
            } else {
                StoreService.placeBid(bidValue, requestId, this.props.storeId).then(
                    response => {
                        // window.alert("Bid Request has been created");
                        this.props.toastMessage("Bid created", "success", 5);
                        this.loadStoreBidRequests();
                    }
                );
            }
        } else {
            this.props.toastMessage("Enter bid value", "error", 5);
        }
    };

    render() {
        let storeBidRequests = this.state.storeBidRequests;
        let bidValue = this.state.bidValue;
        if (storeBidRequests && storeBidRequests.length >= 1) {
            let projectIds = Object.keys(storeBidRequests);
            // return projectIds.map(projectId => {
            let bidRequests = storeBidRequests;
            return (
                <div className="table-responsive">
                    <table className="table table-cart">
                        <thead>
                        <tr>
                            <th className="product-image" />
                            <th>Product</th>
                            <th>Attributes</th>
                            <th>Quantity</th>
                            <th>Current bestprice</th>
                            <th>Created at</th>
                            <th>Status</th>
                            <th>Delivery address</th>
                            <th>Your bid</th>
                            <th />
                        </tr>
                        </thead>
                        <tbody>
                        {bidRequests.map((request, index) => {
                            let {
                                category,
                                type,
                                brand,
                                subtype,
                                attributes,
                                descriptionAttributes
                            } = request.sku;
                            let quantity = request.quantity;
                            let status = request.status;
                            let bestPrice = Number.POSITIVE_INFINITY;
                            let tmp;
                            let bids = request.bids;
                            let _id = request._id;
                            let deliveryAddress = request.deliveryAddress;
                            let minStoreId;
                            let date = request.createdAt;
                            date = date.substr(0, date.indexOf("T"));
                            date = date
                                .split("-")
                                .reverse()
                                .join("-");

                            bids.forEach(bid => {
                                tmp = bid.sellerPrice;
                                if (tmp < bestPrice) {
                                    bestPrice = tmp;
                                    minStoreId = bid.storeId;
                                }
                            });
                            let inputStyle;
                            if (status === "PENDING") {
                                inputStyle = {
                                    backgroundColor: " #0f69f2",
                                    borderColor: " #0f69f2"
                                };
                            } else if (status === "ACTIVE") {
                                inputStyle = {
                                    backgroundColor: " #4CAF50",
                                    borderColor: " #4CAF50"
                                };
                            }
                            let attributesMap = {};
                            attributes.forEach(item => {
                                if (!attributesMap[item.key]) {
                                    attributesMap[item.key] = item.value;
                                }
                            });
                            return (
                                <tr>
                                    <td>
                                        <ProductImage images={request.sku.images} subtype={request.sku.subtype} />
                                    </td>
                                    <td className="product-name">
                                        <ProductTitle
                                            brand={brand}
                                            subtype={subtype}
                                            descriptionAttributes={descriptionAttributes}
                                            attributes={attributesMap}
                                        />
                                    </td>
                                    <td>
                                        <ul>
                                            {attributes.map(attribute => (
                                                <li>
                                                    {" "}
                                                    {attribute.key}:{attribute.value}
                                                </li>
                                            ))}
                                            <li>Category:{category}</li>
                                            <li>Type:{type}</li>
                                        </ul>
                                    </td>
                                    <td>{quantity}</td>
                                    <td>
                                        ₹{bestPrice}(
                                        {request.sku.pricing.isTaxIncluded === "Y"
                                            ? "Including tax "
                                            : "Excluding tax "}
                                        and
                                        {request.sku.pricing.isDeliveryIncluded === "Y"
                                            ? " Including Delivery charges "
                                            : " Excluding Delivery charges"}
                                        )
                                        {this.props.storeId === minStoreId && (
                                            <div
                                                style={{
                                                    color: "green"
                                                }}
                                            >
                                                Yours is the best bid
                                            </div>
                                        )}
                                    </td>
                                    <td>{date}</td>
                                    <td>
                                            <span
                                                className="badge text-uppercase mb-3"
                                                style={inputStyle}
                                            >
                                                {status || "ACTIVE"}
                                            </span>
                                    </td>
                                    <td>{deliveryAddress}</td>
                                    <td>
                                        <input
                                            type="number"
                                            style={{
                                                border: "1px solid hsl(0,0%,80%)",
                                                borderRadius: "4px",
                                                height: "38px",
                                                padding: "2px 8px"
                                            }}
                                            placeholder="Your Bid"
                                            value={request.bidValue}
                                            onChange={e => {
                                                this.onChangeBidValue(e, request);
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <div>
                                            <a
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    this.createNewBid(request, bestPrice);
                                                }}
                                            >
                                                Place Bid
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            );
            // }
            // );
        } else if (!storeBidRequests || storeBidRequests.length === 0) {
            return (
                <section className="slice sct-color-1">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <div className="d-block font-size-3 font-size-md-6 c-gray-light">
                                        <i className="fa fa-shopping-bag" aria-hidden="true" />
                                    </div>

                                    <div style={{ fontSize: 16 }}>
                                        There are no matching Bid Requests available for your store
                                        at the moment.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
        }
    }
}

const actions = {
    toastMessage: Actions.toastMessage
};

export default connect(
    null,
    actions
)(StoreBidRequestView);
