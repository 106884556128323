import React from "react";
import StoreService from "../../../../services/StoreService";
import EditSingleLocation from "./EditSingleLocation";
import { connect } from "react-redux";
import Actions from "./../../../../state/actions";
import AdminService from "../../../../services/AdminService";

class ServiceLocationsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    if (this.props.stock) {
      StoreService.getStockLocations(this.props.stock._id).then((response) => {
        this.setState({ serviceAreas: response.serviceAreas.serviceAreas });
      });
    } else if (this.props.storeId) {
      AdminService.getVirtualStoreLocations(this.props.storeId).then(
        (response) => {
          this.setState({ serviceAreas: response.serviceAreas.serviceAreas });
        }
      );
    } else {
      this.setState({ serviceAreas: this.props.serviceAreas });
    }
  };

  editServiceArea = (serviceArea) => {
    this.setState({ showEditServiceArea: true, serviceArea });
  };

  hideEditServiceArea = () => {
    this.setState({ showEditServiceArea: false });
  };

  editServiceLocation = (serviceArea) => {
    let storeId = this.props.stock.storeId;
    let stockId = this.props.stock._id;
    StoreService.updateStockServiceLocation(serviceArea, storeId, stockId)
      .then((response) => {
        this.props.toastMessage(
          "This service area has  been updated",
          "success",
          3
        );
        this.props.hideServiceLocations(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  render() {
    let { from } = this.props;
    let { serviceAreas = [], showEditServiceArea, serviceArea } = this.state;
    if (showEditServiceArea) {
      return (
        <EditSingleLocation
          editServiceLocation={this.editServiceLocation}
          serviceArea={serviceArea}
        />
      );
    }
    if (serviceAreas.length === 1) {
      let serviceArea = serviceAreas[0];
      return (
        <div>
          <div className="mb-1">State: {serviceArea.state}</div>
          <div className="mb-1">District: {serviceArea.district}</div>
          <div className="mb-1">Zone: {serviceArea.zone}</div>
          <div className="mb-1">
            <div>Localities: {serviceArea.localities.join(", ")}</div>
            {!from && (
              <div style={{ float: "right" }}>
                <a className="btn-link text-danger mr-1">
                  <i
                    onClick={() => {
                      this.editServiceArea(serviceArea);
                    }}
                    className="fa fa-pencil-square-o"
                  />
                </a>
              </div>
            )}
          </div>
        </div>
      );
    }
    return (
      <div>
        <table className="table table-cart">
          <thead>
            <tr>
              <th>State</th>
              <th>District</th>
              <th>Zone</th>
              <th>Localities</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {serviceAreas.map((serviceArea, index) => (
              <React.Fragment>
                <tr>
                  <td>{serviceArea.state}</td>
                  <td>{serviceArea.district}</td>
                  <td>{serviceArea.zone}</td>
                  <td>{serviceArea.localities.join(", ")}</td>
                  {!from && (
                    <React.Fragment>
                      <td>
                        <span>
                          <a className="btn-link text-danger mr-1">
                            <i
                              onClick={() => {
                                this.editServiceArea(serviceArea);
                              }}
                              className="fa fa-pencil-square-o"
                            />
                          </a>
                        </span>
                      </td>
                    </React.Fragment>
                  )}
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

function actions(dispatch) {
  return {
    toastMessage: (message, type, duration) => {
      dispatch(Actions.toastMessage(message, type, duration));
    },
  };
}

export default connect(null, actions, null, { forwardRef: true })(
  ServiceLocationsView
);
