import React from "react";
import Select from "react-select";
import StoreService from "../../../../services/StoreService";
import ValidationUtils from "../../../../utils/ValidationUtils";
import Error from "../../../../widgets/alerts/Error";

class EditSingleLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let { serviceArea } = this.props;
    this.setState({
      district: serviceArea.district,
      zone: serviceArea.zone,
      localities: serviceArea.localities,
    });
    this.getLocalities(serviceArea.zone);
  }

  getLocalities = (zone) => {
    StoreService.getLocalityOptions(zone).then((localitiesOptions) => {
      this.setState({ localitiesOptions });
    });
  };

  onChangeLocalities = (options) => {
    this.setState({
      localities: options,
    });
  };

  submitForm = (e) => {
    let { serviceArea } = this.props;
    e.preventDefault();
    var errorMessage = ValidationUtils.validateServiceAreaForm(
      this.state,
      true
    );
    if (errorMessage) {
      this.setState({ error: errorMessage });
      return;
    }
    let { district, zone } = this.state;
    let localitiesValues = this.state.localities || serviceArea.localities;
    let localities = [];
    localitiesValues.forEach((l, index) => {
      localities.push(l.value ? l.value : l);
    });
    this.props.editServiceLocation({ district, zone, localities });
  };

  render() {
    let { serviceArea } = this.props;
    let { localitiesOptions } = this.state;

    let localitiesValues = [];

    serviceArea.localities.forEach((l) => {
      localitiesValues.push({ label: l, value: l });
    });

    return (
      <div className="form-body">
        <form className="form-default" onSubmit={this.submitForm}>
          <Error message={this.state.error} style={{ margin: "10px" }} />
          <div className="d-flex mb-1 flex-column">
            <div className="mb-1 d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>

            <Select
              className="flex-fill mb-1"
              placeholder="District"
              defaultValue={{
                label: serviceArea.state,
                value: serviceArea.state,
              }}
              isDisabled={true}
            />

            <Select
              className="flex-fill mb-1"
              placeholder="District"
              defaultValue={{
                label: serviceArea.district,
                value: serviceArea.district,
              }}
              isDisabled={true}
            />

            <Select
              className="flex-fill mb-1"
              placeholder="Zone"
              defaultValue={{
                label: serviceArea.zone,
                value: serviceArea.zone,
              }}
              isDisabled={true}
            />

            <Select
              className="flex-fill"
              placeholder="Localities"
              options={localitiesOptions}
              defaultValue={localitiesValues}
              onChange={this.onChangeLocalities}
              isMulti
            />
          </div>

          <div className="mt-2 d-flex justify-content-end">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default EditSingleLocation;
