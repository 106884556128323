import http from "../services/Ajax";

class StoreService {
  static createStore(storeInfo) {
    return http.post("/api/v2/stores", storeInfo).then((response) => {
      if (response.success) {
        return response.data;
      } else {
        throw new Error();
      }
    });
  }

  static createVirtualStore(storeInfo) {
    return http.post("/api/v2/virtual-stores", storeInfo).then((response) => {
      if (response.success) {
        return response.data;
      } else {
        throw new Error();
      }
    });
  }

  static uploadBannerImages(formData, storeId) {
    return http
      .uploadFile(
        "/api/v2/virtual-stores/" + storeId + "/images",
        formData
      )
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  }

  static removeBannerImage(storeId, imageName) {
    return http
      .patch("/api/v2/virtual-stores/" + storeId + "/banner-image/" + imageName)
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  }

  static deleteServiceArea(serviceArea, storeId) {
    return http
      .post("/api/v2/virtual-stores/" + storeId + "/delete-service-area", {
        serviceArea,
      })
      .then((response) => {
        if (response.success) {
          return response.success;
        } else {
          throw new Error();
        }
      });
  }

  static updateServiceLocation(serviceArea, storeId) {
    return http.patch("/api/v2/virtual-stores/" + storeId + "/service-area", {
      serviceArea,
    });
  }

  static updateStockServiceLocation(serviceArea, storeId, stockId) {
    return http
      .patch(
        "/api/v2/stores/" + storeId + "/stocks/" + stockId + "/service-area",
        {
          serviceArea,
        }
      )
      .then((response) => {
        if (response.success) {
          return response.success;
        } else {
          throw new Error();
        }
      });
  }

  static addServiceLocation(serviceAreas, storeId) {
    return http
      .post("/api/v2/virtual-stores/" + storeId + "/service-area", {
        serviceAreas,
      })
      .then((response) => {
        if (response.success) {
          return response.success;
        } else {
          throw new Error();
        }
      });
  }

  static calculatePricing(quantity, skuId, serviceArea) {
    return http
      .post("/api/v2/stores/sku/pricing", { quantity, skuId, serviceArea })
      .then((response) => {
        return response;
      });
  }

  static changeQuantity(orderId, quantity, storeId) {
    return http
      .patch("/api/v2/stores/" + storeId + "/update-order-quantity", {
        quantity,
        storeId,
        orderId,
      })
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  }

  static updateDeliveryStatus(status, storeId, orderId) {
    return http
      .patch("/api/v2/stores/" + storeId + "/update-delivery-status", {
        status,
        storeId,
        orderId,
      })
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  }

  static fetchStores() {
    return http.get("/api/v2/stores").then((response) => {
      if (response.success) {
        return response.data;
      } else {
        throw new Error();
      }
    });
  }

  static fetchStore(storeType, storeId) {
    let api = null;
    if (storeType === "ps") {
      api = "/api/v2/stores/" + storeId;
    } else if (storeType === "v") {
      api = "/api/v2/virtual-stores/" + storeId;
    }
    return http.get(api).then((response) => {
      if (response.success) {
        return response.data;
      } else {
        throw new Error();
      }
    });
  }

  static getSellerPhone() {
    return http.get("/api/v2/stores/seller-phone").then((response) => {
      if (response.success) {
        return response.data;
      } else {
        throw new Error();
      }
    });
  }

  static fetchStocks(storeId, page, pageSize, checkedFilters) {
    return http
      .post(
        "/api/v2/stores/" +
          storeId +
          "/get-stocks?currentPage=" +
          page +
          "&pageSize=" +
          pageSize,
        { checkedFilters }
      )
      .then((response) => {
        if (response.success) {
          return response.data;
        } else {
          throw new Error();
        }
      });
  }

  static getStockLocations(stockId) {
    {
      return http
        .get("/api/v2/stores/stock/" + stockId + "/service-area")
        .then((response) => {
          if (response.success) {
            return response;
          } else {
            throw new Error();
          }
        });
    }
  }

  static fetchVirtualStoreStocks(storeId, page, pageSize, checkedFilters) {
    return http
      .post(
        "/api/v2/virtual-stores/" +
          storeId +
          "/get-store-stocks?currentPage=" +
          page +
          "&pageSize=" +
          pageSize,
        { checkedFilters }
      )
      .then((response) => {
        if (response.success) {
          return response.data;
        } else {
          throw new Error();
        }
      });
  }

  static getVirtualStoreLocations(storeId) {
    return http
      .get("/api/v2/virtual-stores/" + storeId + "/service-area")
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  }

  static fetchVirtualStoreStockIds(storeId) {
    return http
      .get("/api/v2/virtual-stores/" + storeId + "/stockIds")
      .then((response) => {
        if (response.success) {
          return response.data;
        } else {
          throw new Error();
        }
      });
  }

  static getStateOptions = () => {
    return http.get("/api/v2/locations/states").then((response) => {
      if (response.success) {
        return response.data;
      } else {
        throw new Error();
      }
    });
  };

  static getVirtualStoreStateOptions = (storeId) => {
    return http.get("/api/v2/locations/states/" + storeId).then((response) => {
      if (response.success) {
        return response.data;
      } else {
        throw new Error();
      }
    });
  };

  static getDistrictOptions = (state) => {
    return http
      .get("/api/v2/locations/districts?state=" + state)
      .then((response) => {
        if (response.success) {
          return response.data;
        } else {
          throw new Error();
        }
      });
  };

  static getVirtualStoreDistrictOptions = (state, storeId) => {
    return http
      .get(
        "/api/v2/locations/virtual-store/districts?state=" +
          state +
          "&storeId=" +
          storeId
      )
      .then((response) => {
        if (response.success) {
          return response.data;
        } else {
          throw new Error();
        }
      });
  };

  static getZoneOptions = (district) => {
    return http
      .get("/api/v2/locations/zones?district=" + district)
      .then((response) => {
        if (response.success) {
          let zoneOptions = response.data;
          return zoneOptions.map((l) => ({ value: l, label: l }));
        } else {
          throw new Error();
        }
      });
  };

  static getVirtualStoreZoneOptions = (district, storeId) => {
    return http
      .get(
        "/api/v2/locations/virtual-store/zones?district=" +
          district +
          "&storeId=" +
          storeId
      )
      .then((response) => {
        if (response.success) {
          let zoneOptions = response.data;
          return zoneOptions.map((l) => ({ value: l, label: l }));
        } else {
          throw new Error();
        }
      });
  };

  static getLocalityOptions = (zone) => {
    return http
      .get("/api/v2/locations/localities?zone=" + zone)
      .then((response) => {
        if (response.success) {
          let localityOptions = response.data;
          return localityOptions.map((l) => ({ value: l, label: l }));
        } else {
          throw new Error();
        }
      });
  };

  static getVirtualStoreLocalityOptions = (zone, storeId) => {
    return http
      .get(
        "/api/v2/locations/virtual-store/localities?zone=" +
          zone +
          "&storeId=" +
          storeId
      )
      .then((response) => {
        if (response.success) {
          let localityOptions = response.data;
          return localityOptions.map((l) => ({ value: l, label: l }));
        } else {
          throw new Error();
        }
      });
  };

  static addStocksToStore(storeId, stocks) {
    return http
      .post("/api/v2/stores/" + storeId + "/stocks", stocks)
      .then((response) => {
        if (response.success) {
          return response.data;
        } else {
          throw new Error();
        }
      });
  }

  static addStocksToVirtualStore(storeId, stocks) {
    return http
      .post("/api/v2/virtual-stores/" + storeId + "/stocks", stocks)
      .then((response) => {
        if (response.success) {
          return response.data;
        } else {
          throw new Error();
        }
      });
  }

  static getVirtualStoreStocks(buyerData, checkedFilters) {
    return http
      .post("/api/v2/virtual-store-stocks/", { buyerData, checkedFilters })
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  }

  static filterStocks(filter, projectId) {
    let { subtype, brand } = filter;
    delete filter.subtype;
    delete filter.brand;
    delete filter.district;
    delete filter.locality;
    delete filter.zone;

    return http
      .post("/api/v2/project/" + projectId + "/stocks", {
        subtype,
        brand,
        attributes: filter,
      })
      .then((response) => {
        if (response.success) {
          return response.data;
        } else {
          throw new Error();
        }
      });
  }

  static deleteStocks(storeId, stockIds) {
    return http.post("/api/v2/stores/" + storeId + "/stocks/delete", {
      stockIds: stockIds,
    });
  }

  static addQuantity(quantityDetails, storeId, stockId) {
    return http
      .patch("/api/v2/stores/" + storeId + "/add-stock-quantity", {
        quantityDetails,
        stockId,
      })
      .then((response) => {
        if (response.success) {
          return response.success;
        } else {
          throw new Error();
        }
      });
  }

  static updateQuantityStatus(stockId, skuId, storeId, index) {
    return http
      .patch("/api/v2/stores/" + storeId + "/update-stock-quantity-status", {
        stockId,
        skuId,
        index,
      })
      .then((response) => {
        if (response.success) {
          return response.success;
        } else {
          throw new Error();
        }
      });
  }

  static deleteVirtualStoreStocks(storeId, stockIds, skuIds) {
    return http.post("/api/v2/virtual-stores/" + storeId + "/stocks/delete", {
      stockIds,
      skuIds,
    });
  }

  static deleteStore(storeId, type) {
    return http.delete("/api/v2/stores/" + storeId);
  }

  static deleteVirtualStore(storeId, type) {
    return http.delete("/api/v2/virtual-stores/" + storeId);
  }

  static editStocks(storeId, stockId, price, index) {
    return http.patch("/api/v2/stores/" + storeId + "/stocks/" + stockId, {
      price,
      index,
    });
  }

  static editVirtualStoreStocks(storeId, stockId, price, ownStockMarkUp) {
    return http.patch(
      "/api/v2/virtual-stores/" + storeId + "/stocks/" + stockId,
      {
        price,
        ownStockMarkUp,
      }
    );
  }

  static getSellerAllOrders() {
    return http.get("/api/v2/stores/all-orders").then((response) => {
      if (response.success) {
        return response.data;
      } else {
        throw new Error();
      }
    });
  }

  static getStoreOrders(storeId) {
    return http
      .get("/api/v2/stores/" + storeId + "/orders")
      .then((response) => {
        if (response.success) {
          return response.data;
        } else {
          throw new Error();
        }
      });
  }

  static getVirtualStoreOrders(storeId) {
    return http
      .get("/api/v2/virtual-stores/" + storeId + "/orders")
      .then((data) => {
        if (data.success) {
          return data.data;
        } else {
          throw new Error();
        }
      });
  }

  static getStoreBidRequest(storeId) {
    return http
      .get("/api/v2/stores/" + storeId + "/bidRequests")
      .then((data) => {
        if (data.success) {
          return data.data;
        } else {
          throw new Error();
        }
      });
  }

  static placeBid(bidValue, requestId, storeId) {
    return http
      .post("/api/v2/bidrequests/" + requestId + "/bids", { bidValue, storeId })
      .then((response) => {
        if (response.success) {
          return response.data;
        } else {
          throw new Error();
        }
      });
  }

  static publishVirtualStore(storeId, url) {
    return http.patch("/api/v2/virtual-stores/" + storeId + "/publish/", {
      url,
    });
  }
}

export default StoreService;
