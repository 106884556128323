import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import SettingsView from "../settings/SettingsView";
import SkusContainer from "../rb/skus/SkusContainer";
import AdminOrdersView from "./../rb/skus/AdminOrdersView";
import AdminBidRequestsView from "./../rb/skus/AdminBidRequestsView";
import AdminNonServiceableView from "./../rb/skus/AdminNonServiceableView";
import CustomerLeadContainer from "./../rb/Leads/employees/CustomerLeadContainer";
import DeactivateBids from "./DeactivateBids";
import VirtualStoresView from "../rb/skus/VirtualStoresView";
import AdminUsersView from "../rb/skus/AdminUsersView";
import VirtualStoresGmv from "../rb/skus/VirtualStoresGmv";

class AdminMainContent extends React.Component {
  render() {
    let { smallScreen, leftNav, contentHeight } = this.props;

    if (smallScreen) {
      return <div>Please access it from Laptop/Desktop computer.</div>;
    } else {
      return (
        <div className="row">
          <div className="col-md-2">{leftNav}</div>
          <div className="col-md-10">
            <div className="main-content">
              <div
                className="mc"
                style={{
                  height: "calc(100vh - 50px)",
                  overflowY: "auto",
                }}
                id="main-layout"
              >
                <div style={{ padding: "20px" }}>
                  <Switch>
                    <Route path="/dashboard/skus" component={SkusContainer} />
                    <Route path="/dashboard/users" component={AdminUsersView} />
                    <Route
                      path="/dashboard/settings"
                      component={SettingsView}
                    />
                    <Route
                      path="/dashboard/orders"
                      component={AdminOrdersView}
                    />
                    <Route
                      path="/dashboard/bid-requests"
                      component={AdminBidRequestsView}
                    />
                    <Route
                      path="/dashboard/non-serviceable"
                      component={AdminNonServiceableView}
                    />
                    <Route
                      path="/dashboard/virtual-stores"
                      component={VirtualStoresView}
                    />
                    <Route path="/dashboard/gmv" component={VirtualStoresGmv} />
                    <Route
                      path="/dashboard/crm/"
                      component={CustomerLeadContainer}
                    />

                    <Route path="/dashboard/dab" component={DeactivateBids} />

                    <Redirect from="/" to="/dashboard/skus" />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default AdminMainContent;
