import React from "react";
import MapComponent from "../../Map";
import Select from "react-select";
import StoreService from "./../../../../services/StoreService";
import ValidationUtils from "../../../../utils/ValidationUtils";
import Error from "../../../../widgets/alerts/Error";
import history from "../../../../utils/History";
import LocationFinder from "../../Seller/Store/LocationFinder";

const options = [
  { value: "shop", label: "Shop" },
  { value: "site", label: "Site" },
];

class NewProjectMataData extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      projectType: { value: "site", label: "Site" },
      serviceArea: {},
    };
  }

  componentDidMount() {
    StoreService.getStateOptions().then((stateOptions) => {
      this.setState({
        stateOptions: stateOptions.map((l) => ({ value: l, label: l })),
      });
    });
  }

  onChangeName = (e) => {
    this.setState({ name: e.target.value });
  };

  onChangePhone = (e) => {
    this.setState({ phone: e.target.value });
  };

  onChangeAddress = (e) => {
    this.setState({ address: e.target.value });
  };

  onChangeProjectType = (option) => {
    this.setState({ projectType: option || null });
  };

  onChangeState = (option, getOptions) => {
    let { serviceArea = {} } = this.state;
    serviceArea["state"] = option;
    serviceArea.district = null;
    serviceArea.zone = null;
    serviceArea.locality = null;
    getOptions === false ? (getOptions = false) : (getOptions = true);
    if (getOptions != false) {
      this.getDistricts(option.value);
    }
    this.setState({ serviceArea: { ...serviceArea } });
  };

  onChangeDistrict = (option, getOptions) => {
    let { serviceArea = {} } = this.state;
    serviceArea["district"] = option;
    serviceArea.zone = null;
    serviceArea.locality = null;
    getOptions === false ? (getOptions = false) : (getOptions = true);
    if (getOptions != false) {
      this.getZones(option.value);
    }
    this.setState({ serviceArea: { ...serviceArea } });
  };

  onChangeZone = (option, getOptions) => {
    let { serviceArea = {} } = this.state;
    serviceArea["zone"] = option;
    serviceArea.locality = null;
    getOptions === false ? (getOptions = false) : (getOptions = true);
    if (getOptions != false) {
      this.getLocalities(option.value);
    }
    this.setState({
      serviceArea: { ...serviceArea },
    });
  };

  onChangeLocality = (options) => {
    let { serviceArea = {} } = this.state;
    serviceArea["locality"] = options;
    this.setState({
      serviceArea: { ...serviceArea },
    });
  };

  getDistricts = (state) => {
    StoreService.getDistrictOptions(state).then((districtOptions) => {
      this.setState({
        districtOptions: districtOptions.map((l) => ({ value: l, label: l })),
      });
    });
  };

  getZones = (district) => {
    StoreService.getZoneOptions(district).then((zoneOptions) => {
      this.setState({ zoneOptions });
    });
  };

  getLocalities = (zone) => {
    StoreService.getLocalityOptions(zone).then((localitiesOptions) => {
      this.setState({ localitiesOptions });
    });
  };

  cancelForm = () => {
    history.push("/dashboard/projects/");
  };

  submitForm = (e) => {
    e.preventDefault();
    var errorMessage = ValidationUtils.validateNewProjectForm(this.state);
    if (errorMessage) {
      this.setState({ error: errorMessage });
      return;
    }

    let data = JSON.parse(JSON.stringify(this.state));
    let location = this.mapRef.getLocationDetails();
    data.position = {
      latitude: location.lat,
      longitude: location.lng,
    };
    let serviceArea = data.serviceArea;
    serviceArea.state = serviceArea.state ? serviceArea.state.value : null;
    serviceArea.district = serviceArea.district
      ? serviceArea.district.value
      : null;
    serviceArea.zone = serviceArea.zone ? serviceArea.zone.value : null;
    serviceArea.locality = serviceArea.locality
      ? serviceArea.locality.value
      : null;
    data.projectType = data.projectType.value;
    this.props.submit(data);
  };

  render() {
    let { name, phone, address, serviceArea = {}, projectType } = this.state;

    return (
      <div className="row d-flex justify-content-center">
        <div className="col-md-9">
          <div
            className="card form-card form-card--style-2"
            style={{
              border: "1px solid #f1f1f1",
              padding: "15px",
            }}
          >
            <div className="form-body">
              <form className="form-default" onSubmit={this.submitForm}>
                <h4 className="text-center">New Project</h4>
                <Error message={this.state.error} style={{ margin: "10px" }} />
                <div className="col-12">
                  <div className="form-group">
                    <label>Project Name</label>
                    <input
                      className="form-control form-control-lg"
                      value={name}
                      onChange={this.onChangeName}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>Phone</label>
                    <input
                      className="form-control form-control-lg"
                      value={phone}
                      onChange={this.onChangePhone}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <label>Project Type</label>
                    <Select
                      className="flex-fill mb-2"
                      placeholder="Project Type"
                      options={options}
                      value={projectType}
                      onChange={this.onChangeProjectType}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <label>Project Location</label>
                    <LocationFinder
                      onChangeState={this.onChangeState}
                      onChangeDistrict={this.onChangeDistrict}
                      onChangeZone={this.onChangeZone}
                      onChangeLocality={this.onChangeLocality}
                    />
                    <Select
                      className="flex-fill mb-2"
                      placeholder="State"
                      options={this.state.stateOptions}
                      value={serviceArea.state}
                      onChange={this.onChangeState}
                    />

                    {serviceArea.state && (
                      <Select
                        className="flex-fill mb-2"
                        placeholder="District"
                        options={this.state.districtOptions}
                        value={serviceArea.district}
                        onChange={this.onChangeDistrict}
                      />
                    )}
                    {serviceArea.district && (
                      <Select
                        className="flex-fill mb-3"
                        placeholder="Zone"
                        options={this.state.zoneOptions}
                        value={serviceArea.zone}
                        onChange={this.onChangeZone}
                      />
                    )}

                    {serviceArea.zone && (
                      <Select
                        className="flex-fill"
                        placeholder="Locality"
                        options={this.state.localitiesOptions}
                        value={serviceArea.locality}
                        onChange={this.onChangeLocality}
                      />
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>Project Address</label>
                    <input
                      className="form-control form-control-lg"
                      value={address}
                      onChange={this.onChangeAddress}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <label>Project Location In Map</label>

                    <div>
                      <MapComponent
                        ref={(r) => {
                          this.mapRef = r;
                        }}
                      />{" "}
                    </div>
                  </div>
                </div>

                <div className="my-3 d-flex justify-content-between">
                  <button
                    className="btn btn-secondary"
                    onClick={this.cancelForm}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewProjectMataData;
