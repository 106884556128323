import React from "react";
import Select from "react-select";
import AdminService from "../../../services/AdminService";
import StoreService from "../../../services/StoreService";
import Actions from "./../../../state/actions";
import { connect } from "react-redux";
import LocationFinder from "../Seller/Store/LocationFinder";

class VirtualStoresFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
      serviceArea: {},
      serviceAreaFilter: false,
      sortType: { label: "Alphabetically (A-Z)", value: "alphabetically" },
    };
  }
  componentDidMount = () => {
    this.getStateOptions();
    // this.getServiceEmployees("store creation");
  };

  getStateOptions = () => {
    StoreService.getStateOptions().then((stateOptions) => {
      this.setState({
        stateOptions: stateOptions.map((l) => ({ value: l, label: l })),
      });
    });
  };
  getDistrictOptions = () => {
    StoreService.getDistrictOptions().then((districtOptions) => {
      this.setState({
        districtOptions: districtOptions.map((l) => ({ value: l, label: l })),
      });
    });
  };

  getZones = (district) => {
    StoreService.getZoneOptions(district).then((zoneOptions) => {
      this.setState({ zoneOptions });
    });
  };

  getLocalities = (zone) => {
    let { serviceArea } = this.state;
    let localities = [];
    StoreService.getLocalityOptions(zone).then((localitiesOptions) => {
      serviceArea.localities = localitiesOptions.map((l) => l.value);
      serviceArea.localities.forEach((l) => {
        localities.push({ label: l, value: l });
      });
      this.setState({
        localitiesOptions,
        localities,
        serviceArea,
      });
    });
  };

  getServiceEmployees = (followUpType) => {
    AdminService.getEmployees(followUpType).then((employeeOptions = []) => {
      employeeOptions.forEach((option) => {
        option.label = option.name.toUpperCase();
        option.value = option.name;
        delete option.name;
      });
      this.setState({ employeeOptions: employeeOptions });
    });
  };

  onChangeState = (option, getOptions) => {
    let serviceArea = {};
    getOptions === false ? (getOptions = false) : (getOptions = true);
    if (getOptions != false) {
      this.getDistrictOptions(option.value);
    }
    serviceArea.state = option.value;
    this.setState({ serviceAreaFilter: true, serviceArea });
  };

  onChangeDistrict = (option, getOptions) => {
    let { serviceArea } = this.state;
    getOptions === false ? (getOptions = false) : (getOptions = true);
    if (getOptions != false) {
      this.getZones(option.value);
    }
    serviceArea.district = option.value;
    this.setState({ serviceAreaFilter: true, serviceArea });
  };

  onChangeZone = (option, getOptions) => {
    let { serviceArea } = this.state;
    getOptions === false ? (getOptions = false) : (getOptions = true);
    if (getOptions != false) {
      this.getLocalities(option.value);
    }
    serviceArea.zone = option.value;
    this.setState({ serviceArea });
  };

  onChangeLocalities = (options) => {
    let { serviceArea } = this.state;
    if (options) {
      if (options.length > 1) {
        serviceArea.localities = options.map((l) => l.value);
      } else {
        serviceArea.localities = options.value;
      }
    } else {
      serviceArea.localities = [];
    }
    this.setState({ serviceArea, localities: options });
  };

  filterStores = () => {
    let { filters, serviceArea, serviceAreaFilter } = this.state;

    if (serviceAreaFilter && serviceArea) {
      filters.serviceArea = serviceArea;
    }
    filters = JSON.parse(JSON.stringify(filters));
    if (Object.keys(filters).length >= 1) {
      this.props.setFilters(filters);
    } else {
      this.props.toastMessage("Please select atleast one filter", "error", 3);
    }
  };

  onChangeSortType = (option) => {
    this.setState({ sortType: option }, () =>
      this.props.sortStores(option.value)
    );
  };

  handleDateChange = (e) => {
    let filters = this.state.filters;
    let dateFilter = e.target.value;
    dateFilter = new Date(dateFilter);
    filters["dateFilter"] = dateFilter;
    this.setState({ filters: { ...filters } });
  };

  onChangeServiceEmployee = (option) => {
    let filters = this.state.filters;
    filters["employeeId"] = option._id;
    this.setState({ filters: { ...filters } });
  };

  render() {
    let { serviceArea = {}, sortType, localities = [] } = this.state;
    let { from, employeeOptions } = this.props;
    return (
      <div>
        <div
          className="d-flex justify-content-between sidebar sidebar--style-4 z-depth-1-top mb-2"
          style={{ padding: "1rem" }}
        >
          <div style={{ width: 150 }} className="mr-1">
            <div className="mb-1" style={{ fontWeight: 500, fontSize: "14px" }}>
              Sort by
            </div>
            <Select
              options={[
                { label: "Alphabetically (A-Z)", value: "alphabetically" },
                { label: "Highest GMV", value: "gmv" },
              ]}
              value={sortType}
              onChange={this.onChangeSortType}
            />
          </div>
          <div className="mr-1">
            <div className="mb-1" style={{ fontWeight: 500, fontSize: "14px" }}>
              Last Opened Date
            </div>
            <input
              type="date"
              className="form-control"
              onChange={this.handleDateChange}
            />
          </div>
          {from != "employee" && (
            <div className="mr-1">
              <div
                className="mb-1"
                style={{ fontWeight: 500, fontSize: "14px", width: "220px" }}
              >
                Assignee
              </div>
              <Select
                className="flex-fill mb-2"
                placeholder="Select service employee"
                options={employeeOptions}
                onChange={this.onChangeServiceEmployee}
              />
            </div>
          )}

          <div className="mr-1" style={{ width: "200px" }}>
            <LocationFinder
              onChangeState={this.onChangeState}
              onChangeDistrict={this.onChangeDistrict}
              onChangeZone={this.onChangeZone}
              onChangeLocality={this.onChangeLocalities}
            />
          </div>

          <div className="mr-1">
            <div className="mb-1" style={{ fontWeight: 500, fontSize: "14px" }}>
              Service Location
            </div>
            <div>
              <div className="mr-1" style={{ width: "200px" }}>
                <Select
                  className="flex-fill mb-1"
                  placeholder="State"
                  options={this.state.stateOptions}
                  value={
                    serviceArea.state
                      ? { label: serviceArea.state, value: serviceArea.state }
                      : null
                  }
                  onChange={this.onChangeState}
                  isMulti={false}
                />
              </div>
              {serviceArea.state && (
                <div className="mr-1" style={{ width: "200px" }}>
                  <Select
                    className="flex-fill mb-1"
                    placeholder="District"
                    options={this.state.districtOptions}
                    value={
                      serviceArea.district
                        ? {
                            label: serviceArea.district,
                            value: serviceArea.district,
                          }
                        : null
                    }
                    onChange={this.onChangeDistrict}
                    isMulti={false}
                  />
                </div>
              )}
              {serviceArea.district && (
                <div className="mr-1" style={{ width: "200px" }}>
                  <Select
                    className="flex-fill mb-1"
                    placeholder="Zone"
                    options={this.state.zoneOptions}
                    value={
                      serviceArea.zone
                        ? { label: serviceArea.zone, value: serviceArea.zone }
                        : null
                    }
                    onChange={this.onChangeZone}
                  />
                </div>
              )}

              {serviceArea.zone && (
                <div style={{ width: "400px" }}>
                  <Select
                    className="flex-fill"
                    placeholder="Localities"
                    options={this.state.localitiesOptions}
                    value={localities ? localities : null}
                    onChange={this.onChangeLocalities}
                    isMulti
                  />
                </div>
              )}
            </div>
            <div className="mt-1" style={{ float: "right" }}>
              <button className="btn btn-primary" onClick={this.filterStores}>
                Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const actions = {
  toastMessage: Actions.toastMessage,
};

export default connect(null, actions)(VirtualStoresFilter);
