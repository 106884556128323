import React from "react";

export default function ProductTitle({
  brand,
  subtype,
  descriptionAttributes,
  attributes
}) {
  return (
    <div className="d-flex text-center flex-column" style={{ marginBottom: 6 }}>
      <h5 className="mb-0">{brand + " " + subtype}</h5>
      <div className="d-flex text-center justify-content-center flex-wrap">
        {descriptionAttributes.map((item, index) => {
          if (!attributes[item]) {
            return null;
          }
          return (
            <span style={{ whiteSpace: "nowrap" }}>
              {attributes[item]}
              {index < descriptionAttributes.length - 1 ? "/" : ""}
            </span>
          );
        })}
      </div>
    </div>
  );
}
