import React from "react";
import ProjectRequirementsView from "./ProjectRequirementsView";
import ProjectService from "./../../../../services/ProjectService";
import Actions from "./../../../../state/actions";
import { connect } from "react-redux";

class ProjectRequirementsContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { requirements: [], cart: [] };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.loadRequirements();
  }

  loadRequirements = () => {
    ProjectService.fetchRequirements(this.props.project._id)
      .then((data = []) => {
        this.setState({
          requirements: data.serviceable.map((sku) => ({ sku })),
          nonServiceables: data.nonServiceable.map((sku) => ({ sku })),
          loading: false,
        });
      })
      .catch((e) => this.setState({ loading: false }));
  };

  changeRequirementValue = (index, key, value, serviceArea) => {
    let { requirements } = this.state;
    let product = requirements[index];
    product = { ...product, [key]: value };
    requirements[index] = product;
    this.setState({ requirements: [...requirements] });
    ProjectService.calculatePricing(value, product.sku._id, serviceArea).then(
      (data) => {
        console.log(data);
      }
    );
  };

  updateCart = (skuId, quantity, priceWithTax, totalPrice) => {
    let { cart } = this.state;
    let product = cart.find((prod) => prod.sku._id === skuId);
    product.quantity = quantity;
    product.priceWithTax = priceWithTax;
    product.totalPrice = totalPrice;
    this.setState({ cart: [...cart] });
  };

  addToCart = (requirement, quantity, priceWithTax, totalPrice) => {
    let { cart } = this.state;
    // let filtered = cart.filter(item => item.sku._id === requirement.sku._id);
    // if (filtered.length > 0) {
    //   this.props.toastMessage("Item already in cart", "error", 5);
    //   return;
    // }
    requirement = JSON.parse(JSON.stringify(requirement));
    requirement.quantity = quantity;
    requirement.priceWithTax = priceWithTax;
    requirement.totalPrice = totalPrice;
    cart.push(requirement);
    this.setState({ cart: [...cart] });
    this.props.toastMessage("Product has been added to the cart", "success", 2);
  };

  resetCart = () => {
    this.setState({ cart: [] });
  };

  render() {
    let { loading, requirements, nonServiceables, cart } = this.state;
    return (
      <ProjectRequirementsView
        loading={loading}
        requirements={requirements}
        nonServiceables={nonServiceables}
        changeRequirementValue={this.changeRequirementValue}
        addToCart={this.addToCart}
        cart={cart}
        project={this.props.project}
        showAddRequirements={this.props.showAddRequirements}
        resetCart={this.resetCart}
        updateCart={this.updateCart}
      />
    );
  }
}

const actions = {
  toastMessage: Actions.toastMessage,
};

export default connect(null, actions)(ProjectRequirementsContainer);
