import React from "react";
import AdminService from "../../../services/AdminService";
import ProductImage from "../Shop/ProductImage";
import ProductTitle from "../Shop/ProductTitle";

class AdminBidRequestsView extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { adminBidRequests: [] };
    }

    componentDidMount() {
        AdminService.getAdminBidRequests().then((adminBidRequests = []) => {
            this.setState({ adminBidRequests });
        });
    }
    render() {
        let adminBidRequests = this.state.adminBidRequests;
        if (adminBidRequests && adminBidRequests.length >= 1) {
            // return projectIds.map(projectId => {
            let bidRequests = adminBidRequests;
            return (
                <div className="table-responsive">
                    <table className="table table-cart">
                        <thead>
                            <tr>
                                <th className="product-image" />
                                <th>Product</th>
                                <th>Attributes</th>
                                <th>Quantity</th>
                                <th>Project name</th>
                                <th>Project phone</th>
                                <th>Current best seller price</th>
                                <th>Store name</th>
                                <th>Store phone</th>
                                <th>Created at</th>
                                <th>Status</th>
                                <th>Delivery address</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {bidRequests.map((request, index) => {
                                let {
                                    category,
                                    type,
                                    brand,
                                    subtype,
                                    attributes,
                                    descriptionAttributes
                                } = request.sku;
                                let quantity = request.quantity;
                                let status = request.status;
                                let bestPrice = Number.POSITIVE_INFINITY;
                                let tmp;
                                let bids = request.bids;
                                let deliveryAddress = request.deliveryAddress;
                                let minStoreId;
                                let date = request.createdAt;
                                let attributesMap = {};
                                attributes.forEach(item => {
                                    if (!attributesMap[item.key]) {
                                        attributesMap[item.key] = item.value;
                                    }
                                });
                                date = date.substr(0, date.indexOf("T"));
                                date = date
                                    .split("-")
                                    .reverse()
                                    .join("-");

                                bids.forEach(bid => {
                                    tmp = bid.sellerPrice;
                                    if (tmp < bestPrice) {
                                        bestPrice = tmp;
                                        minStoreId = bid.storeId;
                                    }
                                });
                                let inputStyle;
                                if (status === "PENDING") {
                                    inputStyle = {
                                        backgroundColor: " #0f69f2",
                                        borderColor: " #0f69f2"
                                    };
                                } else if (status === "ACTIVE") {
                                    inputStyle = {
                                        backgroundColor: " #4CAF50",
                                        borderColor: " #4CAF50"
                                    };
                                }
                                return (
                                    <tr>
                                        <td>
                                            <ProductImage images={request.sku.images} subtype={request.sku.subtype} />
                                        </td>
                                        <td className="product-name">
                                            <ProductTitle
                                                brand={brand}
                                                subtype={subtype}
                                                descriptionAttributes={descriptionAttributes}
                                                attributes={attributesMap}
                                            /></td>
                                        <td>
                                            <ul>
                                                {attributes.map(attribute => (
                                                    <li>
                                                        {" "}
                                                        {attribute.key}:{attribute.value}
                                                    </li>
                                                ))}
                                                <li>Category:{category}</li>
                                                <li>Type:{type}</li>
                                            </ul>
                                        </td>
                                        <td>{quantity}</td>
                                        <td>{request.projectName}</td>
                                        <td>{request.projectPhone}</td>
                                        <td>
                                            ₹{bestPrice}(
                      {request.sku.pricing.isTaxIncluded === "Y"
                                                ? "Including tax "
                                                : "Excluding tax "}
                      and
                      {request.sku.pricing.isDeliveryIncluded === "Y"
                                                ? " Including Delivery charges"
                                                : " Excluding Delivery charges"})
                                                </td>
                                        <td>{request.storeName}</td>
                                        <td>{request.storePhone}</td>
                                        <td>{date}</td>
                                        <td>
                                            <span
                                                className="badge text-uppercase mb-3"
                                                style={inputStyle}
                                            >
                                                {status || "ACTIVE"}
                                            </span>
                                        </td>
                                        <td>{deliveryAddress}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            );
        } else if (!adminBidRequests || adminBidRequests.length === 0) {
            return (
                <section className="slice sct-color-1">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <div className="d-block font-size-3 font-size-md-6 c-gray-light">
                                        <i className="fa fa-shopping-bag" aria-hidden="true" />
                                    </div>
                                    <h5>NO BID REQUESTS</h5>
                                    <p className="mt-5 px-5">
                                        We have no bid requests at the moment.
                        </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
        }
    }
}

export default AdminBidRequestsView;
