import React from "react";
import { Link } from "react-router-dom";
import StorageService from "../../../src/services/StorageService";
import CitySelectorModal from "../../../src/components/home/CitySelectorModal";

class AnonymousHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClickCity = () => {
    this.setState({ showCityModal: true });
  };

  componentDidMount() {
    var city = StorageService.getCity();
    this.setState({ city: city });
  }

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  render() {
    var city = this.state.city;
    return (
      <div className="container">
        <CitySelectorModal show={this.state.showCityModal} />
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle"
            style={{ borderColor: "#fff" }}
            onClick={this.toggleMenu}
          >
            <i className="fa fa-bars icon-custom" style={{ color: "#fff" }} />
          </button>

          <a
            className="navbar-brand"
            href="/"
            style={{ marginTop: "9px", paddingLeft: "15px" }}
          >
            <img
              src="images/logo.png"
              style={{ height: "32px" }}
              alt="Buildstreet"
            />
          </a>
        </div>

        <div
          className={`navbar-collapse collapse ${
            this.state.showMenu ? "in" : ""
          }`}
        >
          <ul className="nav navbar-nav navbar-right">
            {/*<li className="dropdown">*/}
            {/*  <Link to="/prices" className="dropdown-toggle">*/}
            {/*    Price List*/}
            {/*  </Link>*/}
            {/*</li>*/}
            {/*<li className="dropdown">*/}
            {/*  <Link to="/product-categories" className="dropdown-toggle">*/}
            {/*    Product Categories*/}
            {/*  </Link>*/}
            {/*</li>*/}
            <li className="dropdown">
              <Link to="/signup" className=" dropdown-toggle">
                Buyer Sign Up
              </Link>
            </li>
            <li className="dropdown">
              <Link to="/seller-signup" className=" dropdown-toggle">
                Seller Sign Up
              </Link>
            </li>
            <li className="dropdown">
              <Link to="/signin" className=" dropdown-toggle">
                Sign In
              </Link>
            </li>
            <li className="dropdown">
              <a href="#">
                <i className="fa fa-phone" style={{ marginRight: "5px" }} />
                +91 81249 68000
              </a>
            </li>

            {/*{city && (*/}
            {/*  <li>*/}
            {/*    <a onClick={this.onClickCity}>*/}
            {/*      <i*/}
            {/*        className="fa fa-map-marker"*/}
            {/*        style={{ fontSize: "14px" }}*/}
            {/*      />{" "}*/}
            {/*      <label*/}
            {/*        style={{ fontSize: "12px", textTransform: "capitalize" }}*/}
            {/*      >*/}
            {/*        {city.value}*/}
            {/*      </label>*/}
            {/*    </a>*/}
            {/*  </li>*/}
            {/*)}*/}
          </ul>
        </div>
      </div>
    );
  }
}

export default AnonymousHeader;
