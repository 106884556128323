import React from "react";
import { connect } from "react-redux";
import Actions from "./../../../../state/actions";
import AdminService from "../../../../services/AdminService";
import history from "../../../../utils/History";
import AdminLeadsView from "./../admin/AdminLeadsView";

class CustomerLeadContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  createLead = (leadInfo) => {
    AdminService.createLead(leadInfo)
      .then((response) => {
        if (response.success) {
          this.props.toastMessage("Lead has been created", "success", 5);
          window.location.reload();
        }
        this.closeCreateLead();
        history.push("/dashboard/crm");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  render() {
    return (
      <div>
        <AdminLeadsView submit={this.createLead} />
      </div>
    );
  }
}

const actions = {
  toastMessage: Actions.toastMessage,
};

export default connect(null, actions)(CustomerLeadContainer);