import React from "react";
import AdminService from "../../../services/AdminService";
import StoreService from "../../../services/StoreService";
import Actions from "./../../../state/actions";
import { connect } from "react-redux";
import SingleServiceAreaForm from "../Seller/Store/SingleServiceAreaForm";
import StorageService from "../../../services/StorageService";

class BestStockModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { serviceArea: {} };
  }

  componentDidMount() {
    let { storeId, from } = this.props;
    if (from === "admin") {
      StoreService.getStateOptions().then((stateOptions) => {
        this.setState({
          stateOptions: stateOptions.map((l) => ({ value: l, label: l })),
        });
      });
    } else {
      StoreService.getVirtualStoreStateOptions(storeId).then((stateOptions) => {
        this.setState({
          stateOptions: stateOptions.map((l) => ({ value: l, label: l })),
        });
      });
    }
  }

  onChangeState = (option, getOptions) => {
    let { serviceArea = {} } = this.state;
    serviceArea.state = option.value;
    serviceArea.district = null;
    serviceArea.zone = null;
    serviceArea.locality = null;

    getOptions === false ? (getOptions = false) : (getOptions = true);
    this.setState({ serviceArea }, () => {
      if (getOptions != false) {
        this.getDistrictOptions(option.value);
      }
    });
  };

  onChangeDistrict = (option, getOptions) => {
    let { serviceArea } = this.state;
    serviceArea.district = option.value;
    serviceArea.zone = null;
    serviceArea.locality = null;

    getOptions === false ? (getOptions = false) : (getOptions = true);
    this.setState({ serviceAreaFilter: true, serviceArea }, () => {
      if (getOptions != false) this.getZones(option.value);
    });
  };

  onChangeZone = (option, getOptions) => {
    let { serviceArea } = this.state;
    serviceArea.zone = option.value;
    serviceArea.locality = null;

    getOptions === false ? (getOptions = false) : (getOptions = true);
    this.setState({ serviceArea: { ...serviceArea } }, () => {
      if (getOptions != false) this.getLocalities(option.value);
    });
  };

  onChangeLocality = (option) => {
    let { serviceArea = {} } = this.state;
    serviceArea["locality"] = option.value;
    this.setState({
      serviceArea: { ...serviceArea },
    });
  };

  getDistrictOptions = (state) => {
    let { storeId, from } = this.props;
    if (from === "admin") {
      StoreService.getDistrictOptions(state).then((districtOptions) => {
        this.setState({
          districtOptions: districtOptions.map((l) => ({
            value: l,
            label: l,
          })),
        });
      });
    } else {
      StoreService.getVirtualStoreDistrictOptions(state, storeId).then(
        (districtOptions) => {
          this.setState({
            districtOptions: districtOptions.map((l) => ({
              value: l,
              label: l,
            })),
          });
        }
      );
    }
  };

  getZones = (district) => {
    let { storeId, from } = this.props;
    if (from === "admin") {
      StoreService.getZoneOptions(district).then((zoneOptions) => {
        this.setState({ zoneOptions });
      });
    } else {
      StoreService.getVirtualStoreZoneOptions(district, storeId).then(
        (zoneOptions) => {
          this.setState({ zoneOptions });
        }
      );
    }
  };

  getLocalities = (zone) => {
    let { storeId, from } = this.props;
    let { serviceArea } = this.state;
    if (from === "admin") {
      StoreService.getLocalityOptions(zone).then((localitiesOptions) => {
        this.setState({
          localitiesOptions,
          localities: localitiesOptions,
          serviceArea,
        });
      });
    } else {
      StoreService.getVirtualStoreLocalityOptions(zone, storeId).then(
        (localitiesOptions) => {
          this.setState({
            localitiesOptions,
            localities: localitiesOptions,
            serviceArea,
          });
        }
      );
    }
  };

  onChangeQuantity = (e) => {
    this.setState({ quantity: e.target.value });
  };

  getBestStock = () => {
    let { quantity, serviceArea } = this.state;
    let { skuId, from } = this.props;

    if (quantity && serviceArea) {
      if (from === "seller") {
        StoreService.calculatePricing(quantity, skuId, serviceArea).then(
          (response) => {
            if (response.success) {
              this.setState({
                data: response.data,
                message: null,
              });
            } else if (response.msg) {
              this.setState({ message: response.msg, data: {} });
            } else {
              this.setState({ data: response.data, message: null });
            }
          }
        );
      } else {
        AdminService.calculatePricing(
          quantity,
          skuId,
          serviceArea,
          "ADMIN"
        ).then((response) => {
          if (response.success) {
            this.setState({ data: response.data, message: null });
          } else if (response.msg) {
            this.setState({ message: response.msg, data: {} });
          } else {
            this.setState({ data: response.data, message: null });
          }
        });
      }
    } else {
      this.props.toastMessage("Please enter all the fields", "error", 3);
    }
  };

  render() {
    let { message, data = {}, serviceArea = {} } = this.state;
    let { storeId } = this.props;
    return (
      <div>
        <div className="mb-2" style={{ width: "200px" }}>
          <input
            name="quantity"
            type="number"
            style={{
              border: "1px solid hsl(0,0%,80%)",
              borderRadius: "4px",
              height: "38px",
              padding: "2px 8px",
            }}
            placeholder="Enter Quantity"
            onChange={this.onChangeQuantity}
          />
        </div>
        <div className="mb-2" style={{ height: "calc(100vh - 220px)" }}>
          <div>
            <SingleServiceAreaForm
              districtOptions={this.state.districtOptions}
              serviceArea={serviceArea}
              onChangeDistrict={this.onChangeDistrict}
              zoneOptions={this.state.zoneOptions}
              onChangeZone={this.onChangeZone}
              localitiesOptions={this.state.localitiesOptions}
              onChangeLocality={this.onChangeLocality}
              stateOptions={this.state.stateOptions}
              onChangeState={this.onChangeState}
              storeId={storeId}
            />
          </div>
          <div>
            <button
              className="btn btn-md btn-primary mt-1"
              onClick={this.getBestStock}
            >
              Get best stock
            </button>
          </div>
          {data && Object.keys(data).length >= 1 && !data.options && (
            <div style={{ zIndex: 0 }} className="card mt-1 p-2">
              {data.storeName && (
                <div>
                  <span className="strong-500">Store name: </span>
                  <span>{data.storeName}</span>
                </div>
              )}
              {data.sellerName && (
                <div>
                  <span className="strong-500">Seller name: </span>
                  <span>{data.sellerName}</span>
                </div>
              )}
              {data.phone && (
                <div>
                  <span className="strong-500">Phone: </span>
                  <span>{data.phone}</span>
                </div>
              )}
              {data.priceWithTax && (
                <div>
                  <span className="strong-500">Price with tax: </span>
                  <span>{" ₹ " + data.priceWithTax}</span>
                </div>
              )}
              {data.totalPrice && (
                <div>
                  <span className="strong-500">Total price: </span>
                  <span> {" ₹ " + data.totalPrice}</span>
                </div>
              )}
            </div>
          )}
          {message && (
            <div style={{ zIndex: 0 }} className="mt-1 pt-2 card">
              <h5 className="text-center">{message}</h5>
            </div>
          )}
          {data.options && data.options.length >= 1 && (
            <div style={{ zIndex: 0 }} className="card mt-1 p-2">
              {data.options.map((option) => (
                <div>
                  <div>
                    <span className="strong-500">Store name: </span>
                    <span>{option.storeName}</span>
                  </div>
                  <div>
                    <span className="strong-500">Seller name: </span>
                    <span>{option.sellerName}</span>
                  </div>
                  <div>
                    <span className="strong-500">Phone: </span>
                    <span>{option.phone}</span>
                  </div>
                  <div>
                    <span className="strong-500">Available quantity: </span>
                    <span> {option.quantity}</span>
                  </div>
                  <div>
                    <span className="strong-500">Price with tax: </span>
                    <span>{" ₹ " + option.price}</span>
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const actions = {
  toastMessage: Actions.toastMessage,
};

export default connect(null, actions)(BestStockModal);
