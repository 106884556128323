import React from "react";
import AdminService from "../../../services/AdminService";
import VirtualStoresDisplay from "./VirtualStoresDisplay";
import VirtualStoresFilter from "./VirtualStoresFilter";
import { VIRTUAL_STORE_PAGE_SIZE } from "../Constants";
import InfiniteScroller from "../../../widgets/InfiniteScroll/InfiniteScroller";
import ModalN from "../../../widgets/modal/ModalN";
import MonthlyRevenueView from "./MonthlyRevenueView";

class VirtualStoresView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { virtualStores: {}, count: -1, currentPage: 1, filters: {} };
  }

  componentDidMount() {
    this.loadData();
    console.log(this.props.userType);
  }

  loadData = () => {
    this.getServiceEmployees();
    this.getVirtualStoresCount();
    this.getVirtualStores();
    this.getVirtualStoreStats();
  };

  getServiceEmployees = () => {
    AdminService.getEmployees("store creation").then((employeeOptions = []) => {
      employeeOptions.forEach((option) => {
        option.label = option.name.toUpperCase();
        option.value = option.name;
        delete option.name;
      });
      this.setState({ employeeOptions });
    });
  };

  getSellerDetails = (sellerId) => {
    let { sellerDetails = [] } = this.state;
    let sellerName;
    let sellerPhone;
    for (let i = 0; i < sellerDetails.length; i++) {
      let seller = sellerDetails[i];
      if (seller._id === sellerId) {
        sellerName = seller.name;
        sellerPhone = seller.phone;
        break;
      }
    }

    return sellerName + " - " + sellerPhone;
  };

  sortStores = (type) => {
    let { virtualStores = {}, sellerDetails = [] } = this.state;
    if (type === "alphabetically") {
      virtualStores = this.sortByAlphabeticalOrder(
        virtualStores,
        sellerDetails
      );
    } else if (type === "gmv") {
      virtualStores = this.sortByHighestGMV(virtualStores, sellerDetails);
    }
    this.setState({
      virtualStores,
    });
  };

  getName = (sellerId, sellerDetails) => {
    let sellerName;
    for (let i = 0; i < sellerDetails.length; i++) {
      let seller = sellerDetails[i];
      if (seller._id === sellerId) {
        sellerName = seller.name;
        break;
      }
    }
    return sellerName;
  };

  sortByAlphabeticalOrder = (virtualStores, sellerDetails) => {
    let aName;
    let bName;
    let sortedStoreDetails = {};
    let orderedIds = Object.keys(virtualStores).sort(function (a, b) {
      sellerDetails.forEach((seller) => {
        if (seller._id === a) {
          aName = seller.name.toLowerCase();
        } else if (seller._id === b) {
          bName = seller.name.toLowerCase();
        }
      });

      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    });

    orderedIds.forEach((id) => {
      sortedStoreDetails[id] = virtualStores[id];
    });
    return sortedStoreDetails;
  };

  sortByHighestGMV = (virtualStores) => {
    let sortedStoreDetails = {};
    let d = new Date();
    let month = d.getMonth() + 1;
    month = month.toString();
    if (month.length === 1) {
      month = "0" + month;
    }
    let orderedIds = Object.keys(virtualStores).sort(function (a, b) {
      let totalPriceA = 0;
      let totalPriceB = 0;
      virtualStores[a].forEach((store) => {
        if (store.orders && store.orders.length >= 1) {
          totalPriceA = 0;
          store.orders.forEach((order) => {
            if (
              order.createdAt.substring(5, 7) === month &&
              order.status === "DELIVERED" &&
              order.totalPrice
            ) {
              totalPriceA += order.totalPrice;
            }
          });
        }
      });

      virtualStores[b].forEach((store) => {
        if (store.orders && store.orders.length >= 1) {
          totalPriceB = 0;
          store.orders.forEach((order) => {
            if (
              order.createdAt.substring(5, 7) === month &&
              order.status === "DELIVERED" &&
              order.totalPrice
            ) {
              totalPriceB += order.totalPrice;
            }
          });
        }
      });

      if (totalPriceA < totalPriceB) {
        return 1;
      }
      if (totalPriceA > totalPriceB) {
        return -1;
      }
      return 0;
    });

    orderedIds.forEach((id) => {
      sortedStoreDetails[id] = virtualStores[id];
    });
    return sortedStoreDetails;
  };

  setFilters = (filters) => {
    this.setState(
      {
        virtualStores: {},
        filters: { ...filters },
        count: -1,
        currentPage: 1,
        deliveredRevenues: [],
        nonDeliveredRevenues: [],
      },
      this.loadData
    );
  };

  modifyDate = (date) => {
    if (!date) {
      return "";
    }
    let now = new Date(date);
    date = date.substr(0, date.indexOf("T"));
    date = date.split("-").reverse().join("-");

    let time = now.toLocaleTimeString();
    return date + "/" + time;
  };

  openTotalRevenuePopup = (revenues, type) => {
    this.setState({ showTotalRevenuePopup: true, revenues, type });
  };

  closeTotalRevenuePopup = () => {
    this.setState({ showTotalRevenuePopup: false, revenues: [], type: null });
  };

  getThisMonthRevenue = (revenues) => {
    let d = new Date();
    let month = d.getMonth() + 1;
    month = "0" + month;
    month = month.slice(-2);
    let year = d.getFullYear();
    let thisMonthRevenue =
      revenues.find((r) => r.month === year + "-" + month) || [];
    return thisMonthRevenue.count ? parseInt(thisMonthRevenue.count) : 0;
  };

  getVirtualStoresCount = () => {
    let { filters = {} } = this.state;
    AdminService.getVirtualStoresCount(filters, "admin").then((data) => {
      this.setState({ count: data });
    });
  };

  getVirtualStoreStats = () => {
    let {
      deliveredRevenues = [],
      nonDeliveredRevenues = [],
      filters,
    } = this.state;
    AdminService.getVirtualStoreStats(filters, "admin").then((data = {}) => {
      let { delivered = [], pending = [] } = data;
      this.setState({
        deliveredRevenues: deliveredRevenues.concat(delivered),
        nonDeliveredRevenues: nonDeliveredRevenues.concat(pending),
      });
    });
  };

  getVirtualStores = () => {
    let {
      virtualStores = {},
      sellerDetails = [],
      currentPage,
      filters,
    } = this.state;
    let newSellerDetails = [];
    filters.page = currentPage;
    filters.pageSize = VIRTUAL_STORE_PAGE_SIZE;
    this.setState({ loadingStores: true });
    AdminService.getVirtualStores(filters, "admin")
      .then((data = []) => {
        if (data && data.length >= 1) {
          data.forEach((d) => {
            newSellerDetails.push(d.seller);
            d.virtualStores.forEach((store) => {
              if (store.sellerId in virtualStores) {
                virtualStores[store.sellerId].push(store);
              } else {
                virtualStores[store.sellerId] = [store];
              }
            });
          });
        }
        sellerDetails = sellerDetails.concat(newSellerDetails || []);
        virtualStores = this.sortByAlphabeticalOrder(
          virtualStores,
          sellerDetails
        );
        this.setState({
          virtualStores,
          sellerDetails,
          currentPage: currentPage + 1,
          loadingStores: false,
        });
      })
      .catch((e) => this.setState({ loadingStores: false }));
  };

  render() {
    let {
      virtualStores = {},
      deliveredRevenues = [],
      nonDeliveredRevenues = [],
      currentPage,
      count,
      loadingStores,
      revenues,
      type,
      filters,
      employeeOptions,
    } = this.state;
    let { from } = this.props;
    let loadMore = false;
    if (count !== -1) {
      let totalPages = Math.ceil(count / VIRTUAL_STORE_PAGE_SIZE);
      loadMore = currentPage - 1 < totalPages;
    }

    return (
      <div className="row">
        <div className="col-md-12 border border-dark pb-3">
          <VirtualStoresFilter
            sortStores={this.sortStores}
            employeeOptions={employeeOptions}
            setFilters={this.setFilters}
          />

          {virtualStores && Object.keys(virtualStores).length > 0 ? (
            <InfiniteScroller
              loadMore={() => {
                if (!loadingStores && loadMore) {
                  this.getVirtualStores(filters);
                }
              }}
            >
              <div className="table-responsive">
                <table className="table table-cart">
                  <thead>
                    <tr>
                      <th>Store name</th>
                      <th>Status</th>
                      <th>Service Areas</th>
                      <th>Last opened at</th>
                      <th>Hits</th>
                      <th>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.openTotalRevenuePopup(
                              deliveredRevenues,
                              "delivered"
                            )
                          }
                        >
                          {"GMV : ₹" +
                            this.getThisMonthRevenue(deliveredRevenues)}
                        </a>
                        <span>{" | "}</span>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.openTotalRevenuePopup(
                              nonDeliveredRevenues,
                              "pending"
                            )
                          }
                        >
                          {"Delivery Pending : ₹" +
                            this.getThisMonthRevenue(nonDeliveredRevenues)}
                        </a>
                      </th>
                      <th>Assignee</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(virtualStores).map((seller) => (
                      <React.Fragment>
                        <div
                          style={{ fontSize: 16, fontWeight: 500, padding: 5 }}
                        >
                          {this.getSellerDetails(seller)}
                        </div>

                        {virtualStores[seller].map((store) => (
                          <VirtualStoresDisplay
                            store={store}
                            modifyDate={this.modifyDate}
                            from={from}
                            employeeOptions={employeeOptions}
                          />
                        ))}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </InfiniteScroller>
          ) : (
            <section className="slice sct-color-1">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="text-center">
                      <div className="d-block font-size-3 font-size-md-6 c-gray-light">
                        <i className="fa fa-shopping-bag" aria-hidden="true" />
                      </div>

                      <h5>NO VIRTUAL STORES</h5>
                      <p className="mt-5 px-5">
                        We have no visited virtual stores.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
        {this.state.showTotalRevenuePopup && (
          <ModalN
            title={"Total " + type + " revenue"}
            show={this.state.showTotalRevenuePopup}
            close={this.closeTotalRevenuePopup}
          >
            <MonthlyRevenueView type={type} revenues={revenues} />
          </ModalN>
        )}
      </div>
    );
  }
}

export default VirtualStoresView;
