import React from "react";

class DeliveryAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    var address = this.props.address;
    var addressNode = <span></span>;
    var style = Object.assign({}, this.props.style, {
      border: "2px solid #eee",
      minHeight: "100px",
      background: "#fff",
      borderLeft: "3px solid #f39c12"
    });
    if (address) {
      addressNode = (
        <div
          className="panel panel-default panel-sidebar-1 address-link"
          style={style}
        >
          <div className="panel-body">
            {address.addressLine1 && <span>{address.addressLine1}</span>}&nbsp;
            {address.addressLine2 && <span>{address.addressLine2}</span>}&nbsp;
            {address.addressLine3 && <span>{address.addressLine2}</span>}&nbsp;
            {address.city && <span>{address.city}</span>}&nbsp;
            {address.pinCode && <span>{address.pinCode}</span>}
          </div>
        </div>
      );
    }

    return addressNode;
  }
}

export default DeliveryAddress;
