import React from "react";
import Select from "react-select";
import Auth from "./../../services/StorageService";

class CitySelector extends React.Component {
  onCityChange = city => {
    Auth.setCity(city);
    this.props.onCitySelect();
  };

  render() {
    var city = Auth.getCity();
    var cityValue = city ? city.value : undefined;
    return (
      <div>
        <div
          className="text-center"
          style={{
            fontSize: "16px",
            textTransform: "uppercase",
            fontWeight: 500
          }}
        >
          <strong> Select your city</strong>
        </div>
        <div
          className="form-body"
          style={{ marginTop: "30px", marginBottom: "10px" }}
        >
          <form id="frmRegister" className="sky-form" noValidate>
            <fieldset className="no-padding">
              <section>
                <div className="row">
                  <div className="col-xs-6 col-xs-offset-3">
                    <div className="form-group">
                      <Select
                        name="form-field-name"
                        value={cityValue}
                        options={this.props.cities}
                        onChange={this.onCityChange}
                        clearable={false}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
}

export default CitySelector;
