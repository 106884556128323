import React from "react";
import ordersService from "../../services/OrdersService";
import Order from "./Order";

class BidRequestOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    ordersService.getOrderByRequestId(this.props.requestId, order => {
      this.setState({ order: order });
    });
  }

  render() {
    var order = this.state.order;
    if (!order) {
      return null;
    }
    return (
      <div>
        <h5>Order for bid request #{this.props.requestNumber}</h5>
        <Order order={this.state.order} />
      </div>
    );
  }
}

export default BidRequestOrder;
