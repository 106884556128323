import React from "react";

export default function() {
  return (
    <section className="slice bg-white">
      <br />

      <div className="wp-section">
        <div className="container">
          <div className="row">
            <h5>Terms and Conditions</h5>
            <label style={{ fontSize: "14px", fontWeight: "bold" }}>
              Acceptance of Terms of Use
            </label>

            <p>
              Please read these Terms of Use carefully. These Terms of Use
              govern your access and use of this Site. By accessing or using
              this Site you agree to be bound by these Terms of Use and to any
              additional guidelines, restrictions, or rules that may be posted
              in connection with specific sections or services of this Site. All
              such additional posted guidelines, restrictions, or rules are
              hereby incorporated by reference into these Terms of use.
              buildstreet.com reserves the right to make changes to this Site
              and to these Terms of Use at any time without prior notice. You
              should review these Terms of Use each time you access this Site.
              You also agree that we may provide all legal communications and
              notices to you electronically by posting them on our website or,
              at our election, by sending an e-mail to the e-mail address you
              provided to us when you registered at our website. You may
              withdraw your consent to receive communications from us
              electronically by contacting customer care. However, if you
              withdraw your consent to receive our communications
              electronically, you must discontinue your use of your account.
            </p>

            <label style={{ fontSize: "14px", fontWeight: "bold" }}>
              Copyrighted Materials for Limited Use
            </label>

            <p>
              This Site contains graphics, photographs, images, document
              layouts, artwork, text, fonts, music, software tools, and other
              information (referred to herein as “Content”). This Site and all
              Content are the copyrighted property of buildstreet.com, or the
              copyrighted property of parties from whom buildstreet.com has
              licensed such property. All rights in this Site and its Content
              are reserved worldwide. It is strictly prohibited to retain, copy,
              distribute, publish, or use any portion of the Content except as
              expressly allowed in these Terms of Use. buildstreet.com reserves
              the right to add to, delete from, or modify any part of Content at
              any time without prior notice.
            </p>

            <label style={{ fontSize: "14px", fontWeight: "bold" }}>
              Use of Site
            </label>

            <p>
              You are granted permission to access and use this Site and its
              Content for the sole purpose of evaluating, and ordering products
              solely through buildstreet.com. No other download, retention, use,
              publication, or distribution of any portion of the Content is
              authorized or permitted. Obtaining Products from buildstreet.com
              does not entitle you to use any portion of Content apart from the
              finished Products as they are supplied by buildstreet.com.
            </p>

            <p>
              You agree to use this Site in a responsible manner that is in full
              compliance with these Terms of Use and with your local laws and
              regulations, including export and import regulations. Without
              limitation, no portion of Content may be utilized as a trademark
              or service mark, for any unlawful purpose or use, to defame any
              person, to violate any person’s right of privacy or publicity, to
              infringe upon any copyright, trade name, trademark, service mark
              or other intellectual property right of any person or entity. You
              agree that you will not use the Site to produce Products that are
              offensive, unlawful, harassing, libelous, threatening, harmful,
              obscene, malicious or otherwise objectionable. buildstreet.com may
              terminate its service to customers found to be using
              buildstreet.com to engage in undesirable activities.
              <br />
              You agree that you are responsible for protecting your password
              and controlling access to your registered account. You agree that
              you will be responsible for all orders placed or other actions
              that are taken through your registered account.
            </p>

            <p>
              Please review our Privacy Policy which also governs your visit to
              this site. All content on our site, including text, icons,
              graphics, logos, images and software is the property of our
              company or its affiliates/principals and protected by India and
              international copyright laws.
            </p>

            <p>
              We reserve the right to cancel products to any customer for any
              reason at any time. The customer’s sole remedy in such cases will
              be the full refund of any funds that we have collected in
              reference to the cancelled products and/or services.
            </p>

            <label style={{ fontSize: "14px", fontWeight: "bold" }}>
              Indemnification
            </label>

            <p>
              You agree that you shall indemnify and defend buildstreet.com and
              all parties from whom buildstreet.com has licensed portions of
              Content, and their directors, officers, and employees, against all
              claims, liability, damages, costs and expenses, including
              reasonable legal fees and expenses arising out of or related to
              (i) your breach of these Terms of Use or (ii) any suit, claim, or
              demand arising from or relating to any text, photograph, image,
              graphic or other material you incorporated into Products that was
              not part of the standard Site Content.
            </p>

            <label style={{ fontSize: "14px", fontWeight: "bold" }}>
              Disclaimer of Warranty
            </label>

            <p>
              This site is provided on an ‘AS IS’ and ‘AS AVAILABLE’ basis. We
              make no representations or warranties of any kind, whether express
              or implied, as to the operation of this site or the information,
              content, materials or products included on it. You expressly agree
              that your use of this site is at your sole risk, by your own free
              will, and that you are solely responsible for any consequences
              arising from this use. We disclaim all warranties, whether
              expressed or implied, including, but not limited to, implied
              warranties of merchantability and or fitness for a particular
              purpose we do not warrant that this site, its servers , or its
              electronic communication are free of viruses or other harmful
              components. We will not be liable for any damages of any kind
              arising from the use of this site, including, but not limited to
              direct, indirect, incidental, punitive and consequential damages.
            </p>

            <label style={{ fontSize: "14px", fontWeight: "bold" }}>
              Limitation of Liability
            </label>

            <p>
              In no event shall buildstreet.com or its licensors, suppliers, or
              vendors, their officers, directors, employees, or agents be liable
              for any special, incidental, indirect, or consequential damages of
              any kind, or for any damages whatsoever resulting from loss of
              use, data, or profits, whether or not buildstreet.com has been
              advised of the possibility of damage, arising out of or in
              connection with the use or performance of the site or of failure
              to provide products or services that you order from
              buildstreet.com or its affiliates, including without limitation,
              damages arising from mistake, omission, virus, delay, or
              interruption of service. In no event shall buildstreet.com be
              liable or responsible for any damages or consequences arising from
              or related to your inappropriate or unauthorized use of this site
              or its content. In no event shall buildstreet.com be responsible
              for any delays / damages occurred during transit of shipments.
            </p>

            <label style={{ fontSize: "14px", fontWeight: "bold" }}>
              Site Feedback
            </label>

            <p>
              Any comments, suggestions, proposals or other feedback
              (collectively “Feedback Information”) provided to buildstreet.com
              in connection with the operation or content of this Site shall be
              provided by the submitter and received by buildstreet.com on a
              non-confidential basis. All such comments, suggestions, and other
              information shall become the exclusive property of
              buildstreet.com. By submitting any such information to
              buildstreet.com, you agree that you are transferring and
              assigning, at no charge, all of your right, title and interest in
              the information, including all copyrights and other intellectual
              property rights. You agree that buildstreet.com shall be free to
              use such information on an unrestricted basis.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
