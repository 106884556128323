import http from "../services/Ajax";

class InvestorService {
  static getVirtualStoresGMV(filters) {
    return http
      .post("/api/v2/investor/virtual-stores-gmv", { filters })
      .then((data) => {
        if (data.success) {
          return data;
        } else {
          throw new Error();
        }
      });
  }
}

export default InvestorService;
