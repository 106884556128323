import React from "react";
import ProjectService from "./../../../../services/ProjectService";
import Actions from "./../../../../state/actions";
import { connect } from "react-redux";
import ProductImage from "../../Shop/ProductImage";
import ProductTitle from "../../Shop/ProductTitle";

class BidRequestsView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedBidReqs: [],
      bidRequests: [],
      indexes: []
    };
  }

  componentDidMount() {
    this.loadBidRequests();
  }

  loadBidRequests = () => {
    ProjectService.getBidRequest(this.props.projectId).then(
      (bidRequests = []) => {
        this.setState({ bidRequests });
      }
    );
  };

  handleChange(request) {
    // this.props.handleFilterChange(request, index);
  }

  checkBoxChange = (index, request, bestBid, checked) => {
    let { selectedBidReqs, indexes } = this.state;
    if (checked) {
      selectedBidReqs[request._id] = bestBid._id;
      indexes.push(index);
    } else {
      delete selectedBidReqs[request._id];
      let idx = indexes.indexOf(index);
      if (idx !== -1) {
        indexes.splice(idx, 1);
      }
    }

    this.setState({
      selectedBidReqs: { ...selectedBidReqs },
      indexes: [...indexes]
    });
  };

  doBuyNow = () => {
    let { selectedBidReqs, indexes } = this.state;
    if (Object.keys(selectedBidReqs).length) {
      ProjectService.createOrderFromBid(
        this.props.projectId,
        selectedBidReqs
      ).then(response => {
        // window.alert("Order has been created");
        this.props.toastMessage("Order has been created", "success", 5);
        let { bidRequests } = this.state;
        let newBidRequests = [];
        bidRequests.forEach((br, index) => {
          if (indexes.indexOf(index) === -1) {
            newBidRequests.push(br);
          }
        });
        this.setState({ bidRequests: newBidRequests, selectedBidReqs: {} });
      });
    } else {
      // window.alert("Select the bids to create order");
      this.props.toastMessage("Select the bids to create order", "error", 5);
    }
  };


  render() {
    let bidRequests = this.state.bidRequests;
    let bestBid = {};

    if (bidRequests && Object.keys(bidRequests).length >= 1) {
      return (
        <div className="table-responsive">
          <table className="table table-cart">
            <thead>
              <tr>
                <th />
                <th className="product-image" />
                <th>Product</th>
                <th>Attributes</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Total Price</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {bidRequests.map((request, index) => {
                let {
                  category,
                  type,
                  brand,
                  subtype,
                  attributes,
                  descriptionAttributes
                } = request.sku;
                let quantity = request.quantity;
                let basePrice = request.basePrice;
                let status = request.status;
                let totalPrice = null;
                let bids = request.bids;
                let bestPrice = Number.POSITIVE_INFINITY;
                let tmp = 0;
                bids.forEach(bid => {
                  tmp = bid.price.value;
                  if (tmp < bestPrice) {
                    request["bestBid"] = bid;
                  }
                });
                if (request.bestBid) {
                  totalPrice = request.bestBid.price.value * quantity;
                }
                let inputStyle;
                if (status === "PENDING") {
                  inputStyle = {
                    backgroundColor: " #0f69f2",
                    borderColor: " #0f69f2"
                  };
                } else if (status === "ACTIVE") {
                  inputStyle = {
                    backgroundColor: " #4CAF50",
                    borderColor: " #4CAF50"
                  };
                }
                let attributesMap = {};
                attributes.forEach(item => {
                  if (!attributesMap[item.key]) {
                    attributesMap[item.key] = item.value;
                  }
                });

                return (
                  <tr>
                    <td>
                      <div>
                        <input
                          type="checkbox"
                          id="checkBox"
                          onChange={e => {
                            this.checkBoxChange(
                              index,
                              request,
                              request.bestBid,
                              e.target.checked
                            );
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <ProductImage images={request.sku.images} subtype={request.sku.subtype} />
                    </td>
                    <td className="product-name">
                      <ProductTitle
                        brand={brand}
                        subtype={subtype}
                        descriptionAttributes={descriptionAttributes}
                        attributes={attributesMap}
                      />
                    </td>
                    <td>
                      <ul>
                        {attributes.map(attribute => (
                          <li>
                            {" "}
                            {attribute.key}:{attribute.value}
                          </li>
                        ))}
                        <li>Category:{category}</li>
                        <li>Type:{type}</li>
                      </ul>
                    </td>
                    <td>{quantity}</td>
                    <td>
                      {basePrice !== request.bestBid.price.value && (
                        <div className="mb-1">
                          Base price : ₹{basePrice}
                          {/* (
                          {request.sku.pricing.isTaxIncluded === "Y"
                            ? "Including tax "
                            : "Excluding tax "}
                      and
                          {request.sku.pricing.isDeliveryIncluded === "Y"
                            ? " Including Delivery charges "
                            : " Excluding Delivery charges"}
                      ) */}
                        </div>
                      )}
                      ₹{request.bestBid.price.value} (
                      {request.sku.pricing.isTaxIncluded === "Y"
                        ? "Including tax "
                        : "Excluding tax "}
                      and
                      {request.sku.pricing.isDeliveryIncluded === "Y"
                        ? " Including Delivery charges"
                        : " Excluding Delivery charges"}
                      )
                    </td>
                    <td>
                      ₹{totalPrice} (
                      {request.sku.pricing.isTaxIncluded === "Y"
                        ? "Including tax "
                        : "Excluding tax "}
                      and
                      {request.sku.pricing.isDeliveryIncluded === "Y"
                        ? " Including Delivery charges"
                        : " Excluding Delivery charges"}
                      )
                    </td>
                    <td>
                      <span
                        className="badge text-uppercase mb-3"
                        style={inputStyle}
                      >
                        {status || "ACTIVE"}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <button className="btn btn-success" onClick={this.doBuyNow}>
            Place Order
          </button>
        </div>
      );
    } else if (!bidRequests || Object.keys(bidRequests).length === 0) {
      return (
        <section className="slice sct-color-1">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="text-center">
                  <div className="d-block font-size-3 font-size-md-6 c-gray-light">
                    <i className="fa fa-shopping-bag" aria-hidden="true" />
                  </div>

                  <h5>NO BID REQUESTS</h5>
                  <p className="mt-5 px-5">
                    You have not made any bid requests in this project.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
}

const actions = {
  toastMessage: Actions.toastMessage
};

export default connect(
  null,
  actions
)(BidRequestsView);
