import React from "react";
import HomeBanner from "./HomeBanner";
import CategoriesContainer from "./CategoriesContainer";
import Footer from "./Footer";

export default function() {
  return (
    <div style={{ background: "#fafafa" }}>
      {/*<CitySelectorModal />*/}
      <HomeBanner />
      <CategoriesContainer />
      <Footer />
    </div>
  );
}
