import React from "react";
import { connect } from "react-redux";
import LeftNav from "./LeftNav";
import responsive from "../../utils/Responsive";
import UserBanner from "../../widgets/UserBanner.js";
import "./Dashboard.css";
import BuyerMainContent from "./BuyerMainContent";
import BusinessMainContent from "./BusinessMainContent";
import AdminMainContent from "./AdminMainContent";
import { getProductAttributes } from "../../state/actions/product";
import EmployeeMainContent from "./EmployeeMainContent";
import InvestorMainContent from "./InvestorMainContent";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showLeftNav: true };
  }

  componentDidMount() {
    this.adjustHeight();
    var mql = responsive.bigScreenMedia();
    mql.addListener(this.mediaQueryChanged);
    this.setState({ mql: mql, showLeftNav: mql.matches });
    this.props.getProductAttributes();
  }

  adjustHeight = () => {
    var height = responsive.getPageHeight();
    this.setState({ contentHeight: height });
  };

  componentWillUnmount() {
    this.state.mql.removeListener(this.mediaQueryChanged);
  }

  mediaQueryChanged = () => {
    this.setState({ showLeftNav: this.state.mql.matches });
  };

  render() {
    var leftNav = null;
    var user = this.props.user;
    var contentHeight = this.state.contentHeight ? this.state.contentHeight : 0;
    if (this.state.showLeftNav) {
      leftNav = (
        <div>
          <UserBanner user={user} />

          <div
            style={{ textAlign: "left", marginTop: "10px", padding: "0 10px" }}
          >
            <LeftNav userType={user.type} />
          </div>
        </div>
      );
    }
    let mainContent = null;
    let smallScreen = responsive.smallScreen();
    if (user.type === "C") {
      mainContent = (
        <BuyerMainContent
          smallScreen={smallScreen}
          leftNav={leftNav}
          contentHeight={contentHeight}
        />
      );
    } else if (user.type === "B") {
      mainContent = (
        <BusinessMainContent
          smallScreen={smallScreen}
          leftNav={leftNav}
          contentHeight={contentHeight}
        />
      );
    } else if (user.type === "A") {
      mainContent = (
        <AdminMainContent
          smallScreen={smallScreen}
          leftNav={leftNav}
          contentHeight={contentHeight}
        />
      );
    } else if (user.type === "O") {
      mainContent = (
        <EmployeeMainContent
          smallScreen={smallScreen}
          leftNav={leftNav}
          userType={user.type}
          contentHeight={contentHeight}
        />
      );
    } else if (user.type === "I") {
      mainContent = (
        <InvestorMainContent
          smallScreen={smallScreen}
          leftNav={leftNav}
          userType={user.type}
          contentHeight={contentHeight}
        />
      );
    }

    return (
      <div className="" style={{ paddingTop: "0px" }}>
        {mainContent}
      </div>
    );
  }
}

function data(state) {
  return {
    user: state.user,
  };
}

function actions(dispatch) {
  return {
    getProductAttributes: () => dispatch(getProductAttributes()),
  };
}
export default connect(data, actions)(Dashboard);
