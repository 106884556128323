import React, { Component, useState } from "react";
import Responsive from "../../../utils/Responsive";
import { Checkbox } from "./Checkbox";

class ShopProductsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subtypes: [],
      brands: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(key, value) {
    this.props.handleMenuChange(key, value);
  }

  componentDidMount() {
    var mql = Responsive.smallScreenMedia();
    mql.addListener(this.mediaQueryChanged);
    this.setState({ mql: mql, showMobileNav: mql.matches });
  }

  componentWillUnmount() {
    this.state.mql.removeListener(this.mediaQueryChanged);
  }

  mediaQueryChanged = () => {
    this.setState({ showMobileNav: this.state.mql.matches });
  };

  render() {
    let { subtypes } = this.props;
    let { checkedFilters = {} } = this.props;

    return (
      <div>
        <div
          className={
            this.state.showMobileNav
              ? "d-flex mb-2 pb-1"
              : "d-flex justify-content-center mb-2 pb-1"
          }
          style={{ overflow: "auto" }}
        >
          {subtypes.map((subtype, index) => {
            return (
              <div
                className="block"
                style={{
                  borderBottom:
                    checkedFilters.subtype === subtype
                      ? "3px solid maroon"
                      : "0px",
                  marginRight: "20px",
                }}
                key={index}
              >
                <div className="block-image mb-1">
                  <div className="view view-first">
                    <img
                      src={`/sku-images/${subtype
                        .toLowerCase()
                        .replaceAll(" ", "-")}.jpg`}
                      className="img-fluid img-center img-primary"
                      height={40}
                    />
                  </div>
                </div>
                <div className="text-center">
                  <a
                    className="heading heading-6 product-title text-normal strong-500"
                    onClick={() => this.handleChange("subtype", subtype)}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {subtype}
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default ShopProductsMenu;
