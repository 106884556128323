import React from "react";
import StoreService from "../../../../services/StoreService";
import PricingForm from "./PricingForm";
import StockPriceForm from "./StockPriceForm";
import Actions from "./../../../../state/actions";
import { connect } from "react-redux";

class QuanatityAndPricingView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { quantities: props.stock.quantities };
  }

  cancelPriceEdit = () => {
    this.setState({ priceEditIndex: -1 });
  };

  showAddPricing = () => {
    this.setState({ showAddPricingModal: true });
  };

  updateStockPrice = (stockId, price, qtyIndex) => {
    this.props.updateStockPrice(stockId, price, qtyIndex);
    this.cancelPriceEdit();
  };

  addQuantity = quantityDetails => {
    let { stock } = this.props;
    StoreService.addQuantity(quantityDetails, stock.storeId, stock._id).then(
      response => {
        if (response) {
          this.props.toastMessage(
            "Quantity and pricing details have been added",
            "success",
            5
          );
          this.props.hideQtyAndPricing(true);
        }
      }
    );
  };

  updateQuantityStatus = index => {
    let { quantities } = this.state;
    quantities[index].status === "ACTIVE"
      ? (quantities[index].status = "INACTIVE")
      : (quantities[index].status = "ACTIVE");
    this.setState({ quantities: [...quantities] });
    this.props.updateQuantityStatus(this.props.stock, index);
  };

  render() {
    let { stock } = this.props;
    let { showAddPricingModal, quantities } = this.state;
    if (showAddPricingModal) {
      return (
        <PricingForm
          addQuantity={this.addQuantity}
          from={true}
          product={stock}
        />
      );
    } else {
      return (
        <div>
          <table className="table table-cart">
            <thead>
              <tr>
                <th>Min quantity</th>
                <th>Multiple</th>
                <th>Price</th>
                <th>Status</th>
                <th>Update to</th>
              </tr>
            </thead>
            <tbody>
              {quantities.map((quantity, index) => (
                <React.Fragment>
                  <tr>
                    <td style={{ width: "25%" }}>{quantity.min}</td>
                    <td style={{ width: "25%" }}>{quantity.multiple}</td>
                    <td style={{ width: "50%" }}>
                      {this.state.priceEditIndex !== index ? (
                        <a
                          style={{
                            textDecoration: "underline",
                            color: "blue",
                            margin: "4px 0"
                          }}
                          onClick={() => {
                            this.setState({ priceEditIndex: index });
                          }}
                        >
                          {quantity.price ? "₹" + quantity.price.value : null}
                        </a>
                      ) : (
                        <StockPriceForm
                          stock={JSON.parse(JSON.stringify(stock))}
                          updateStockPrice={this.updateStockPrice}
                          cancelPriceEdit={this.cancelPriceEdit}
                          price={quantity.price.value}
                          index={index}
                        />
                      )}
                    </td>

                    <td>
                      <div
                        className={
                          quantity.status === "INACTIVE"
                            ? "badge red"
                            : "badge green"
                        }
                      >
                        {!quantity.status || quantity.status === "ACTIVE"
                          ? "AVAILABLE"
                          : "UNAVAILABLE"}
                      </div>
                    </td>
                    <td>
                      <div
                        className="btn btn-link"
                        onClick={() => this.updateQuantityStatus(index)}
                      >
                        {!quantity.status || quantity.status === "ACTIVE"
                          ? "UNAVAILABLE"
                          : "AVAILABLE"}
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
          <div className="d-flex justify-content-end">
            <button
              className="btn-link btn-primary"
              onClick={this.showAddPricing}
            >
              <i className="fa fa-plus" />
            </button>
          </div>
        </div>
      );
    }
  }
}

const actions = {
  toastMessage: Actions.toastMessage
};

export default connect(
  null,
  actions
)(QuanatityAndPricingView);
