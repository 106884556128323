import React from "react";

export default function() {
  return (
    <section className="slice bg-white">
      <br />

      <div className="wp-section">
        <div className="container">
          <div className="row">
            <h5>Order And Shipping Policy</h5>

            <b>How does the customer check the current status of his order?</b>

            <p>
              Once your order is confirmed, Buildsteet shares the order id and
              tracking details via E-mail/SMS. Order details can also be tracked
              by logging into the respective accounts
            </p>
            <b> When will the customer receive his order?</b>

            <p>
              The timeline for delivery of order will be communicated at the
              time of placing the order
            </p>

            <b>Do I have to pay delivery charges additionally?</b>

            <p>
              Price communicated to the customer at the time of placing order is
              inclusive of delivery charges and all applicable taxes. Customer
              does not have to pay any additional charges.
            </p>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </section>
  );
}
