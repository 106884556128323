import React from "react";
import ProjectCartView from "./ProjectCartView";
import ProductAsRequirement from "./ProductAsRequirement";
import ProjectNonServiceableReqs from "./ProjectNonServiceableReqs";

class ProjectRequirementsView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showCart: false
    };
  }

  cancelPriceEdit = () => {
    this.setState({ priceEditIndex: -1 });
  };

  updateRequirementPrice = (projectId, price) => {
    this.props.updateRequirementPrice(projectId, price).then(response => {
      this.cancelPriceEdit();
    });
  };
  showCart = () => {
    this.setState({ showCart: true });
  };

  closeCart = () => {
    this.setState({ showCart: false });
    this.props.resetCart();
  };

  roundOff = (cost, roundOff) => {
    return Math.ceil(cost / roundOff) * roundOff;
  };

  render() {
    let { loading, requirements = [], nonServiceables = [] } = this.props;
    let fromComponent = "PROJECT_REQUIREMENTS";

    if (this.props.cart.length) {
      if (this.state.showCart) {
        return (
          <ProjectCartView
            cart={this.props.cart}
            project={this.props.project}
            closeCart={this.closeCart}
            updateCart={this.props.updateCart}
          />
        );
      }
    }

    return (
      <div>
        {this.props.cart.length >= 1 && (
          <div className="d-flex justify-content-end mb-2">
            <button
              className="btn btn-dark"
              onClick={this.showCart}
              disabled={this.props.cart.length === 0}
              style={{ borderRadius: 4 }}
            >
              <i className="fa fa-shopping-cart" />
              {this.props.cart.length}
            </button>
          </div>
        )}

        <div className="row">
          {requirements.length >= 1 && (
            <div className="my-3 p-3 col">
              {requirements.map((requirement, index) => {
                return (
                  <ProductAsRequirement
                    requirement={requirement}
                    index={index}
                    changeRequirementValue={this.props.changeRequirementValue}
                    addToCart={this.props.addToCart}
                    fromComponent={fromComponent}
                    project={this.props.project}
                    updateCart={this.props.updateCart}
                  />
                );
              })}
            </div>
          )}
        </div>

        {nonServiceables.length >= 1 && (
          <React.Fragment>
            <hr className="mb-3" />
            <div className="row">
              {nonServiceables.map((nonServiceable, index) => {
                return (
                  <ProjectNonServiceableReqs
                    nonServiceable={nonServiceable.sku}
                    index={index}
                  />
                );
              })}
            </div>
          </React.Fragment>
        )}

        <div className="row">
          {requirements.length === 0 && nonServiceables.length === 0 && (
            <div className="col-lg-12">
              <div className="text-center">
                <p className="mt-5 px-5" style={{ fontSize: 16 }}>
                  You have not shortlisted any products for this project yet.{" "}
                  Click{" "}
                  <a
                    className="btn-link"
                    onClick={this.props.showAddRequirements}
                  >
                    here
                  </a>{" "}
                  to shortlist the products.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ProjectRequirementsView;
