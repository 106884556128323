import React from "react";

class MarkUpMarkUpPriceForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { markUpPrice: props.markUpPrice || null };
  }

  onChangeStockMarkUpPriceValue = (e) => {
    this.setState({ markUpPrice: e.target.value });
  };

  submitForm = (e) => {
    e.preventDefault();
    this.props.updateStockMarkUpPrice(
      this.props.stock._id,
      this.state.markUpPrice,
      this.props.index
    );
  };

  render() {
    let { markUpPrice } = this.state;
    return (
      <form onSubmit={this.submitForm}>
        <div>
          {!this.state.priceEditIndex ? (
            <a
              style={{
                textDecoration: "underline",
                color: "blue",
                margin: "4px 0",
              }}
              onClick={() => {
                this.setState({ priceEditIndex: true });
              }}
            >
              {markUpPrice ? (
                "₹" + markUpPrice
              ) : (
                <input
                  placeholder="MarkUpPrice"
                  value={markUpPrice}
                  onChange={this.onChangeStockMarkUpPriceValue}
                  style={{ width: "50px" }}
                />
              )}
            </a>
          ) : (
            <input
              placeholder="MarkUpPrice"
              value={markUpPrice}
              onChange={this.onChangeStockMarkUpPriceValue}
              style={{ width: "50px" }}
            />
          )}
        </div>
        <div className="d-flex align-items-center mt-2 justify-content-between">
          <a
            className="btn btn-light btn-xs"
            onClick={this.props.cancelMarkUpPriceEdit}
          >
            Cancel
          </a>
          <button type="submit" className="btn btn-primary btn-xs">
            Add
          </button>
        </div>
      </form>
    );
  }
}

export default MarkUpMarkUpPriceForm;
