import React from "react";
import StoreService from "../../../../services/StoreService";
import ProductImage from "../../Shop/ProductImage";
import ProductTitle from "../../Shop/ProductTitle";
import Select from "react-select";
import Actions from "./../../../../state/actions";
import { connect } from "react-redux";

let statusOptions = [
  {
    label: "PENDING",
    value: "PENDING",
  },
  {
    label: "DELIVERED",
    value: "DELIVERED",
  },
];

class StoreOrdersView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (!this.props.from || this.props.from !== "allOrders") {
      this.getOrders();
    }
  }

  getOrders = () => {
    if (this.props.virtual || this.props.type === "v") {
      StoreService.getVirtualStoreOrders(this.props.storeId).then(
        (orders = []) => {
          this.setState({ orders });
        }
      );
    } else {
      StoreService.getStoreOrders(this.props.storeId).then((orders = []) => {
        this.setState({ orders });
      });
    }
  };

  onChangeStatus = (index, option) => {
    let orders = this.state.orders || this.props.orders;
    let orderId = orders[index]._id;
    StoreService.updateDeliveryStatus(
      option.value,
      orders[index].storeId || this.props.storeId,
      orderId
    ).then((response) => {
      if (response.success) {
        orders[index] = { ...orders[index], status: option.value };
        this.setState({ orders: [...orders] });
        // this.getOrders();
      }
    });
  };

  onChangeQuantity = (orderIndex, e) => {
    let orders = this.state.orders || this.props.orders;
    let orderId = orders[orderIndex]._id;
    orders[orderIndex].quantity = e.target.value;
    this.setState({ orders: [...orders], orderId, orderIndex });
  };

  modifyDate = (date) => {
    if (!date) {
      return "";
    }
    let now = new Date(date);
    date = date.substr(0, date.indexOf("T"));
    date = date.split("-").reverse().join("-");

    let time = now.toLocaleTimeString();
    return date + "/" + time;
  };

  submitQuantity = (index) => {
    let orders = this.state.orders || this.props.orders;
    let orderId = this.state.orderId || orders[index]._id;
    StoreService.changeQuantity(
      orderId,
      orders[index].quantity,
      orders[index].storeId || this.props.storeId
    ).then((response) => {
      if (response.success) {
        // this.getOrders();
        orders[index] = { ...orders[index], quantity: orders[index].quantity };
        this.props.toastMessage("Quantity updated", "success", 3);
        this.setState({ orders: [...orders] });
      }
    });
  };

  render() {
    let orders = this.state.orders || this.props.orders || [];
    let { from } = this.props;
    if (orders && orders.length >= 1) {
      return (
        <div className="table-responsive">
          <table className="table table-cart">
            <thead>
              <tr>
                <th className="product-image" />
                <th>Product</th>
                <th>Attributes</th>
                <th>Quantity</th>
                <th>Ordered at</th>
                {from && from === "allOrders" && <th>Store</th>}
                {this.props.type !== "v" && !this.props.virtual && (
                  <React.Fragment>
                    <th>Price</th>
                    <th>Total Price</th>
                    <th>Status</th>
                    <th>Delivery address</th>
                  </React.Fragment>
                )}
                {(this.props.type === "v" || this.props.virtual) && (
                  <React.Fragment>
                    <th>Purchase</th>
                    <th>Margin</th>
                    <th>Sales</th>
                  </React.Fragment>
                )}
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => {
                let {
                  category,
                  type,
                  brand,
                  subtype,
                  attributes,
                  descriptionAttributes,
                } = order.sku;
                let {
                  quantity,
                  sellerPrice,
                  createdAt,
                  deliveryAddress,
                  markupPrice,
                  basePrice,
                  storeName,
                } = order;

                let defaultStatus = {
                  label: order.status,
                  value: order.status,
                };

                let attributesMap = {};
                attributes.forEach((item) => {
                  if (!attributesMap[item.key]) {
                    attributesMap[item.key] = item.value;
                  }
                });
                return (
                  <tr>
                    <td>
                      <ProductImage
                        images={order.sku.images}
                        subtype={order.sku.subtype}
                      />
                    </td>
                    <td className="product-name">
                      <ProductTitle
                        brand={brand}
                        subtype={subtype}
                        descriptionAttributes={descriptionAttributes}
                        attributes={attributesMap}
                      />
                    </td>
                    <td>
                      <ul>
                        {attributes.map((attribute) => (
                          <li>
                            {attribute.key}:{attribute.value}
                          </li>
                        ))}
                        <li>Category:{category}</li>
                        <li>Type:{type}</li>
                      </ul>
                    </td>
                    <td>
                      {!this.props.virtual && order.status !== "DELIVERED" ? (
                        <div
                          className="d-flex"
                          style={{ justifyContent: "space-evenly" }}
                        >
                          <input
                            name="quantity"
                            type="number"
                            style={{
                              border: "1px solid hsl(0,0%,80%)",
                              borderRadius: "4px",
                              height: "38px",
                              padding: "2px 8px",
                              width: "60%",
                            }}
                            placeholder="Enter Quantity"
                            value={quantity}
                            onChange={(e) => this.onChangeQuantity(index, e)}
                          />
                          <button
                            onClick={(e) => this.submitQuantity(index)}
                            className="btn btn-sm btn-success"
                          >
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </button>
                        </div>
                      ) : (
                        quantity
                      )}
                    </td>
                    <td>{this.modifyDate(createdAt)}</td>
                    {from && from === "allOrders" && <td>{storeName}</td>}
                    {!this.props.virtual && this.props.type !== "v" && (
                      <React.Fragment>
                        <td>
                          ₹{sellerPrice}(
                          {order.sku.pricing.isTaxIncluded === "Y"
                            ? "Including tax "
                            : "Excluding tax "}
                          and
                          {order.sku.pricing.isDeliveryIncluded === "Y"
                            ? " Including Delivery charges "
                            : " Excluding Delivery charges"}
                          )
                        </td>

                        <td>
                          ₹{sellerPrice * quantity}(
                          {order.sku.pricing.isTaxIncluded === "Y"
                            ? "Including tax "
                            : "Excluding tax "}
                          and
                          {order.sku.pricing.isDeliveryIncluded === "Y"
                            ? " Including Delivery charges "
                            : " Excluding Delivery charges"}{" "}
                          )
                        </td>
                        <td style={{ width: 125 }}>
                          {order.status === "DELIVERED" ? (
                            order.status
                          ) : (
                            <Select
                              className="flex-fill mb-1"
                              placeholder="Delivery status"
                              options={statusOptions}
                              value={{
                                value: order.status,
                                label: order.status,
                              }}
                              onChange={(option) =>
                                this.onChangeStatus(index, option)
                              }
                              isMulti={false}
                            />
                          )}
                        </td>

                        <td>{deliveryAddress}</td>
                      </React.Fragment>
                    )}
                    {(this.props.virtual || this.props.type === "v") && (
                      <React.Fragment>
                        <td>
                          {basePrice
                            ? "₹ " + basePrice + " / ₹ " + basePrice * quantity
                            : "NA"}
                        </td>
                        <td>
                          {markupPrice
                            ? "₹ " +
                              markupPrice +
                              " / ₹ " +
                              markupPrice * quantity
                            : "NA"}
                        </td>
                        <td>
                          {markupPrice
                            ? "₹ " +
                              (parseInt(markupPrice) + basePrice) +
                              " / ₹ " +
                              (parseInt(markupPrice) + basePrice) * quantity
                            : "₹ " + basePrice * quantity}
                        </td>
                      </React.Fragment>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    } else if (!orders || orders.length === 0) {
      return (
        <section className="slice sct-color-1">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="text-center">
                  <div className="d-block font-size-3 font-size-md-6 c-gray-light">
                    <i className="fa fa-shopping-bag" aria-hidden="true" />
                  </div>

                  <div style={{ fontSize: 16 }}>
                    There are no orders for your store at the moment.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
}
const actions = {
  toastMessage: Actions.toastMessage,
};

export default connect(null, actions, null, { forwardRef: true })(
  StoreOrdersView
);
