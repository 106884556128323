import React from "react";

export default ({ edit, fromComponent }) => (
  <thead>
    <tr>
      {/*<th>Lead source</th>*/}
      <th>Name</th>
      <th>Follow-up Type</th>
      <th>Status</th>
      <th>Comments</th>
      {/*<th>Created at</th>*/}
      <th>Phone</th>
      <th>Email</th>
      <th>Location</th>
      <th>Created at</th>
      {/*<th>Updated on</th>*/}
      <th>Next Call Date</th>
      <th>Call log</th>
      {edit && <th>Edit</th>}
      <th>Details</th>
      {fromComponent === "pl" && <th>Update next call date</th>}
      <th>Source</th>
    </tr>
  </thead>
);
