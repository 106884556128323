import React from "react";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

class RevenueView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.orders) {
      this.processData(nextProps);
    }
  }

  componentDidMount() {
    this.processData(this.props);
  }

  processData = (props) => {
    let { orders } = props;
    let ordersRevenues = [];
    let totalRevenuePerStore;
    ordersRevenues = this.getRevenuesPerMonth(orders);
    totalRevenuePerStore = this.getTotalRevenuePerStore(orders);

    this.setState({
      ordersRevenues,
      totalRevenuePerStore,
    });
  };

  getRevenuesPerMonth = (orders) => {
    let revenues = {};
    orders.forEach((order) => {
      let d = order.createdAt;
      d = new Date(d);
      let date = d.getDate();
      let month = monthNames[d.getMonth()];
      let year = d.getFullYear();
      if (month + "-" + year in revenues) {
        revenues[month + "-" + year].push({
          price: parseInt(order.totalPrice),
          date: month + " " + date,
        });
      } else {
        revenues[month + "-" + year] = [
          { price: parseInt(order.totalPrice), date: month + " " + date },
        ];
      }
    });
    return revenues;
  };

  getTotalRevenuePerStore = (orders) => {
    let totalRevenuePerStore = 0;

    orders.map((order) => {
      totalRevenuePerStore += order.totalPrice;
    });

    return parseInt(totalRevenuePerStore);
  };

  getTotalRevenuePerMonth = (orders) => {
    let totalRevenuePerMonth = 0;
    orders.map((order) => {
      totalRevenuePerMonth += order.price;
    });
    return totalRevenuePerMonth;
  };

  render() {
    let { ordersRevenues = {}, totalRevenuePerStore } = this.state;
    let { from } = this.props;

    return (
      <div>
        {ordersRevenues && Object.keys(ordersRevenues).length > 0 ? (
          <div>
            <table className="table table-cart">
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Revenue/Order {" - ₹ " + totalRevenuePerStore}</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(ordersRevenues).map((key) => (
                  <React.Fragment>
                    <tr>
                      <td>{key}</td>
                      <td>
                        <table className="table table-cart">
                          <tbody>
                            {ordersRevenues[key].map((order) => (
                              <tr>
                              <td>
                                {"₹ " + order.price + " on " + order.date}
                              </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className="strong-500">
                          {"Total : " +
                            this.getTotalRevenuePerMonth(ordersRevenues[key])}
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>{"No " + from}</div>
        )}
      </div>
    );
  }
}

export default RevenueView;
