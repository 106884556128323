import React from "react";
import AdminService from "../../../../services/AdminService";
import StoreService from "../../../../services/StoreService";
import ModalN from "../../../../widgets/modal/ModalN";
import EditLead from "./../EditLead";
import LeadDisplay from "./LeadDisplay";
import LeadsFilter from "./LeadsFilter";
import NonAdminLeadsHeader from "./LeadsHeader";
import InfiniteScroller from "../../../../widgets/InfiniteScroll/InfiniteScroller";
import { LEADS_PAGE_SIZE } from "../../Constants";

const followUpTypeOptions = [
  { value: "customer", label: "Customer" },
  { value: "seller", label: "Seller" },
  { value: "collection", label: "Collection" },
  { value: "store creation", label: "Store Creation" },
];

class TodaysLeads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leads: [],
      commentEditIndex: 0,
      refresh: false,
      hover: false,
      logHistory: [],
      count: 0,
      currentPage: 1,
    };
  }

  componentDidMount() {
    this.loadStates();
    this.getNextCallDateOptions();
  }

  getNextCallDateOptions = () => {
    AdminService.getNextCallDate("next-call-date").then(
      (nextCallDateOptions = []) => {
        this.setState({ nextCallDateOptions });
      }
    );
  };

  loadStates = () => {
    StoreService.getStateOptions().then((stateOptions) => {
      this.setState({
        stateOptions: stateOptions.map((l) => ({ value: l, label: l })),
      });
    });
  };

  showEditLead = (lead, index) => {
    this.setState({ leadToEdit: lead, leadToEditIndex: index });
  };

  hideEditLead = () => {
    this.setState({ leadToEdit: null, leadToEditIndex: -1 });
  };

  onEditLead = (edited) => {
    let { leads, leadToEditIndex } = this.state;
    let lead = leads[leadToEditIndex];
    leads[leadToEditIndex] = { ...lead, ...edited };
    this.setState({
      leads: [...leads],
      leadToEdit: null,
      leadToEditIndex: -1,
    });
  };

  setFilters = (filters) => {
    this.setState(
      { leads: [], filters: { ...filters }, count: 0, currentPage: 1 },
      this.loadLeads
    );
  };

  loadLeads = () => {
    let { currentPage, leads, filters = {} } = this.state;
    filters = JSON.parse(JSON.stringify(filters));
    filters.page = currentPage;
    filters.pageSize = LEADS_PAGE_SIZE;
    this.setState({ loadingLeads: true });
    AdminService.getTodaysLeads(filters)
      .then((response = []) => {
        if (currentPage === 1) {
          this.setState({
            leads: leads.concat(response.leads),
            count: response.count,
            currentPage: currentPage + 1,
            loadingLeads: false,
          });
        } else {
          this.setState({
            leads: leads.concat(response.leads),
            currentPage: currentPage + 1,
            loadingLeads: false,
          });
        }
      })
      .catch((e) => this.setState({ loadingLeads: false }));
  };

  render() {
    let { leads, currentPage, count, loadingLeads, leadToEdit } =
      this.state || {};
    let {
      businessTypeOptions = [],
      statusOptions = [],
      commentsOptions = [],
      leadSourceOptions = [],
    } = this.props;
    let totalPages = Math.ceil(count / LEADS_PAGE_SIZE);
    let loadMore = currentPage - 1 < totalPages;

    return (
      <div className="row">
        <div className="col-md-12 border border-dark mb-2">
          <LeadsFilter
            leadSourceOptions={leadSourceOptions}
            followUpTypeOptions={followUpTypeOptions}
            setFilters={this.setFilters}
            fromComponent="tl"
            statusOptions={statusOptions}
            businessTypeOptions={businessTypeOptions}
          />
        </div>

        {leads && leads.length >= 1 ? (
          <div>
            <h5 style={{ textAlignLast: "center" }}>
              {"Viewing" + " " + leads.length + " of " + count}
            </h5>
            <div
              className="col-md-12 table-responsive"
              style={{ overflow: "unset" }}
            >
              <InfiniteScroller
                loadMore={() => {
                  if (!loadingLeads && loadMore) this.loadLeads();
                }}
              >
                <table className="table table-cart">
                  <NonAdminLeadsHeader edit />
                  <tbody>
                    {leads.map((lead, index) => {
                      return (
                        <LeadDisplay
                          lead={lead}
                          index={index}
                          statusOptions={statusOptions}
                          commentsOptions={commentsOptions}
                          nextCallDateOptions={this.state.nextCallDateOptions}
                          loadLeads={this.loadLeads}
                          getNextCallDateOptions={this.getNextCallDateOptions}
                          fromComponent="tl"
                          showEditLead={this.showEditLead}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </InfiniteScroller>
              {leadToEdit && (
                <ModalN
                  modalHeight={{
                    minHeight: "300px",
                    overflowY: "auto",
                  }}
                  title="Edit lead"
                  show={true}
                  close={this.hideEditLead}
                >
                  <EditLead
                    lead={leadToEdit}
                    statusOptions={statusOptions}
                    commentsOptions={commentsOptions}
                    nextCallDateOptions={this.state.nextCallDateOptions}
                    stateOptions={this.state.stateOptions}
                    businessTypeOptions={businessTypeOptions}
                    onEditLead={this.onEditLead}
                  />
                </ModalN>
              )}
            </div>
          </div>
        ) : (
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="text-center">
                <p className="mt-5 px-5">
                  NO LEADS HAVE BEEN ASSIGNED FOR TODAY
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default TodaysLeads;
