import React from "react";
import { connect } from "react-redux";
import ordersService from "../../services/OrdersService";
import Actions from "../../state/actions";

class NotifyPayment extends React.Component {
  notifyPayment = () => {
    ordersService.notifyCustomerPayment(
      this.props.orderId,
      message => {
        message = message || "Order update failed.";
        this.props.toastMessage(message, "error", 3);
      },
      () => {
        this.props.onCompleteHook();
        var message = {
          content:
            "I have done NEFT/IMPS transfer for order " +
            this.props.orderNumber,
          type: "T",
          sender: { type: "U" },
          date: new Date()
        };
        this.props.processMessage(message);
        this.props.toastMessage(
          "Done! Your payment has been notified. We will contact you to discuss about delivery options.",
          "success",
          3
        );
      }
    );
  };

  render() {
    if (!this.props.orderId) {
      return null;
    }
    return (
      <div>
        <h5 className="text-center" style={{ margin: "10px 0 50px 0" }}>
          Notify Payment - Order #{this.props.orderNumber}
        </h5>

        <div className="col-md-12">
          <div style={{ marginBottom: "20px" }}>
            Are you sure you have made the payment using NEFT/IMPS?
          </div>
          <a className="btn btn-base pull-right" onClick={this.notifyPayment}>
            Confirm
          </a>
        </div>
      </div>
    );
  }
}

function actions(dispatch) {
  return {
    processMessage: message => dispatch(Actions.processMessage(message)),
    toastMessage: (message, type, duration) => {
      dispatch(Actions.toastMessage(message, type, duration));
    }
  };
}

export default connect(
  null,
  actions
)(NotifyPayment);
