/**
 * @flow
 */

"use strict";

import user from "./user";
import contact from "./contact";
import navigation from "./navigation";
import request from "./request";
import order from "./order";
import message from "./message";
import toaster from "./toaster";
import modal from "./modal";
import product from "./product";
import sku from "./sku";


var { combineReducers } = require("redux");

export default combineReducers({
  user: user,
  contact: contact,
  navigation: navigation,
  requests: request,
  orders: order,
  messages: message,
  toaster: toaster,
  modal: modal,
  products: product,
  sku:sku
});
