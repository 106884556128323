import ajax from './Ajax'

export default {
    getOrderById: function (id, cb) {
        ajax.get('/orders/' + id).then(function (res) {
            if (!res.success) {
                cb({});
            } else {
                cb(res.data);
            }
        });
    },
    getOrderByRequestId: function (requestId, cb) {
        ajax.get('/orders/request/' + requestId).then(function (res) {
            if (!res.success) {
                cb({});
            } else {
                cb(res.data);
            }
        });
    },
    notifyCustomerPayment: function (orderId, error, success) {
        ajax.put('/orders/' + orderId + '/customer-payment').then(res=> {
            if (!res.success) {
                error(res.message);
            } else {
                success(res.data);
            }
        });
    },
    processPayment: function (orderId, gwPaymentId, success, error) {
        ajax.post('/orders/' + orderId + '/payment', {gwPaymentId: gwPaymentId}).then(res=> {
            if (!res.success) {
                error(res.message);
            } else {
                success(res.data);
            }
        });
    }
}