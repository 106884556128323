import React from "react";
import { connect } from "react-redux";
import Actions from "../../state/actions";
import history from "../../utils/History";

class LogOut extends React.Component {
  logOut = () => {
    var routeTo = this.props.routeTo;
    this.props.logOut(() => {
      history.push(routeTo);
    });
  };

  render() {
    return (
      <a onClick={this.logOut} className="left-link">
        <i className="fa fa-sign-out" style={{ marginRight: "10px" }} />
        Sign Out
      </a>
    );
  }
}

var actions = function(dispatch) {
  return {
    logOut: cb => dispatch(Actions.logOut(cb))
  };
};

export default connect(
  null,
  actions
)(LogOut);
