import React from "react";
import StoreService from "../../../../services/StoreService";
import StoreOrdersView from "./StoreOrdersView";

class SellerAllOrdersContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { view: "storeOrders" };
  }

  componentDidMount() {
    StoreService.getSellerAllOrders().then((data) => {
      if (data) {
        this.setState({
          storeOrders: data.newStoreOrders,
          virtualStoreOrders: data.newVirtualStoreOrders,
        });
      }
    });
  }

  showStoreOrders = () => {
    this.setState({ view: "storeOrders" });
  };

  showVirtualStoreOrders = () => {
    this.setState({ view: "virtualStoreOrders" });
  };

  render() {
    let { storeOrders = [], virtualStoreOrders = [], view } = this.state;
    return (
      <div>
        <div className="tabs tabs--style-2 tabs--centered" role="tabpanel">
          <ul
            className="nav nav-tabs justify-content-center d-flex"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <a
                className={
                  view === "storeOrders"
                    ? "nav-link active text-center text-uppercase tab-link"
                    : "nav-link text-center text-uppercase tab-link"
                }
                onClick={this.showStoreOrders}
              >
                Orders to fulfill
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={
                  view === "virtualStoreOrders"
                    ? "nav-link active text-center text-uppercase tab-link"
                    : "nav-link text-center text-uppercase tab-link"
                }
                onClick={this.showVirtualStoreOrders}
              >
                Marketing store orders
              </a>
            </li>
          </ul>
        </div>
        <div>
          <StoreOrdersView
            from={"allOrders"}
            orders={view === "storeOrders" ? storeOrders : virtualStoreOrders}
            virtual={view === "virtualStoreOrders" ? true : false}
          />
        </div>
      </div>
    );
  }
}
export default SellerAllOrdersContainer;
