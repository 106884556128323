import React from "react";
import ValidationUtils from "../../../../utils/ValidationUtils";
import Error from "../../../../widgets/alerts/Error";

class PricingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeMin = (e) => {
    this.setState({ min: e.target.value });
  };

  onChangeMultiple = (e) => {
    this.setState({ multiple: e.target.value });
  };

  changePrice = (e) => {
    this.setState({ price: e.target.value });
  };

  submitForm = (e) => {
    e.preventDefault();
    var errorMessage = ValidationUtils.validatePricingForm(this.state);
    if (errorMessage) {
      this.setState({ error: errorMessage });
      return;
    }
    let { price, min, multiple } = this.state;
    this.props.addQuantity({
      price: { value: price },
      min,
      multiple,
      status: "ACTIVE",
    });
  };

  render() {
    let { index, product, from = false } = this.props;
    return (
      <div className="form-body">
        <form className="form-default" onSubmit={this.submitForm}>
          <Error message={this.state.error} style={{ margin: "10px" }} />
          <div className="mb-1">
            <div>
              Price (
              {product.sku.pricing.isTaxIncluded === "Y"
                ? "Including Taxes "
                : "Excluding Taxes "}
              {product.sku.pricing.isDeliveryIncluded === "Y"
                ? "and including Delivery charges"
                : "and excluding Delivery charges"}
              )
            </div>
            <input
              style={{
                border: "1px solid hsl(0,0%,80%)",
                borderRadius: "4px",
                height: "38px",
                padding: "2px 8px",
              }}
              type="number"
              placeholder="Price"
              value={this.state.price}
              onChange={this.changePrice}
            />
          </div>

          <div className="d-flex flex-column">
            <div>Quantity</div>
            <div>
              <input
                className="mr-2"
                type="number"
                style={{
                  border: "1px solid hsl(0,0%,80%)",
                  borderRadius: "4px",
                  height: "38px",
                  padding: "2px 8px",
                }}
                placeholder="Min"
                value={this.state.min}
                onChange={this.onChangeMin}
              />
              <input
                type="number"
                style={{
                  border: "1px solid hsl(0,0%,80%)",
                  borderRadius: "4px",
                  height: "38px",
                  padding: "2px 8px",
                }}
                placeholder="Multiple"
                value={this.state.multiple}
                onChange={this.onChangeMultiple}
              />
            </div>
          </div>

          <button
            type="submit"
            style={{ float: "right" }}
            className="btn btn-primary mt-1"
          >
            {from ? "Add" : "Save"}
          </button>
        </form>
      </div>
    );
  }
}

export default PricingForm;
