import React from "react";
import { Link } from "react-router-dom";
import PopUpModal from "../../widgets/modal/PopUpModal";
import BidRequestStatus from "./BidRequestStatus";

class BidRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getViewBidsModal = () => {
    this.setState({ showBids: true });
  };

  getProduct = product => {
    var label;
    if (
      product.category &&
      (product.brand || product.productType || product.variant)
    ) {
      label = product.category + "/";
    } else if (!product.category) {
      label = "";
    } else {
      label = product.category;
    }
    if (product.brand && (product.productType || product.variant)) {
      label += product.brand + "/";
    } else if (!product.brand) {
      label += "";
    } else {
      label += product.brand;
    }
    if (product.productType && product.variant) {
      label += product.productType + "/";
    } else if (!product.productType) {
      label += "";
    } else {
      label += product.productType;
    }
    if (product.variant) {
      label += product.variant;
    }
    return label;
  };

  getTitle = () => {
    var request = this.props.request;
    if (request.rProduct) {
      var head = (
        <div>
          <span>
            {request.number}- {this.getProduct(request.rProduct)}
          </span>
          <span style={{ float: "right" }}>{request.bids.length} Bids</span>
        </div>
      );
      if (request.bids.length >= 1) {
        return (
          <Link
            className="btn-link"
            to={"dashboard/bid-requests/" + request._id}
          >
            {head}
          </Link>
        );
      } else {
        return head;
      }
    } else {
      var head = (
        <div>
          <span>
            {request.number}- {request.product.category}/{request.product.brand}
            /{request.product.name}
          </span>
          <span style={{ float: "right" }}>{request.bids.length} Bids</span>
        </div>
      );
      if (request.bids.length >= 1) {
        return (
          <Link
            className="btn-link"
            to={"dashboard/bid-requests/" + request._id}
          >
            {head}
          </Link>
        );
      } else {
        return head;
      }
    }
  };

  render() {
    var request = this.props.request;
    if (!request) {
      return null;
    }
    return (
      <div
        className="panel panel-default panel-sidebar-1"
        style={{ marginTop: "10px", borderLeft: "4px solid #cd1b38" }}
      >
        <div
          className="panel-heading"
          style={{ padding: "10px", border: "#fff", backgroundColor: "#fff" }}
        >
          <h2 style={{ fontWeight: "bold" }}>{this.getTitle()}</h2>
        </div>

        <div className="panel-body" style={{ padding: "10px" }}>
          <div className="row">
            <div className="col-md-5 col-md-offset-1">
              {request.quantity && (
                <p>
                  {request.quantity.value} {request.quantity.unit}
                </p>
              )}
            </div>
            <div className="col-md-5 col-md-offset-1">
              {request.biddingLastDate && (
                <p>
                  Bidding ends on{" "}
                  {new Date(request.biddingLastDate).toLocaleDateString()}
                </p>
              )}
            </div>
            <div className="col-md-5 col-md-offset-1">
              {request.paymentTerms && <p>{request.paymentTerms}</p>}
            </div>
            <div className="col-md-5 col-md-offset-1">
              <p>
                {request.city}- {request.pinCode}
              </p>
            </div>
          </div>
          <div className="row">
            {this.state.showBids && (
              <PopUpModal
                closeModal={() => this.setState({ showBids: false })}
                componentId="VIEW_BIDS"
                request={request}
                modalSize="lg"
              />
            )}
          </div>
        </div>
        <span
          style={{
            fontSize: "10px",
            color: "#999",
            paddingLeft: "10px"
          }}
        >
          {new Date(request.doc).toLocaleString()}
        </span>
        <BidRequestStatus request={request} />
      </div>
    );
  }
}

export default BidRequest;
