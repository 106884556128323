import React from "react";
import EmployeeLeadsView from "./EmployeeLeadsView";

class EmployeeLeadsContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  componentDidMount() {
    this.setState({ loading: true });
  }

  render() {
    let { loading } = this.state;

    return <EmployeeLeadsView loading={loading} />;
  }
}

export default EmployeeLeadsContainer;
