import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ChatView from "./../chat/MobileChatView";
import "./Dashboard.css";
import SettingsView from "../settings/SettingsView";
import StoresViewContainer from "../rb/Seller/Store/StoresViewContainer";
import StoreViewContainer from "../rb/Seller/Store/StoreViewContainer";
import NewStoreContainer from "../rb/Seller/Store/NewStoreContainer";
import NewVirtualStoreContainer from "../rb/Seller/Store/virtualstore/NewVirtualStoreContainer";
import SellerAllOrdersContainer from "../rb/Seller/Store/SellerAllOrdersContainer";

class BusinessMainContent extends React.Component {
  render() {
    let { smallScreen, leftNav, contentHeight } = this.props;

    if (smallScreen) {
      return (
        <div className="row">
          <div className="col-md-12">
            <div
              className="col-xs-10 col-xs-offset-1 main-content"
              style={{ padding: "2px" }}
            >
              <div
                className="mc"
                style={{ height: contentHeight - 45, overflowY: "auto" }}
                id="main-layout"
              >
                <Switch>
                  <Route
                    path="/dashboard/new-store"
                    component={NewStoreContainer}
                  />
                  <Route
                    path="/dashboard/new-virtual-store"
                    component={NewVirtualStoreContainer}
                  />
                  <Route
                    path="/dashboard/stores/:type/:storeId/:view"
                    component={StoreViewContainer}
                  />
                  <Route
                    path="/dashboard/orders"
                    component={SellerAllOrdersContainer}
                  />
                  <Route
                    path="/dashboard/stores"
                    component={StoresViewContainer}
                  />
                  <Route path="/dashboard/chat" component={ChatView} />
                  <Route path="/dashboard/settings" component={SettingsView} />
                  <Redirect from="/" to="/dashboard/stores" />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row">
          <div className="col-md-2">{leftNav}</div>
          <div className="col-md-10">
            <div className="main-content">
              <div
                className="mc"
                style={{
                  height: "calc(100vh - 50px)",
                  overflowY: "auto",
                }}
                id="main-layout"
              >
                <div style={{ padding: "20px" }}>
                  <Switch>
                    <Route
                      path="/dashboard/new-store"
                      component={NewStoreContainer}
                    />
                    <Route
                      path="/dashboard/new-virtual-store"
                      component={NewVirtualStoreContainer}
                    />
                    <Route
                      path="/dashboard/stores/:type/:storeId/:view"
                      component={StoreViewContainer}
                    />
                    <Route
                      path="/dashboard/orders"
                      component={SellerAllOrdersContainer}
                    />
                    <Route
                      path="/dashboard/stores"
                      component={StoresViewContainer}
                    />
                    <Route path="/dashboard/chat" component={ChatView} />
                    <Route
                      path="/dashboard/settings"
                      component={SettingsView}
                    />
                    <Redirect from="/" to="/dashboard/stores" />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default BusinessMainContent;
