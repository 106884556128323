import React from "react";
import { connect } from "react-redux";
import LinkToModal from "../../widgets/modal/LinkToModal";
import { getAmountPayable, getConfirmedBid, convertToPaisa } from "./../../utils/Utils";
import orderService from "../../services/OrdersService";
import Actions from "../../state/actions";
import Env from "./../../Env";
var rzpay_key = Env.rzpay_key;

class OrderPaymentMethod extends React.Component {
  initiateCardTx = () => {
    var order = this.props.order;
    var request = order.request;
    var user = order.user;
    var options = {
      key: rzpay_key,
      name: "buildstreet.com",
      handler: response => {
        this.paymentCallback(response);
      },
      theme: {
        color: "#54b2f7"
      },
      notes: {
        bidRequestId: order.request._id
      },
      prefill: {
        email: user.email,
        contact: user.phone
      }
    };
    var amount = getAmountPayable(
      request.quantity.value,
      getConfirmedBid(request.confirmedBid, request.bids)
    );
    options.amount = convertToPaisa(amount); // 2000 paise = INR 20
    //options.description = data.description;
    var rzp = new window.Razorpay(options);
    rzp.open();
  };

  paymentCallback = response => {
    var paymentId = response ? response.razorpay_payment_id : null;
    if (paymentId) {
      orderService.processPayment(
        this.props.order._id,
        paymentId,
        data => {
          var paymentCapturedStatus = data.paymentCapturedStatus;
          if (paymentCapturedStatus === "SUCCESS") {
            var message = {
              content:
                "I have made Card/Net Banking/Wallets/EMI payment for order " +
                this.props.order.number,
              type: "T",
              sender: { type: "U" },
              date: new Date()
            };
            this.props.processMessage(message);
            this.props.toastMessage(
              "Done! Payment is successful. We will contact you to discuss delivery options.",
              "success",
              10
            );
            this.props.refresh();
          } else if (paymentCapturedStatus === "C_FAILED") {
            this.props.toastMessage("The amount is invalid", "error", 10);
          } else if (paymentCapturedStatus === "NOKEY") {
            this.props.toastMessage("Invalid request", "error", 10);
          }
        },
        message => {
          message =
            message ||
            "Looks like something went wrong. We will check our records and get back to you.";
          //todo put in the chat
          this.props.toastMessage(message, "error", 10);
        }
      );
    } else {
      this.props.toastMessage("Payment failed. Please try again", "error", 10);
    }
  };

  getDescription = order => {
    var method = order.paymentMethod,
      status = order.status;
    var descriptionNode = null;
    if (method === "Card/Net Banking/Wallets/EMI") {
      descriptionNode = (
        <div>
          Additional charges will be applied for making payment using gateway.
          <br /> Here are the payment gateway charges.
          <br />
          <br />
          Visa/MasterCard/Maestro Cards, Net Banking and Wallet: 2% TDR + 14.5%
          Service Taxes.
          <br />
          EMI, American Express Cards: 3% TDR + 14.5% Service Taxes.
          {status === "PAYMENT_PENDING" && (
            <div>
              <br />
              Total amount including payment gateway charges will be displayed
              in the payment page.
              <br />
              Please click on the button below to complete the payment.
            </div>
          )}
        </div>
      );
    } else if (method === "Cash/Cheque on Delivery") {
      descriptionNode = (
        <div>
          You can hand over the cash/cheque to our sales agents at the time of
          delivery.
        </div>
      );
    } else if (method === "NEFT/IMPS transfer") {
      descriptionNode = (
        <div>
          {status === "PAYMENT_PENDING" && (
            <div>
              Please transfer the money to the following account and click on
              the button below to let us know.
              <br />
            </div>
          )}
          Account Number: 915020033819935
          <br />
          Account Type: Current
          <br />
          Bank: Axis Bank, RA Puram Branch, Chennai
          <br />
          IFSC: UTIB0000676
        </div>
      );
    } else if (method === "Credit") {
      descriptionNode = (
        <div>
          We are processing your credit request. We will get in touch with you
          shortly. If you have any concerns, you can talk to us in the chat.
        </div>
      );
    }
    return descriptionNode;
  };

  getAction = order => {
    var method = order.paymentMethod,
      status = order.status;
    var actionNode = null;
    if (
      method === "Card/Net Banking/Wallets/EMI" &&
      status === "PAYMENT_PENDING"
    ) {
      actionNode = (
        <a className="btn btn-primary pull-right" onClick={this.initiateCardTx}>
          Make Payment
        </a>
      );
    } else if (method === "Cash/Cheque on Delivery") {
      actionNode = null;
    } else if (
      method === "NEFT/IMPS transfer" &&
      status === "PAYMENT_PENDING"
    ) {
      actionNode = (
        <LinkToModal
          className="btn btn-primary pull-right"
          modalType="NOTIFY_PAYMENT"
          modalSize="lg"
          modalProps={{
            orderId: order._id,
            orderNumber: order.number,
            onCompleteHook: () => {
              this.props.refresh();
            }
          }}
        >
          Done! Money Transferred
        </LinkToModal>
      );
    } else if (method === "Credit") {
      actionNode = null;
    }
    return actionNode;
  };

  render() {
    var order = this.props.order;
    if (!order) {
      return null;
    }

    return (
      <div>
        {this.getDescription(order)}
        <div style={{ marginTop: "10px" }}>{this.getAction(order)}</div>
      </div>
    );
  }
}

function actions(dispatch) {
  return {
    toastMessage: (message, type, duration) => {
      dispatch(Actions.toastMessage(message, type, duration));
    },
    processMessage: message => dispatch(Actions.processMessage(message))
  };
}

export default connect(
  null,
  actions
)(OrderPaymentMethod);
