import React from "react";
import BidRequestStatus from "./BidRequestStatus";

class BidRequestRo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getProduct = product => {
    var label;
    if (
      product.category &&
      (product.brand || product.productType || product.variant)
    ) {
      label = product.category + "/";
    } else if (!product.category) {
      label = "";
    } else {
      label = product.category;
    }
    if (product.brand && (product.productType || product.variant)) {
      label += product.brand + "/";
    } else if (!product.brand) {
      label += "";
    } else {
      label += product.brand;
    }
    if (product.productType && product.variant) {
      label += product.productType + "/";
    } else if (!product.productType) {
      label += "";
    } else {
      label += product.productType;
    }
    if (product.variant) {
      label += product.variant;
    }
    return label;
  };

  render() {
    var request = this.props.request;
    var rProduct = this.props.rProduct;
    if (!request) {
      return null;
    }
    return (
      <div
        className="panel panel-default panel-sidebar-1"
        style={{ marginTop: "10px", borderLeft: "4px solid #cd1b38" }}
      >
        <div
          className="panel-heading"
          style={{ padding: "10px", border: "#fff", backgroundColor: "#fff" }}
        >
          <h2 style={{ fontWeight: "bold;k" }}>
            {rProduct && (
              <span>
                {request.number}- {this.getProduct(rProduct)}
              </span>
            )}
            {!rProduct && request.rProduct && (
              <span>
                {request.number}- {this.getProduct(request.rProduct)}
              </span>
            )}
            {!rProduct && !request.rProduct && request.product && (
              <span>
                {request.number}- {request.product.category}/
                {request.product.brand}/{request.product.name}
              </span>
            )}
          </h2>
        </div>

        <div className="panel-body" style={{ padding: "10px" }}>
          <div className="row">
            <div className="col-md-5 col-md-offset-1">
              {request.quantity && (
                <p>
                  {request.quantity.value} {request.quantity.unit}
                </p>
              )}
            </div>
            <div className="col-md-5 col-md-offset-1">
              {request.biddingEndDate && (
                <p>
                  Bidding ends on{" "}
                  {new Date(request.biddingEndDate).toLocaleDateString()}
                </p>
              )}
            </div>
            <div className="col-md-5 col-md-offset-1">
              {request.paymentTerms && <p>{request.paymentTerms}</p>}
            </div>
            <div className="col-md-5 col-md-offset-1">
              <p>
                {request.city} - {request.pinCode}
              </p>
            </div>
          </div>
        </div>
        <span
          style={{
            fontSize: "10px",
            color: "#999",
            paddingLeft: "10px"
          }}
        >
          {new Date(request.doc).toLocaleString()}
        </span>
        <BidRequestStatus status={request.status} comments={request.comments} />
      </div>
    );
  }
}

export default BidRequestRo;
