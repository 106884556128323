import React from "react";
import { connect } from "react-redux";
import ModalN from "../../../../widgets/modal/ModalN";
import ProductAttributes from "../../Shop/ProductAttributes";
import ProductImage from "../../Shop/ProductImage";
import ProductTitle from "../../Shop/ProductTitle";
import PricingForm from "./PricingForm";
import ServiceAreaForm from "./ServiceAreaForm";
import ServiceLocationsView from "./ServiceLocationsView";
import Actions from "../../../../state/actions";
import ResellerMarkupForm from "./ResellerMarkupForm";

class NewStoreProductsInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      products: props.products || [],
    };
    this.manageDuplicate = this.manageDuplicate.bind(this);
  }

  copyServiceArea = (e, index) => {
    if (index > 0) {
      let { products } = this.state;
      let prevIndex = index - 1;
      console.log("products", products[prevIndex]);
      if (products[index - 1].serviceAreas) {
        products[index].serviceAreas = [...products[prevIndex].serviceAreas];
        this.setState({ products: [...products] });
      }
    }
  };

  manageDuplicate(e, type, index) {
    let { products } = this.state;

    if (type === "copy") {
      let product = products[index];
      products.push(JSON.parse(JSON.stringify(product)));
    } else if (type === "remove") {
      products.splice(index, 1);
    }
    this.setState({ products: [...products] });
  }

  validate = () => {
    return true;
  };

  getProductDetails = () => {
    let { products } = this.state;
    products.map((e) => {
      console.log("getting product details" + e.sku);
    });

    return products;
  };

  onChangeProductQuantity = (index, field, value) => {
    let { products } = this.state;
    let product = products[index];
    let serviceQty = product.serviceQty || {};
    serviceQty[field] = value;
    this.changeProductValue(index, "serviceQty", { ...serviceQty });
  };

  showAddServiceArea = (productIndex) => {
    this.setState({ showAddServiceAreaModal: true, productIndex });
  };

  hideAddService = () => {
    this.setState({ showAddServiceAreaModal: false, productIndex: null });
  };

  addServiceArea = (serviceArea) => {
    let { products, productIndex } = this.state;
    let product = products[productIndex];
    let serviceAreas = product.serviceAreas || [];
    for (let i = 0; i < serviceAreas.length; i++) {
      if (JSON.stringify(serviceAreas[i]) === JSON.stringify(serviceArea)) {
        this.props.toastMessage(
          "This service area has already been added",
          "error",
          3
        );
        this.hideAddService();
        return;
      }
    }
    serviceAreas.push(serviceArea);
    product = { ...product, serviceAreas: [...serviceAreas] };
    products[productIndex] = product;
    this.setState({ products: [...products] });
    this.hideAddService();
  };

  addServiceAreas = (newServiceAreas) => {
    let { products, productIndex } = this.state;
    let product = products[productIndex];
    let serviceAreas = product.serviceAreas || [];

    serviceAreas = serviceAreas.concat(newServiceAreas);
    product = { ...product, serviceAreas: serviceAreas };
    products[productIndex] = product;
    this.setState({ products: [...products] });
    this.hideAddService();
  };

  showAddPricing = (productIndex) => {
    this.setState({ showAddPricingModal: true, productIndex });
  };

  hideAddPricing = () => {
    this.setState({ showAddPricingModal: false, productIndex: null });
  };

  addQuantity = (quantity) => {
    let { products, productIndex } = this.state;
    let product = products[productIndex];
    let quantities = product.quantities || [];
    quantities.push(quantity);
    product = { ...product, quantities: [...quantities] };
    products[productIndex] = product;
    this.setState({ products: [...products] });
    this.hideAddPricing();
  };

  addMarkUpPrice = (price, productIndex) => {
    let { products } = this.state;
    let product = products[productIndex];
    product.markUpPrice = price;
    products[productIndex] = product;
    this.setState({ products: [...products] });
  };

  showServiceAreaLocations = (serviceAreas) => {
    this.setState({
      showServiceLocations: true,
      serviceAreas: serviceAreas.map((sa) => ({
        state: sa.state.value,
        district: sa.district.value,
        zone: sa.zone.value,
        localities: sa.localities.map((l) => l.value),
      })),
    });
  };

  hideServiceLocations = () => {
    this.setState({ showServiceLocations: false, serviceAreas: [] });
  };

  removeServiceArea = (pIndex, saIndex) => {
    let { products } = this.state;
    let product = products[pIndex];
    let serviceAreas = product.serviceAreas;
    serviceAreas.splice(saIndex, 1);
    product.serviceAreas = [...serviceAreas];
    products[pIndex] = { ...product };
    this.setState({ products: [...products] });
  };

  removeQtyAndPricing = (pIndex, qtyIndex) => {
    let { products } = this.state;
    let product = products[pIndex];
    let quantities = product.quantities;
    quantities.splice(qtyIndex, 1);
    product.quantities = [...quantities];
    products[pIndex] = { ...product };
    this.setState({ products: [...products] });
  };

  render() {
    let { products } = this.state;
    let { user } = this.props;
    return (
      <div>
        {products.map((product, index) => {
          let { sku } = product;
          let { attributes = [], descriptionAttributes, notes } = sku;
          let attributesMap = {};
          attributes.forEach((item) => {
            if (!attributesMap[item.key]) {
              attributesMap[item.key] = item.value;
            }
          });

          return (
            <div className="d-flex justify-content-between p-2 mb-2 card ">
              <div
                className="card-body"
                style={{ width: "100%", padding: "0.5rem" }}
              >
                <div className="row" style={{ width: "100%" }}>
                  <div className="col-md-6 d-flex flex-column ">
                    <div
                      className="card card-product"
                      style={{ margin: "0.4rem" }}
                    >
                      <div className="card-body" style={{ padding: "1rem" }}>
                        <ProductTitle
                          brand={sku.brand}
                          subtype={sku.subtype}
                          descriptionAttributes={descriptionAttributes}
                          attributes={attributesMap}
                        />

                        <ProductImage
                          images={sku.images}
                          subtype={sku.subtype}
                        />

                        <div className="mt-1">
                          <div>
                            {sku.category +
                              " | " +
                              sku.type +
                              " | " +
                              sku.subtype +
                              " | " +
                              sku.brand}
                          </div>
                        </div>

                        <div className="mt-2">
                          <ProductAttributes
                            notes={notes}
                            attributes={attributes}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.props.type === "ps" ? (
                    <div className="col-md-6 d-flex flex-column">
                      <div
                        className="p-2 mb-1"
                        style={{ border: "1px solid #ddd" }}
                      >
                        <h6>Service Area</h6>
                        {index !== 0 &&
                          (!product.serviceAreas ||
                            product.serviceAreas.length === 0) && (
                            <div className="d-flex justify-content-end align-items-center">
                              <div key={"Checkbox-" + index}>
                                <a
                                  className="btn btn-link font-normal"
                                  onClick={(e) =>
                                    this.copyServiceArea(e, index)
                                  }
                                >
                                  Copy Service Area from above
                                </a>
                              </div>
                            </div>
                          )}
                        <div className="mt-1">
                          {product.serviceAreas && (
                            <div>
                              {product.serviceAreas.map(
                                (serviceArea, saIndex) => (
                                  <div
                                    className="p-1 d-flex justify-content-between"
                                    style={{
                                      border: "1px solid #ddd",
                                      marginBottom: "3px",
                                    }}
                                  >
                                    <span>
                                      {`${serviceArea.state.value} -${
                                        serviceArea.district.value
                                      } - ${serviceArea.zone.value} - ${
                                        serviceArea.localities.length >= 2
                                          ? serviceArea.localities[0].value +
                                            " , " +
                                            serviceArea.localities[1].value +
                                            "..."
                                          : serviceArea.localities.map(
                                              (locality) => locality.value + ","
                                            )
                                      }`}
                                    </span>
                                    <span className="d-flex align-items-center">
                                      <a
                                        className="btn-link ml-1 mr-1"
                                        onClick={() => {
                                          this.showServiceAreaLocations([
                                            serviceArea,
                                          ]);
                                        }}
                                      >
                                        View
                                      </a>
                                      <a
                                        className="btn-link text-danger mr-1"
                                        onClick={() => {
                                          this.removeServiceArea(
                                            index,
                                            saIndex
                                          );
                                        }}
                                      >
                                        <i className="fa fa-times" />
                                      </a>
                                    </span>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </div>

                        <div className="d-flex justify-content-end">
                          <button
                            className="btn-link btn-primary"
                            onClick={() => this.showAddServiceArea(index)}
                          >
                            <i className="fa fa-plus" />
                          </button>
                        </div>
                      </div>

                      <hr />

                      <div className="p-2" style={{ border: "1px solid #ddd" }}>
                        <h6>Quantity and Pricing</h6>
                        {product.quantities && (
                          <div>
                            {product.quantities.map((quantity, qtyIndex) => (
                              <div
                                className="p-2 mb-1"
                                style={{ border: "1px solid #ddd" }}
                              >
                                <span>
                                  {" Min : " +
                                    quantity.min +
                                    " Multiple : " +
                                    quantity.multiple +
                                    " Price : ₹ " +
                                    quantity.price.value}
                                </span>
                                <a
                                  className="btn-link text-danger mr-1"
                                  style={{ float: "right" }}
                                  onClick={() => {
                                    this.removeQtyAndPricing(index, qtyIndex);
                                  }}
                                >
                                  <i className="fa fa-times" />
                                </a>
                              </div>
                            ))}
                          </div>
                        )}
                        <div className="d-flex justify-content-end">
                          <button
                            className="btn-link btn-primary"
                            onClick={() => this.showAddPricing(index)}
                          >
                            <i className="fa fa-plus" />
                          </button>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center mt-2">
                        <button
                          className="btn btn-primary btn-xs mr-1"
                          onClick={(e) =>
                            this.manageDuplicate(e, "copy", index)
                          }
                        >
                          Copy
                        </button>
                        <button
                          className="btn btn-danger btn-xs mr-1"
                          onClick={(e) =>
                            this.manageDuplicate(e, "remove", index)
                          }
                        >
                          Remove Product
                        </button>
                      </div>
                    </div>
                  ) : (
                    <ResellerMarkupForm
                      addMarkUpPrice={this.addMarkUpPrice}
                      productIndex={index}
                      basicUnit={sku.basicUnit}
                      user={user}
                      markUpValue={user == "admin" ? sku.pricing.value : null}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {this.state.showAddServiceAreaModal && (
          <ModalN
            title="Add service area"
            show={this.state.showAddServiceAreaModal}
            close={this.hideAddService}
            modalHeight={{
              minHeight: "300px",
              overflowY: "auto",
            }}
          >
            <ServiceAreaForm
              addServiceArea={this.addServiceArea}
              addServiceAreas={this.addServiceAreas}
              product={this.state.products[this.state.productIndex]}
              serviceAreas={
                this.state.products[this.state.productIndex].serviceAreas
              }
              changeProductValue={this.changeProductValue}
            />
          </ModalN>
        )}
        {this.state.showAddPricingModal && (
          <ModalN
            title="Add pricing details"
            show={this.state.showAddPricingModal}
            close={this.hideAddPricing}
          >
            <PricingForm
              addQuantity={this.addQuantity}
              product={this.state.products[this.state.productIndex]}
              changeProductValue={this.changeProductValue}
            />
          </ModalN>
        )}

        {this.state.showServiceLocations && (
          <ModalN
            title="Service Locations"
            show={this.state.showServiceLocations}
            close={this.hideServiceLocations}
          >
            <ServiceLocationsView
              from={true}
              serviceAreas={this.state.serviceAreas}
            />
          </ModalN>
        )}
      </div>
    );
  }
}

function actions(dispatch) {
  return {
    toastMessage: (message, type, duration) => {
      dispatch(Actions.toastMessage(message, type, duration));
    },
  };
}

export default connect(null, actions, null, { forwardRef: true })(
  NewStoreProductsInfo
);
