import React from "react";

export default ({ images = [], subtype }) => {
  let image = images[0]
    ? images[0]
    : subtype.toLowerCase().replaceAll(" ", "-") + ".jpg";

  return (
    <div className="card-image swiper-js-container">
      <div className="">
        <div
          className="swiper-container"
          data-swiper-items="1"
          data-swiper-space-between="0"
        >
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <img
                src={"/sku-images/" + image}
                className="img-fluid img-center img-primary"
                style={{ height: "150px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
