import React from "react";
import SignUp from "./SignUp";

class SellerSignUpContainer extends React.Component {
  render() {
    return (
      <SignUp seller />
    );
  }
}
export default SellerSignUpContainer;