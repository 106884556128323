import React from "react";
import StoreService from "../../../../services/StoreService";
import StoreStocksView from "./StoreStocksView";

const PAGE_SIZE = 9;

class StoreStocksContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { stocks: [], currentPage: 1 };
  }

  refreshStocks = () => {
    this.setState({ currentPage: 1 }, this.loadStocks);
  };

  loadStocks = (checkedFilters, isScroll) => {
    let { currentPage, stocks = [] } = this.state;
    this.setState({ loading: true });
    if (this.props.type !== "v") {
      StoreService.fetchStocks(
        this.props.storeId,
        isScroll ? currentPage : 1,
        PAGE_SIZE,
        checkedFilters
      )
        .then((data) => {
          let { totalCount } = data;
          this.setState({
            stocks: isScroll ? stocks.concat(data.stocks) : data.stocks,
            totalCount: isScroll
              ? currentPage === 1
                ? totalCount
                : this.state.totalCount
              : totalCount,
            currentPage: data.stocks.length ? currentPage + 1 : 1,
            loading: false,
          });
        })
        .catch((e) => this.setState({ loading: false }));
    } else {
      StoreService.fetchVirtualStoreStocks(
        this.props.storeId,
        isScroll ? currentPage : 1,
        PAGE_SIZE,
        checkedFilters
      )
        .then((data) => {
          let { stocks = [], totalCount } = data;

          this.setState({
            stocks: isScroll ? stocks.concat(data.stocks) : data.stocks,
            totalCount: isScroll
              ? currentPage === 1
                ? totalCount
                : this.state.totalCount
              : totalCount,
            currentPage: data.stocks.length ? currentPage + 1 : 1,
            loading: false,
          });
        })
        .catch((e) => this.setState({ loading: false }));
    }
  };

  sortStocks = (stocks) => {
    stocks.sort((a) => (!a.isOwnStock ? 1 : -1));
  };

  updateStockPrice = (stockId, price, index) => {
    return StoreService.editStocks(
      this.props.storeId,
      stockId,
      price,
      index
    ).then((response) => {
      this.loadStocks();
    });
  };

  updateStockMarkUpPrice = (stockId, price, ownStockMarkUp) => {
    return StoreService.editVirtualStoreStocks(
      this.props.storeId,
      stockId,
      price,
      ownStockMarkUp
    ).then((response) => {
      this.loadStocks();
    });
  };

  render() {
    let { loading, stocks, currentPage, totalCount } = this.state;
    let totalPages = Math.ceil(totalCount / PAGE_SIZE);
    let loadMore = currentPage - 1 < totalPages;

    return (
      <StoreStocksView
        loading={loading}
        storeId={this.props.storeId}
        stocks={stocks}
        handleDelete={this.props.handleDelete}
        deleteStockList={this.props.deleteStockList}
        updateStockPrice={this.updateStockPrice}
        updateStockMarkUpPrice={this.updateStockMarkUpPrice}
        showAddStocks={this.props.showAddStocks}
        type={this.props.type}
        refreshStocks={this.refreshStocks}
        loadStocks={this.loadStocks}
        loadMore={loadMore}
        totalCount={totalCount}
      />
    );
  }
}

export default StoreStocksContainer;
