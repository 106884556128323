import React from "react";

class ImageNameForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { ...props.product };
    }

    onChangeImageName = e => {
        let name = this.state.name || {};
        name.value = e.target.value;
        this.setState({ name: { ...name } });
    };

    submitForm = e => {
        e.preventDefault();
        this.props.updateImageName(this.state._id, this.state.name, this.props.index);
    };

    render() {
        let name = this.state;

        return (
            <form onSubmit={this.submitForm} className="d-flex">
                <div>
                    <input
                        placeholder="Name"
                        value={name ? name.value : null}
                        onChange={this.onChangeImageName}
                    />
                </div>
                <a
                    className="btn btn-link text-danger btn-sm" onClick={this.props.cancelImageNameEdit}
                >
                    <i className="fa fa-times" />
                </a>
            </form>
        );
    }
}

export default ImageNameForm;
