import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import VirtualStoresGmv from "../rb/skus/VirtualStoresGmv";
import VirtualStoresView from "../rb/skus/VirtualStoresView";

class InvestorMainContent extends React.Component {
  render() {
    let { smallScreen, leftNav, contentHeight } = this.props;

    if (smallScreen) {
      return <div>Please access it from Laptop/Desktop computer.</div>;
    } else {
      return (
        <div className="row">
          <div className="col-md-2">{leftNav}</div>
          <div className="col-md-10">
            <div className="main-content">
              <div
                className="mc"
                style={{
                  height: "calc(100vh - 50px)",
                  overflowY: "auto",
                }}
                id="main-layout"
              >
                <div style={{ padding: "20px" }}>
                  <Switch>
                    <Route
                      path="/dashboard/reselling-stores-gmv"
                      component={VirtualStoresGmv}
                    />
                    {/* <Route
                      path="/dashboard/virtual-stores"
                      component={VirtualStoresView}
                    /> */}

                    <Route
                      path="/dashboard/virtual-stores"
                      render={(props) => (
                        <VirtualStoresView from="Investor" {...props} />
                      )}
                    />

                    <Redirect from="/" to="/dashboard/reselling-stores-gmv" />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default InvestorMainContent;
