import React from "react";
import Select from "react-select";
import AdminService from "../../../services/AdminService";
import StoreService from "../../../services/StoreService";
import ValidationUtils from "../../../utils/ValidationUtils";
import Error from "../../../widgets/alerts/Error";

class EditLead extends React.Component {
  constructor(props) {
    super(props);
    let lead = props.lead;
    this.state = {
      status: lead.status,
      comment: lead.comment,
    };
  }

  getStatusOptions = (followUpType) => {
    let options =
      this.props.statusOptions && this.props.statusOptions.length > 0
        ? this.props.statusOptions
        : [];
    return options.filter((item) => {
      return item.multiType.indexOf(followUpType) !== -1;
    });
  };

  getCommentsOptions = (followUpType, status) => {
    status = status ? status.value : status;
    let commentsOptions =
      this.props.commentsOptions && this.props.commentsOptions.length > 0
        ? this.props.commentsOptions[0].options
        : [];
    return commentsOptions.filter((item) => {
      return (
        item.multiType.indexOf(followUpType) !== -1 ||
        item.multiType.indexOf(status) !== -1
      );
    });
  };

  onChangeName = (e) => {
    this.setState({ name: e.target.value });
  };

  onChangeStatus = (option) => {
    this.setState({ status: option });
  };

  onChangeComment = (e, flag) => {
    if (flag === "from-select") {
      if (e.value === "Others") {
        this.setState({ commentEditIndex: 1 });
      } else {
        this.setState({ comment: e });
      }
    } else {
      this.setState({ comment: e.target.value });
    }
  };

  onChangeBusinessTypeOptions = (option) => {
    this.setState({ businessType: option });
  };

  onChangeState = (option) => {
    this.getDistricts(option.value);
    let { serviceArea = {} } = this.state;
    serviceArea["state"] = option;
    this.setState({ serviceArea: { ...serviceArea } });
  };

  onChangeDistrict = (option) => {
    this.getZones(option.value);
    let { serviceArea = {} } = this.state;
    serviceArea["district"] = option;
    this.setState({ serviceArea: { ...serviceArea } });
  };

  onChangeZone = (option) => {
    this.getLocalities(option.value);
    let { serviceArea = {} } = this.state;
    serviceArea["zone"] = option;
    this.setState({
      serviceArea: { ...serviceArea },
    });
  };

  onChangeLocality = (options) => {
    let { serviceArea = {} } = this.state;
    serviceArea["locality"] = options;
    this.setState({
      serviceArea: { ...serviceArea },
    });
  };

  getZones = (district) => {
    StoreService.getZoneOptions(district).then((zoneOptions) => {
      this.setState({ zoneOptions });
    });
  };

  getDistricts = (state) => {
    StoreService.getDistrictOptions(state).then((districtOptions) => {
      this.setState({
        districtOptions: districtOptions.map((l) => ({ value: l, label: l })),
      });
    });
  };

  getLocalities = (zone) => {
    StoreService.getLocalityOptions(zone).then((localityOptions) => {
      this.setState({ localityOptions });
    });
  };

  calcNextCallDate = (nextCallDate, updatedOn, status, comment) => {
    let days;
    let nextCallDateOptions = this.props.nextCallDateOptions[0].options;

    days = nextCallDateOptions.filter((item) => {
      return (
        item.multiType.indexOf(status) !== -1 ||
        item.multiType.indexOf(comment) !== -1
      );
    });

    days = parseInt(days[0].value);

    if (updatedOn) {
      nextCallDate = new Date(updatedOn).setDate(
        new Date().getDate(updatedOn) + days
      );
      return nextCallDate;
    } else {
      nextCallDate = new Date(nextCallDate).setDate(
        new Date().getDate(nextCallDate) + days
      );
      return nextCallDate;
    }
  };

  submitForm = (e) => {
    e.preventDefault();
    var validationObject = {
      name: this.state.name ? this.state.name : this.props.lead.name,
      status:
        this.state.status && this.state.status.value
          ? this.state.status.value
          : null,
      comment:
        this.state.commentEditIndex === 1
          ? this.state.comment
            ? this.state.comment
            : null
          : this.state.comment && this.state.comment.value
          ? this.state.comment.value
          : null,
    };
    var errorMessage = ValidationUtils.validateEditLeadForm(validationObject);
    if (errorMessage) {
      this.setState({ error: errorMessage });
      return;
    }
    let { lead } = this.props;
    let leadId = this.props.lead._id;
    let data = JSON.parse(JSON.stringify(this.state));
    data.status = data.status.value;
    data.comment =
      this.state.commentEditIndex === 1 ? data.comment : data.comment.value;

    data.updatedOn = new Date();
    let next;
    if (data.status !== "junk") {
      next = this.calcNextCallDate(
        lead.nextCallDate,
        lead.updatedOn,
        this.state.status.value,
        this.state.comment.value
      );
      data.nextCallDate = new Date(next);
    }

    if (this.props.lead.businessType) {
      delete data.businessType;
    } else if (data.businessType) {
      data["businessType"] = data.businessType.value || null;
    }

    if (this.props.lead.serviceArea) {
      delete data.serviceArea;
    } else if (data.serviceArea) {
      data.serviceArea.state = data.serviceArea.state.value;
      data.serviceArea.district = data.serviceArea.district.value;
      data.serviceArea.zone = data.serviceArea.zone.value;
      data.serviceArea.locality = data.serviceArea.locality.value;
    }

    if (this.props.lead.name) {
      delete data.name;
    }

    AdminService.updateLead(leadId, data)
      .then((response) => {
        this.props.onEditLead(response.lead);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  render() {
    let { status, comment, serviceArea = {}, name, businessType } = this.state;
    let { lead, stateOptions } = this.props;
    return (
      <div className="form-body">
        <form className="form-default" onSubmit={this.submitForm}>
          <Error message={this.state.error} style={{ margin: "10px" }} />
          {!lead.name && (
            <div className="col-12">
              <div className="form-group">
                <input
                  className="form-control form-control-lg"
                  placeholder="Name"
                  value={name}
                  onChange={this.onChangeName}
                />
              </div>
            </div>
          )}

          <Select
            className="flex-fill mb-2"
            placeholder="Status"
            options={this.getStatusOptions(lead.followUpType)}
            value={status || null}
            onChange={this.onChangeStatus}
          />

          <div>
            {this.state.commentEditIndex !== 1 ? (
              <Select
                className="flex-fill mb-2"
                placeholder="Comments"
                options={this.getCommentsOptions(lead.followUpType, status)}
                value={comment || null}
                onChange={(option) => {
                  this.onChangeComment(option, "from-select");
                }}
              />
            ) : (
              <div class="form-group">
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={this.onChangeComment}
                />
                <div className="mt-1">
                  <a
                    className="btn btn-link text-danger btn-sm"
                    onClick={() => {
                      this.setState({ commentEditIndex: 0 });
                    }}
                  >
                    <i className="fa fa-times" />
                  </a>
                </div>
              </div>
            )}
          </div>

          {!lead.serviceArea && (
            <div className="form-group">
              <Select
                className="flex-fill mb-2"
                placeholder="State"
                options={this.props.stateOptions}
                value={serviceArea ? serviceArea.state : null}
                onChange={this.onChangeState}
              />
              {this.state.serviceArea && this.state.serviceArea.state && (
                <Select
                  className="flex-fill mb-2"
                  placeholder="District"
                  options={this.state.districtOptions}
                  value={serviceArea ? serviceArea.district : null}
                  onChange={this.onChangeDistrict}
                />
              )}
              {this.state.serviceArea && this.state.serviceArea.district && (
                <Select
                  className="flex-fill mb-3"
                  placeholder="Zone"
                  options={this.state.zoneOptions}
                  value={
                    this.state.serviceArea ? this.state.serviceArea.zone : null
                  }
                  onChange={this.onChangeZone}
                />
              )}

              {serviceArea && serviceArea.zone && (
                <Select
                  className="flex-fill"
                  placeholder="Locality"
                  options={this.state.localityOptions}
                  value={serviceArea ? serviceArea.locality : null}
                  onChange={this.onChangeLocality}
                />
              )}
            </div>
          )}

          {!lead.businessType && (
            <Select
              className="flex-fill mb-2"
              placeholder="Business type"
              options={this.props.businessTypeOptions}
              value={businessType || null}
              onChange={this.onChangeBusinessTypeOptions}
            />
          )}

          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
    );
  }
}

export default EditLead;
