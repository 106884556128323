import React, { Component } from "react";
import XLSX from "xlsx";
import Env from "./../../../Env";
import Ajax from "../../../services/Ajax";
import storage from "../../../services/StorageService";

class ExcelParser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
      data: [],
      cols: []
    };
    this.handleFile = this.handleFile.bind(this);
    this.processFile = this.processFile.bind(this);
  }

  handleFile(e) {
    const files = e.target.files;
    if (files && files[0]) this.setState({ file: files[0] });
  }

  processFile() {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = e => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true
      });
      wb.SheetNames.forEach(sheet => {
        /* Convert worksheet to array of arrays */
        var ws = XLSX.utils.sheet_to_json(wb.Sheets[sheet]);
        /* Save the data to MONGO */
        addDocument(ws, sheet);
      });
    };

    if (rABS) {
      reader.readAsBinaryString(this.state.file);
    } else {
      reader.readAsArrayBuffer(this.state.file);
    }
  }
  render() {
    return (
      <div className="row d-flex  justify-content-between align-items-center">
        <div>
          <h5 className="mb-0">Upload Excel file</h5>
        </div>
        <div>
          <input
            type="file"
            className="form-control col-md-6"
            id="file"
            accept=".xlsx,.xls,.xlsm"
            onChange={this.handleFile}
          />
        </div>
        <div>
          <input
            type="submit"
            className="btn btn-success "
            value="Process File"
            onClick={this.processFile}
          />
        </div>
      </div>
    );
  }
}
async function addDocument(ws, schema) {
  console.log("inside add document");
  const data = stringify(ws);
  console.log(data);

  return fetch(Env.serverURL + `/api/v2/${schema}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": storage.getToken()
    },
    body: data
  }).then(response => {
    //displays error DO NOT REMOVE
    if (response) {
      console.log(response.msg);
    }
    // alert("Records added.Check log for further details");
  });
}

const COMMA = ",";
const COLON = ":";

function stringify(ws) {
  var returnVal = "[";
  //for every row in Worksheet
  ws.forEach(function (row) {
    var prevHeader = "";
    var id = "";
    for (var header in row) {
      var value = row[header];

      //if not continuation of previous header close the brace correctly
      if (!header.startsWith(prevHeader)) {
        if (hasKeyword(prevHeader, "Obj")) {
          row[prevHeader] += "},";
        } else if (hasKeyword(prevHeader, "KV")) {
          row[prevHeader] += "],";
        }
      }
      //logic to frame the ID column
      if (hasKeyword(header, "Identity")) {
        id += value.trim() + "|";
      }
      //if list convert to comma separated List of Strings
      if (header.endsWith("List")) {
        var arr1 = value.trim().split(COMMA);
        var listVal = "[";
        for (var ele in arr1) {
          listVal += '"' + arr1[ele].trim() + '",';
        }
        listVal = stripLastComma(listVal);
        listVal += "],";
        row[header] = listVal;
      } else if (hasKeyword(header, "KV")) {
        // if KeyValue, split by colon and frame Array of KeyValue Object
        var arr = value.trim().split(COLON);
        if (header.startsWith(prevHeader)) {
          row[prevHeader] +=
            ',{"key":"' + arr[0].trim() + '","value":"' + arr[1].trim() + '"}';
          delete row[header];
          continue;
        } else {
          //first column with KV mentioned
          row[header] =
            '[{"key":"' + arr[0].trim() + '","value":"' + arr[1].trim() + '"}';
        }
      } else if (hasKeyword(header, "Obj")) {
        // if Object, split by colon and create a nested object
        var arr = value.trim().split(COLON);
        if (header.startsWith(prevHeader)) {
          row[prevHeader] += ',"' + arr[0].trim() + '":"' + arr[1].trim() + '"';
          delete row[header];
          continue;
        } else {
          //first column with Obj mentioned
          row[header] = '{"' + arr[0].trim() + '":"' + arr[1].trim() + '"';
        }
      } else {
        //Normal type of column (Not List/KV/Obj)
        row[header] = '"' + row[header] + '",';
      }
      //header becomes prevHeader
      prevHeader = header;
    } //end for loop

    //outside for loop - last column handling
    if (hasKeyword(prevHeader, "KV")) {
      //if Last column is KV close array
      row[prevHeader] += "],";
    } else if (hasKeyword(prevHeader, "Obj")) {
      //if Last column is Obj close NestedObj
      row[prevHeader] += "},";
    }

    //if ID is present add to JSON
    if (id) {
      row["_id"] = '"' + id.substring(0, id.length - 1) + '"';
    }

    //iterating through every column framed
    //start of a document
    returnVal += "{";
    for (header in row) {
      returnVal +=
        '"' +
        //to remove the keywords
        header
          .replace("KV", "")
          .replace("List", "")
          .replace("Identity", "")
          .replace("Obj", "") +
        '":' +
        row[header];
    }
    //truncating the last extra comma
    //end of a document
    returnVal = stripLastComma(returnVal) + "},";
  });
  //truncation the last comma in document array
  //end of document array
  return stripLastComma(returnVal) + "]";
}

export default ExcelParser;

function hasKeyword(prevHeader, keyword) {
  return prevHeader.indexOf(keyword) > -1;
}

function stripLastComma(returnVal) {
  if (returnVal.endsWith(COMMA)) {
    returnVal = returnVal.substring(0, returnVal.lastIndexOf(COMMA));
  }
  return returnVal;
}
