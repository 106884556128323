/**
 * @flow
 */

'use strict';

const initialState = {
    name: null, email: null, phone: null
};

function contact(state = initialState, action = null) {
    if (action.type === 'CONTACT_SUBMITTED') {
        let {_id, name, email, phone} = action.data;
        return {
            _id,
            name, email, phone
        };
    }
    return state;
}

export default contact;
