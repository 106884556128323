import React from "react";
import Select from "react-select";
import AdminService from "../../../services/AdminService";
import ValidationUtils from "../../../utils/ValidationUtils";
import Error from "../../../widgets/alerts/Error";

const followUpTypeOptions = [
  { value: "customer", label: "Customer" },
  { value: "seller", label: "Seller" },
  { value: "collection", label: "Collection" },
  { value: "store creation", label: "Store Creation" },
];

class CreateLeadForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      followUpType: { value: "customer", label: "Customer" },
    };
  }

  componentDidMount = () => {
    this.getServiceEmployees(this.state.followUpType.value);
    this.getLeadSource();
  };

  getServiceEmployees = (followUpType) => {
    AdminService.getEmployees(followUpType).then((employeeOptions = []) => {
      employeeOptions.forEach((option) => {
        option.label = option.name.toUpperCase();
        option.value = option.name;
        delete option.name;
      });
      this.setState({ employeeOptions: employeeOptions });
    });
  };

  getLeadSource = () => {
    AdminService.getLeadSourceOptions().then((leadSourceOptions = []) => {
      this.setState({ leadSourceOptions: leadSourceOptions });
    });
  };
  onChangeLeadSource = (option) => {
    this.setState({ leadSource: option || null });
  };

  onChangeName = (e) => {
    this.setState({ name: e.target.value });
  };

  onChangeFollowUpType = (option) => {
    this.setState({ followUpType: option || null });
    this.getServiceEmployees(option.value);
  };

  onChangePhone = (e) => {
    this.setState({ phone: e.target.value });
  };

  onChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  onChangeServiceEmployee = (option) => {
    this.setState({ serviceEmployee: option || null });
  };

  submitForm = (e) => {
    e.preventDefault();
    var errorMessage = ValidationUtils.validateNewLeadForm(this.state);
    if (errorMessage) {
      this.setState({ error: errorMessage });
      return;
    }

    let data = JSON.parse(JSON.stringify(this.state));

    data.followUpType = data.followUpType.value;
    data.leadSource = data.leadSource.value;
    data.empId = data.serviceEmployee._id;
    data.serviceEmployee = data.serviceEmployee.value;
    data.nextCallDate = Date.now();
    data.updatedOn = null;
    delete data.employeeOptions;
    this.props.submit(data);
  };

  render() {
    let {
      leadSource,
      name,
      followUpType,
      phone,
      email,
      serviceEmployee,
      employeeOptions,
      leadSourceOptions,
    } = this.state;
    return (
      <div className="row d-flex justify-content-center">
        <div className="col-md-9">
          <div
            className="card form-card form-card--style-2"
            style={{
              border: "1px solid #f1f1f1",
              padding: "15px",
            }}
          >
            <div className="form-body">
              <form className="form-default" onSubmit={this.submitForm}>
                <h4 className="text-center">New Lead</h4>
                <Error message={this.state.error} style={{ margin: "10px" }} />

                <div className="col-12">
                  <div className="form-group">
                    <label>Lead source</label>
                    <Select
                      className="flex-fill mb-2"
                      placeholder="Lead source"
                      options={leadSourceOptions}
                      value={leadSource}
                      onChange={this.onChangeLeadSource}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      className="form-control form-control-lg"
                      value={name}
                      onChange={this.onChangeName}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <label>Follow-up type</label>
                    <Select
                      className="flex-fill mb-2"
                      placeholder="follow up type"
                      options={followUpTypeOptions}
                      value={followUpType}
                      onChange={this.onChangeFollowUpType}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <label>Phone</label>
                    <input
                      className="form-control form-control-lg"
                      value={phone}
                      onChange={this.onChangePhone}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <label>Email (optional)</label>
                    <input
                      className="form-control form-control-lg"
                      value={email}
                      onChange={this.onChangeEmail}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <label>Service Employee</label>
                    <Select
                      className="flex-fill mb-2"
                      placeholder="Select service employee"
                      options={employeeOptions}
                      value={serviceEmployee}
                      onChange={this.onChangeServiceEmployee}
                    />
                  </div>
                </div>

                <div className="my-3 d-flex justify-content-between">
                  <button
                    className="btn btn-secondary"
                    onClick={this.props.close}
                  >
                    Cancel
                  </button>

                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateLeadForm;
