import React from "react";
import ShopWrapper from "./ShopWrapper";
import NewStoreProductsInfo from "./NewStoreProductsInfo";
import StoreService from "../../../../services/StoreService";
import Actions from "./../../../../state/actions";
import { connect } from "react-redux";
import history from "../../../../utils/History";

class StoreStocksWizard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { step: 1 };
  }

  onNextStep = () => {
    let { step } = this.state;
    let selectedProducts = this.shopRef.getSelectedProducts();
    if (selectedProducts && selectedProducts.length >= 1) {
      if (step === 1) {
        if (this.shopRef.validate()) {
          this.setState({
            products: selectedProducts,
            step: ++step,
          });
        }
      } else if (step === 2) {
        let products = this.productsMetaRef.getProductDetails();
        if (this.props.type === "ps") {
          for (let i = 0; i < products.length; i++) {
            if (
              !products[i].serviceAreas ||
              products[i].serviceAreas.length === 0
            ) {
              this.props.toastMessage("Please add service area", "error", 5);
              return;
            } else if (
              !products[i].quantities ||
              products[i].quantities.length === 0
            ) {
              this.props.toastMessage(
                "Please add quantity and pricing",
                "error",
                5
              );
              return;
            }
          }
          if (this.productsMetaRef.validate()) {
            this.setState(
              {
                products: this.productsMetaRef.getProductDetails(),
              },
              this.createStore
            );
          }
        } else if (this.props.type === "v") {
          for (let i = 0; i < products.length; i++) {
            if (!products[i].markUpPrice) {
              this.props.toastMessage(
                "Please add your markup price",
                "error",
                5
              );
              return;
            }
          }
          if (this.productsMetaRef.validate()) {
            this.setState(
              {
                products: this.productsMetaRef.getProductDetails(),
              },
              this.createVirtualStore
            );
          }
        }
      }
    } else if (!selectedProducts || selectedProducts === 0) {
      this.props.toastMessage("Please select products to proceed.", "error", 5);
    }
  };

  onPrevStep = () => {
    let { step } = this.state;
    this.setState({ step: --step });
  };

  createStore = () => {
    let data = JSON.parse(JSON.stringify(this.state));
    let { products } = data;
    console.log("create store: products:", products);
    products.forEach((p) => {
      let serviceAreas = p.serviceAreas;
      let out = [];
      serviceAreas.forEach((serviceArea) => {
        let state = serviceArea.state ? serviceArea.state.value : null;
        let district = serviceArea.district ? serviceArea.district.value : null;
        let zone = serviceArea.zone ? serviceArea.zone.value : null;
        let localities = serviceArea.localities || [];
        out.push({
          state,
          district,
          zone,
          localities: localities.map((l) => l.value),
        });
      });

      p.serviceAreas = out;
      p.skuId = p.sku._id;
      delete p.sku;
    });

    StoreService.addStocksToStore(this.props.storeId, products).then(
      (response) => {
        this.props.onAddStocks();
        if (this.props.view === "s") {
          this.props.doRefresh();
        } else {
          history.push("/dashboard/stores/ps/" + this.props.storeId + "/s");
        }
      }
    );
  };

  createVirtualStore = () => {
    let data = JSON.parse(JSON.stringify(this.state));
    let { products } = data;
    console.log("create store: products:", products);
    products.forEach((p) => {
      p.skuId = p.sku._id;
      delete p.sku;
    });

    StoreService.addStocksToVirtualStore(this.props.storeId, products).then(
      (response) => {
        this.props.onAddStocks();
        history.push("/dashboard/stores/v/" + this.props.storeId + "/s");
      }
    );
  };

  render() {
    let { step, products } = this.state;

    return (
      <div className="my-3">
        {this.props.user !== "admin" && (
          <div className="text-center mb-2" style={{ fontSize: 16 }}>
            <i
              className="fa fa-info-circle text-info"
              style={{ marginRight: 5 }}
            />
            Add stocks to your store by selecting products in this page and
            providing service area and price details in the next page.
          </div>
        )}

        {step === 1 && (
          <ShopWrapper
            ref={(r) => {
              if (r) {
                this.shopRef = r;
              }
            }}
            user={this.props.user}
            goToNext={this.onNextStep}
            store={this.props.store}
          />
        )}
        {step === 2 && (
          <NewStoreProductsInfo
            products={products}
            type={this.props.type}
            user={this.props.user}
            ref={(r) => {
              if (r) {
                this.productsMetaRef = r;
              }
            }}
          />
        )}

        <div className="my-3 d-flex justify-content-between">
          {step > 1 && step <= 2 && (
            <button className="btn btn-secondary" onClick={this.onPrevStep}>
              Back
            </button>
          )}

          {step === 2 && (
            <button className="btn btn-primary" onClick={this.onNextStep}>
              Submit
            </button>
          )}
        </div>
      </div>
    );
  }
}

const actions = {
  toastMessage: Actions.toastMessage,
};

export default connect(null, actions)(StoreStocksWizard);
