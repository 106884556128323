import React from "react";
import StoreService from "../../../../services/StoreService";
import Actions from "./../../../../state/actions";
import { connect } from "react-redux";

class StoreBannerImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = { bannerImages: props.store.bannerImages || [] };
  }

  onChangeImage = (e) => {
    let bannerImagesLength = +this.state.bannerImages.length;
    if (Object.keys(e.target.files).length > 3 - bannerImagesLength) {
      this.props.toastMessage(
        "Maximum " + (3 - bannerImagesLength) + " image(s) can be added",
        "error",
        5
      );
    } else {
      this.setState({
        imgCollection: e.target.files,
      });
    }
  };

  removePic = (imageName) => {
    let { bannerImages = [] } = this.state;
    let storeId = this.props.store._id;
    StoreService.removeBannerImage(storeId, imageName).then((response) => {
      if (response.success) {
        let index = bannerImages.indexOf(imageName);
        bannerImages.splice(index, 1);
        this.props.toastMessage("Image deleted", "success", 5);
        this.setState({ bannerImages });
      }
    });
  };

  submit = () => {
    if (this.state.imgCollection) {
      let storeId = this.props.store._id;
      let { imgCollection } = this.state;
      let formData = new FormData();
      let keys = Object.keys(imgCollection);
      keys.forEach((key) => {
        formData.append("imgCollection", imgCollection[key]);
      });

      StoreService.uploadBannerImages(formData, storeId).then((response) => {
        if (response.success) {
          this.props.toastMessage("Image(s) added successfully", "success", 5);
          this.setState({ bannerImages: response.bannerImages });
        }
      });
    } else {
      this.props.toastMessage("Please add images", "error", 5);
    }
  };

  render() {
    let { bannerImages = [] } = this.state;
    let bannerImagesLength = +bannerImages.length;
    return (
      <div>
        {bannerImages && bannerImages.length >= 1 && (
          <div>
            {bannerImages.map((img) => (
              <div className="d-flex align-items-center justify-content-center">
                <div
                  className="my-2"
                  style={{
                    border: "1px solid #3c8dbc",
                    padding: 3,
                    borderRadius: 5,
                  }}
                >
                  <img
                    src={"./banner/" + img}
                    className="img-fluid img-center img-primary"
                    style={{ height: "150px", width: 300 }}
                    alt="banner"
                  />
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  className="ml-1"
                  onClick={() => this.removePic(img)}
                >
                  <span className="fa fa-trash text-danger"></span>
                </div>
              </div>
            ))}
          </div>
        )}
        {bannerImages && bannerImages.length < 3 && (
          <div
            style={{ height: "70vh" }}
            className="d-flex align-items-center justify-content-center mt-3"
          >
            <div className="form-group">
              <div>
                <h4>Banner Image(s)</h4>
                <p>
                  Upload max {3 - bannerImagesLength} image(s) (Recommended
                  resolution 1600 x 900)
                </p>
              </div>
              <div>
                <input
                  type="file"
                  name="myfile"
                  accept=".png, .jpg, .jpeg"
                  onChange={this.onChangeImage}
                  multiple
                />
              </div>
              <button
                className="btn btn-sm btn-primary mt-1"
                onClick={this.submit}
              >
                Upload
              </button>
            </div>
          </div>
        )}
      </div>
    );
    // if (bannerImages && bannerImages.length >= 1) {
    //   return (
    //     <div>
    //       {bannerImages.map((img) => (
    //         <div className="d-flex align-items-center justify-content-center">
    //           <div
    //             className="my-2"
    //             style={{
    //               border: "1px solid #3c8dbc",
    //               padding: 3,
    //               borderRadius: 5,
    //             }}
    //           >
    //             <img
    //               src={"./banner/" + img}
    //               className="img-fluid img-center img-primary"
    //               style={{ height: "150px", width: 300 }}
    //               alt="banner"
    //             />
    //           </div>
    //           <div
    //             style={{ cursor: "pointer" }}
    //             className="ml-1"
    //             onClick={() => this.removePic(img)}
    //           >
    //             <span className="fa fa-trash text-danger"></span>
    //           </div>
    //         </div>
    //       ))}
    //       {bannerImages.length >= 1 && bannerImages.length < 3 && (
    //         <div className="d-flex align-items-center justify-content-center mt-3">
    //           <div className="form-group">
    //             <div>
    //               <h4>Banner Image(s)</h4>
    //               <p>
    //                 Upload max {3 - bannerImagesLength} image(s) (Recommended
    //                 resolution 1600 x 900)
    //               </p>
    //             </div>
    //             <div>
    //               <input
    //                 type="file"
    //                 name="myfile"
    //                 accept=".png, .jpg, .jpeg"
    //                 onChange={this.onChangeImage}
    //                 multiple
    //               />
    //             </div>
    //             <button
    //               className="btn btn-sm btn-primary mt-1"
    //               onClick={this.submit}
    //             >
    //               Upload
    //             </button>
    //           </div>
    //         </div>
    //       )}
    //     </div>
    //   );
    // } else {
    //   return (
    //     <div
    //       style={{ height: "70vh" }}
    //       className="d-flex align-items-center justify-content-center"
    //     >
    //       <div className="col-12">
    //         <div className="form-group">
    //           <div>
    //             <h4>Banner Image(s)</h4>
    //             <p>
    //               Upload max {3 - bannerImagesLength} image(s) (Recommended
    //               resolution 1600 x 900)
    //             </p>
    //           </div>
    //           <div>
    //             <input
    //               type="file"
    //               name="myfile"
    //               accept=".png, .jpg, .jpeg"
    //               onChange={this.onChangeImage}
    //               multiple
    //             />
    //           </div>
    //           <button
    //             className="btn btn-sm btn-primary mt-1"
    //             onClick={this.submit}
    //           >
    //             Upload
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }
  }
}

const actions = {
  toastMessage: Actions.toastMessage,
};

export default connect(null, actions)(StoreBannerImages);
