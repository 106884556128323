import React from "react";

export default ({ from, selectAllLeads, allChecked }) => (
  <thead>
    <tr>
      {from === "adminUL" ? (
        <th>
          <input
            type="checkbox"
            id="checkBox"
            checked={allChecked || false}
            onChange={(e) => {
              selectAllLeads(e.target.checked);
            }}
          />
        </th>
      ) : null}
      <th>Lead source</th>
      <th>Follow-up Type</th>
      <th>Status</th>
      <th>Name</th>
      <th>Phone</th>
      <th>Email</th>
      <th>Created on</th>
      <th>Updated on</th>
      <th>Assignee</th>
      <th>Next Call Date</th>
      <th>Call Log</th>
      <th>Details</th>
    </tr>
  </thead>
);

{
  /*<th>Status</th>*/
}
{
  /*<th>Comment</th>*/
}
{
  /*<th>Created at</th>*/
}
{
  /*<th>Location</th>*/
}
