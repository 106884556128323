import React from "react";
import ProjectService from "../../../../services/ProjectService";
import history from "../../../../utils/History";
import NewProjectMataData from "./NewProjectMataData";

class NewProjectContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  createProject = projectInfo => {
    ProjectService.createProject(projectInfo)
      .then(response => {
        history.push(`/dashboard/projects/${response._id}/r`);
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    return <NewProjectMataData submit={this.createProject} />;
  }
}

export default NewProjectContainer;
