import React from "react";
import MapComponent from "../../Map";
import ValidationUtils from "../../../../utils/ValidationUtils";
import Error from "../../../../widgets/alerts/Error";
import history from "../../../../utils/History";

class NewStoreMataData extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeName = e => {
    this.setState({ name: e.target.value });
  };

  onChangePhone = e => {
    this.setState({ phone: e.target.value });
  };

  onChangeAddress = e => {
    this.setState({ address: e.target.value });
  };

  cancelForm = () => {
    history.push("/dashboard/stores/");
  };

  submitForm = e => {
    e.preventDefault();
    var errorMessage = ValidationUtils.validateNewStoreForm(this.state);
    if (errorMessage) {
      this.setState({ error: errorMessage });
      return;
    }
    let data = JSON.parse(JSON.stringify(this.state));
    let location = this.mapRef.getLocationDetails();
    data.position = {
      latitude: location.lat,
      longitude: location.lng
    };
    this.props.submit(data);
  };

  render() {
    let { name, phone, address } = this.state;

    return (
      <div className="row d-flex justify-content-center">
        <div className="col-md-9">
          <div
            className="card form-card form-card--style-2"
            style={{
              border: "1px solid #f1f1f1",
              padding: "15px"
            }}
          >
            <div className="form-body">
              <form className="form-default" onSubmit={this.submitForm}>
                <h4 className="text-center">New Store</h4>
                <Error message={this.state.error} style={{ margin: "10px" }} />
                <div className="col-12">
                  <div className="form-group">
                    <label>Store Name</label>
                    <input
                      className="form-control form-control-lg"
                      value={name}
                      onChange={this.onChangeName}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <label>Store Phone</label>
                    <input
                      className="form-control form-control-lg"
                      value={phone}
                      onChange={this.onChangePhone}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <label>Store Address</label>
                    <input
                      className="form-control form-control-lg"
                      value={address}
                      onChange={this.onChangeAddress}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <label>Store Location In Map</label>

                    <div>
                      <MapComponent
                        ref={r => {
                          this.mapRef = r;
                        }}
                      />{" "}
                    </div>
                  </div>
                </div>

                <div className="my-3 d-flex justify-content-between">
                  <button
                    className="btn btn-secondary"
                    onClick={this.cancelForm}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewStoreMataData;
