import React from "react";
import { Checkbox } from "../../Shop/Checkbox";
import ProductTitle from "../../Shop/ProductTitle";
import ProductImage from "../../Shop/ProductImage";
import ProductAttributes from "../../Shop/ProductAttributes";
import ModalN from "../../../../widgets/modal/ModalN";
import ServiceLocationsView from "./ServiceLocationsView";
import MarkUpPriceForm from "./virtualstore/MarkUpPriceForm";
import StoreService from "../../../../services/StoreService";
import Actions from "./../../../../state/actions";
import { connect } from "react-redux";
import QuantityAndPricingView from "./QuantityAndPricingView";
import BestStockModal from "../../skus/BestStockModal";

class StoreStockView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { hover: false, storeId: props.stock.storeId };
  }

  handleMouseIn = () => {
    this.setState({ hover: true });
  };

  handleMouseOut = () => {
    this.setState({ hover: false });
  };

  updateStockPrice = (stockId, price, qtyIndex) => {
    // let { quantities } = this.state;
    let { quantities } = this.props.stock;
    let qty = quantities[qtyIndex];
    qty.price = { ...qty.price, value: price };
    quantities[qtyIndex] = { ...qty };
    this.setState({ quantities });
    this.props.updateStockPrice(stockId, price, qtyIndex);
  };

  updateStockMarkUpPrice = (stockId, price) => {
    let { ownStockMarkUp = false } = this.state;
    this.setState({ markUpPrice: price });
    this.props
      .updateStockMarkUpPrice(stockId, price, ownStockMarkUp)
      .then((response) => {
        this.hideEditMarkUpPrice();
      });
  };

  cancelPriceEdit = () => {
    this.setState({ priceEditIndex: -1 });
  };

  showServiceLocations = (serviceAreas) => {
    this.setState({ showServiceLocations: true, serviceAreas });
  };

  hideServiceLocations = (refresh) => {
    this.setState({ showServiceLocations: false, serviceAreas: [] });
    if (refresh) {
      this.props.refreshStocks();
    }
  };

  // showQtyAndPricing = (stockId, skuId, storeId, quantities) => {
  showQtyAndPricing = (stock) => {
    this.setState({
      showQtyAndPricing: true,
      showQtyAndPricingStock: stock,
    });
  };

  hideQtyAndPricing = (refresh) => {
    this.setState({
      showQtyAndPricing: false,
      showQtyAndPricingStock: null,
    });
    if (refresh) {
      this.props.refreshStocks();
    }
  };

  editMarkUpPrice = (markUpPrice, ownStockMarkUp) => {
    this.setState({ editMarkUpPrice: true, markUpPrice, ownStockMarkUp });
  };

  hideEditMarkUpPrice = () => {
    this.setState({ editMarkUpPrice: false, ownStockMarkUp: false });
  };

  openBestStockModal = (skuId) => {
    this.setState({ showBestStockModal: true, skuId });
  };

  showBestStockModal = () => {
    this.setState({ showBestStockModal: false, skuId: null });
  };

  updateQuantityStatus = (stock, index) => {
    let { _id: stockId, skuId, storeId } = stock;
    StoreService.updateQuantityStatus(stockId, skuId, storeId, index).then(
      (response) => {
        if (response) {
          this.props.toastMessage(
            "Stock status has been updated",
            "success",
            5
          );
        }
      }
    );
  };

  render() {
    let { stock, index, type } = this.props;
    let { ownStockMarkUp, storeId } = this.state;
    let {
      sku,
      _id,
      markUpPrice = null,
      isOwnStock,
      ownStockMarkUpPrice = null,
    } = stock;
    let { brand, subtype, attributes, descriptionAttributes, notes } = sku;
    let attributesMap = {};
    let serviceAreas = stock.serviceAreas;
    attributes.forEach((item) => {
      if (!attributesMap[item.key]) {
        attributesMap[item.key] = item.value;
      }
    });
    return (
      <div className="col-md-4">
        <div
          className="card card-product z-depth-1-top z-depth-2--hover"
          style={{ margin: "0.4rem", height: "390px", overflow: "auto" }}
        >
          <div className="card-body" style={{ padding: "1rem" }}>
            {type === "v" && isOwnStock && (
              <span className="block-ribbon block-ribbon-fixed block-ribbon-left bg-green">
                Own stock
              </span>
            )}
            <div className="d-flex justify-content-end">
              <div style={{ marginRight: 5 }}>Delete</div>
              <Checkbox
                name={index}
                checked={this.props.checkedForDeletion}
                onChange={() => this.props.onSelectForDelete(_id, sku._id)}
              />
            </div>

            <ProductTitle
              brand={brand}
              subtype={subtype}
              descriptionAttributes={descriptionAttributes}
              attributes={attributesMap}
            />

            <ProductImage images={sku.images} subtype={subtype} />

            <div className="mt-2">
              <ProductAttributes notes={notes} attributes={sku.attributes} />

              <div className="d-flex justify-content-between">
                {type !== "v" && (
                  <div
                    className="btn-link mr-2"
                    onClick={() => {
                      this.showQtyAndPricing(stock);
                    }}
                    style={{ maxWidth: "max-content", cursor: "pointer" }}
                  >
                    Quantity and Pricing
                  </div>
                )}

                {type === "v" && !this.state.editMarkUpPrice && (
                  <div>
                    <span>{"Markup price : "}</span>
                    <span
                      className="btn-link mr-2"
                      onClick={() => {
                        this.editMarkUpPrice(markUpPrice);
                      }}
                      style={{ maxWidth: "max-content", cursor: "pointer" }}
                    >
                      {markUpPrice ? "₹ " + markUpPrice : "Add"}
                    </span>
                  </div>
                )}

                {type === "v" && !isOwnStock && (
                  <div onClick={() => this.openBestStockModal(sku._id)}>
                    <span className="btn-link mr-2">{"Best stock"}</span>
                  </div>
                )}

                {type === "v" && isOwnStock && (
                  <div>
                    <span>{"Own store Markup price : "}</span>
                    <span
                      className="btn-link mr-2"
                      onClick={() => {
                        this.editMarkUpPrice(ownStockMarkUpPrice, true);
                      }}
                      style={{ maxWidth: "max-content", cursor: "pointer" }}
                    >
                      {ownStockMarkUpPrice ? "₹ " + ownStockMarkUpPrice : "Add"}
                    </span>
                  </div>
                )}

                {type !== "v" && (
                  <div
                    className="btn-link"
                    onClick={() => {
                      this.showServiceLocations(serviceAreas);
                    }}
                    style={{ maxWidth: "max-content", cursor: "pointer" }}
                  >
                    Service Locations
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.state.showServiceLocations && (
          <ModalN
            title="Service Locations"
            show={this.state.showServiceLocations}
            close={() => {
              this.hideServiceLocations(false);
            }}
          >
            <ServiceLocationsView
              stock={this.props.stock}
              hideServiceLocations={this.hideServiceLocations}
              from="stock"
            />
          </ModalN>
        )}
        {this.state.showQtyAndPricing && (
          <ModalN
            title="Quantity and Pricing"
            show={this.state.showQtyAndPricing}
            close={() => {
              this.hideQtyAndPricing(false);
            }}
          >
            <QuantityAndPricingView
              stock={this.state.showQtyAndPricingStock}
              updateQuantityStatus={this.updateQuantityStatus}
              updateStockPrice={this.updateStockPrice}
              hideQtyAndPricing={this.hideQtyAndPricing}
            />
          </ModalN>
        )}

        {this.state.editMarkUpPrice && (
          <ModalN
            title="Mark-up price"
            show={this.state.editMarkUpPrice}
            close={this.hideEditMarkUpPrice}
          >
            <MarkUpPriceForm
              stock={JSON.parse(JSON.stringify(stock))}
              updateStockMarkUpPrice={this.updateStockMarkUpPrice}
              cancelMarkUpPriceEdit={this.hideEditMarkUpPrice}
              markUpPrice={ownStockMarkUp ? ownStockMarkUpPrice : markUpPrice}
              index={index}
            />
          </ModalN>
        )}

        {this.state.showBestStockModal && (
          <ModalN
            title="Get best stock"
            show={this.state.showBestStockModal}
            close={this.showBestStockModal}
          >
            <BestStockModal
              from="seller"
              storeId={storeId}
              skuId={this.state.skuId}
            />
          </ModalN>
        )}
      </div>
    );
  }
}

const actions = {
  toastMessage: Actions.toastMessage,
};

export default connect(null, actions)(StoreStockView);
