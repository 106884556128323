import { render } from "@testing-library/react";
import React from "react";
import AdminService from "../../../services/AdminService";
import ModalN from "../../../widgets/modal/ModalN";
import ServiceLocationsView from "../Seller/Store/ServiceLocationsView";
import ServiceLocationsView2 from "../Seller/Store/ServiceLocationsView2";
import ProductAttributes from "./../../rb/Shop/ProductAttributes";
import ProductImage from "./../../rb/Shop/ProductImage";
import ProductTitle from "./../../rb/Shop/ProductTitle";
import VirtualNonServiceablesView from "./VirtualNonServiceablesView";

class AdminNonServiceableView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { nonServiceableReqs: [] };
  }

  componentDidMount() {
    AdminService.getNonServiceableReqs().then((data) => {
      this.setState({
        nonServiceableReqs: [...data.output],
        virtualNonServiceables: [...data.virtualNonServiceables],
      });
    });
  }

  render() {
    let { virtualNonServiceables, nonServiceableReqs } = this.state;
    return (
      <div>
        <div>
          {nonServiceableReqs &&
            nonServiceableReqs.length >= 1 &&
            nonServiceableReqs.map((req) => {
              let sku = req.sku;
              let { attributes = [], descriptionAttributes, notes } = sku;
              let attributesMap = {};
              attributes.forEach((item) => {
                if (!attributesMap[item.key]) {
                  attributesMap[item.key] = item.value;
                }
              });
              return (
                <div className="col-lg-4  mb-2">
                  <div className="card card-product z-depth-1-top z-depth-2--hover">
                    <div className="card-body" style={{ padding: "1rem" }}>
                      <span
                        className="badge text-uppercase"
                        style={{
                          position: "absolute",
                          top: "-10px",
                          right: "0px",
                          backgroundColor: "#c89034",
                          fontWeight: 400,
                        }}
                      >
                        Unavailable
                      </span>

                      <ProductTitle
                        brand={sku.brand}
                        subtype={sku.subtype}
                        descriptionAttributes={descriptionAttributes}
                        attributes={attributesMap}
                      />

                      <ProductImage images={sku.images} subtype={sku.subtype} />

                      <div className="mt-2">
                        <ProductAttributes
                          notes={notes}
                          attributes={attributes}
                        />
                      </div>

                      <div
                        className="mt-1"
                        style={{ textTransform: "uppercase" }}
                      >
                        {req.locality + ", " + req.zone + ", " + req.district}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          {(!nonServiceableReqs || nonServiceableReqs.length == 0) && (
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="text-center">
                    <div className="d-block font-size-3 font-size-md-6 c-gray-light">
                      <i className="fa fa-shopping-bag" aria-hidden="true" />
                    </div>
                    <h5>NO NON-SERVICEABLE REQUIREMENTS</h5>
                    <p className="mt-5 px-5">
                      No non-serviceable requirements has been created
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          {virtualNonServiceables && virtualNonServiceables.length > 0 && (
            <VirtualNonServiceablesView
              virtualNonServiceables={virtualNonServiceables}
            />
          )}
        </div>
      </div>
    );
  }
}
export default AdminNonServiceableView;
