import React from "react";
import AdminService from "../../../../services/AdminService";
import ModalN from "../../../../widgets/modal/ModalN";
import Actions from "./../../../../state/actions";
import { connect } from "react-redux";
import AdminLeadDisplay from "./AdminLeadDisplay";
import AdminLeadsHeader from "./AdminLeadsHeader";
import InfiniteScroller from "../../../../widgets/InfiniteScroll/InfiniteScroller";
import { LEADS_PAGE_SIZE } from "../../Constants";
import LeadsFilter from "../employees/LeadsFilter";

const followUpTypeOptions = [
  { value: "customer", label: "Customer" },
  { value: "seller", label: "Seller" },
  { value: "collection", label: "Collection" },
  { value: "store creation", label: "Store Creation" },
];

class AdminUnAttendedLeads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leads: [],
      count: 0,
      currentPage: 1,
      selectedLeads: [],
      employeeOptions: props.employeeOptions,
    };
  }

  loadLeads = () => {
    let { currentPage, leads, filters = {} } = this.state;
    filters = JSON.parse(JSON.stringify(filters));
    filters.page = currentPage;
    filters.pageSize = LEADS_PAGE_SIZE;
    this.setState({ loadingLeads: true });
    AdminService.getAdminUnAttendedLeads(filters)
      .then((response = []) => {
        if (currentPage === 1) {
          this.setState({
            leads: leads.concat(response.leads),
            count: response.count,
            currentPage: currentPage + 1,
            loadingLeads: false,
          });
        } else {
          this.setState({
            leads: leads.concat(response.leads),
            currentPage: currentPage + 1,
            loadingLeads: false,
          });
        }
      })
      .catch((e) => this.setState({ loadingLeads: false }));
  };

  setFilters = (filters) => {
    this.setState(
      { leads: [], filters: { ...filters }, count: 0, currentPage: 1 },
      this.loadLeads
    );
  };

  modifyDate = (date, from) => {
    let now = new Date(date);
    let time;
    date = date.substr(0, date.indexOf("T"));
    date = date.split("-").reverse().join("-");
    if (from) {
      time = now.toLocaleTimeString();
      return date + "/" + time;
    }
    return date;
  };

  showUpdateNextCallDate = (when) => {
    if (this.state.selectedLeads && this.state.selectedLeads.length > 0) {
      this.setState({ showUpdateNextCallDateModal: true, when });
    } else {
      this.props.toastMessage("Please select leads to update", "error", 3);
    }
  };

  hideUpdateNextCallDate = () => {
    this.setState({ showUpdateNextCallDateModal: false });
  };

  selectAllLeads = (checked) => {
    let { leads, selectedLeads, allChecked } = this.state;
    if (checked) {
      leads.map((lead) => {
        selectedLeads.push(lead._id);
        lead["isChecked"] = checked;
        allChecked = checked;
      });
    } else {
      selectedLeads = [];
      allChecked = checked;
      leads.map((lead) => {
        lead["isChecked"] = checked;
      });
    }
    this.setState({
      selectedLeads,
      leads,
      allChecked,
    });
  };

  addLeadsToUpdate = (leadId, checked) => {
    let { selectedLeads, leads } = this.state;
    if (checked) {
      selectedLeads.push(leadId);
      leads.map((lead) => {
        if (lead._id === leadId) {
          lead.isChecked = checked;
        }
      });
    } else {
      selectedLeads.map((lead, index) => {
        if (lead === leadId) {
          selectedLeads.splice(index, 1);
        }
      });

      leads.map((lead) => {
        if (lead._id === leadId) {
          lead.isChecked = checked;
        }
      });
    }
    this.setState({
      selectedLeads,
      leads,
    });
  };

  updateNextCallDate = () => {
    let { when, selectedLeads } = this.state;
    AdminService.updateNextCallDate(when, selectedLeads).then((response) => {
      if (response) {
        this.props.toastMessage("Next call date updated.", "success", 3);
        this.hideUpdateNextCallDate();
        this.setFilters();
      }
    });
  };

  render() {
    let {
      leads,
      showUpdateNextCallDateModal,
      currentPage,
      count,
      loadingLeads,
    } = this.state || {};
    let {
      businessTypeOptions = [],
      leadSourceOptions = [],
      statusOptions = [],
    } = this.props;
    let totalPages = Math.ceil(count / LEADS_PAGE_SIZE);
    let loadMore = currentPage - 1 < totalPages;

    return (
      <div className="row">
        <div className="col-md-12 border border-dark pb-3">
          <LeadsFilter
            followUpTypeOptions={followUpTypeOptions}
            setFilters={this.setFilters}
            fromComponent="adminUL"
            employeeOptions={this.state.employeeOptions}
            businessTypeOptions={businessTypeOptions}
            leadSourceOptions={leadSourceOptions}
            statusOptions={statusOptions}
          />
        </div>
        {leads && leads.length >= 1 ? (
          <div>
            <h5 style={{ textAlignLast: "center" }}>
              {"Viewing" + " " + leads.length + " of " + count}
            </h5>
            <div className="d-flex" style={{ justifyContent: "flex-end" }}>
              <span>{"Update next call date to"}</span>
              &nbsp;
              <span
                className="btn-link"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.showUpdateNextCallDate("today");
                }}
              >
                today
              </span>
              &nbsp;
              <span>{"/"}</span>
              &nbsp;
              <span
                style={{ cursor: "pointer" }}
                className="btn-link"
                onClick={() => {
                  this.showUpdateNextCallDate("tomorrow");
                }}
              >
                tomorrow
              </span>
            </div>
            <hr />
            <div className="table-responsive">
              <InfiniteScroller
                loadMore={() => {
                  if (!loadingLeads && loadMore) this.loadLeads();
                }}
              >
                <table className="table table-cart">
                  <AdminLeadsHeader
                    from="adminUL"
                    selectAllLeads={this.selectAllLeads}
                    allChecked={this.state.allChecked}
                  />
                  <tbody>
                    {leads.map((lead, index) => {
                      return (
                        <AdminLeadDisplay
                          lead={lead}
                          loadLeads={this.loadLeads}
                          from="adminUL"
                          index={index}
                          addLeadsToUpdate={this.addLeadsToUpdate}
                          selectedLeads={this.state.selectedLeads}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </InfiniteScroller>
            </div>
            <ModalN
              title="Update next call date"
              show={showUpdateNextCallDateModal}
              close={this.hideUpdateNextCallDate}
            >
              <div>
                <div className="my-2">
                  Are you sure you want to update the next call date?
                </div>
                <div className="d-flex justify-content-between">
                  <button
                    className="btn btn-secondary mr-2"
                    onClick={this.hideUpdateNextCallDate}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={this.updateNextCallDate}
                  >
                    Update
                  </button>
                </div>
              </div>
            </ModalN>
          </div>
        ) : (
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="text-center">
                <p className="mt-5 px-5">NO UN-ATTENDED LEADS</p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const actions = {
  toastMessage: Actions.toastMessage,
};

export default connect(null, actions)(AdminUnAttendedLeads);
