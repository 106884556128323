/**
 * @flow
 */

"use strict";
import ajax from "./../../services/Ajax";

function requestRequests() {
  return {
    type: "REQUEST_REQUESTS"
  };
}

function receiveRequests(requests) {
  return {
    type: "RECEIVE_REQUESTS",
    data: { requestItems: requests },
    isFetching: false
  };
}

function shouldFetchRequests(state) {
  const requests = state.requests;
  if (!requests) {
    return true;
  } else if (requests.isFetching) {
    return false;
  } else {
    return true;
  }
}

function fetchRequests() {
  return (dispatch, getState) => {
    if (shouldFetchRequests(getState())) {
      dispatch(requestRequests());
      ajax.get("/bid-requests").then(json => {
        if (json.success) {
          dispatch(receiveRequests(json.data));
        } else {
          dispatch(receiveRequests([]));
        }
      });
    }
  };
}

function fetchBidRequest(requestId) {
  return dispatch => {
    ajax.get("/bid-requests/" + requestId).then(json => {
      if (json.success) {
        dispatch(receiveRequest(json.data));
      }
    });
  };
}

function receiveRequest(request) {
  return {
    type: "RECEIVE_REQUEST",
    data: { request: request },
    isFetching: false
  };
}

function confirmBid(requestId, bidId, error, success) {
  return (dispatch, getState) => {
    ajax
      .put("/bid-requests/" + requestId + "/bids/" + bidId + "/confirm")
      .then(json => {
        if (json.success) {
          dispatch({
            type: "DUMMY"
          });
          success();
        } else {
          error(json.message);
        }
      });
  };
}

export { fetchRequests, fetchBidRequest, confirmBid };
