import React from "react";
import ProjectRequirementsWizard from "../Projects/ProjectRequirementsWizard";
import ProjectRequirementsContainer from "./ProjectRequirementsContainer";
import OrdersView from "./OrdersView";
import BidRequestsView from "./BidRequestsView";
import ModalN from "./../../../../widgets/modal/ModalN";
import history from "../../../../utils/History";

class ProjectView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      refresh: false
    };
  }

  showAddRequirements = () => {
    history.push("/dashboard/projects/" + this.props.project._id + "/ar");
  };

  showOrders = () => {
    history.push("/dashboard/projects/" + this.props.project._id + "/o");
  };

  showBidRequests = () => {
    history.push("/dashboard/projects/" + this.props.project._id + "/br");
  };

  showRequirements = () => {
    history.push("/dashboard/projects/" + this.props.project._id + "/r");
  };

  startProjectDelete = () => {
    this.setState({ showDeleteModal: true });
    // this.props.deleteProject();
  };

  hideDeleteModal = () => {
    this.setState({ showDeleteModal: false });
  };

  getView = () => {
    let { project } = this.props;
    let { view = "r" } = this.props;

    if (view === "ar") {
      return (
        <div className="my-3 p-3 border border-light shadow">
          <div className="text-center mb-2" style={{ fontSize: 16 }}>
            Shortlist all products you may need for this project
          </div>
          <ProjectRequirementsWizard projectId={project._id} />
        </div>
      );
    }

    if (view === "br") {
      return (
        <div className="my-3 p-3 border border-light shadow">
          <BidRequestsView projectId={project._id} />
        </div>
      );
    }

    if (view === "o") {
      return (
        <div className="my-3 p-3 border border-light shadow">
          <OrdersView projectId={project._id} />
        </div>
      );
    }

    if (view === "r") {
      return (
        <div>
          <div className="text-center mb-2" style={{ fontSize: 16 }}>
            Place your orders
          </div>
          <ProjectRequirementsContainer
            project={project}
            showAddRequirements={this.showAddRequirements}
          />
        </div>
      );
    }
  };

  render() {
    let { project, loading, view } = this.props;
    let { showDeleteModal } = this.state;

    // if (loading) {
    //   return <Spinner />;
    // }
    if (!project) {
      return null;
    }

    return (
      <div className="mt-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <h3 style={{ marginBottom: 10 }}>{project.name}</h3>
            <small
              style={{ fontSize: "12px", marginLeft: "5px", color: "#7f7f7f" }}
            >
              {project.projectType}
            </small>
          </div>
          <div>
            <span
              className="badge bg-base-1 text-uppercase mb-1"
              style={{ textTransform: "capitalize" }}
            >
              {project.status || "ACTIVE"}
            </span>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div>
            {project.serviceArea.locality +
              ", " +
              project.serviceArea.zone +
              ", " +
              project.serviceArea.district}
          </div>
          <div className="d-flex">
            <a className="btn-link" onClick={this.startProjectDelete}>
              <span className="text-danger ">Delete Project</span>
            </a>
          </div>
        </div>

        <div className="tabs tabs--style-2 tabs--centered" role="tabpanel">
          <ul
            className="nav nav-tabs justify-content-center d-flex"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <a
                className={
                  view === "r"
                    ? "nav-link active text-center text-uppercase tab-link"
                    : "nav-link text-center text-uppercase tab-link"
                }
                onClick={this.showRequirements}
              >
                Place Order
              </a>
            </li>

            <li className="nav-item" role="presentation">
              <a
                role="tab"
                className={
                  view === "ar"
                    ? "nav-link active text-center text-uppercase tab-link"
                    : "nav-link text-center text-uppercase tab-link"
                }
                onClick={this.showAddRequirements}
              >
                Shortlist Products
              </a>
            </li>

            <li className="nav-item" role="presentation">
              <a
                aria-controls="profile"
                role="tab"
                data-toggle="tab"
                className={
                  view === "br"
                    ? "nav-link active text-center text-uppercase tab-link"
                    : "nav-link text-center text-uppercase tab-link"
                }
                onClick={this.showBidRequests}
              >
                View Bid Requests
              </a>
            </li>

            <li className="nav-item" role="presentation">
              <a
                aria-controls="home"
                role="tab"
                data-toggle="tab"
                className={
                  view === "o"
                    ? "nav-link active text-center text-uppercase tab-link"
                    : "nav-link text-center text-uppercase tab-link"
                }
                onClick={this.showOrders}
              >
                View Orders
              </a>
            </li>
          </ul>
        </div>

        <div className="mt-2">{this.getView()}</div>
        <ModalN
          title="Delete Project"
          show={showDeleteModal}
          close={this.hideDeleteModal}
        >
          <div>
            <div className="my-2">
              Are you sure you want to delete this project?
            </div>
            <div className="d-flex justify-content-between">
              <button
                className="btn btn-secondary mr-2"
                onClick={this.hideDeleteModal}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger"
                onClick={this.props.deleteProject}
              >
                Delete
              </button>
            </div>
          </div>
        </ModalN>
      </div>
    );
  }
}
export default ProjectView;
