function showModal(modalType, modalProps, modalSize) {
  return {
    type: "SHOW_MODAL",
    modalType: modalType,
    modalProps: modalProps,
    modalSize: modalSize
  };
}

function hideModal() {
  return { type: "HIDE_MODAL" };
}

export { showModal, hideModal };
