import React, { PureComponent } from "react";
// import { Input, InputGroupAddon, InputGroup, Button } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Actions from "./../state/actions";
import { connect } from "react-redux";

// import Text from "../Text";

class CopyText extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  copyURLToClipboard = e => {
    e.stopPropagation();
    e.preventDefault();
    let { index = 0 } = this.props;
    let copyText = document.getElementById(`text-to-copy-${index}`);
    copyText.select();
    document.execCommand("copy");
    this.setState({ copied: true });

    setTimeout(() => {
      this.setState({ copied: false });
    }, 3000);
  };

  render() {
    let { text, index = 0 } = this.props;

    if (this.state.copied) {
      this.props.toastMessage("Link copied", "success", 5);
    }

    return (
      <div style={{ width: "100%" }}>
        <div className="d-flex justify-content-center">
          <InputGroup className="mb-1 d-flex">
            <input
              readOnly
              value={text}
              style={{
                borderColor: "#e6e6e6",
                borderRadius: 4,
                backgroundColor: "#fff",
                width: "maxContent",
                ...this.props.style
              }}
              className="copy-to-clip"
              id={`text-to-copy-${index}`}
            />
            <InputGroup.Append>
              <Button
                color="secondary"
                className="btn-sm"
                onClick={this.copyURLToClipboard}
              >
                Copy
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </div>
      </div>
    );
  }
}

const actions = {
  toastMessage: Actions.toastMessage
};

export default connect(
  null,
  actions
)(CopyText);
