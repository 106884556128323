import React from "react";
import Select from "react-select";
import AdminService from "../../../services/AdminService";
import ModalN from "../../../widgets/modal/ModalN";
import ServiceLocationsView from "../Seller/Store/ServiceLocationsView";
import RevenueView from "./RevenueView";
import Actions from "./../../../state/actions";
import { connect } from "react-redux";

class VirtualStoresDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  showServiceLocations = (serviceAreas) => {
    this.setState({ showServiceLocations: true, serviceAreas });
  };

  hideServiceLocations = () => {
    this.setState({ showServiceLocations: false, serviceAreas: [] });
  };

  setEditAssignee = (index) => {
    this.setState({ editAssignee: true, editIndex: index });
  };

  onChangeServiceEmployee = (option, storeId) => {
    AdminService.setVirtualStoreAssignee(storeId, option).then((response) => {
      if (response) {
        this.props.toastMessage("FSE updated", "success", 3);
        this.setState({ assignee: option });
      }
    });
  };

  getURL = (storeCode, storeName, short) => {
    storeName = storeName.replace(/ /g, "-");
    return `https://storefronts.in/${storeCode}/${!short ? storeName : null}`;
  };

  getTotalRevenuePerStoreThisMonth = (orders, type) => {
    let storeOrdersThisMonth = this.ordersThisMonth(orders, type);
    if (storeOrdersThisMonth.length >= 1) {
      let totalRevenuePerStore = 0;
      storeOrdersThisMonth.forEach((order) => {
        totalRevenuePerStore += order.totalPrice;
      });
      return parseInt(totalRevenuePerStore);
    }
  };

  ordersThisMonth = (orders, type) => {
    let d = new Date();
    let month = d.getMonth() + 1;
    month = "0" + month;
    month = month.slice(-2);
    let year = d.getFullYear();
    let thisMonthDeliveredOrders = [];
    let thisMonthPendingOrders = [];

    if (type === "delivered") {
      orders.forEach((order) => {
        if (
          order.createdAt.slice(0, 7) == year + "-" + month &&
          order.status === "DELIVERED"
        ) {
          thisMonthDeliveredOrders.push(order);
        }
      });
      return thisMonthDeliveredOrders;
    } else if (type === "pending") {
      orders.forEach((order) => {
        if (
          order.createdAt.slice(0, 7) == year + "-" + month &&
          order.status !== "DELIVERED"
        ) {
          thisMonthPendingOrders.push(order);
        }
      });
      return thisMonthPendingOrders;
    }
  };

  getSpecificOrders = (orders, type) => {
    let specificOrders = [];
    if (type === "delivered") {
      orders.forEach((order) => {
        if (order.status === "DELIVERED") {
          specificOrders.push(order);
        }
      });
    } else if (type === "pending") {
      orders.forEach((order) => {
        if (order.status !== "DELIVERED") {
          specificOrders.push(order);
        }
      });
    }
    return specificOrders;
  };

  showRevenuesPopup = (orders, type, modalName) => {
    this.setState({
      showRevenuesPopup: true,
      orders: this.getSpecificOrders(orders, type),
      modalName,
    });
  };

  hideRevenuesPopup = () => {
    this.setState({ showRevenuesPopup: false, storeId: null, modalName: null });
  };

  getStatusBadgeColor = (status) => {
    if (status === "INACTIVE") {
      return " disabled-store";
    } else if (status === "PUBLISHED") {
      return " published-store";
    } else {
      return " bg-base-1";
    }
  };

  render() {
    let { editAssignee, editIndex, assignee } = this.state;
    let { store, from, employeeOptions } = this.props;
    let { name, serviceAreas, openedAt, hits, index, status } = store;
    return (
      <React.Fragment>
        <tr>
          <td
            style={{ cursor: "pointer" }}
            className={status === "INACTIVE" ? "pointer-event-none" : ""}
            onClick={() => window.open(this.getURL(store.code, store.name))}
          >
            {name}
          </td>
          <td>
            <span
              className={
                "badge text-uppercase" + this.getStatusBadgeColor(status)
              }
            >
              {status}
            </span>
          </td>
          <td>
            <div
              className="btn-link"
              onClick={() => {
                this.showServiceLocations(serviceAreas);
              }}
              style={{ maxWidth: "max-content", cursor: "pointer" }}
            >
              Service Locations
            </div>
          </td>
          <td>
            {openedAt
              ? this.props.modifyDate(openedAt)
              : "Store not opened yet"}
          </td>
          <td>{hits}</td>
          <td>
            <div className="d-flex justify-content-around align-items-center">
              <div className="delivered mx-1">
                {this.getSpecificOrders(store.orders, "delivered").length >=
                1 ? (
                  <React.Fragment>
                    {this.ordersThisMonth(store.orders, "delivered").length >=
                    1 ? (
                      <span
                        className="btn-link"
                        onClick={() => {
                          this.showRevenuesPopup(
                            store.orders,
                            "delivered",
                            "GMV"
                          );
                        }}
                      >
                        {"GMV - ₹ " +
                          this.getTotalRevenuePerStoreThisMonth(
                            store.orders,
                            "delivered"
                          )}
                      </span>
                    ) : (
                      <span
                        className="btn-link"
                        onClick={() => {
                          this.showRevenuesPopup(
                            store.orders,
                            "delivered",
                            "GMV"
                          );
                        }}
                      >
                        No GMV
                      </span>
                    )}
                  </React.Fragment>
                ) : (
                  <span>No GMV</span>
                )}
              </div>
              <div>|</div>
              <div className="pending mx-1">
                {this.getSpecificOrders(store.orders, "pending").length >= 1 ? (
                  <React.Fragment>
                    {this.ordersThisMonth(store.orders, "pending").length >=
                    1 ? (
                      <span
                        className="btn-link"
                        onClick={() => {
                          this.showRevenuesPopup(
                            store.orders,
                            "pending",
                            "Delivery pending"
                          );
                        }}
                      >
                        {"Delivery pending - ₹ " +
                          this.getTotalRevenuePerStoreThisMonth(
                            store.orders,
                            "pending"
                          )}
                      </span>
                    ) : (
                      <span
                        className="btn-link"
                        onClick={() => {
                          this.showRevenuesPopup(
                            store.orders,
                            "pending",
                            "Delivery pending"
                          );
                        }}
                      >
                        No pending revenue
                      </span>
                    )}
                  </React.Fragment>
                ) : (
                  <span>No pending revenue</span>
                )}
              </div>
            </div>
          </td>
          {from !== "employee" && from !== "Investor" && (
            <td
              className={
                status === "INACTIVE"
                  ? "col-md-2 pointer-event-none"
                  : "col-md-2"
              }
            >
              {store.assignee ? (
                editAssignee && editIndex == index ? (
                  <Select
                    className="flex-fill mb-2"
                    placeholder="Select service employee"
                    options={employeeOptions}
                    value={
                      assignee
                        ? assignee
                        : {
                            label: store.assignee.name,
                            value: store.assignee.name,
                          }
                    }
                    onChange={(option) =>
                      this.onChangeServiceEmployee(option, store._id)
                    }
                  />
                ) : (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => this.setEditAssignee(index)}
                  >
                    {store.assignee.name}
                  </span>
                )
              ) : (
                <Select
                  className="flex-fill mb-2"
                  placeholder="Select service employee"
                  options={employeeOptions}
                  value={editIndex === index && assignee ? assignee : null}
                  onChange={(option) => {
                    this.onChangeServiceEmployee(option, store._id);
                  }}
                />
              )}
            </td>
          )}
          {from === "Investor" && (
            <td>
              <span>{store.assignee ? store.assignee.name : "-"}</span>
            </td>
          )}
        </tr>
        {this.state.showServiceLocations && (
          <ModalN
            title="Service Locations"
            show={this.state.showServiceLocations}
            close={this.hideServiceLocations}
          >
            <ServiceLocationsView from={true} storeId={store._id} />
          </ModalN>
        )}
        {this.state.showRevenuesPopup && (
          <ModalN
            title={this.state.modalName + " summary"}
            show={this.state.showRevenuesPopup}
            close={this.hideRevenuesPopup}
          >
            <RevenueView
              orders={this.state.orders}
              storeId={this.state.storeId}
              from={this.state.modalName}
            />
          </ModalN>
        )}
      </React.Fragment>
    );
  }
}

const actions = {
  toastMessage: Actions.toastMessage,
};

export default connect(null, actions, null, { forwardRef: true })(
  VirtualStoresDisplay
);
