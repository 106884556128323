import React from "react";
import AdminService from "../../../../services/AdminService";

class AdminLeadsCount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    AdminService.getAdminAllLeadsCount(this.props.from).then((response) => {
      this.setState({ allCount: response.allCount });
    });
  };

  showEmployeeCount = (e, index) => {
    AdminService.getCountPerEmployee(index).then((response) => {
      this.setState({
        countPerEmployee: response.allCount || {},
        showEmployeeCount: true,
        tableIndex: index,
      });
    });
  };

  hideEmployeeCount = (index) => {
    this.setState({ showEmployeeCount: false, tableIndex: index });
  };

  render() {
    let {
      allCount = {},
      showEmployeeCount,
      countPerEmployee = {},
      tableIndex,
    } = this.state;
    return (
      <div className="table-responsive">
        <table className="table table-cart">
          <thead>
            <tr>
              <th>#</th>
              <th>Collection</th>
              <th>Hot</th>
              <th>Warm</th>
              <th>Cold</th>
              <th>Converted</th>
              <th>Junk</th>
              <th>Not attended</th>
              <th>Project creation recall</th>
              <th>Store creation recall</th>
              <th>Ringing not responding</th>
              <th>Not intrested</th>
              <th>Wrong number</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(allCount).map((key, index) => {
              return (
                <React.Fragment>
                  <tr>
                    <td
                      style={{ fontWeight: "bold" }}
                      className="text-uppercase"
                    >
                      {key}
                      {!showEmployeeCount && (
                        <span>
                          <i
                            onClick={(e) => this.showEmployeeCount(e, index)}
                            className="fa fa-chevron-down ml-1"
                            aria-hidden="true"
                          ></i>
                        </span>
                      )}

                      {showEmployeeCount && tableIndex === index && (
                        <span>
                          <i
                            onClick={(e) => this.hideEmployeeCount(index)}
                            className="fa fa-chevron-up ml-1"
                            aria-hidden="true"
                          ></i>
                        </span>
                      )}
                    </td>
                    <td>
                      <div className=" card card-product z-depth-1-top z-depth-2--hover">
                        <div className="card-body">
                          {allCount[key].collection || "0"}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className=" card card-product z-depth-1-top z-depth-2--hover">
                        <div className="card-body">
                          {allCount[key].hot || "0"}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className=" card card-product z-depth-1-top z-depth-2--hover">
                        <div className="card-body">
                          {allCount[key].warm || "0"}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className=" card card-product z-depth-1-top z-depth-2--hover">
                        <div className="card-body">
                          {allCount[key].cold || "0"}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className=" card card-product z-depth-1-top z-depth-2--hover">
                        <div className="card-body">
                          {allCount[key].converted || "0"}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className=" card card-product z-depth-1-top z-depth-2--hover">
                        <div className="card-body">
                          {allCount[key].junk || "0"}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className=" card card-product z-depth-1-top z-depth-2--hover">
                        <div className="card-body">
                          {allCount[key].unAttended || "0"}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className=" card card-product z-depth-1-top z-depth-2--hover">
                        <div className="card-body">
                          {allCount[key].PCR || "0"}
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className=" card card-product z-depth-1-top z-depth-2--hover">
                        <div className="card-body">
                          {allCount[key].SCR || "0"}
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className=" card card-product z-depth-1-top z-depth-2--hover">
                        <div className="card-body">
                          {allCount[key].RNR || "0"}
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className=" card card-product z-depth-1-top z-depth-2--hover">
                        <div className="card-body">
                          {allCount[key].NI || "0"}
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className=" card card-product z-depth-1-top z-depth-2--hover">
                        <div className="card-body">
                          {allCount[key].WN || "0"}
                        </div>
                      </div>
                    </td>
                  </tr>

                  {showEmployeeCount &&
                    tableIndex === index &&
                    Object.keys(countPerEmployee).map((key, index) => {
                      return (
                        <tr>
                          <td>
                            <div>{key}</div>
                          </td>
                          <td>
                            <div style={{ marginLeft: "25px" }}>
                              {countPerEmployee[key].collection || "0"}
                            </div>
                          </td>
                          <td>
                            <div style={{ marginLeft: "25px" }}>
                              {countPerEmployee[key].hot || "0"}
                            </div>
                          </td>
                          <td>
                            <div style={{ marginLeft: "25px" }}>
                              {countPerEmployee[key].warm || "0"}
                            </div>
                          </td>
                          <td>
                            <div style={{ marginLeft: "25px" }}>
                              {countPerEmployee[key].cold || "0"}
                            </div>
                          </td>
                          <td>
                            <div style={{ marginLeft: "25px" }}>
                              {countPerEmployee[key].converted || "0"}
                            </div>
                          </td>
                          <td>
                            <div style={{ marginLeft: "25px" }}>
                              {countPerEmployee[key].junk || "0"}
                            </div>
                          </td>
                          <td>
                            <div style={{ marginLeft: "25px" }}>
                              {countPerEmployee[key].unAttended || "0"}
                            </div>
                          </td>
                          <td>
                            <div style={{ marginLeft: "25px" }}>
                              {countPerEmployee[key].PCR || "0"}
                            </div>
                          </td>

                          <td>
                            <div style={{ marginLeft: "25px" }}>
                              {countPerEmployee[key].SCR || "0"}
                            </div>
                          </td>

                          <td>
                            <div style={{ marginLeft: "25px" }}>
                              {countPerEmployee[key].RNR || "0"}
                            </div>
                          </td>

                          <td>
                            <div style={{ marginLeft: "25px" }}>
                              {countPerEmployee[key].NI || "0"}
                            </div>
                          </td>

                          <td>
                            <div style={{ marginLeft: "25px" }}>
                              {countPerEmployee[key].WN || "0"}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
export default AdminLeadsCount;
