import React from "react";
import { Link } from "react-router-dom";
import signUpService from "../../services/SignUpService";
import LinkToModal from "../../widgets/modal/LinkToModal";
import ModalN from "../../widgets/modal/ModalN";
import ForgotPassword from "./ForgotPassword";
import ResendEmailConfirmation from "./ResendEmailConfirmation";

class EmailConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    signUpService.confirmEmail(this.props.match.params.key, response => {
      if (response.success) {
        this.setState({ signedUp: "success" });
      } else {
        this.setState({ signedUp: "failed" });
      }
    });
  }

  showResendEmailConfPopup = () => {
    this.setState({ resendEmailConfPopup: true });
  };

  cancelResendEmailConfPopup = () => {
    this.setState({ resendEmailConfPopup: false });
  };

  render() {
    if (this.state.signedUp === "success") {
      return (
        <section className="slice ">
          <div className="wp-section">
            <div className="container">
              <div className="row">
                <div style={{ textAlign: "center", marginTop: "100px" }}>
                  <h5>Email Confirmed!</h5>

                  <div style={{ marginTop: "20px" }}>
                    Thanks for confirming your email. Please{" "}
                    <Link to="/signin" className="btn-link">
                      login
                    </Link>{" "}
                    to continue.
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    } else if (this.state.signedUp === "failed") {
      return (
        <section className="slice ">
          <div className="wp-section">
            <div className="container">
              <div className="row">
                <div style={{ textAlign: "center", marginTop: "100px" }}>
                  <h5>Email Confirmation failed!</h5>

                  <div style={{ marginTop: "20px" }}>
                    Looks like you have followed an incorrect link. Please check
                    your email for recent emails.
                    <br />
                    Click{" "}
                    <a
                      className="btn-link"
                      onClick={this.showResendEmailConfPopup}
                    >
                      here
                    </a>{" "}
                    to resend email confirmation again if required.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ModalN
            show={this.state.resendEmailConfPopup}
            title="Resend Email Confirmation"
            close={this.cancelResendEmailConfPopup}
          >
            <ResendEmailConfirmation
              email=""
              onComplete={this.cancelResendEmailConfPopup}
            />
          </ModalN>
        </section>
      );
    } else {
      return null;
    }
  }
}

export default EmailConfirmation;
