import React from "react";
import StoreStocksWizard from "../Seller/Store/StoreStocksWizard";
import ExcelParser from "./../../rb/BsAdmin/ExcelParser";

class SkusContainer extends React.Component {
  render() {
    return (
      <div>
        <div>
          <ExcelParser />
        </div>
        <hr />
        <div>
          <StoreStocksWizard user={"admin"} />
        </div>
      </div>
    );
  }
}

export default SkusContainer;
