import React from "react";
import { connect } from "react-redux";
import StoreService from "./../../../../../services/StoreService";
import Actions from "./../../../../../state/actions";
import ServiceAreaForm from "../ServiceAreaForm";
import EditSingleLocation from "../EditSingleLocation";
import AdminService from "../../../../../services/AdminService";
const defaultState = "Tamil Nadu";

class EditServiceLocations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.getServiceAreas();
  };

  getServiceAreas = () => {
    StoreService.getVirtualStoreLocations(this.props.store._id).then(
      (response) => {
        this.setState({ serviceAreas: response.serviceAreas.serviceAreas });
      }
    );
  };

  showAddServiceArea = () => {
    this.setState({ showAddServiceArea: true });
  };

  hideAddService = () => {
    this.setState({ showAddServiceArea: false });
  };

  addServiceArea = (serviceArea) => {
    let { serviceAreas = [] } = this.state || [];
    let { _id } = this.props.store;
    serviceArea.state = serviceArea.state.value;
    serviceArea.district = serviceArea.district.value;
    serviceArea.zone = serviceArea.zone.value;
    serviceArea.localities = serviceArea.localities.map((l) => l.value);
    for (let i = 0; i < serviceAreas.length; i++) {
      if (
        JSON.stringify(serviceAreas[i].zone) == JSON.stringify(serviceArea.zone)
      ) {
        this.props.toastMessage(
          "This service area has already been added",
          "error",
          3
        );
        this.getServiceAreas();
        return;
      }
    }
    StoreService.addServiceLocation([serviceArea], _id)
      .then((response) => {
        this.props.toastMessage(
          "This service location has been added",
          "success",
          3
        );
        this.props.refreshStore();
        this.props.hideEditLocation();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  addServiceAreas = (newServiceAreas) => {
    let { _id } = this.props.store;
    newServiceAreas.forEach((sa) => {
      sa.state = sa.state.value;
      sa.district = sa.district.value;
      sa.zone = sa.zone.value;
      sa.localities = sa.localities.map((l) => l.value);
    });

    StoreService.addServiceLocation(newServiceAreas, _id)
      .then((response) => {
        this.props.toastMessage(
          "Service location(s) has been added",
          "success",
          3
        );
        this.props.refreshStore();
        this.props.hideEditLocation();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  removeServiceArea = (saIndex) => {
    let { _id } = this.props.store;
    let { serviceAreas = [] } = this.state;
    let serviceArea = serviceAreas[saIndex];
    StoreService.deleteServiceArea(serviceArea, _id)
      .then((response) => {
        this.props.toastMessage(
          "This service location has been removed",
          "success",
          3
        );
        this.props.refreshStore();
        this.props.hideEditLocation();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  editServiceArea = (saIndex, serviceArea) => {
    this.setState({ showEditServiceArea: true, serviceArea, saIndex });
  };

  editServiceLocation = (serviceArea) => {
    let storeId = this.props.store._id;
    StoreService.updateServiceLocation(serviceArea, storeId)
      .then((response) => {
        this.props.toastMessage(
          "This service area has  been updated",
          "success",
          3
        );
        this.props.refreshStore();
        this.props.hideEditLocation();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  render() {
    let {
      showAddServiceArea,
      showEditServiceArea,
      serviceAreas = [],
      serviceArea,
    } = this.state;

    if (showAddServiceArea) {
      return (
        <ServiceAreaForm
          from={"edit"}
          addServiceArea={this.addServiceArea}
          addServiceAreas={this.addServiceAreas}
          serviceAreas={serviceAreas}
        />
      );
    }
    if (showEditServiceArea) {
      return (
        <EditSingleLocation
          editServiceLocation={this.editServiceLocation}
          serviceArea={serviceArea}
        />
      );
    }
    return (
      <div className="col-12 d-flex flex-column">
        <div
          className="p-2 mb-1"
          style={{
            border: "1px solid #ddd",
            overflowY: "auto",
            height: "calc(100vh - 500px)",
          }}
        >
          <div className="d-flex ">
            <a
              className="btn-link btn-primary"
              onClick={this.showAddServiceArea}
            >
              <i className="fa fa-plus" /> Add
            </a>
          </div>

          <div className="mt-1">
            {serviceAreas && (
              <div>
                {serviceAreas.map((serviceArea, saIndex) => (
                  <div
                    className="p-1 d-flex"
                    style={{
                      border: "1px solid #ddd",
                      marginBottom: "3px",
                    }}
                  >
                    <span>
                      {`${serviceArea.state || defaultState} - ${
                        serviceArea.district
                      } - ${serviceArea.zone} - ${
                        serviceArea.localities.length >= 2
                          ? serviceArea.localities[0] +
                            " , " +
                            serviceArea.localities[1] +
                            "..."
                          : serviceArea.localities.map(
                              (locality) => locality + ","
                            )
                      }`}
                    </span>
                    <div
                      className="p-1 d-flex justify-content-end"
                      style={{ flex: "auto" }}
                    >
                      <span>
                        <a
                          className="btn-link text-danger mr-1"
                          onClick={() => {
                            this.removeServiceArea(saIndex);
                          }}
                        >
                          <i className="fa fa-trash" />
                        </a>
                      </span>
                      <span>
                        <a className="btn-link text-danger mr-1">
                          <i
                            onClick={() => {
                              this.editServiceArea(saIndex, serviceArea);
                            }}
                            className="fa fa-pencil-square-o"
                          />
                        </a>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function actions(dispatch) {
  return {
    toastMessage: (message, type, duration) => {
      dispatch(Actions.toastMessage(message, type, duration));
    },
  };
}

export default connect(null, actions, null, { forwardRef: true })(
  EditServiceLocations
);
