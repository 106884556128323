import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import EmployeeLeadsContainer from "../rb/Leads/employees/EmployeeLeadsContainer";
import AdminBidRequestsView from "../rb/skus/AdminBidRequestsView";
import AdminNonServiceableView from "../rb/skus/AdminNonServiceableView";
import EmployeeVirtualStoresView from "../rb/skus/EmployeeVirtualStoresView";
import AdminOrdersView from "./../rb/skus/AdminOrdersView";

class EmployeeMainContent extends React.Component {
  render() {
    let { smallScreen, leftNav, contentHeight, userType } = this.props;

    if (smallScreen) {
      return (
        <div className="row">
          <div className="col-md-12">
            <div
              className="col-xs-10 col-xs-offset-1 main-content"
              style={{ padding: "2px" }}
            >
              <div
                className="mc"
                style={{ height: contentHeight - 45, overflowY: "auto" }}
                id="main-layout"
              >
                <Switch>
                  <Route
                    path="/dashboard/leads"
                    component={EmployeeLeadsContainer}
                    userType={userType}
                  />
                  <Route
                    path="/dashboard/virtual-stores"
                    component={EmployeeVirtualStoresView}
                    userType={userType}
                  />
                  <Route path="/dashboard/orders" component={AdminOrdersView} />
                  <Route
                    path="/dashboard/bid-requests"
                    component={AdminBidRequestsView}
                  />
                  <Route
                    path="/dashboard/non-serviceable"
                    component={AdminNonServiceableView}
                  />
                  <Redirect from="/" to="/dashboard/leads" />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row">
          <div className="col-md-2">{leftNav}</div>
          <div className="col-md-10">
            <div className="main-content">
              <div
                className="mc"
                style={{
                  height: "calc(100vh - 50px)",
                  overflowY: "auto",
                }}
                id="main-layout"
              >
                <div style={{ padding: "20px" }}>
                  <Switch>
                    <Route
                      path="/dashboard/leads"
                      component={EmployeeLeadsContainer}
                      userType={userType}
                    />
                    <Route
                      path="/dashboard/virtual-stores"
                      component={EmployeeVirtualStoresView}
                      userType={userType}
                    />
                    <Route
                      path="/dashboard/orders"
                      component={AdminOrdersView}
                    />
                    <Route
                      path="/dashboard/bid-requests"
                      component={AdminBidRequestsView}
                    />
                    <Route
                      path="/dashboard/non-serviceable"
                      component={AdminNonServiceableView}
                    />

                    <Redirect from="/" to="/dashboard/leads" />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
export default EmployeeMainContent;
