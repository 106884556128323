import React from "react";

export default function() {
  return (
    <section className="slice bg-white">
      <br />

      <div className="wp-section">
        <div className="container">
          <div className="row">
            <h5>Privacy Policy</h5>

            <p>
              At buildstreet.com, we are committed to safeguarding and
              preserving the privacy of our visitors. We collect information
              about you to process your orders and provide efficient after sales
              services. This Website Privacy Policy explains what happens to any
              personal data that you provide to us, or that we collect from you
              whilst you visit our site.
            </p>

            <b>Information We May Collect</b>

            <p>
              The type of information we will collect about you includes your
              name, address, phone number, email address. We may also collect
              information about your use of our site including details of your
              visits such as pages viewed and the resources that you access.
              Such information includes traffic data, location data, etc.
            </p>

            <b>Use of Your Information</b>

            <p>
              We use the information that we collect from you to provide our
              services to you. In addition to this we may use the information
              for one or more of the following purposes:
              <br />
              1. To provide information to you that you request from us relating
              to our products or services.
              <br />
              2. To provide information to you relating to other products
              including 3rd party products that may be of interest to you. Such
              additional information will only provided where you have consented
              to receive such information. This consent can be withdrawn by you
              at any time.
              <br />
              3. To inform you of any changes to our website, services or goods
              and products.
            </p>

            <b>Use Of Cookies</b>

            <p>
              We use "cookies" to improve your shopping experience and to save
              you time. Cookies are little tags that we place onto your
              computer. We assign a cookie to your computer when you first visit
              us in order to enable us to recognize you each time you return.
              Through cookies we can customize our Website, so as to give you a
              more personalized &amp; convenient browsing experience. Cookies
              also enable us to recall your past activities, post your account
              information, so as to improve your visit experience and save time
              entering repeat information. Such information will not identify
              you personally. It is statistical data. You can adjust the
              settings on your computer/browser to decline any cookies if you
              wish.
            </p>

            <b>Storing Your Personal Data</b>

            <p>
              In operating our website it may become necessary to transfer data
              that we collect from you to locations outside of India for
              processing and storing. By providing your personal data to us, you
              agree to this transfer, storing or processing. We do our utmost to
              ensure that all reasonable steps are taken to ensure that your
              data is stored securely. However, the sending of information via
              the internet is not totally secure and despite best safeguards,
              such information can be intercepted. We cannot guarantee the
              security of data that you choose to send us electronically, though
              we strive to use best processes and mechanisms for securing your
              data.
            </p>

            <b>Disclosing Your Information</b>

            <p>
              We will not disclose your personal information to any other party
              other than in accordance with this Privacy Policy and in the
              circumstances detailed below:
              <br />
              1. In the event that we sell any or all of our business to a new
              buyer.
              <br />
              2. Where we are legally required by law to disclose your personal
              information.
              <br />
              3. To enhance fraud protection and reduce the risk of fraud.
            </p>

            <b>Third Party Links</b>

            <p>
              On occasion we include links to third parties on this website.
              Where we provide a link it does not mean that we endorse or
              approve that site’s policy towards visitor privacy. You should
              review their privacy policy before sending them any personal data.
            </p>

            <b>Website Security</b>

            <p>
              We maintain physical, electronic, and procedural safeguards
              designed to help us protect your non-public personal information.
              All supplied sensitive/credit information is transmitted via
              Secure Socket Layer (SSL) technology and then encrypted into our
              Payment gateway providers database only to be accessible by those
              authorized with special access rights to such systems, and are
              required to keep the information confidential. When you setup an
              account at buildstreet.com, we help protect your account
              information by asking you to choose a password. We recommend you
              to make a strong &amp; unique password. You may change the
              password as often as you wish by going to “My Account” and
              selecting "Change Password".
            </p>

            <b>Contacting Us</b>

            <p>
              Please do not hesitate to contact us regarding any matter relating
              to this Privacy Policy at hello@buildstreet.com We do update this
              Policy from time to time so please do review this Policy
              regularly.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
