"use strict";
import Env from "../../Env";
var io = require("socket.io-client");
var serverURL = Env.serverURL;
var socket;

function requestMessages() {
  return {
    type: "REQUEST_MESSAGES"
  };
}

function receiveMessages(messages) {
  return {
    type: "RECEIVE_MESSAGES",
    data: { messages: messages },
    isFetching: false
  };
}

function receiveMessage(message) {
  return {
    type: "RECEIVE_MESSAGE",
    data: message
  };
}

function addMessage(message) {
  return {
    type: "ADD_MESSAGE",
    data: { message: message }
  };
}

function setUpSocket() {
  return () => {
    socket = io.connect(serverURL, { transports: ["websocket"] });
  };
}

function emitUserInfo(userInfoId) {
  return () => {
    socket.emit("set-user-info", userInfoId);
  };
}

function emitToken(token) {
  return () => {
    socket.emit("set-user-token", token);
  };
}

function emitBsToken(token) {
  return () => {
    socket.emit("set-bs-token", token);
  };
}

function setupAllMessagesReceiver() {
  return (dispatch, getState) => {
    dispatch(requestMessages());
    socket.on("all-messages-user", function(messages) {
      messages = messages || [];
      dispatch(receiveMessages(messages));
    });
  };
}

function setUpMessageReceiver() {
  return dispatch => {
    socket.on("new-message", function(message) {
      dispatch(receiveMessage(message));
    });
  };
}

function processMessage(message) {
  return dispatch => {
    dispatch(addMessage(message));
    socket.emit("user-message", message);
  };
}

function setupNoUserHandler(cb) {
  return dispatch => {
    socket.on("no-user", function() {
      cb();
      dispatch({ type: "DUMMY" });
    });
  };
}

function getFirstWelcomeMessage() {
  var messages = [
    {
      content: "Hi there, Welcome to Buildstreet!",
      type: "T",
      sender: { type: "BS" },
      date: new Date()
    }
  ];
  return {
    type: "RECEIVE_MESSAGES",
    data: { messages: messages },
    isFetching: false
  };
}

function getOtherWelcomeMessages() {
  return dispatch => {
    var message;
    var today = new Date().getHours();
    if (today >= 9 && today <= 19) {
      message = {
        content: "Let us know what you need.",
        type: "T",
        sender: { type: "BS" },
        date: new Date()
      };
    } else {
      message = {
        content:
          "Let us know what you need. We will get back to you as early as we can.",
        type: "T",
        sender: { type: "BS" },
        date: new Date()
      };
    }

    setTimeout(function() {
      dispatch({
        type: "RECEIVE_MESSAGE",
        data: { message: message },
        isFetching: false
      });
    }, 2000);
  };
}

export {
  setUpSocket,
  setupAllMessagesReceiver,
  processMessage,
  setUpMessageReceiver,
  emitUserInfo,
  emitToken,
  emitBsToken,
  setupNoUserHandler,
  getFirstWelcomeMessage,
  getOtherWelcomeMessages
};
