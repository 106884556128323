import React, { Component } from "react";
import Error from "../../widgets/alerts/Error";
import ChatUserInfoForm from "./ChatUserInfoForm";

class UserInfoFormContainer extends Component {
  render() {
    return (
      <div>
        <Error message={this.props.error} style={{ margin: "10px" }} />

        <div className="col-md-10 col-md-offset-1">
          <ChatUserInfoForm onSubmit={this.props.submit} />
        </div>
      </div>
    );
  }
}

export default UserInfoFormContainer;
