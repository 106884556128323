import React from "react";
import { Link } from "react-router-dom";
import "./HomeBanner.css";
import ChatContainer from "./../chat/ChatContainer";
import responsive from "../../utils/Responsive";

export default function() {
  var mr = responsive.smallScreen() ? "10px" : "20px";

  return (
    <section className="bg-custom home" id="home">
      <div className="home-sm">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-7">
              <div className="home-wrapper home-wrapper-alt p-0">
                <h1 className="h1 font-light text-white w-full main-text">
                  Buying Building Materials Made Painless
                </h1>

                <ul
                  className="ls-l text-standard list-check"
                  style={{ marginTop: "20px" }}
                >
                  <li className="step">
                    <i
                      className="fa fa-comment-o"
                      style={{ fontSize: "20px", marginRight: mr }}
                    />
                    Tell us what you need in the chat
                  </li>
                  <li className="step">
                    <i
                      className="fa fa-thumbs-o-up"
                      style={{ fontSize: "20px", marginRight: mr }}
                    />
                    Best quote will reach you in 24 hours
                  </li>
                  <li className="step">
                    <i
                      className="fa fa-smile-o"
                      style={{ fontSize: "20px", marginRight: mr }}
                    />
                    Create order and see it delivered
                  </li>
                </ul>
                <div className="main-btn">
                  <a
                    style={{ margin: "20px 0 50px 0" }}
                    className="btn btn-b-white btn-hb"
                  >
                    Sign Up
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-lg-offset-2">
              <div className="intro-form">
                <h3
                  className="text-center"
                  style={{ fontWeight: 500, marginBottom: "5px" }}
                >
                  Request Quote
                </h3>
                <ChatContainer
                  convoStyle={{
                    padding: "10px",
                    overflowY: "auto",
                    height: "328px",
                    marginBottom: "5px"
                  }}
                />

                <div style={{ textAlign: "center", marginTop: "3px" }}>
                  <Link to="signin" className="btn-link">
                    Signing in
                  </Link>{" "}
                  stores your chat and order history
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
