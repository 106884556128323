import React from 'react'
import {connect} from 'react-redux'
import NotifyPayment from './../../components/orders/NotifyPayment'
import ResendPhoneOTP from './../../components/auth/ResendPhoneOTP'
import ResendEmailConfirmation from './../../components/auth/ResendEmailConfirmation'
import BidRequestOrder from './../../components/orders/BidRequestOrder'
import ChangePassword from './../../components/settings/ChangePassword'
import ForgotPassword from './../../components/auth/ForgotPassword'
import DeliveryAddress from './../../components/orders/DeliveryAddress'
import ConfirmedBid from './../../components/bids/ConfirmedBid'
import BidRequestRo from '../../components/bidrequests/BidRequestRo'
import ChatContainer from '../../components/chat/ChatContainer'

const COMPONENTS = {
    'NOTIFY_PAYMENT': NotifyPayment,
    'RESEND_PHONE_OTP': ResendPhoneOTP,
    'BID_REQUEST_ORDER': BidRequestOrder,
    'CHANGE_PASSWORD': ChangePassword,
    'FORGOT_PASSWORD': ForgotPassword,
    'RESEND_EMAIL_CONFIRMATION': ResendEmailConfirmation,
    'DELIVERY_ADDRESS': DeliveryAddress,
    'BID': ConfirmedBid,
    'BID_REQUEST': BidRequestRo,
    'CHAT_SCREEN': ChatContainer

};

const ModalComponentHandler = ({modalType, modalProps, onCompleteHook}) => {
    if (!modalType) {
        return null
    }

    const SpecificModal = COMPONENTS[modalType];
    return <SpecificModal {...modalProps} onCompleteHook={onCompleteHook}/>
};

export default connect(state => state.modal)(ModalComponentHandler)