import React from "react";
import BidLink from "./BidLink";
import Bid from "./Bid";

class Bids extends React.Component {
  constructor(props) {
    super(props);
    this.state = { bids: [] };
  }

  render() {
    var bids = this.props.bids;
    var selectedBid = this.props.selectedBid;
    var confirmedBid = this.props.confirmedBid;

    var BidComponent = BidLink;
    if (this.props.viewOnly) {
      BidComponent = Bid;
    }

    var BidsNode = bids.map(b => {
      var active = false,
        confirmed = false;
      if (selectedBid && b._id === selectedBid._id) {
        active = true;
      }
      if (confirmedBid && b._id === confirmedBid) {
        confirmed = true;
      }
      return (
        <div className="col-md-12" key={b._id}>
          <BidComponent
            bid={b}
            onBidSelect={this.props.onBidSelect}
            active={active}
            confirmed={confirmed}
            quantity={this.props.quantity}
          />
        </div>
      );
    });

    return (
      <div>
        <p
          className="propsCount text-center"
          style={{ margin: "10px 0 0px 0" }}
        >
          {bids.length} {bids.length === 1 ? " Bid" : " Bid(s)"}
        </p>
        {BidsNode}
      </div>
    );
  }
}

export default Bids;
