import React, { Component } from "react";
import mapboxgl from "mapbox-gl";
// import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

mapboxgl.accessToken =
  "pk.eyJ1IjoicHJhZGVlcGJ1aWxkc3RyZWV0IiwiYSI6ImNrYnFtMXcwbTFybmQzMHF0djdqZ3p2eTQifQ.YKMld5A5Bg0dFHuk1QX_TA";

class MapComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: 0,
      lat: 0,
      zoom: 2,
      place: "Current Locations"
    };
    //this.place = "Current Locations";
  }

  componentDidMount() {
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom
    });

    // this.geocoder = new MapboxGeocoder({
    //   accessToken: mapboxgl.accessToken,
    //   mapboxgl: mapboxgl,
    //   marker: false,
    //   clearOnBlur: true
    // });

    // document
    //   .getElementById("geocoder")
    //   .appendChild(this.geocoder.onAdd(this.map));

    this.geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true
    });

    this.map.addControl(this.geolocate);

    //setting current location marker on map
    this.marker = new mapboxgl.Marker({
      draggable: true
    });

    this.map.addControl(new mapboxgl.NavigationControl());

    this.map.on("move", () => {
      this.setState({
        lng: this.marker.getLngLat()
          ? this.marker.getLngLat().lng.toFixed(4)
          : null,
        lat: this.marker.getLngLat()
          ? this.marker.getLngLat().lat.toFixed(4)
          : null,
        //lng: this.marker.getLngLat().lng.toFixed(4),
        //lat: this.marker.getLngLat().lat.toFixed(4),
        zoom: this.map.getZoom().toFixed(2)
      });
      // this.getLocation();
    });

    this.map.on("load", () => {
      this.geolocate.trigger();
      // this.geocoder.on("result", e => {
      //   //map.getSource('single-point').setData(e.result.geometry);
      //   this.marker.setLngLat(e.result.geometry.coordinates);
      //   // this.getLocation();
      // });

      this.geolocate.on("geolocate", e => {
        const latlng = [e.coords.longitude, e.coords.latitude];
        this.marker.setLngLat([e.coords.longitude, e.coords.latitude]);
        this.marker.addTo(this.map);
        // this.getLocation();
      });
    });
    this.marker.on("dragend", () => {
      //      this.getLocation();

      this.setState({
        lng: this.marker.getLngLat().lng.toFixed(4),
        lat: this.marker.getLngLat().lat.toFixed(4),
        zoom: this.map.getZoom().toFixed(2)
      });
    });
  }

  getLocation = async () => {
    const lngLat = this.marker.getLngLat();
    if (!lngLat) {
      return;
      // return Promise.reject("no value recd");
    }
    const url =
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
      lngLat.lng +
      "%2C%20" +
      lngLat.lat +
      ".json?access_token=" +
      mapboxgl.accessToken;
    //console.log(url);
    var location = "";
    const response = await fetch(url);
    const data = await response.json();

    fetch(url).then(async response => {
      const data = await response.json();

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      // this.setState({ lng: lngLat.lng, lat: lngLat.lat });

      if (data.features.length > 0) {
        let place = {};
        for (var i = 0; i < data.features.length; i++) {
          var feature = data["features"][i];

          if (feature["id"].indexOf("locality") > -1) {
            place["locality"] = feature.text;
            // this.geocoder.setInput(location);
            continue;
          }
          if (feature["id"].indexOf("place") > -1) {
            place["place"] = feature.text;
            continue;
          }

          if (feature["id"].indexOf("region") > -1) {
            place["state"] = feature.text;
            continue;
          }

          if (feature["id"].indexOf("country") > -1) {
            place["country"] = feature.text;
          }
        }
        this.setState({ place: location });
      }
    });
  };

  getLocationDetails = () => {
    let data = JSON.parse(JSON.stringify(this.state));
    return data;
  };

  render() {
    return (
      <div>
        {/* <div className="sidebarStyle">
          <div>
            Longitude: {this.state.lng} | Latitude: {this.state.lat} | Zoom:{" "}
            {this.state.zoom} | place: {this.state.place}
          </div>
        </div> */}
        {/*<div*/}
        {/*  id="geocoder"*/}
        {/*  style={{ width: "100%", borderRadius: "5px", minHeight: "5vh" }}*/}
        {/*/>*/}
        <div ref={el => (this.mapContainer = el)} className="mapContainer" />
      </div>
    );
  }
}
export default MapComponent;
