import http from "../services/Ajax";

class AdminService {
  static getAdminOrdersCount() {
    return http.get("/api/v2/admin/orders-count").then((data) => {
      if (data.success) {
        return data;
      } else {
        throw new Error();
      }
    });
  }

  static getAdminOrders(filters) {
    return http.post("/api/v2/admin/orders", { filters }).then((data) => {
      if (data.success) {
        return data;
      } else {
        throw new Error();
      }
    });
  }

  static searchSku(sku) {
    return http.get("/api/v2/admin/sku-search?sku=" + sku).then((response) => {
      if (response.success) {
        return response;
      } else {
        throw new Error();
      }
    });
  }

  static searchStoreName(storeName) {
    return http
      .get("/api/v2/admin/storename-search?storeName=" + storeName)
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  }

  static getAdminAllUsers(reqFilters) {
    return http.post("/api/v2/admin/users", { reqFilters }).then((data) => {
      if (data.success) {
        return data;
      } else {
        throw new Error();
      }
    });
  }

  static updateUser(userDetails) {
    return http
      .patch("/api/v2/admin/users/update-user", { userDetails })
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  }

  static getAdminBidRequests() {
    return http.get("/api/v2/admin/bid-requests").then((data) => {
      if (data.success) {
        return data.data;
      } else {
        throw new Error();
      }
    });
  }

  static calculatePricing(quantity, skuId, serviceArea, from) {
    return http
      .post("/api/v2/admin/sku/pricing", { quantity, skuId, serviceArea, from })
      .then((response) => {
        return response;
      });
  }

  static getSkuGmv(skuId, serviceArea, month) {
    return http
      .post("/api/v2/admin/sku/get-gmv", { skuId, serviceArea, month })
      .then((response) => {
        return response;
      });
  }

  static getVirtualStoresCount(filters, from) {
    return http
      .post("/api/v2/admin/virtual-stores/count", { filters, from })
      .then((data) => {
        if (data.success) {
          return data.data;
        } else {
          throw new Error();
        }
      });
  }

  static getVirtualStoreStats(filters, from) {
    return http
      .post("/api/v2/admin/virtual-stores/stats", { filters, from })
      .then((data) => {
        if (data.success) {
          return data.data;
        } else {
          throw new Error();
        }
      });
  }

  static getVirtualStores(filters, from) {
    return http
      .post("/api/v2/admin/virtual-stores", { filters, from })
      .then((response) => {
        if (response.success) {
          return response.data.virtualStores;
        } else {
          throw new Error();
        }
      });
  }

  static getVirtualStoreLocations(storeId) {
    return http
      .get("/api/v2/admin/store/" + storeId + "/service-area")
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  }

  static setVirtualStoreAssignee(storeId, employee) {
    return http
      .post("/api/v2/admin/virtual-stores/assignee", { storeId, employee })
      .then((response) => {
        if (response.success) {
          return response.success;
        } else {
          throw new Error();
        }
      });
  }

  static getNonServiceableReqs() {
    return http.get("/api/v2/admin/non-serviceable").then((response) => {
      if (response.success) {
        return response.data;
      } else {
        throw new Error();
      }
    });
  }

  static editImageName(skuId, name, index) {
    return http.patch("/api/v2/skus/", { skuId, name, index });
  }

  static changeMarkUpValue(price, skuId) {
    return http
      .patch("/api/v2/skus/edit-price/", { price, skuId })
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  }

  static createLead(leadInfo) {
    return http
      .post("/api/v2/admin/create-lead/", leadInfo)
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  }

  static getLeads() {
    return http.get("/api/v2/admin/leads").then((data) => {
      if (data.success) {
        return data.data;
      } else {
        throw new Error();
      }
    });
  }

  static getEmployees(followUpType) {
    let api = "/api/v2/admin/employees";
    if (followUpType) {
      api = api + "?followUpType=" + followUpType;
    }
    return http.get(api).then((data) => {
      if (data.success) {
        return data.data;
      } else {
        throw new Error();
      }
    });
  }

  static getTodaysLeads(filters) {
    return http
      .post("/api/v2/emp/todays-leads", { filters })
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  }

  static getAdminLeadsCount() {
    return http.get("/api/v2/admin/admin-leads-count").then((response) => {
      if (response.success) {
        return response;
      } else {
        throw new Error();
      }
    });
  }

  static getEmployeeLeadsCount() {
    return http.get("/api/v2/emp/emp-leads-count").then((response) => {
      if (response.success) {
        return response;
      } else {
        throw new Error();
      }
    });
  }

  static getAdminAllLeadsCount(from) {
    return http
      .post("/api/v2/admin/admin-all-leads-count", { from })
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  }

  static getCountPerEmployee(from) {
    return http
      .post("/api/v2/admin/count-per-employee", { from })
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  }

  static getAdminTodaysLeads(filters) {
    return http
      .post("/api/v2/admin/admin-todays-leads", { filters })
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  }

  static getLeadsHistory(filters) {
    return http
      .post("/api/v2/emp/leads-history", { filters })
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  }

  static getAdminLeadsHistory(filters) {
    return http
      .post("/api/v2/admin/admin-leads-history", { filters })
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  }

  static getUnAttendedLeads(filters) {
    return http
      .post("/api/v2/emp/unattended-leads", { filters })
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  }

  static getAdminUnAttendedLeads(filters) {
    return http
      .post("/api/v2/admin/unattended-leads", { filters })
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  }

  static getPipeLineLeads(filters) {
    return http
      .post("/api/v2/emp/pipeline-leads", { filters })
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  }

  static getAdminPipeLineLeads(filters) {
    return http
      .post("/api/v2/admin/admin-pipeline-leads", { filters })
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  }

  static getStatusOptions() {
    return http.get("/api/v2/get-status-options").then((response) => {
      return response.data;
    });
  }

  static getCommentsOptions() {
    return http.get("/api/v2/get-comments-options").then((response) => {
      return response.data;
    });
  }

  static getBusinessTypeOptions() {
    return http.get("/api/v2/get-business-type-options").then((response) => {
      return response.data;
    });
  }

  static getLeadSourceOptions() {
    return http.get("/api/v2/get-leadSource-options").then((response) => {
      return response.data;
    });
  }

  static getNextCallDate(nextCallDate) {
    return http
      .get("/api/v2/get-next-call-date?nextCallDate=" + nextCallDate)
      .then((response) => {
        return response.data;
      });
  }

  static updateNextCallDate(when, selectedLeads) {
    return http
      .patch("/api/v2/admin/update-next-calldate", { when, selectedLeads })
      .then((response) => {
        if (response.success) {
          return response.success;
        } else {
          throw new Error();
        }
      });
  }

  static changeServiceEmployee(data) {
    return http
      .patch("/api/v2/admin/update-service-employee", data)
      .then((response) => {
        if (response.success) {
          return response.success;
        } else {
          throw new Error();
        }
      });
  }

  static changeNextCallDate(data) {
    // from pipeline
    return http
      .patch("/api/v2/admin/update-next-calldate-pipeline", data)
      .then((response) => {
        if (response.success) {
          return response.success;
        } else {
          throw new Error();
        }
      });
  }

  static updateLead(leadId, data) {
    return http.patch("/api/v2/lead/" + leadId, data).then((response) => {
      if (response.success) {
        return response;
      } else {
        throw new Error();
      }
    });
  }
}

export default AdminService;
