import React from "react";
import ajax from "../../services/Ajax";

class DeactivateBids extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { data: "loading...." };
  }

  componentDidMount() {
    ajax.get("/api/v2/deactivate-bids").then(res => {
      if (!res.success) {
        this.setState({ data: "failed!" });
      } else {
        this.setState({ data: JSON.stringify(res.data) });
      }
    });
  }

  render() {
    return <div>{this.state.data}</div>;
  }
}

export default DeactivateBids;
