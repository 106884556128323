import React from "react";
import ProductImage from "./ProductImage";
import ProductTitle from "./ProductTitle";
import ProductAttributes from "./ProductAttributes";
import ModalN from "../../../widgets/modal/ModalN";
import ImageNameForm from "./../../rb/skus/ImageNameForm";
import AdminService from "../../../services/AdminService";
import ServiceAreaForm from "./../../rb/Seller/Store/ServiceAreaForm";
import BestStockModal from "../skus/BestStockModal";
import GMVModal from "../skus/GMVModal";

class Product extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      images: props.product.images,
      refresh: false,
      price: props.product.pricing ? props.product.pricing.value : null,
    };
  }

  showImageNameEdit = () => {
    this.setState({ showImageNameEditModal: true });
  };

  hideImageNameEditModal = () => {
    this.setState({ showImageNameEditModal: false });
  };

  updateImageName = (skuId, name, index) => {
    AdminService.editImageName(skuId, name, index).then((response) => {
      if (response.success) {
        let newImages = this.state.images;
        newImages[index] = name.value;
        this.setState({ images: newImages });
        this.cancelImageNameEdit();
      }
    });
  };

  cancelImageNameEdit = () => {
    this.setState({ priceEditIndex: -1 });
  };

  editPrice = () => {
    this.setState({ editPrice: true });
  };

  cancelPriceEdit = () => {
    this.setState({ editPrice: false });
  };

  onChangePriceValue = (skuId, e) => {
    this.setState({ price: e.target.value, skuId });
  };

  changePrice = () => {
    let { price, skuId } = this.state;
    AdminService.changeMarkUpValue(price, skuId).then((response) => {
      if (response) {
        this.cancelPriceEdit();
      }
    });
  };

  openGMVModal = (skuId) => {
    this.setState({ skuId, openGMVModal: true });
  };

  closeGMVModal = () => {
    this.setState({ openGMVModal: false });
  };

  openBestStockModal = (skuId) => {
    this.setState({ skuId, openBestStockModal: true });
  };

  closeBestStockModal = () => {
    this.setState({ openBestStockModal: false });
  };

  render() {
    let { editPrice, price, skuId } = this.state;
    let { product, selectProduct, stocks, user } = this.props;
    let { showImageNameEditModal } = this.state;
    let { descriptionAttributes, attributes, notes } = product;
    let attributesMap = {};
    let images = this.state.images;
    attributes.forEach((item) => {
      if (!attributesMap[item.key]) {
        attributesMap[item.key] = item.value;
      }
    });

    return (
      <div className="col-lg-4" style={{ marginBottom: "10px" }}>
        <div className="card card-product z-depth-1-top z-depth-2--hover">
          <div className="card-body" style={{ padding: "1rem" }}>
            <ProductTitle
              brand={product.brand}
              subtype={product.subtype}
              descriptionAttributes={descriptionAttributes}
              attributes={attributesMap}
            />

            <ProductImage images={images} subtype={product.subtype} />

            <ProductAttributes notes={notes} attributes={attributes} />
          </div>
          {user === "admin" && images && images.length >= 1 && (
            <span className="btn btn-link" onClick={this.showImageNameEdit}>
              Edit image name
            </span>
          )}

          <div className="card-footer">
            <div className="product-buttons">
              <div className="row align-items-center">
                <div className="col-8">
                  {user !== "admin" && (
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-block btn-base-1 btn-circle btn-icon-left"
                        onClick={() => {
                          selectProduct(product);
                        }}
                      >
                        <i className="icon ion-android-cart" />

                        {user !== "admin"
                          ? stocks
                            ? "Add to Project"
                            : "Add as stock"
                          : ""}
                      </button>
                    </div>
                  )}
                  {user === "admin" && (
                    <div>
                      {editPrice ? (
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            ₹{" "}
                            <input
                              placeholder="Price"
                              value={price}
                              onChange={(e) =>
                                this.onChangePriceValue(product._id, e)
                              }
                              style={{ width: "50px", height: 22 }}
                            />
                          </div>
                          <div className="d-flex align-items-center ml-2">
                            <button
                              onClick={this.changePrice}
                              className="btn btn-primary btn-xs"
                            >
                              Submit
                            </button>
                            <a
                              className="btn btn-light btn-xs ml-2"
                              onClick={this.cancelPriceEdit}
                            >
                              Cancel
                            </a>
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex align-items-center justify-content-between">
                          <div
                            style={{ cursor: "pointer" }}
                            className="btn-link"
                            onClick={this.editPrice}
                          >
                            {"Margin: ₹ " + price}
                          </div>
                          <div
                            style={{ cursor: "pointer" }}
                            className="btn-link"
                            onClick={() => this.openGMVModal(product._id)}
                          >
                            GMV
                          </div>
                          <div
                            style={{ cursor: "pointer" }}
                            className="btn-link"
                            onClick={() => this.openBestStockModal(product._id)}
                          >
                            Best stock
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.openBestStockModal && (
          <ModalN
            title="Get best stock"
            show={this.state.openBestStockModal}
            close={this.closeBestStockModal}
          >
            <BestStockModal from="admin" skuId={skuId} />
          </ModalN>
        )}
        {this.state.openGMVModal && (
          <ModalN
            title="GMV"
            show={this.state.openGMVModal}
            close={this.closeGMVModal}
            style={{ width: 780 }}
          >
            <GMVModal skuId={skuId} />
          </ModalN>
        )}
        <ModalN
          title="Edit image names"
          show={showImageNameEditModal}
          close={this.hideImageNameEditModal}
        >
          <div>
            <div>
              {images.map((image, index) => (
                <div>
                  {this.state.priceEditIndex !== index ? (
                    <a
                      style={{
                        textDecoration: "underline",
                        color: "blue",
                        margin: "4px 0",
                      }}
                      onClick={() => {
                        this.setState({ priceEditIndex: index });
                      }}
                    >
                      {image}
                    </a>
                  ) : (
                    <ImageNameForm
                      product={JSON.parse(JSON.stringify(product))}
                      updateImageName={this.updateImageName}
                      cancelImageNameEdit={this.cancelImageNameEdit}
                      index={index}
                    />
                  )}
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-between">
              <button
                className="btn btn-danger mr-2"
                onClick={this.hideImageNameEditModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </ModalN>
      </div>
    );
  }
}

export default Product;
