import React from "react";
import Select from "react-select";
import AdminService from "../../../services/AdminService";
import UsersFilter from "./UsersFilter";
import Actions from "./../../../state/actions";
import { connect } from "react-redux";

const typeOptions = [
  { value: "A", label: "A - Admin" },
  { value: "B", label: "B - Business / Seller" },
  { value: "C", label: "C - Customer / Buyer" }
];

const booleanOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" }
];

class AdminUsersView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let { filters } = this.state;
    // this.loadUsers(filters);
  }

  onChangeFilters = filters => {
    this.setState({ filters }, this.loadUsers);
  };

  loadUsers = () => {
    let { filters } = this.state;
    AdminService.getAdminAllUsers(filters).then(data => {
      this.setState({ users: data.data });
    });
  };

  setEditIndex = index => {
    this.setState({ editIndex: index, editUser: true });
  };

  onChangeName = e => {
    let { editIndex, users } = this.state;
    let user = users[editIndex];
    user.name = e.target.value;
    this.setState({ users: [...users] });
  };

  onChangeUserType = option => {
    let { editIndex, users } = this.state;
    let user = users[editIndex];
    user.type = option.value;
    this.setState({ users: [...users] });
  };

  onChangePhone = e => {
    let { editIndex, users } = this.state;
    let user = users[editIndex];
    user.phone = e.target.value;
    this.setState({ users: [...users] });
  };

  onChangePhoneConfirmed = option => {
    let { editIndex, users } = this.state;
    let user = users[editIndex];
    user.phoneConfirmed = option.value;
    this.setState({ users: [...users] });
  };

  onChangeEmail = e => {
    let { editIndex, users } = this.state;
    let user = users[editIndex];
    user.email = e.target.value;
    this.setState({ users: [...users] });
  };

  onChangeEmailConfirmed = option => {
    let { editIndex, users } = this.state;
    let user = users[editIndex];
    user.emailConfirmed = option.value;
    this.setState({ users: [...users] });
  };

  getUserType = type => {
    if (type === "A") {
      return "Admin";
    } else if (type === "B") {
      return "Business / Seller";
    } else if (type === "C") {
      return "Customer / Buyer";
    } else if (type === "E") {
      return "Employee";
    }
  };

  updateUser = () => {
    let { editIndex, users } = this.state;
    let user = users[editIndex];
    AdminService.updateUser(user).then(response => {
      if (response.success) {
        this.props.toastMessage("Details have been updated", "success", 3);
        this.setState({ editIndex: null, editUser: false });
        this.loadUsers();
      }
    });
  };

  cancelUpdateUser = () => {
    this.setState({ editIndex: null, editUser: false });
  };

  render() {
    let { users, editIndex, editUser } = this.state;

    return (
      <div className="row">
        <div className="col-md-12 border border-dark pb-3">
          <UsersFilter onChangeFilters={this.onChangeFilters} />

          {users && users.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-cart">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>User type</th>
                    <th>Phone</th>
                    <th>Phone confirmed</th>
                    <th>Email</th>
                    <th>Email confirmed</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user, index) => {
                    let {
                      name,
                      email,
                      phone,
                      phoneConfirmed,
                      emailConfirmed,
                      type
                    } = user;
                    return (
                      <tr>
                        <td>
                          {editIndex === index && editUser ? (
                            <input
                              type="text"
                              placeholder="Name"
                              value={name}
                              onChange={this.onChangeName}
                            />
                          ) : (
                            name || "NA"
                          )}
                        </td>

                        <td>
                          {editIndex === index && editUser ? (
                            <Select
                              className="flex-fill "
                              placeholder="User type"
                              options={typeOptions}
                              value={type}
                              onChange={this.onChangeUserType}
                            />
                          ) : type ? (
                            this.getUserType(type)
                          ) : (
                            "NA"
                          )}
                        </td>

                        <td>
                          {editIndex === index && editUser ? (
                            <input
                              type="number"
                              placeholder="Phone"
                              value={phone}
                              onChange={this.onChangePhone}
                            />
                          ) : (
                            phone || "NA"
                          )}
                        </td>

                        <td>
                          {editIndex === index && editUser ? (
                            <Select
                              className="flex-fill "
                              placeholder="Phone confirmed"
                              options={booleanOptions}
                              value={phoneConfirmed}
                              onChange={this.onChangePhoneConfirmed}
                            />
                          ) : phoneConfirmed ? (
                            "Yes"
                          ) : (
                            "No"
                          )}
                        </td>

                        <td>
                          {editIndex === index && editUser ? (
                            <input
                              type="email"
                              placeholder="Email"
                              value={email}
                              onChange={this.onChangeEmail}
                            />
                          ) : (
                            email || "NA"
                          )}
                        </td>

                        <td>
                          {editIndex === index && editUser ? (
                            <Select
                              className="flex-fill "
                              placeholder="Email confirmed"
                              options={booleanOptions}
                              value={emailConfirmed}
                              onChange={this.onChangeEmailConfirmed}
                            />
                          ) : emailConfirmed ? (
                            "Yes"
                          ) : (
                            "No"
                          )}
                        </td>
                        <td>
                          <i
                            className="fa fa-pencil-square-o "
                            onClick={() => {
                              this.setEditIndex(index);
                            }}
                          />
                          {editIndex === index && editUser ? (
                            <button
                              onClick={this.updateUser}
                              className="btn btn-xs btn-success ml-1"
                            >
                              <i className="fa fa-check " />
                            </button>
                          ) : null}
                          {editIndex === index && editUser ? (
                            <button
                              onClick={this.cancelUpdateUser}
                              className="btn btn-xs btn-danger ml-1"
                            >
                              <i className="fa fa-times " />
                            </button>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <section className="slice sct-color-1">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="text-center">
                      <div className="d-block font-size-3 font-size-md-6 c-gray-light">
                        <i className="fa fa-shopping-bag" aria-hidden="true" />
                      </div>

                      <h5>NO USERS</h5>
                      <p className="mt-5 px-5">
                        We have no users at the moment.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </div>
    );
  }
}

function actions(dispatch) {
  return {
    toastMessage: (message, type, duration) => {
      dispatch(Actions.toastMessage(message, type, duration));
    }
  };
}
export default connect(
  null,
  actions
)(AdminUsersView);
