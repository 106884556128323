import React from "react";
import Select from "react-select";
import AdminService from "../../../../services/AdminService";
import ModalN from "../../../../widgets/modal/ModalN";
import DetailedLeadView from "../DetailedLeadView";
import { formatLeadDate } from "../../../../utils/Utils";

class AdminLeadDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leadId: props.lead._id,
      lead: props.lead,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.state = {
      lead: nextProps.lead,
    };
  }

  showPreviousCallLog = (logHistory) => {
    this.setState({ showPreviousCallLogModal: true, logHistory: logHistory });
  };

  hidePreviousCallLogModal = () => {
    this.setState({ showPreviousCallLogModal: false });
  };

  getServiceEmployees = (followUpType) => {
    AdminService.getEmployees(followUpType).then((employeeOptions = []) => {
      employeeOptions.forEach((option) => {
        option.label = option.name.toUpperCase();
        option.value = option.name;
        delete option.name;
      });
      this.setState({ employeeOptions: employeeOptions });
    });
  };

  setEditIndex = (followUpType) => {
    this.getServiceEmployees(followUpType);
    this.setState({ editIndex: 1 });
  };

  onChangeServiceEmployee = (option) => {
    this.setState({ serviceEmployee: option });
  };

  changeServiceEmployee = (leadId) => {
    let data = {};
    data.serviceEmployee = this.state.serviceEmployee.value;
    data.serviceEmployeeId = this.state.serviceEmployee._id;
    data.leadId = leadId;
    AdminService.changeServiceEmployee(data).then((response) => {
      if (response) {
        this.setState({ commentEditIndex: 0 });
        this.props.setFilters();
      }
    });
  };
  handleDateChange = (e) => {
    let nextCallDate = e.target.value;
    nextCallDate = new Date(nextCallDate);
    this.setState({ nextCallDate });
  };

  updateNextCallDate = () => {
    let data = {};
    data.nextCallDate = this.state.nextCallDate;
    data.leadId = this.state.lead._id;
    AdminService.changeNextCallDate(data).then((response) => {
      if (response) {
        this.props.setFilters();
      }
    });
  };

  showAllDetails = () => {
    this.setState({ showAllDetails: true });
  };

  hideAllDetails = () => {
    this.setState({ showAllDetails: false });
  };

  render() {
    let { from } = this.props;
    let { editIndex, employeeOptions = [], lead } = this.state;
    let {
      leadSource,
      name,
      followUpType,
      phone,
      email,
      updatedOn,
      nextCallDate,
      logHistory,
      serviceEmployee,
      createdAt,
      status,
    } = lead;
    let leadId = lead._id;
    return (
      <tr>
        {from === "adminUL" ? (
          <td>
            <input
              type="checkbox"
              id="checkBox"
              checked={lead.isChecked || false}
              onChange={(e) => {
                this.props.addLeadsToUpdate(leadId, e.target.checked);
              }}
            />
          </td>
        ) : null}
        <td className="product-name">{leadSource}</td>
        <td>{followUpType}</td>
        <td>{status}</td>
        <td>{name}</td>
        <td>{phone ? phone : "NA"}</td>
        <td>{email ? email : "not available"}</td>
        <td>{createdAt ? formatLeadDate(createdAt, "from") : "-"}</td>
        <td>
          {updatedOn ? formatLeadDate(updatedOn, "from") : "Not yet updated"}
        </td>
        <td>
          {from !== "pipeline" && <span>{serviceEmployee}</span>}
          {from === "pipeline" && editIndex !== 1 && (
            <div className="d-flex">
              <div className="mr-1">{serviceEmployee}</div>
              <div className="left-link" style={{ cursor: "pointer" }}>
                <i
                  className="fa fa-pencil-square-o "
                  onClick={() => {
                    this.setEditIndex(followUpType);
                  }}
                ></i>
              </div>
            </div>
          )}
          {from === "pipeline" && editIndex === 1 && (
            <div className="form-group">
              <Select
                className="flex-fill mb-2"
                placeholder="Select service employee"
                options={employeeOptions}
                value={serviceEmployee || null}
                onChange={this.onChangeServiceEmployee}
              />
              {this.state.serviceEmployee && (
                <button
                  className="btn btn-xs btn-success"
                  onClick={() => {
                    this.changeServiceEmployee(leadId);
                  }}
                >
                  Submit
                </button>
              )}
            </div>
          )}
        </td>
        <td>
          {formatLeadDate(nextCallDate)}
          {from === "pipeline" && (
            <div>
              <input
                type="date"
                className="form-control"
                onChange={this.handleDateChange}
              />
              {this.state.nextCallDate && (
                <button
                  className="btn btn-success btn-xs"
                  onClick={this.updateNextCallDate}
                >
                  Update
                </button>
              )}
            </div>
          )}
        </td>

        <td>
          {logHistory && logHistory.length >= 1 ? (
            <div>
              <div
                className="btn-link"
                onClick={() => {
                  this.showPreviousCallLog(logHistory);
                }}
              >
                View
              </div>
            </div>
          ) : (
            <div>NA</div>
          )}
        </td>
        <td>
          <a className="btn-link" onClick={this.showAllDetails}>
            View
          </a>
        </td>
        {this.state.showPreviousCallLogModal && (
          <ModalN
            title="Previous call log"
            show={this.state.showPreviousCallLogModal}
            close={this.hidePreviousCallLogModal}
          >
            {this.state.logHistory && this.state.logHistory.length > 0 && (
              <div>
                <table className="table table-cart">
                  <thead>
                    <tr>
                      <th>Updated on</th>
                      <td>Status</td>
                      <th>Comments</th>
                      <th>Next call date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.logHistory.map((log, index) => (
                      <tr>
                        <td>
                          {log.updatedOn
                            ? formatLeadDate(log.updatedOn)
                            : "not available"}
                        </td>
                        <td>{log.status ? log.status : "not available"}</td>
                        <td>{log.comment ? log.comment : "not available"}</td>
                        <td>
                          {log.nextCallDate
                            ? formatLeadDate(log.nextCallDate)
                            : "not available"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {!this.state.logHistory ||
              (this.state.logHistory.length === 0 && (
                <div>No previous log</div>
              ))}
          </ModalN>
        )}
        {this.state.showAllDetails && (
          <ModalN
            title="Lead details"
            show={this.state.showAllDetails}
            close={this.hideAllDetails}
          >
            <DetailedLeadView lead={lead} />
          </ModalN>
        )}
      </tr>
    );
  }
}

export default AdminLeadDisplay;
