import React from "react";
import StoreStocksContainer from "./StoreStocksContainer";
import StoreStocksWizard from "./StoreStocksWizard";
import StoreOrdersView from "./StoreOrdersView";
import StoreBidRequestView from "./StoreBidRequestView";
import ModalN from "../../../../widgets/modal/ModalN";
import history from "../../../../utils/History";
import Actions from "./../../../../state/actions";
import { connect } from "react-redux";
import CopyText from "./../../../../utils/CopyText";
import EditServiceLocations from "./virtualstore/EditServiceLocations";
import StoreBannerImages from "./StoreBannerImages";

class StoreView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      refresh: new Date().getTime(),
    };
  }

  handleDelete = (stockId, skuId) => {
    let { deleteStockList = [], skuIds = [] } = this.state;

    let index = deleteStockList.indexOf(stockId);
    if (index !== -1) {
      deleteStockList.splice(index, 1);
    } else {
      deleteStockList.push(stockId);
    }

    let skuIndex = skuIds.indexOf(skuId);
    if (skuIndex !== -1) {
      skuIds.splice(skuIndex, 1);
    } else {
      skuIds.push(skuId);
    }

    this.setState({
      deleteStockList: [...deleteStockList],
      skuIds: [...skuIds],
    });
  };

  deleteStocks = () => {
    //Ids of stocks to be deleted saved in deleteStockList
    let { deleteStockList, skuIds } = this.state;
    this.props.deleteStocks(deleteStockList, skuIds);
  };

  showDeleteStocks = () => {
    this.setState({ showDeleteStocksModal: true });
  };

  hideDeleteStocksModal = () => {
    this.setState({ showDeleteStocksModal: false });
  };

  showStocks = () => {
    if (this.props.view === "s") {
      this.doRefresh();
    } else {
      history.push(
        "/dashboard/stores/" +
          this.props.type +
          "/" +
          this.props.store._id +
          "/s"
      );
    }
  };

  showAddStocks = () => {
    history.push(
      "/dashboard/stores/" +
        this.props.type +
        "/" +
        this.props.store._id +
        "/as"
    );
  };

  startStoreDelete = () => {
    this.setState({ showDeleteModal: true });
  };

  showStoreOrders = () => {
    history.push(
      "/dashboard/stores/" +
        this.props.type +
        "/" +
        this.props.store._id +
        "/so"
    );
  };

  showBannerImages = () => {
    history.push(
      "/dashboard/stores/" +
        this.props.type +
        "/" +
        this.props.store._id +
        "/bi"
    );
  };

  showPublishStoreModal = () => {
    this.setState({ showPublishStoreModal: true });
  };

  hidePublishStoreModal = () => {
    this.setState({ showPublishStoreModal: false });
  };

  showUrlModal = () => {
    this.setState({ showUrlModal: true });
  };

  hideUrlModal = () => {
    this.setState({ showUrlModal: false });
  };

  getURL = (short) => {
    let storeName = this.props.store.name;
    storeName = storeName.replace(/ /g, "-");
    return `https://storefronts.in/${this.props.store.code}${
      !short ? "/" + storeName : ""
    }`;
  };

  publishStore = () => {
    this.props.publishStore(this.getURL()).then((response) => {
      this.hidePublishStoreModal();
    });
  };

  hideDeleteModal = () => {
    this.setState({ showDeleteModal: false });
  };

  showStoreBidRequests = () => {
    history.push(
      "/dashboard/stores/" +
        this.props.type +
        "/" +
        this.props.store._id +
        "/br"
    );
  };

  editServiceLocations = () => {
    this.setState({ showEditLocation: true });
  };

  hideEditLocation = () => {
    this.setState({ showEditLocation: false });
    this.showStocks();
  };

  getView = () => {
    let { store, view, type } = this.props;
    let { deleteStockList = [], showDeleteStocksModal } = this.state;

    if (view === "as") {
      return (
        <div className="my-3 p-3 border border-light shadow">
          <StoreStocksWizard
            store={store}
            storeId={store._id}
            onAddStocks={this.showStocks}
            type={type}
            view={view}
            doRefresh={this.doRefresh}
          />
        </div>
      );
    }

    if (view === "br") {
      return (
        <div className="my-3 p-3 border border-light shadow">
          <StoreBidRequestView storeId={store._id} />
        </div>
      );
    }

    if (view === "so") {
      return (
        <div className="my-3 p-3 border border-light shadow">
          <StoreOrdersView
            virtual={store.virtual}
            type={type}
            storeId={store._id}
          />
        </div>
      );
    }

    if (view === "bi") {
      return (
        <div className="my-3 p-3 border border-light shadow">
          <StoreBannerImages
            showBannerImages={this.showBannerImages}
            store={store}
          />
        </div>
      );
    }

    if (view === "s") {
      return (
        <div>
          {deleteStockList.length > 0 && (
            <div className="d-flex justify-content-end mb-1">
              <div>
                <a
                  className="btn btn-danger btn-sm"
                  onClick={this.showDeleteStocks}
                >
                  Delete Selected Stocks
                </a>
              </div>
            </div>
          )}

          <StoreStocksContainer
            storeId={store._id}
            handleDelete={this.handleDelete}
            deleteStockList={deleteStockList}
            showAddStocks={this.showAddStocks}
            type={type}
          />

          {showDeleteStocksModal && (
            <ModalN
              title="Delete Stocks"
              show={showDeleteStocksModal}
              close={this.hideDeleteStocksModal}
            >
              <div>
                <div className="my-2">
                  Are you sure you want to delete the selected stocks?
                </div>
                <div className="d-flex justify-content-between">
                  <button
                    className="btn btn-secondary mr-2"
                    onClick={this.hideDeleteStocksModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={this.deleteStocks}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </ModalN>
          )}
        </div>
      );
    }
  };

  doRefresh = () => {
    this.setState({ refresh: null }, () => {
      this.setState({ refresh: new Date().getTime() });
    });
  };

  render() {
    let { loading, store, view = "s", type } = this.props;
    let { showDeleteModal, refresh } = this.state;

    if (!store || !refresh) {
      return null;
    }

    return (
      <div className="mt-4">
        <div className="d-flex justify-content-between">
          <div>
            <h3 style={{ marginBottom: 10 }}>{store.name}</h3>
          </div>
          <div>
            <span
              className="badge bg-base-1 text-uppercase mb-1"
              style={{ textTransform: "capitalize" }}
            >
              {store.status || "ACTIVE"}
            </span>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          {store.virtual ? (
            <div>
              <div>
                {store.status === "ACTIVE" && (
                  <div
                    className="btn-link"
                    onClick={this.showPublishStoreModal}
                  >
                    Publish store
                  </div>
                )}
              </div>
              {store.status === "PUBLISHED" && (
                <div className="btn-link" onClick={this.showUrlModal}>
                  Copy Store Link
                </div>
              )}
            </div>
          ) : (
            <div style={{ textTransform: "capitalize" }}>{store.address}</div>
          )}
          {store.virtual && type === "v" && (
            <div
              className="btn-link"
              style={{ cursor: "pointer" }}
              onClick={this.editServiceLocations}
            >
              <i className="fa fa-map-marker" style={{ marginRight: "5px" }} />
              Service Locations
            </div>
          )}
          <div className="d-flex">
            <a className="btn-link" onClick={this.startStoreDelete}>
              <span className="text-danger ">Delete Store</span>
            </a>
          </div>
        </div>

        <div>
          <div className="tabs tabs--style-2 tabs--centered" role="tabpanel">
            <ul
              className="nav nav-tabs justify-content-center d-flex"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <a
                  className={
                    view === "s"
                      ? "nav-link active text-center text-uppercase tab-link"
                      : "nav-link text-center text-uppercase tab-link"
                  }
                  onClick={this.showStocks}
                >
                  View Stocks
                </a>
              </li>

              <li className="nav-item" role="presentation">
                <a
                  className={
                    view === "as"
                      ? "nav-link active text-center text-uppercase tab-link"
                      : "nav-link text-center text-uppercase tab-link"
                  }
                  onClick={this.showAddStocks}
                >
                  Add Stocks
                </a>
              </li>

              {type !== "v" && (
                <div>
                  <li className="nav-item" role="presentation">
                    <a
                      className={
                        view === "br"
                          ? "nav-link active text-center text-uppercase tab-link"
                          : "nav-link text-center text-uppercase tab-link"
                      }
                      onClick={this.showStoreBidRequests}
                    >
                      View Bid Requests
                    </a>
                  </li>
                </div>
              )}

              <li className="nav-item" role="presentation">
                <a
                  className={
                    view === "so"
                      ? "nav-link active text-center text-uppercase tab-link"
                      : "nav-link text-center text-uppercase tab-link"
                  }
                  onClick={this.showStoreOrders}
                >
                  View Orders
                </a>
              </li>
              {store.status === "PUBLISHED" && (
                <li className="nav-item" role="presentation">
                  <a
                    className={
                      view === "bi"
                        ? "nav-link active text-center text-uppercase tab-link"
                        : "nav-link text-center text-uppercase tab-link"
                    }
                    onClick={this.showBannerImages}
                  >
                    Banner Images
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="mt-2">{this.getView()}</div>
        <ModalN
          title="Delete Store"
          show={showDeleteModal}
          close={this.hideDeleteModal}
        >
          <div>
            <div className="my-2">
              Are you sure you want to delete this store?
            </div>
            <div className="d-flex justify-content-between">
              <button
                className="btn btn-secondary mr-2"
                onClick={this.hideDeleteModal}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger"
                onClick={this.props.deleteStore}
              >
                Delete
              </button>
            </div>
          </div>
        </ModalN>
        {this.state.showPublishStoreModal && (
          <ModalN
            title="Publish store"
            show={this.showPublishStoreModal}
            close={this.hidePublishStoreModal}
          >
            <div>
              {/* <span>{this.getURL()}</span> */}
              <CopyText text={this.getURL()} />
              <div className="my-2">
                Are you sure you want to publish this store?
              </div>
              <div className="d-flex justify-content-between">
                <button
                  className="btn btn-secondary mr-2"
                  onClick={this.hidePublishStoreModal}
                >
                  Cancel
                </button>
                <button className="btn btn-success" onClick={this.publishStore}>
                  Publish
                </button>
              </div>
            </div>
          </ModalN>
        )}
        {this.state.showUrlModal && (
          <ModalN
            title="Copy Store Link"
            show={this.showUrlModal}
            close={this.hideUrlModal}
          >
            <div>
              <div className="d-flex justify-content-center flex-column align-items-center">
                <CopyText
                  text={this.getURL()}
                  width="maxContent"
                  style={{ width: 300 }}
                />
                <div className="my-2">-- OR -- </div>
                <CopyText
                  text={this.getURL(true)}
                  width="maxContent"
                  style={{ width: 300 }}
                />
              </div>

              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-secondary"
                  onClick={this.hideUrlModal}
                >
                  Close
                </button>
              </div>
            </div>
          </ModalN>
        )}
        {this.state.showEditLocation && (
          <ModalN
            show={this.state.showEditLocation}
            close={this.hideEditLocation}
            title="Edit service location"
            modalHeight={{
              minHeight: "calc(100vh - 500px)",
            }}
          >
            <EditServiceLocations
              store={store}
              hideEditLocation={this.hideEditLocation}
              refreshStore={this.props.refreshStore}
            />
          </ModalN>
        )}
      </div>
    );
  }
}

const actions = {
  toastMessage: Actions.toastMessage,
};

export default connect(null, actions)(StoreView);
