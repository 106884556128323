import React from "react";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

class MonthlyRevenueView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({ revenues: this.props.revenues });
  }

  getMonthName = (month) => {
    month = parseInt(month.slice(5)) - 1;
    return monthNames[month];
  };

  render() {
    let { revenues = [] } = this.state;
    let { type } = this.props;
    if (revenues.length >= 1) {
      return (
        <div className="strong-500">
          {revenues.map((revenue) => (
            <div>
              {this.getMonthName(revenue.month) + " - ₹" + revenue.count}
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div>
          <h5>{"No " + type + " revenue"}</h5>
        </div>
      );
    }
  }
}

export default MonthlyRevenueView;
