import React from "react";
import ModalN from "../../../widgets/modal/ModalN";
import ServiceLocationsView2 from "../Seller/Store/ServiceLocationsView2";
import ProductAttributes from "../Shop/ProductAttributes";
import ProductImage from "../Shop/ProductImage";
import ProductTitle from "../Shop/ProductTitle";

class VirtualNonServiceablesView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  showServiceLocations = (serviceAreas) => {
    this.setState({ showServiceLocations: true, serviceAreas });
  };

  hideServiceLocations = () => {
    this.setState({ showServiceLocations: false, serviceAreas: [] });
  };

  render() {
    let { virtualNonServiceables } = this.props;
    return virtualNonServiceables.map((req) => {
      let { sku, districts } = req;
      let { attributes, descriptionAttributes, notes } = sku;
      let attributesMap = {};
      attributes.forEach((item) => {
        if (!attributesMap[item.key]) {
          attributesMap[item.key] = item.value;
        }
      });
      return (
        <div className="col-lg-4  mb-2">
          <div className="card card-product z-depth-1-top z-depth-2--hover">
            <div className="card-body" style={{ padding: "1rem" }}>
              <span
                className="badge text-uppercase"
                style={{
                  position: "absolute",
                  top: "-10px",
                  right: "0px",
                  backgroundColor: "#c89034",
                  fontWeight: 400,
                }}
              >
                Unavailable
              </span>

              <ProductTitle
                brand={sku.brand}
                subtype={sku.subtype}
                descriptionAttributes={descriptionAttributes}
                attributes={attributesMap}
              />

              <ProductImage images={sku.images} subtype={sku.subtype} />

              <div className="mt-2">
                <ProductAttributes notes={notes} attributes={attributes} />
              </div>

              <div
                className="btn-link"
                onClick={() => {
                  this.showServiceLocations(districts);
                }}
                style={{ maxWidth: "max-content", cursor: "pointer" }}
              >
                Service Locations
              </div>
            </div>
          </div>
          {this.state.showServiceLocations && (
            <ModalN
              title="Service Locations"
              show={this.state.showServiceLocations}
              close={this.hideServiceLocations}
            >
              <ServiceLocationsView2 serviceAreas={this.state.serviceAreas} />
            </ModalN>
          )}
        </div>
      );
    });
  }
}

export default VirtualNonServiceablesView;
