import React from "react";
import Bid from "./Bid";
import {getConfirmedBid} from "./../../utils/Utils";

class ConfirmedBid extends React.Component {
  render() {
    var request = this.props.request;
    var confirmedBid = request.confirmedBid,
      bids = request.bids;
    var bid = getConfirmedBid(confirmedBid, bids);
    if (!bid) {
      return null;
    }
    return (
      <div>
        <Bid bid={bid} quantity={request.quantity} />
      </div>
    );
  }
}

export default ConfirmedBid;
