import React from "react";
import AdminService from "../../../services/AdminService";
import InfiniteScroller from "../../../widgets/InfiniteScroll/InfiniteScroller";
import ProductImage from "../Shop/ProductImage";
import ProductTitle from "../Shop/ProductTitle";
import { ADMIN_ORDERS_PAGE_SIZE } from "./../Constants";
import OrdersFilter from "./OrdersFilter";

class AdminOrdersView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { adminOrders: [], count: -1, currentPage: 1, filters: {} };
  }

  componentDidMount() {
    this.getOrdersCount();
    this.getOrders();
  }

  getOrdersCount = () => {
    AdminService.getAdminOrdersCount().then((data) => {
      this.setState({
        count: data.count,
      });
    });
  };

  getOrders = () => {
    let { currentPage, adminOrders = [], filters = {} } = this.state;
    this.setState({ loadingOrders: true });
    filters.currentPage = currentPage;
    filters.pageSize = ADMIN_ORDERS_PAGE_SIZE;
    AdminService.getAdminOrders(filters).then((data) => {
      this.setState({
        adminOrders: adminOrders.concat(data.data || []),
        completedOrders: data.completedOrders || 0,
        loadingOrders: false,
        currentPage: currentPage + 1,
      });
    });
  };

  modifyDate = (date) => {
    if (!date) {
      return "";
    }
    let now = new Date(date);
    date = date.substr(0, date.indexOf("T"));
    date = date.split("-").reverse().join("-");

    let time = now.toLocaleTimeString();
    return date + "/" + time;
  };

  setFilters = (filters) => {
    this.setState(
      {
        adminOrders: [],
        filters: { ...filters },
        count: -1,
        currentPage: 1,
      },
      this.getOrders
    );
  };

  render() {
    let {
      adminOrders = [],
      completedOrders,
      loadingOrders,
      count,
      currentPage,
      filters,
    } = this.state;
    let loadMore = false;
    if (count !== -1) {
      let totalPages = Math.ceil(count / ADMIN_ORDERS_PAGE_SIZE);
      loadMore = currentPage - 1 < totalPages;
    }

    return (
      <div className="row">
        <div className="col-md-12 border border-dark pb-3">
          <OrdersFilter setFilters={this.setFilters} />

          {adminOrders && adminOrders.length >= 1 ? (
            <div>
              <h5
                style={{ fontWeight: 700 }}
                className="d-flex justify-content-center align-items-center"
              >
                {" Orders completed on this month : " + completedOrders}
              </h5>
              <InfiniteScroller
                loadMore={() => {
                  if (!loadingOrders && loadMore) {
                    this.getOrders(filters);
                  }
                }}
              >
                <div className="table-responsive">
                  <table className="table table-cart">
                    <thead>
                      <tr>
                        <th className="product-image" />
                        <th>Product</th>
                        <th>Attributes</th>
                        <th>Quantity</th>
                        <th>Ordered at</th>
                        <th>Status</th>
                        <th>Delivery Details</th>
                        <th>Order details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {adminOrders.map((order, index) => {
                        let {
                          category,
                          type,
                          brand,
                          subtype,
                          attributes,
                          descriptionAttributes,
                        } = order.sku;
                        let quantity = order.quantity;
                        let { sellerPrice, basePrice, createdAt } = order;
                        let deliveryAddress = order.deliveryAddress;
                        let attributesMap = {};
                        attributes.forEach((item) => {
                          if (!attributesMap[item.key]) {
                            attributesMap[item.key] = item.value;
                          }
                        });
                        return (
                          <tr>
                            <td>
                              <ProductImage
                                images={order.sku.images}
                                subtype={order.sku.subtype}
                              />
                            </td>
                            <td className="product-name">
                              <ProductTitle
                                brand={brand}
                                subtype={subtype}
                                descriptionAttributes={descriptionAttributes}
                                attributes={attributesMap}
                              />
                            </td>
                            <td>
                              <ul>
                                {attributes.map((attribute) => (
                                  <li>
                                    {" "}
                                    {attribute.key}:{attribute.value}
                                  </li>
                                ))}
                                <li>Category:{category}</li>
                                <li>Type:{type}</li>
                              </ul>
                            </td>
                            <td>{quantity}</td>
                            <td>{this.modifyDate(createdAt)}</td>
                            <td>{order.status}</td>
                            <td>
                              {order.buyerName + ", " + deliveryAddress}
                              <div>{order.buyerPhone}</div>
                            </td>
                            <td>
                              <table className="table table-cart">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Sales price</th>
                                    <th>Store/Buyer name</th>
                                    <th>Store/Buyer phone</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Seller - Buildstreet</td>
                                    <td>
                                      ₹{sellerPrice}(
                                      {order.sku.pricing.isTaxIncluded === "Y"
                                        ? "Including tax "
                                        : "Excluding tax "}
                                      and
                                      {order.sku.pricing.isDeliveryIncluded ===
                                      "Y"
                                        ? " Including Delivery charges"
                                        : " Excluding Delivery charges"}
                                      )
                                    </td>
                                    <td>{order.storeName}</td>
                                    <td>{order.storePhone}</td>
                                  </tr>
                                  {order.virtual && (
                                    <React.Fragment>
                                      <tr>
                                        <td>Buildstreet - Reseller</td>
                                        <td>
                                          ₹{basePrice - order.markupPrice}(
                                          {order.sku.pricing.isTaxIncluded ===
                                          "Y"
                                            ? "Including tax "
                                            : "Excluding tax "}
                                          and
                                          {order.sku.pricing
                                            .isDeliveryIncluded === "Y"
                                            ? " Including Delivery charges"
                                            : " Excluding Delivery charges"}
                                          )
                                        </td>
                                        <td>-</td>
                                        <td>-</td>
                                      </tr>
                                      <tr>
                                        <td>Reseller - Customer</td>
                                        <td>
                                          {" "}
                                          ₹{basePrice}(
                                          {order.sku.pricing.isTaxIncluded ===
                                          "Y"
                                            ? "Including tax "
                                            : "Excluding tax "}
                                          and
                                          {order.sku.pricing
                                            .isDeliveryIncluded === "Y"
                                            ? " Including Delivery charges"
                                            : " Excluding Delivery charges"}
                                          )
                                        </td>
                                        <td>{order.resellerStoreName}</td>
                                        <td>{order.buyerPhone}</td>
                                      </tr>
                                    </React.Fragment>
                                  )}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </InfiniteScroller>
            </div>
          ) : (
            <section className="slice sct-color-1">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="text-center">
                      <div className="d-block font-size-3 font-size-md-6 c-gray-light">
                        <i className="fa fa-shopping-bag" aria-hidden="true" />
                      </div>
                      <h5>NO ORDERS</h5>
                      <p className="mt-5 px-5">
                        We have no orders at the moment.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </div>
    );
  }
}

export default AdminOrdersView;
