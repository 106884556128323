import React from 'react'
import SignInFormContainer from './SignInFormContainer'
import UserInfoFormContainer from './UserInfoFormContainer'

export default function (props) {
    return (
        <div>
            <div style={{textAlign: 'center'}}>
                Please sign in or provide contact to initiate chat
            </div>

            <div className="row">
                <SignInFormContainer signIn={props.signIn} error={props.signInError}/>
            </div>

            <div className="row">
                <div style={{textAlign: 'center'}}>-or-</div>
            </div>

            <div className="row">
                <UserInfoFormContainer error={props.contactError}
                                       submit={props.submitContact}/>
            </div>
        </div>
    )
}
