import React from "react";
import Category from "./Category";

export default function() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12" id="product-categories">
          <div style={{ marginTop: "40px", textAlign: "center" }}>
            <h3 className="section-title">
              <span>Product Categories</span>
            </h3>
          </div>
          <section className="">
            <div className="wp-section">
              <div className="container">
                <div className="row">
                  <div style={{ marginTop: "20px", textAlign: "center" }}>
                    <label>
                      Here is a list of product categories that we sell. This
                      list is just for a reference. If you feel any products
                      related to construction is missing, we still can help
                      buying them.
                    </label>
                  </div>
                  <Category />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
