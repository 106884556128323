import React from "react";
import AdminService from "../../../../services/AdminService";
import AdminLeadsCount from "../admin/AdminLeadsCount";
import LeadsHistory from "./LeadsHistory";
import PipeLineLeads from "./PipeLineLeads";
import TodaysLeads from "./TodaysLeads";
import UnAttendedLeads from "./UnAttendedLeads";

class EmployeeLeadsView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      refresh: false,
      view: "tl",
    };
  }

  componentDidMount = () => {
    this.getLeadsCount();
    this.loadOptions();
    this.loadComments();
    this.loadLeadSource();
    this.loadBusinessTypeOptions();
  };

  loadOptions = () => {
    AdminService.getStatusOptions().then((data) => {
      this.setState({ statusOptions: data[0].options });
    });
  };

  loadLeadSource = () => {
    AdminService.getLeadSourceOptions().then((data) => {
      this.setState({ leadSourceOptions: data[0].options });
    });
  };

  loadComments = () => {
    AdminService.getCommentsOptions().then((commentsOptions = []) => {
      this.setState({ commentsOptions });
    });
  };

  loadBusinessTypeOptions = () => {
    AdminService.getBusinessTypeOptions().then((options = []) => {
      this.setState({ businessTypeOptions: options[0].options });
    });
  };

  getLeadsCount = () => {
    AdminService.getEmployeeLeadsCount().then((response) => {
      if (response) {
        let { todaysCount, historyCount, unAttendedCount, pipelineCount } =
          response;
        this.setState({
          todaysCount,
          historyCount,
          unAttendedCount,
          pipelineCount,
        });
      }
    });
  };

  setQueryParameters(attribute, value) {
    let { checkedFilters = {} } = this.state;

    let attrValues = checkedFilters[attribute] || [];
    let index = attrValues.indexOf(value);
    if (index !== -1) {
      attrValues.splice(index, 1);
    } else {
      attrValues.push(value);
    }
    checkedFilters[attribute] = [...attrValues];

    return checkedFilters;
  }

  handleFilterChange = (attribute, value) => {
    let checkedFilters = this.setQueryParameters(attribute, value);
    this.setState(
      {
        checkedFilters: { ...checkedFilters },
      },
      () => {
        if (attribute === "subtype") {
          this.getBrands();
          this.getBrandFilters();
        }
        if (attribute === "brand") {
          this.getBrandFilters();
        }
        this.setState({ currentPage: 1, products: [] }, this.filterProducts);
      }
    );
  };

  showHistory = () => {
    this.setState({ view: "lh" });
  };

  showTodaysLeads = () => {
    this.setState({ view: "tl" });
  };

  showUnattendedLeads = () => {
    this.setState({ view: "ul" });
  };

  showPipeLineLeads = () => {
    this.setState({ view: "pl" });
  };

  showLeadsCount = () => {
    this.setState({ view: "lc" });
  };

  getView = () => {
    let {
      view,
      statusOptions = [],
      commentsOptions = [],
      businessTypeOptions = [],
      leadSourceOptions = [],
    } = this.state;
    if (
      view === "tl" &&
      statusOptions.length >= 1 &&
      commentsOptions.length >= 1 &&
      businessTypeOptions.length >= 1
    ) {
      return (
        <TodaysLeads
          statusOptions={statusOptions}
          commentsOptions={commentsOptions}
          businessTypeOptions={businessTypeOptions}
          leadSourceOptions={leadSourceOptions}
        />
      );
    }

    if (view === "lh") {
      return (
        <div className="my-3 p-3 border border-light shadow">
          <LeadsHistory
            statusOptions={statusOptions}
            commentsOptions={commentsOptions}
            businessTypeOptions={businessTypeOptions}
            leadSourceOptions={leadSourceOptions}
          />
        </div>
      );
    }

    if (view === "ul") {
      return (
        <div className="my-3 p-3 border border-light shadow">
          <UnAttendedLeads
            statusOptions={statusOptions}
            commentsOptions={commentsOptions}
            businessTypeOptions={businessTypeOptions}
            leadSourceOptions={leadSourceOptions}
          />
        </div>
      );
    }

    if (view === "pl") {
      return (
        <div className="my-3 p-3 border border-light shadow">
          <PipeLineLeads
            statusOptions={statusOptions}
            commentsOptions={commentsOptions}
            businessTypeOptions={businessTypeOptions}
            leadSourceOptions={leadSourceOptions}
          />
        </div>
      );
    }

    if (view === "lc") {
      return (
        <div className="my-3 p-3 border border-light shadow">
          <AdminLeadsCount getLeadsCount={this.getLeadsCount} from="employee" />
        </div>
      );
    }
  };

  render() {
    let { view = "tl" } = this.state;
    return (
      <div className="mt-4">
        <div className="tabs tabs--style-2 tabs--centered" role="tabpanel">
          <ul
            className="nav nav-tabs justify-content-center d-flex"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <a
                className={
                  view === "lh"
                    ? "nav-link active text-center text-uppercase tab-link"
                    : "nav-link text-center text-uppercase tab-link"
                }
                onClick={this.showHistory}
              >
                {"Leads History " + " ( " + this.state.historyCount + " ) "}
              </a>
            </li>

            <li className="nav-item" role="presentation">
              <a
                className={
                  view === "tl"
                    ? "nav-link active text-center text-uppercase tab-link"
                    : "nav-link text-center text-uppercase tab-link"
                }
                onClick={this.showTodaysLeads}
              >
                {"Today's Leads" + " ( " + this.state.todaysCount + " ) "}
              </a>
            </li>

            <li className="nav-item" role="presentation">
              <a
                className={
                  view === "ul"
                    ? "nav-link active text-center text-uppercase tab-link"
                    : "nav-link text-center text-uppercase tab-link"
                }
                onClick={this.showUnattendedLeads}
              >
                {"Unattended Leads" +
                  " ( " +
                  this.state.unAttendedCount +
                  " ) "}
              </a>
            </li>

            <li className="nav-item" role="presentation">
              <a
                className={
                  view === "pl"
                    ? "nav-link active text-center text-uppercase tab-link"
                    : "nav-link text-center text-uppercase tab-link"
                }
                onClick={this.showPipeLineLeads}
              >
                {" Pipeline Leads" + " ( " + this.state.pipelineCount + " ) "}
              </a>
            </li>

            <li className="nav-item" role="presentation">
              <a
                className={
                  view === "lc"
                    ? "nav-link active text-center text-uppercase tab-link"
                    : "nav-link text-center text-uppercase tab-link"
                }
                onClick={this.showLeadsCount}
              >
                Leads count
              </a>
            </li>
          </ul>
        </div>

        <div className="mt-2">{this.getView()}</div>
      </div>
    );
  }
}

export default EmployeeLeadsView;
