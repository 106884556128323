import React from "react";
import Select from "react-select";
import LocationFinder from "./LocationFinder";
class SingleServiceAreaForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {
      districtOptions,
      serviceArea = {},
      zoneOptions,
      localitiesOptions,
      storeId,
    } = this.props;
    return (
      <div>
        <div className="mr-1" style={{ width: "200px" }}>
          <LocationFinder
            onChangeState={this.props.onChangeState}
            onChangeDistrict={this.props.onChangeDistrict}
            onChangeZone={this.props.onChangeZone}
            onChangeLocality={this.props.onChangeLocality}
            storeId={storeId}
          />
        </div>

        <div className="mr-1" style={{ width: "200px" }}>
          <Select
            className="flex-fill mb-1"
            placeholder="State"
            options={this.props.stateOptions}
            value={
              serviceArea.state
                ? {
                    label: serviceArea.state,
                    value: serviceArea.state,
                  }
                : null
            }
            onChange={this.props.onChangeState}
            isMulti={false}
          />
          {serviceArea.state && (
            <Select
              className="flex-fill mb-1"
              placeholder="District"
              options={districtOptions}
              value={
                serviceArea.district
                  ? {
                      label: serviceArea.district,
                      value: serviceArea.district,
                    }
                  : null
              }
              onChange={this.props.onChangeDistrict}
              isMulti={false}
            />
          )}
        </div>
        {serviceArea.district && (
          <div className="mr-1" style={{ width: "200px" }}>
            <Select
              className="flex-fill mb-1"
              placeholder="Zone"
              options={zoneOptions}
              value={
                serviceArea.zone
                  ? {
                      label: serviceArea.zone,
                      value: serviceArea.zone,
                    }
                  : null
              }
              onChange={this.props.onChangeZone}
            />
          </div>
        )}

        {serviceArea.zone && (
          <div className="mr-1" style={{ width: "200px" }}>
            <Select
              className="flex-fill"
              placeholder="Locality"
              options={localitiesOptions}
              value={
                serviceArea.locality
                  ? {
                      label: serviceArea.locality,
                      value: serviceArea.locality,
                    }
                  : null
              }
              onChange={this.props.onChangeLocality}
            />
          </div>
        )}
      </div>
    );
  }
}

export default SingleServiceAreaForm;
