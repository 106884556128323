import React from "react";
import history from "../../../../utils/History";

class ProjectsView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { projects: [] };
  }

  openProject = projectId => {
    history.push("/dashboard/projects/" + projectId + "/r");
  };

  startCreateProject = () => {
    history.push("/dashboard/projects/new");
  };

  render() {
    let { loading, projects = [] } = this.props;

    // if (loading) {
    //   return <Spinner />;
    // }

    return (
      <div>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h3 className="mb-0">Your Projects</h3>

          <a className="btn btn-primary" onClick={this.startCreateProject}>
            Create Project
          </a>
        </div>

        {projects.length >= 1 ? (
          <div className="row">
            {projects.map(project => {
              return (
                <div className="col-md-4">
                  <div className="card">
                    <div
                      className="card-body"
                      onClick={() => {
                        this.openProject(project._id);
                      }}
                      style={{
                        cursor: "pointer"
                      }}
                    >
                      <h3 className="heading heading-5 strong-500">
                        {project.name}
                      </h3>
                      <span className="badge bg-base-1 text-uppercase mb-3">
                        {project.status || "ACTIVE"}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center">
                <p className="mt-5 px-5" style={{ fontSize: 16 }}>
                  You have not created any projects yet. Click{" "}
                  <a className="btn-link" onClick={this.startCreateProject}>
                    here
                  </a>{" "}
                  to create a project.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ProjectsView;
