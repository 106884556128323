import React from "react";
import LinkToModal from "./../../widgets/modal/LinkToModal";

class BidRequestStatus extends React.Component {
  render() {
    var status = this.props.request ? this.props.request.status : null;
    if (!status) {
      return null;
    }
    var statusValue = null;
    if (status === "OPEN") {
      statusValue = "Open";
    } else if (status === "BID_CONFIRMED") {
      statusValue = "Bid Confirmed";
    } else if (status === "CLOSED") {
      statusValue = (
        <span
          dataToggle="tooltip"
          dataPlacement="top"
          title={this.props.request.comments}
        >
          Closed
        </span>
      );
    } else if (status === "ORDER") {
      statusValue = <span>Order Created</span>;
    }

    return (
      <span
        className="label label-info pull-right"
        style={{ marginRight: "10px", padding: "5px" }}
      >
        {statusValue}
        {status === "ORDER" && (
          <span>
            {" "}
            -{" "}
            <LinkToModal
              modalType="BID_REQUEST_ORDER"
              style={{ color: "#fff" }}
              modalProps={{
                requestId: this.props.request._id,
                requestNumber: this.props.request.number
              }}
            >
              View
            </LinkToModal>
          </span>
        )}
      </span>
    );
  }
}

export default BidRequestStatus;
