import React from "react";
import { connect } from "react-redux";
import StoreService from "../../../../services/StoreService";
import StoresView from "./StoresView";
import {toastMessage} from "../../../../state/actions/toaster";

class StoresViewContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.loadStores();
  }

  loadStores = () => {
    StoreService.fetchStores()
      .then(response => {
        this.setState({
          stores: response.stores,
          virtualStores: response.virtualStores
        });
      })
      .catch(e =>
        this.props.toastMessage(
          "Unable to fetch Stores now. Please try again later",
          "error",
          10
        )
      );
  };

  render() {
    let { stores, virtualStores, loading } = this.state;

    return (
      <StoresView
        stores={stores}
        virtualStores={virtualStores}
        loading={loading}
        refreshStore={this.loadStore}
      />
    );
  }
}

function actions(dispatch) {
  return {
    toastMessage: (message, type, duration) =>
      dispatch(toastMessage(message, type, duration))
  };
}

export default connect(
  null,
  actions
)(StoresViewContainer);
