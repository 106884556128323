import React from "react";
import PropTypes from "prop-types";
export const Checkbox = ({
  type = "checkbox",
  name,
  checked = false,
  onChange,
  className,
  style,
}) => (
    <input
      type={type}
      name={name}
      checked={checked}
      onChange={onChange}
      className={className}
      style={style}
    />

  );
Checkbox.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};
