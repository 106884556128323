import React, { Component } from "react";
import ProductAttributes from "../../Shop/ProductAttributes";
import ProductImage from "../../Shop/ProductImage";
import ProductTitle from "../../Shop/ProductTitle";

function ProjectNonServiceableReqs({ nonServiceable }) {
  let { attributes, descriptionAttributes, notes } = nonServiceable;
  let attributesMap = {};
  attributes.forEach((item) => {
    if (!attributesMap[item.key]) {
      attributesMap[item.key] = item.value;
    }
  });
  return (
    <div className="col-lg-4  mb-2">
      <div className="card card-product z-depth-1-top z-depth-2--hover">
        <div className="card-body" style={{ padding: "1rem" }}>
          <span
            className="badge text-uppercase"
            style={{
              position: "absolute",
              top: "-10px",
              right: "0px",
              backgroundColor: "#c89034",
              fontWeight: 400,
            }}
          >
            Unavailable
          </span>

          <ProductTitle
            brand={nonServiceable.brand}
            subtype={nonServiceable.subtype}
            descriptionAttributes={descriptionAttributes}
            attributes={attributesMap}
          />

          <ProductImage
            images={nonServiceable.images}
            subtype={nonServiceable.subtype}
          />

          <div className="mt-2">
            <ProductAttributes
              notes={notes}
              attributes={nonServiceable.attributes}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectNonServiceableReqs;
