import React from "react";

export default function(props) {
  return (
    <div
      id="bs-spinner"
      className="loader"
      style={{
        display: "none",
        position: "absolute",
        left: "50%",
        zIndex: 99999
      }}
    >
      Loading...
    </div>
  );
}
