/**
 * @flow
 */

"use strict";

const initialState = { message: null, type: null, duration: 3 };

function toaster(state = initialState, action = null) {
  if (action.type === "NEW_TOAST") {
    return {
      message: action.data.message,
      type: action.data.type,
      duration: action.data.duration
    };
  }

  if (action.type === "CLEAR_TOAST") {
    return initialState;
  }

  return state;
}
export default toaster;
