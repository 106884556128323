import React from "react";
import Shop from "../../Shop/Shop";
// import VirtualBuyerService from "../../../../services/VirtualBuyerService";
import ProjectService from "./../../../../services/ProjectService";
import Actions from "./../../../../state/actions";
import { connect } from "react-redux";
import StoreService from "../../../../services/StoreService";

class ShopWrapper extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { products: [] };
    this.state = { productsForOrders: [] };
  }

  validate = () => {
    return true;
  };

  componentDidMount() {
    let { store } = this.props;
    if (store && store.virtual) {
      StoreService.fetchVirtualStoreStockIds(this.props.store._id)
        .then((virtualStockIds = []) => {
          this.setState({ virtualStockIds, loading: false });
        })
        .catch(e => this.setState({ loading: false }));
    }
  }

  addProjectRequirements = product => {
    ProjectService.addProjectRequirements(
      this.props.projectId,
      product._id
    ).then(updated => {
      if (updated) {
        this.props.toastMessage("Requirement added", "success", 5);
      } else {
        this.props.toastMessage(
          "Product is already part of the project",
          "error",
          5
        );
      }
    });
  };

  addProduct = product => {
    let { products, virtualStockIds } = this.state;
    let { store } = this.props;
    products = products || [];

    if (store && store.virtual && virtualStockIds.length > 0) {
      if (virtualStockIds.indexOf(product._id) >= 0) {
        this.props.toastMessage(
          "Product is already in your stocks",
          "warning",
          3
        );
        return;
      }
    }

    if (
      !this.props.stocks &&
      products.filter(obj => obj.sku._id === product._id).length > 0
    ) {
      this.props.toastMessage(
        "Product has already been selected",
        "warning",
        3
      );
    } else {
      if (this.props.stocks) {
        this.addProjectRequirements(product);
      } else {
        products.push({ sku: product });
      }
    }
    this.setState({ products: [...products] });
  };

  getSelectedProducts = () => {
    let { products } = JSON.parse(JSON.stringify(this.state));
    return products;
  };

  render() {
    let { products = [] } = this.state;

    return (
      <div>
        {this.props.goToNext && this.props.user !== "admin" && (
          <div
            className="d-flex justify-content-end"
            style={
              products.length > 0
                ? {
                    position: "absolute",
                    top: "200px",
                    right: "30px",
                    zIndex: 1050
                  }
                : {}
            }
          >
            <a
              style={{ fontSize: "16px" }}
              className={
                products.length > 0 ? "btn btn-success effect" : "btn btn-link"
              }
              onClick={this.props.goToNext}
            >{`${products.length} Product(s) selected`}</a>
          </div>
        )}
        <Shop
          user={this.props.user}
          stocks={this.props.stocks}
          selectProduct={this.addProduct}
          projectId={this.props.projectId}
        />
      </div>
    );
  }
}

const actions = {
  toastMessage: Actions.toastMessage
};

export default connect(
  null,
  actions,
  null,
  { forwardRef: true }
)(ShopWrapper);
