import React from "react";
import ProjectService from "./../../../../services/ProjectService";
import ProjectsView  from "./ProjectsView";

class ProjectsViewContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    ProjectService.fetchProjects().then((projects) => {
      this.setState({ projects });
    });
  }

  render() {
    let { projects = [] } = this.state;
    return (
            <ProjectsView
            projects={projects}
            />
    );
  }
}

export default ProjectsViewContainer;
