import React, { Component } from "react";
import { connect } from "react-redux";

// function ProductAttributes({ attributes = [] }) {
//   return (
//     <div style={{ height: "70px", overflow: "auto" }}>
//       {attributes.map((attribute, index) => (
//         <span>
//           {attribute.key}:{" "}
//           <span style={{ fontWeight: 400 }}>
//             {attribute.value + (index < attributes.length - 1 ? ", " : "")}
//           </span>{" "}
//         </span>
//       ))}
//     </div>
//   );
// }

class ProductAttributes extends Component {
  render() {
    let { notes, attributes = [], attributeLabels = {} } = this.props;
    return (
      <div className="mt-1" style={{ height: "70px", overflow: "auto" }}>
        {attributes.map((attribute, index) => (
          <span>
            <span style={{ whiteSpace: "nowrap" }}>
              {attributeLabels[attribute.key] || attribute.key}:{" "}
            </span>
            <span style={{ fontWeight: 400, whiteSpace: "nowrap" }}>
              {attribute.value + (index < attributes.length - 1 ? ", " : "")}
            </span>{" "}
          </span>
        ))}

        {notes && <div>{notes}</div>}
      </div>
    );
  }
}

function data(state) {
  return {
    attributeLabels: state.sku.attributeLabels,
  };
}

export default connect(data)(ProductAttributes);

// export default ProductAttributes;
