export function isEmpty(string) {
  if (!string || string.trim().length === 0) {
    return true;
  }
  return false;
}

export function isNotEmpty(string) {
  return !isEmpty(string);
}

export function calculateBidValue(unitPrice, quantity, delivery) {
  var total = unitPrice * quantity;
  if (delivery) {
    total += delivery;
  }
  return total;
}

export function convertToPaisa(amount) {
  return amount * 100;
}

export function getAmountPayable(quantity, bid) {
  var value = 0;
  if (bid.deliveryCharges) {
    value += bid.deliveryCharges;
  }
  var serviceTax = getServiceTax(value);
  var amount = quantity * bid.unitPrice + value + serviceTax;
  return Math.round(amount);
}

export function getServiceTax(value) {
  if (!value) {
    return 0;
  }
  // var serviceTax = parseFloat((value * 0.145).toFixed(2));
  var serviceTax = 0;
  return serviceTax;
}

export function getConfirmedBid(confirmedBidId, bids) {
  var bid;
  for (var i = 0; i < bids.length; i++) {
    if (bids[i]._id === confirmedBidId) {
      bid = bids[i];
      break;
    }
  }
  return bid;
}
export function getDateFormat(dateObj) {
  var date = new Date(dateObj);
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return dd + "/" + mm + "/" + yyyy;
}

export function getMinMaxPrice(stocks) {
  if (!stocks && stocks.length === 0) {
    return null;
  }

  let min = stocks[0].price ? stocks[0].price.value : Number.POSITIVE_INFINITY;
  let max = stocks[0].price ? stocks[0].price.value : 0;
  let minPriceIndex = 0;
  for (let i = 1; i < stocks.length; i++) {
    let stock = stocks[i];
    if (stock.price && stock.price.value) {
      let value = stock.price.value;
      if (value < min) {
        min = value;
        minPriceIndex = i;
      }
      if (value > max) {
        max = value;
      }
    }
  }
  return { min, max, minPriceIndex };
}
export function getMinMaxQty(stocks) {
  if (stocks && stocks.length === 0) {
    return { min: 0, max: 0 };
  }
  let multiple = stocks[0].serviceQty ? stocks[0].serviceQty.multiple : 0;
  let min = stocks[0].serviceQty
    ? stocks[0].serviceQty.min
    : Number.POSITIVE_INFINITY;

  for (let i = 1; i < stocks.length; i++) {
    let stock = stocks[i];
    if (stock.serviceQty && stock.serviceQty.min) {
      if (stock.serviceQty.min < min) {
        min = stock.serviceQty.min;
        multiple = stock.serviceQty.multiple;
      }
    }
  }
  return { min, multiple };
}

export function getBestPrice(qty, stocks) {
  if (stocks && stocks.length === 0) {
    return "NA";
  }

  let filtered = stocks.filter(stock => {
    let serviceQty = stock.serviceQty;
    return qty >= serviceQty.min;
  });

  let price = getMinMaxPrice(filtered);
  return price ? price.min * qty : "NA";
}

export function getBestStock(qty, stocks) {
  if (stocks && stocks.length === 0) {
    return "NA";
  }

  let filtered = stocks.filter(stock => {
    let serviceQty = stock.serviceQty;
    return qty >= serviceQty.min;
  });

  if (!filtered || filtered.length === 0) {
    return null;
  }

  let best = getMinMaxPrice(filtered);
  return filtered[best.minPriceIndex];
}

export function getAvailableQuantity(qty, bestStock) {
  let minAvailableQuantity =
    Math.floor(qty / bestStock.serviceQty.multiple) *
    bestStock.serviceQty.multiple;
  let maxAvailableQuantity =
    minAvailableQuantity + bestStock.serviceQty.multiple;
  return { minAvailableQuantity, maxAvailableQuantity };
}

export function formatLeadDate(date, from) {
  let now = new Date(date);
  let time;
  date = date.substr(0, date.indexOf("T"));
  date = date
    .split("-")
    .reverse()
    .join("-");
  if (from) {
    time = now.toLocaleTimeString();
    return date + "/" + time;
  }
  return date;
}
