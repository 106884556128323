/**
 * @flow
 */

"use strict";

function toastMessage(message, type, duration) {
  return {
    type: "NEW_TOAST",
    data: { message: message, type: type, duration: duration }
  };
}

function clearMessage() {
  return {
    type: "CLEAR_TOAST"
  };
}

export { toastMessage, clearMessage };
