import React from "react";
import { connect } from "react-redux";
import ModalComponentHandler from "./ModalComponentHandler";
import responsive from "../../utils/Responsive";
import Actions from "../../state/actions";


class Modal extends React.Component {
  closeModal = () => {
    //this.props.hideModal()
    this.close();
  };

  componentWillReceiveProps(n, o) {
    if (n.modalType) {
      window
        .$("#bsModal")
        .modal({ show: true, backdrop: "static", keyboard: false });
    } else {
      window.$("#bsModal").modal("hide");
    }
  }

  getHeight = () => {
    return responsive.getPageHeight();
  };

  close = () => {
    var hook = this.props.modalProps
      ? this.props.modalProps.onCompleteHook
      : null;
    if (hook) {
      hook();
    }
    this.props.hideModal();
  };

  render() {
    var modalClass =
      this.props.modalSize === "lg" ? "modal-dialog modal-lg" : "modal-dialog";
    return (
      <div className="modal fade" id="bsModal" tabIndex="-1" role="dialog">
        <div className={modalClass} role="document" style={this.props.style}>
          <div className="modal-content">
            <div className="modal-header" style={{ borderBottom: "0px" }}>
              <a type="button" className="close" onClick={this.closeModal}>
                <span>x</span>
              </a>
            </div>
            <div
              className="modal-body"
              style={{
                minHeight: "250px",
                maxHeight: this.getHeight(),
                overflowY: "auto"
              }}
            >
              <ModalComponentHandler
                modalType={this.props.modalType}
                modalProps={this.props.modalProps}
                onCompleteHook={this.close}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function data(state) {
  var modal = state.modal;
  return {
    modalType: modal.modalType,
    modalProps: modal.modalProps,
    modalSize: modal.modalSize
  };
}

var actions = function(dispatch) {
  return {
    hideModal: () => dispatch(Actions.hideModal())
  };
};

export default connect(
  data,
  actions
)(Modal);
