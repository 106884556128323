import React from "react";
import { connect } from "react-redux";
import ProjectService from "./../../../../services/ProjectService";
import ProjectView from "./ProjectView";
import history from "../../../../utils/History";
import Actions from "./../../../../state/actions";

class ProjectViewContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.loadProject();
  }

  loadProject = () => {
    ProjectService.fetchProject(this.props.match.params.projectId)
      .then(response => {
        this.setState({ project: response, loading: false });
      })
      .catch(e => this.setState({ loading: false }));
  };

  deleteProject = () => {
    ProjectService.deleteProject(this.props.match.params.projectId).then(
      response => {
        history.push("/dashboard/projects");
        this.props.toastMessage("Project has been deleted.", "success", 5);
      }
    );
  };

  render() {
    let { project, loading } = this.state;
    return (
      <ProjectView
        project={project}
        loading={loading}
        deleteProject={this.deleteProject}
        view={this.props.match.params.view}
      />
    );
  }
}

const actions = {
  toastMessage: Actions.toastMessage
};

export default connect(
  null,
  actions
)(ProjectViewContainer);
