import React from "react";
import { Link } from "react-router-dom";
import LinkToModal from "../../widgets/modal/LinkToModal";
import OrderStatus from "./OrderStatus";

class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getProductTitle = product => {
    var label;
    if (
      product.category &&
      (product.brand || product.productType || product.variant)
    ) {
      label = product.category + "/";
    } else if (!product.category) {
      label = "";
    } else {
      label = product.category;
    }
    if (product.brand && (product.productType || product.variant)) {
      label += product.brand + "/";
    } else if (!product.brand) {
      label += "";
    } else {
      label += product.brand;
    }
    if (product.productType && product.variant) {
      label += product.productType + "/";
    } else if (!product.productType) {
      label += "";
    } else {
      label += product.productType;
    }
    if (product.variant) {
      label += product.variant;
    }
    return label;
  };

  render() {
    var order = this.props.order;
    if (!order) {
      return null;
    }
    return (
      <div
        className="panel panel-default panel-sidebar-1"
        style={{ marginTop: "10px" }}
      >
        <div className="panel-heading" style={{ padding: "10px" }}>
          <Link to={"dashboard/orders/" + order._id} className="btn-link">
            {order.rProduct && (
              <span style={{ fontWeight: "bold" }}>
                {this.getProductTitle(order.rProduct)}
              </span>
            )}
            <span style={{ fontWeight: "bold", float: "right" }}>
              {order.number}
            </span>
            {!order.rProduct && order.product && (
              <span style={{ fontWeight: "bold" }}>
                {order.product.category}/{order.product.brand}/
                {order.product.name}
              </span>
            )}
          </Link>
        </div>

        <div className="panel-body">
          <div className="row">
            <div className="col-md-8">
              <p>
                {order.request.quantity.value +
                  " " +
                  order.request.quantity.unit}
              </p>

              <p>{order.paymentMethod}</p>

              <p>
                <OrderStatus status={order.status} />
              </p>
            </div>
            <div className="col-md-4 bl" style={{ padding: "4px" }}>
              <div>
                <LinkToModal
                  modalType="BID_REQUEST"
                  modalProps={{
                    request: order.request,
                    rProduct: order.rProduct
                  }}
                >
                  Bid Request
                </LinkToModal>
              </div>

              <div>
                <LinkToModal
                  modalType="BID"
                  modalSize="lg"
                  modalProps={{ request: order.request }}
                >
                  Bid Details
                </LinkToModal>
              </div>

              <div>
                <LinkToModal
                  modalType="DELIVERY_ADDRESS"
                  modalSize="lg"
                  modalProps={{ address: order.deliveryAddress }}
                >
                  Delivery Address
                </LinkToModal>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Order;
