import React from "react";

class UsersFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filters: {} };
  }

  onChangeUserName = e => {
    let filters = this.state.filters;
    filters.name = e.target.value || null;
    this.setState({ filters: { ...filters } });
  };

  onChangeEmail = e => {
    let filters = this.state.filters;
    filters.email = e.target.value || null;
    this.setState({ filters: { ...filters } });
  };

  onChangePhone = e => {
    let filters = this.state.filters;
    filters.phone = e.target.value || null;
    this.setState({ filters: { ...filters } });
  };

  clearFilters = () => {
    this.setState({ showClearFilter: false, filters: {} });
  };

  filterUsers = () => {
    let filters = this.state.filters;

    if (!filters.name && !filters.email && !filters.phone) {
      this.setState({ noFilterError: true });
      return;
    }

    filters = JSON.parse(JSON.stringify(filters));
    this.setState({ showClearFilter: true, noFilterError: false });
    this.props.onChangeFilters(filters);
  };

  render() {
    let { showClearFilter, filters, noFilterError } = this.state;
    return (
      <div className="d-flex flex-column">
        {noFilterError && (
          <div className="alert alert-danger">
            At least one filter is required.
          </div>
        )}

        <div
          className="d-flex justify-content-between sidebar sidebar--style-4 z-depth-1-top mb-2"
          style={{ padding: "1rem" }}
        >
          <div>
            <div className="mb-1" style={{ fontWeight: 500, fontSize: "14px" }}>
              User name
            </div>
            <input
              type="text"
              className="form-control mainLoginInput"
              onChange={this.onChangeUserName}
              value={filters.name || ""}
            />
          </div>

          <div>
            <div className="mb-1" style={{ fontWeight: 500, fontSize: "14px" }}>
              Email
            </div>
            <input
              type="text"
              className="form-control mainLoginInput"
              onChange={this.onChangeEmail}
              value={filters.email || ""}
            />
          </div>

          <div>
            <div className="mb-1" style={{ fontWeight: 500, fontSize: "14px" }}>
              Phone
            </div>
            <input
              type="text"
              className="form-control mainLoginInput"
              onChange={this.onChangePhone}
              value={filters.phone || ""}
            />
          </div>

          <div className="d-flex align-items-end">
            {showClearFilter && (
              <div
                style={{ cursor: "pointer" }}
                className="d-flex align-items-center justify-content-center"
                onClick={this.clearFilters}
              >
                <a className="fa fa-times text-danger mr-1" />
                <div className="text-danger mr-1">Clear filters</div>
              </div>
            )}
            <button className="btn btn-primary" onClick={this.filterUsers}>
              Filter
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default UsersFilter;
