import React from "react";

class UserBanner extends React.Component {
  render() {
    var user = this.props.user;
    if (!user) {
      return null;
    }
    return <div className="user-name">{user.name}</div>;
  }
}

export default UserBanner;
