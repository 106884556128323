import React from "react";
import ModalN from "../../../../widgets/modal/ModalN";
import DetailedLeadView from "../DetailedLeadView";
import { formatLeadDate } from "../../../../utils/Utils";
import AdminService from "../../../../services/AdminService";
import { connect } from "react-redux";
import Actions from "./../../../../state/actions";

class LeadDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  showPreviousCallLog = (logHistory) => {
    this.setState({ showPreviousCallLogModal: true, logHistory: logHistory });
  };

  hidePreviousCallLogModal = () => {
    this.setState({ showPreviousCallLogModal: false });
  };

  showAllDetails = () => {
    this.setState({ showAllDetails: true });
  };

  hideAllDetails = () => {
    this.setState({ showAllDetails: false });
  };

  updateNextCallDate = (leadId) => {
    // let { when, selectedLeads } = this.state;
    AdminService.updateNextCallDate("today", leadId).then((response) => {
      if (response) {
        this.props.toastMessage("Next call date updated.", "success", 3);
        // this.hideUpdateNextCallDate();
        this.props.setFilters();
      }
    });
  };

  render() {
    let { lead, fromComponent } = this.props;
    let {
      leadSource,
      name,
      followUpType,
      status,
      createdAt,
      phone,
      email,
      serviceArea = {},
      comment,
      updatedOn,
      nextCallDate,
      logHistory,
    } = lead;
    let leadId = lead._id;
    return (
      <tr>
        <td>{name ? name : "NA"}</td>
        <td>{followUpType}</td>
        <td>
          <div>{status ? status : "NA"}</div>
        </td>

        <td style={{ width: 170 }}>
          <div>{comment ? comment : "NA"}</div>
        </td>

        <td>{phone}</td>
        <td>{email ? email : "NA"}</td>
        <td>
          {serviceArea && Object.keys(serviceArea).length > 0 && (
            <div>
              {(serviceArea.locality ? serviceArea.locality + ", " : "") +
                (serviceArea.zone ? serviceArea.zone + ", " : "") +
                (serviceArea.district ? serviceArea.district : "")}
            </div>
          )}
          {(!serviceArea || Object.keys(serviceArea).length === 0) && (
            <span>Not available</span>
          )}
        </td>

        <td>{createdAt ? formatLeadDate(createdAt, "from") : "-"}</td>
        <td>
          {status === "junk"
            ? "Junk Lead"
            : nextCallDate
            ? formatLeadDate(nextCallDate)
            : "Not available"}
        </td>

        <td>
          {logHistory && logHistory.length >= 1 ? (
            <div>
              <div
                className="btn-link"
                onClick={() => {
                  this.showPreviousCallLog(logHistory);
                }}
              >
                View
              </div>
            </div>
          ) : (
            <div>Not available</div>
          )}
        </td>
        {this.props.fromComponent === "tl" && status !== "junk" && (
          <td>
            <i
              className="fa fa-pencil-square-o "
              onClick={() =>
                this.props.showEditLead(
                  JSON.parse(JSON.stringify(lead)),
                  this.props.index
                )
              }
            />
          </td>
        )}

        {this.props.fromComponent === "tl" && status === "junk" && (
          <td>JUNK LEAD</td>
        )}

        <td>
          <a className="btn-link" onClick={this.showAllDetails}>
            View
          </a>
        </td>

        {fromComponent === "pl" && (
          <td>
            <a
              className="btn-link"
              onClick={() => {
                this.updateNextCallDate(leadId);
              }}
            >
              Today
            </a>
          </td>
        )}

        <td>{leadSource}</td>

        {this.state.showPreviousCallLogModal && (
          <ModalN
            title="Previous call log"
            show={this.state.showPreviousCallLogModal}
            close={this.hidePreviousCallLogModal}
          >
            {this.state.logHistory && this.state.logHistory.length > 0 && (
              <div>
                <table className="table table-cart">
                  <thead>
                    <tr>
                      <th>Updated on</th>
                      <td>Status</td>
                      <th>Comments</th>
                      <th>Next call date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.logHistory.map((log, index) => (
                      <tr>
                        <td>
                          {log.updatedOn ? formatLeadDate(log.updatedOn) : "NA"}
                        </td>
                        <td>{log.status ? log.status : "NA"}</td>
                        <td>{log.comment ? log.comment : "NA"}</td>
                        <td>
                          {log.nextCallDate
                            ? formatLeadDate(log.nextCallDate)
                            : "NA"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {!this.state.logHistory ||
              (this.state.logHistory.length === 0 && (
                <div>No previous log</div>
              ))}
          </ModalN>
        )}
        {this.state.showAllDetails && (
          <ModalN
            title="Lead details"
            show={this.state.showAllDetails}
            close={this.hideAllDetails}
          >
            <DetailedLeadView lead={lead} />
          </ModalN>
        )}
      </tr>
    );
  }
}

const actions = {
  toastMessage: Actions.toastMessage,
};

export default connect(null, actions)(LeadDisplay);
